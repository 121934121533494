import { Grid } from "@mui/material"
import React from "react"
import Calendar from "react-calendar"

function MonthSelection ({
    selectedDate,
    onChangeCalendar,
    closeCalendar
}) {
    
    return (
        <Grid p={1}>
            {/* <TCTitleLabel>Calendar</TCTitleLabel> */}
            <Calendar
                maxDetail="year"
                minDetail="year"
                className={"react-calendar"}
                onChange={(e) => {
                    // if (e >= getDate(user)) {
                    //     setOpenPackModal({
                    //         open: true,
                    //         months: (e.getMonth() + 1) - (new Date().getMonth() + 1)
                    //     })
                    //     return
                    // }
                    // let bookingInfo = checkBookingHours(e)
                    // onChange({
                    //     selectedDate: toLocalGMT(e),
                    //     date: toGMT7(e), 
                    //     ...bookingInfo
                    //     // hours: hours, 
                    //     // totalSessions: 0,
                    //     // totalPrice: 0
                    // });
                    onChangeCalendar(e <= new Date() ? new Date() : e)
                    closeCalendar(false)
                }}
                tileClassName={(({date}) => {
                    // if (date >= getDate(user)) {
                    //     return 'disabled-tile'
                    // }
                })}
                prev2Label={null}
                next2Label={null}
                // prevLabel={ new Date().getFullYear() > moment(selectedDate).toDate().getFullYear() ? "‹" : ""}
                // nextLabel={"›"}
                // minDate={new Date()}
                // maxDate={new Date(moment(addDays(new Date(), user ? (user.package && user.package.advancedDays) ? user.package.advancedDays : 31 : 31)).endOf('month'))} // Count by Days
                // maxDate={new Date(moment(addMonths(new Date(), user ? (user.activeBenefits && user.activeBenefits.advancedMonths) ? user.activeBenefits.advancedMonths : 0 : 0)).endOf('month'))} // Count by Months
                defaultValue={selectedDate} 
                // value={form.selectedDate}
                showNavigation={true}
                // activeStartDate={new Date(`${form.month}-01-${form.year}`)}
                // activeStartDate={new Date(form.year, (form.month - 1), 1)}
            />
        </Grid>
    )
}

export default MonthSelection
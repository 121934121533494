import React from "react"
import { Grid, Card } from "@mui/material"
import { TCTitleLabel, TCLabel } from "components/TopCourtComponents/Label/TopCourtLabel"
import MKButton from "components/MKButton"
import { color } from "helper/constant"
import { currencyFormat } from "helper/numberFormat"
import { useNavigate } from "react-router-dom"
import { ConfirmationOneButton } from "helper/showAlert"
import { courtTypeObjVoucher } from "helper/constant"

function PackCard ({
    value,
    onClickBuy
}) {

    const navigate = useNavigate()

    return (
        <Grid item xs={12}>
            <Card sx={{padding: 0, mb: 2}} onClick={() => {
                console.log('here value', value)
                if (value.packageWaitingPayment && !value.soldOut) {
                    // Action("", "", () => {}, , "", "Pay Now")
                    ConfirmationOneButton("", "Please complete your pending payment first", 
                    "Pay Now", () => {
                        navigate(`/payment/${value.packageWaitingPayment}/value-pack`)
                    })
                }
            }}>
                <Grid sx={{
                    p: 2,
                    pb: 0,
                    borderTopLeftRadius: 16,
                    borderTopRightRadius: 16
                }} style={{backgroundColor: 'white'}}>
                    <TCTitleLabel style={{fontSize: 18, color: color.primary}}>{value.name}</TCTitleLabel>
                    <TCLabel style={{color: color.primary}}>{value.totalSession} {courtTypeObjVoucher[value.court]} Sessions. Package validity is {value.validity} months</TCLabel>
                    {/* <TCLabel style={{color: color.primary}}>Active for {value.validity} Months</TCLabel> */}
                    <TCLabel style={{color: color.primary, fontWeight: 300}}>
                        <div dangerouslySetInnerHTML={{__html: value.description}}/>
                    </TCLabel>
                </Grid>
                <Grid sx={{
                    p: 2, pb: 1
                }} container justifyContent={"center"} alignItems={"center"} textAlign={"left"}>
                    <Grid xs={6} container item>
                        <Grid item xs={12}>
                            {/* <TCLabel style={{color: color.blue, fontSize: 12}}>Starting From</TCLabel> */}
                            <TCLabel style={{color: color.blue, fontSize: 16, fontWeight: 'bold'}}>{currencyFormat(value.price)}</TCLabel>    
                        </Grid>
                    </Grid>
                    <Grid xs={6} item>
                        <MKButton sx={{fontSize: 10, p: 0}} disabled={value.soldOut || value.packageWaitingPayment} fullWidth color={"primary"} onClick={() => 
                            {
                                if (value.soldOut) return;
                                onClickBuy(value)
                            }
                        }>{value.soldOut ? "Sold Out" : "Buy"}</MKButton>
                    </Grid>
                </Grid>
            </Card>
        </Grid>
    )
}

export default PackCard
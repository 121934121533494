import React from "react"
import { Modal, Box } from "@mui/material"
import { TCTitleLabel } from "../Label/TopCourtLabel"
import { centerBox } from 'helper/constant';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";


function ImagesDetail ({
    open,
    setOpen,
    disableZoom,
    src
}) {
    return (
        <Modal 
            open={open}
            onClose={() => {
                setOpen(false)
            }}
        >
            <Box style={{
                ...centerBox,
                width: window.innerWidth >= 444 ? '444px' : '100%'
            }}>
                <div style={{display: 'flex', width: '100%', justifyContent: 'flex-end'}}>
                    <div style={{padding: 6}} onClick={() => {
                        setOpen(false)
                    }}><TCTitleLabel style={{color: 'white'}}>Close X</TCTitleLabel></div>
                </div>
                {
                    disableZoom &&
                    <img src={src} width={window.innerWidth >= 444 ? '444px' : '100%'} height={'auto'} style={{
                        pointerEvents: 'none',
                        maxHeight: '80vh'
                    }}/>
                }
                {
                    !disableZoom &&
                    <TransformWrapper>
                        <TransformComponent>
                            <img src={src} width={'100%'} height={'auto'}/>
                        </TransformComponent>
                    </TransformWrapper>
                }
            </Box>
        </Modal>
    )
}

export default ImagesDetail
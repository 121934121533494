import React, { useEffect, useState } from "react"
import { Card, FormControlLabel, Grid, Switch } from "@mui/material"
import { toGMT7, toLocalGMT, momentFormat } from 'helper/time';
import { addMonths, subMonths } from "date-fns";
import { useSwipeable } from "react-swipeable";

import Calendar from 'react-calendar';
import moment from "moment";
import TCNavigationBar from "components/TopCourtComponents/NavigationBar/TopCourtNavigationBar";
import { getMyServiceSchedule, getMyOffSchedule, setMySchedule, setMyOffSchedule, deleteOffSchedule} from "service/playerService";
import { Confirm } from "helper/showAlert";
import { TCLabel } from "components/TopCourtComponents/Label/TopCourtLabel";
import MKButton from "components/MKButton";
import { TCInput } from "components/TopCourtComponents/Input/TopCourtInput";
import { TCSelect } from "components/TopCourtComponents/Input/TopCourtInput";
import { timeOptions } from "helper/constant";
import SlideDialog from "components/SlideDialog/SlideDialog";
import DialogHeader from "components/SlideDialog/DialogHeader";
import { timeStringFormat } from "helper/constant";
import { TCTitleLabel } from "components/TopCourtComponents/Label/TopCourtLabel";

import { getCourtInfo } from "helper/localStorage";

import DeleteIcon from '@mui/icons-material/Delete';
import { color } from "helper/constant";
import { Confirmation } from "helper/showAlert";
import ScheduleHoursComponent from "components/TopCourtComponents/ScheduleHoursComponent";
import TCAccordion from "components/TopCourtComponents/Accordion/TopCourtAccordion";
import { courtTypeObj } from "helper/constant";
import { ConfirmationOneButton } from "helper/showAlert";

function ServiceSchedule ({

}) {

    const [ swipeState, setSwipeState ] = useState(0)
    const [ selectedDefaultDate, setSelectedDefaultDate ] = useState(new Date())

    const [ scheduleList, setScheduleList ] = useState([])
    const [ scheduleListDate, setScheduleListDate ] = useState([])
    const [ showDetail, setShowDetail ] = useState({
        open: false,
        scheduleData: null
    })

    const [ form, setForm ] = useState({
        selectedDate: toLocalGMT(new Date()),
        date: toGMT7(new Date()),
        month: toGMT7(new Date(), 'MM'),
        year: toGMT7(new Date(), 'YYYY'),
        court: null,
        hours: [],
        totalSessions: 0,
        totalPrice: 0,
    })

    const onChange = (e) => {
        setForm(prev => ({
            ...prev,
            ...e
        }))
    }

    useEffect(() => {
        loadMyServiceShedule()
    }, [])

    useEffect(() => {
        console.log(showDetail)
    }, [showDetail])

    const handlers = useSwipeable({
        onSwipedLeft: () => {
            if (swipeState == 1) { return; }
            setSwipeState(swipeState + 1)

            let monthPlus1 = swipeState + 1
            let month = momentFormat(addMonths(selectedDefaultDate, monthPlus1), 'MM')
            let year = momentFormat(addMonths(selectedDefaultDate, monthPlus1), 'YYYY')

            onChange({
                month: month,
                year: year
            })
        },
        onSwipedRight: () => {
            if (swipeState == 0) { return; }
            setSwipeState(swipeState - 1)

            let monthPlus1 = swipeState - 1
            let month = momentFormat(addMonths(selectedDefaultDate, monthPlus1), 'MM')
            let year = momentFormat(addMonths(selectedDefaultDate, monthPlus1), 'YYYY')

            onChange({
                month: month,
                year: year
            })
        },
    });

    useEffect(() => {
    }, [ scheduleList, scheduleListDate ])

    const loadMyServiceShedule = async () => {
        let { data, error } = await getMyServiceSchedule()
        if (error) { Confirm("", error) }
        if (data) { 
            // merge datenya jadi 1 
            let newDate = []
            let newData = []
            for (let i = 0; i < data.length; i ++) {
                newDate.push(data[i].date)  
                newData.push({
                    ...data[i],
                    startHour: data[i].hours[0]
                }) 
            }
            setScheduleList(newData)
            setScheduleListDate(newDate)
        }
    }

    return (
        <>
            <Grid>
                <TCNavigationBar light={false} transparent={false} fixed title={"Schedule"}/>
                <Grid container padding={0} 
                    style={{marginTop: 0.5, paddingBottom: 20}} >
                    <Grid item xs={12}>
                        <Grid container>
                            {/* mesti check si user */}
                            <Grid item container justifyContent={"flex-start"} >
                                <Grid item xs={4} textAlign={"center"}>
                                    <div className={form.month === momentFormat(addMonths(selectedDefaultDate, -1), 'MM') ? 'calendar-custom-header-selected' : 'calendar-custom-header'}
                                        onClick={() => {
                                            onChange({
                                                month: momentFormat(addMonths(selectedDefaultDate, -1), 'MM'),
                                                year: momentFormat(addMonths(selectedDefaultDate, -1), 'YYYY')
                                            })
                                            setSwipeState(0)
                                        }}
                                    >
                                        {momentFormat(addMonths(selectedDefaultDate, -1), 'MMM YY')}
                                    </div>
                                </Grid>
                                <Grid item xs={4} textAlign={"center"}>
                                    <div className={form.month === momentFormat(selectedDefaultDate, 'MM') ? 'calendar-custom-header-selected' : 'calendar-custom-header'}
                                        onClick={() => {
                                            onChange({
                                                month: momentFormat(selectedDefaultDate, 'MM'),
                                                year: momentFormat(selectedDefaultDate, 'YYYY')
                                            })
                                            setSwipeState(0)
                                        }}
                                    >
                                        {momentFormat(selectedDefaultDate, 'MMM YY')}
                                    </div>
                                </Grid>
                                <Grid item xs={4} textAlign={"center"}>
                                    <div className={form.month === momentFormat(addMonths(selectedDefaultDate, 1), 'MM') ? 'calendar-custom-header-selected' : 'calendar-custom-header'}
                                        onClick={() => {
                                            onChange({
                                                month: momentFormat(addMonths(selectedDefaultDate, 1), 'MM'),
                                                year: momentFormat(addMonths(selectedDefaultDate, 1), 'YYYY')
                                            })
                                            setSwipeState(1)
                                        }}
                                    >
                                        {momentFormat(addMonths(selectedDefaultDate, 1), 'MMM YY')}
                                    </div>
                                </Grid>
                            </Grid>
                            <div {...handlers} style={{width: '100vw'}}>
                                <Calendar 
                                    className={"react-calendar"}
                                    onChange={(e) => {
                                        onChange({
                                            selectedDate: toLocalGMT(e),
                                            date: toGMT7(e)
                                        });
                                        if (scheduleListDate.indexOf(moment(e).format('YYYY-MM-DD')) >= 0) {
                                            // console.log('here info', scheduleListDate, scheduleList)
                                            let scheduleData = []
                                            for (let i = 0; i < scheduleList.length; i ++) {
                                                if (scheduleList[i].date === moment(e).format('YYYY-MM-DD')) {
                                                    scheduleData.push(scheduleList[i])
                                                }
                                            }
                                            scheduleData.sort((a,b) => (a.startHour > b.startHour) ? 1 : ((b.startHour > a.startHour) ? -1 : 0))
                                            setShowDetail({
                                                open: true,
                                                scheduleData: scheduleData
                                            })
                                        }
                                    }}
                                    tileClassName={(({date}) => {
                                        if (moment(date).format('YYYY-MM-DD') < moment(subMonths(new Date(), 1)).format('YYYY-MM-DD')) {
                                            return;
                                        }
                                        if (moment(date).format('YYYY-MM-DD') == moment(form.selectedDate).format('YYYY-MM-DD')) {
                                            return;
                                        }
                                        if (scheduleListDate.indexOf(moment(date).format('YYYY-MM-DD')) >= 0) {
                                            return 'dotted'
                                        }
                                    })}
                                    minDate={subMonths(new Date(), 1)}
                                    maxDate={checkAdvancedDays(true)}
                                    defaultValue={form.selectedDate} 
                                    value={form.selectedDate}
                                    showNavigation={false}
                                    activeStartDate={new Date(form.year, (form.month - 1), 1)}
                                />
                            </div>
                            <div style={{border: '0.5px solid #3447671F', height: '0.5px', marginTop: 10, width: '100%'}}/>
                        </Grid>
                    </Grid>
                </Grid>
                <SetOffDay reloadData={() => { loadMyServiceShedule() }}/>
                <SlideDialog
                    isOpen={showDetail.open} 
                    setIsOpen={(e) => {
                        if (showDetail) setShowDetail(prev => ({
                            ...prev,
                            open: e,
                            scheduleData: null
                        }))
                    }}
                    maxWidth={"lg"}
                >
                    <DialogHeader title={"My Schedule"} closeDialog={() => { setShowDetail(prev => ({
                        ...prev,
                        open: false,
                        scheduleData: null
                    })) }}/>
                    {
                        showDetail.scheduleData && showDetail.scheduleData.map( value => {
                            return (
                                <Card sx={{marginBottom: 1}}>
                                    <Grid p={1}>
                                        <TCLabel style={{fontSize: 12}} bold>{moment(value.date).format("ddd, DD/MM/YYYY")}</TCLabel>
                                        {/* <Grid>
                                            <TCLabel bold>{showDetail.scheduleData.order.user.name}</TCLabel>
                                        </Grid> */}
                                        <Grid>
                                            <TCLabel style={{fontSize: 12}} bold>{courtTypeObj[value.court.courtType]} {value.court.name} {value.classId ? ` : ${value.classId.name}` : ``}</TCLabel>
                                        </Grid>
                                        <Grid container>
                                            {
                                                value.hours.map((h, index) => {
                                                    return (
                                                        <Grid item>
                                                            <TCLabel style={{fontSize: 12}}>
                                                                {`${h} - ${timeStringFormat(Number(h.split(':')[0]) + 1)}`}{index < (value.hours.length - 1) ? <span style={{padding: 3, fontFamily: 'roboto', fontWeight: 50}}>{"|"}</span> : ""}
                                                            </TCLabel>
                                                        </Grid>
                                                    )
                                                })
                                            }
                                        </Grid>
                                    </Grid>
                                </Card>
                            )
                        })
                    }
                </SlideDialog>
            </Grid>
        </>
    )
}

function SetOffDay ({
    reloadData
}) {

    const [ form, setForm ] = useState({
        date: null,
        day: null,
        startHours: null,
        endHours: null
    })
    const [ scheduleInfo, setScheduleInfo ] = useState({
        operationalHours
    })
    const [ offSchedule, setOffSchedule ] = useState([])
    const [ orderSchedule, setOrderSchedule] = useState([])
    const [ listHours, setListHours ] = useState(timeOptions)
    const [ listEndHours, setListEndHours ] = useState(timeOptions)
    const [ lockSchedule, setLockSchedule ] = useState(false)

    const [ loading, setLoading ] = useState(true)

    let placeInfo = getCourtInfo ? JSON.parse(getCourtInfo) : null

    useEffect(() => {
        loadOffSchedule()
    }, [])

    useEffect(() => {
        if (placeInfo) {
            let newHours = []
            for (let i = 0; i < placeInfo.operationalHours.length; i ++) {
                console.log('check placeInfo', placeInfo.operationalHours[i].day, form.day)
                if (placeInfo.operationalHours[i].day === form.day) {
                    for ( let j = 0; j < placeInfo.operationalHours[i].hours.length; j++ ) {
                        newHours.push({
                            value: placeInfo.operationalHours[i].hours[j], 
                            label: placeInfo.operationalHours[i].hours[j]
                        })
                    }
                }
            }
            
            let currHours = newHours
            if (newHours.length === 0) { currHours = timeOptions }
            else currHours = newHours

            checkAvailableHours(form.date, currHours, setListHours, false)

        }
    }, [form.date])

    useEffect(() => {
        if (form.startHours) {
            let hours = []
            let start = false
            for (let i = 0; i < timeOptions.length; i ++) {
                if (start) { hours.push(timeOptions[i]) }
                if (timeOptions[i].value == form.startHours.value) { start = true }
            }
            // hours.unshift({
            //     value:"00:00", label:"00:00"
            // })
            // setListEndHours(hours) 
            checkAvailableHours(form.date, hours, setListEndHours, true)
        }
    }, [form.startHours])

    const saveMyCurrentSchedule = async () => {
        try {
            let { data, error } = await setMySchedule({
                schedule: scheduleInfo.operationalHours
            })
            if (data) {
                Confirm("", "Saved")
            }
        } catch (error) {
            Confirm("", error)
        }
    }

    const saveSchedule = async () => {
        if (!form.date) { Confirm("", "Select date off"); return; }
        if (!form.startHours) { Confirm("", "Select start hours"); return; }
        if (!form.endHours) { Confirm("", "Select end hours"); return; }
        let newForm = {
            ...form
        }
        newForm.startHours = form.startHours ? form.startHours.value : ''
        newForm.endHours = form.endHours ? form.endHours.value : ''
        let { data, error } = await setMyOffSchedule(newForm)
        if (error) { 
            ConfirmationOneButton("", error, "OK", () => {
                reloadData();
                loadOffSchedule();
                setForm({
                    date: null,
                    day: null,
                    startHours: null,
                    endHours: null
                })
            })
        }
        if (data) { 
            // Confirm("", "Success save off schedule")
            loadOffSchedule()
            setForm(prev => ({
                // date: null,
                // day: null,
                date: prev.date,
                day: prev.day,
                startHours: null,
                endHours: null
            }))
        }
    }

    const removeOffSchedule = async (e) => {
        let { data, error } = await deleteOffSchedule({
            date: e
        })
        if (error) { Confirm("", error) }
        if (data) { 
            // Confirm("", "Success delete off schedule")
            loadOffSchedule()
        }
    }

    const loadOffSchedule = async () => {
        setLoading(true)
        let { data, error } = await getMyOffSchedule()
        if (error) { Confirm("", error); }
        if (data) { 
            console.log('here check data', data)
            setScheduleInfo({
                operationalHours: data.schedule
            })
            setOffSchedule(data.scheduleOff)
            setLockSchedule(data.lockSchedule)
            setOrderSchedule(data.orderSchedule)
            setLoading(false)
        }
    }

    const onChange = (e) => {
        setForm(prev => ({
            ...prev,
            ...e
        }))
    }

    const checkAvailableHours = (e, hours, setHours, disableEnd) => {
        console.log('Check Available Hours', e, orderSchedule)
        let similiar = false
        for (let i = 0; i < orderSchedule.length; i ++) {
            if (orderSchedule[i].date == e) {
                let newHours = []
                let disable = false
                console.log('Check Available Hours', orderSchedule[i])
                for (let j = 0; j < hours.length; j++) {
                    if (orderSchedule[i].hours.indexOf(hours[j].value) >= 0) {
                        if (!disable) { 
                            if (disableEnd) { 
                              disable = true 
                              newHours.push({
                                ...hours[j]
                              })
                            } else {
                              newHours.push({
                                ...hours[j],
                                isDisabled: true
                              })
                            }
                          } else {
                            newHours.push({
                              ...hours[j],
                              isDisabled: true
                            })
                        }
                    } else if (disableEnd && disable) {
                      newHours.push({
                        ...hours[j],
                        isDisabled: true
                      })
                    } else {
                      newHours.push(hours[j])
                    }
                }
                similiar = true
                // console.log('Check Available Hours', newHours)
                setHours(newHours)
            }
        }
        if (!similiar) {
            setHours(hours)
        }
        // setListHours
    }

    return (
        <>
            <Grid item container p={2} pt={0}>
                {/* <Grid item xs={12}><TCTitleLabel>My Schedule</TCTitleLabel></Grid> */}
                <Grid item xs={12}>
                    <TCAccordion title={<TCLabel style={{
                        fontSize: 15
                    }} bold>My Schedule</TCLabel>}>
                        <Grid>
                            <ScheduleHoursComponent setCourt={setScheduleInfo} 
                                currOpsHours={scheduleInfo.operationalHours}
                                setShowSummary={() => {}}
                                disabled={lockSchedule}
                                exceptionHours={orderSchedule}
                            />
                        </Grid>
                        <Grid pt={1} item xs={12} textAlign={"right"}>
                            <MKButton color="primary" disabled={lockSchedule} onClick={() => {
                                saveMyCurrentSchedule()
                            }}>Save</MKButton>
                        </Grid>
                    </TCAccordion>
                </Grid>
                <Grid item xs={12} pt={2}>
                    <TCAccordion title={<TCLabel style={{
                        fontSize: 15
                    }} bold>Exclude (Off)</TCLabel>}>
                        <Grid pt={1} item container xs={12}>
                            {/* List Inputan Date dan Hours */}
                            <Grid item container xs={12} spacing={1}>
                                <Grid item xs={12}>
                                    <TCInput value={form.date} 
                                        style={{weekStart: 1}}
                                        label="Date" type="date" 
                                        onChange={(e) => {
                                            onChange({
                                                date: e.currentTarget.value, 
                                                day: moment(e.currentTarget.value).format("dddd"),
                                                startHours: null, endHours: null
                                            })
                                        }}
                                        inputProps={{
                                            InputProps: {
                                                inputProps: { min: moment(new Date()).format("YYYY-MM-DD"), max: checkAdvancedDays()}
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs>
                                    <TCLabel>Hours</TCLabel>
                                    <Grid item container xs spacing={1}>
                                        <Grid item xs>
                                            <TCSelect value={form.startHours} 
                                            disabled={!form.date}
                                            options={listHours}
                                                onChange={(e) => {
                                                    onChange({startHours: e, endHours: null})
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs>
                                            <TCSelect value={form.endHours} 
                                                disabled={!form.startHours}
                                                options={listEndHours}
                                                onChange={(e) => {
                                                    onChange({endHours: e})
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid pt={1} item xs={12} textAlign={"right"}>
                                    <MKButton color="primary" disabled={loading} onClick={() => {
                                        saveSchedule()
                                    }}>Add</MKButton>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item pt={2}>
                            {
                                offSchedule && offSchedule.sort((a,b) => (a.date > b.date) ? 1 : ((b.date > a.date) ? -1 : 0))
                                    .map(value => {
                                    return (
                                        <Card sx={{mb: 2}}>
                                            <Grid p={1} container>
                                                <Grid item container>
                                                    <Grid item xs>
                                                        <Grid item>
                                                            <TCLabel bold>{moment(new Date(value.date)).format("ddd, DD/MM/YYYY")}</TCLabel>                                            
                                                        </Grid>
                                                        <Grid item container spacing={0.5}>
                                                            <Grid item>
                                                                <TCLabel bold>Off Hours :</TCLabel>
                                                            </Grid>
                                                            <Grid item>
                                                                <TCLabel>{`${value.hours[0]} - ${timeStringFormat(Number(value.hours[value.hours.length - 1].split(':')[0]) + 1)}`}</TCLabel>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs textAlign={"right"} onClick={() => {
                                                        Confirmation("", "Delete off schedule ?", () => {}, () => {
                                                            removeOffSchedule(value.date)
                                                        })
                                                    }}>
                                                        <DeleteIcon style={{width: 20, height: 20, color: color.pink}}/>
                                                        <TCLabel style={{
                                                            fontSize: 9
                                                        }}>Last modified: {moment(value.updatedDate).format("DD/MM/YYYY HH:mm:ss")}</TCLabel>
                                                    </Grid>
                                                </Grid>
                                                <Grid item container>
                                                </Grid>
                                            </Grid>
                                        </Card>
                                    )
                                })
                            }
                        </Grid>
                    </TCAccordion>
                </Grid>
            </Grid>
        </>
    )

}

function checkAdvancedDays (date) {
    if (date) {
        return moment(new Date()).add(20, 'days').toDate()
    } else {
        return moment(new Date()).add(20, 'days').format('YYYY-MM-DD')
    }
}

const operationalHours = [
    {
        day: "Sunday",
        isActive: true,
        operationalHours : {
            start: "",
            end: ""
        },
        hours: []
    },
    {
        day: "Monday",
        isActive: true,
        operationalHours : {
            start: "",
            end: ""
        },
        hours: []
    },
    {
        day: "Tuesday",
        isActive: true,
        operationalHours : {
            start: "",
            end: ""
        },
        hours: []
    },
    {
        day: "Wednesday",
        isActive: true,
        operationalHours : {
            start: "",
            end: ""
        },
        hours: []
    },
    {
        day: "Thursday",
        isActive: true,
        operationalHours : {
            start: "",
            end: ""
        },
        hours: []
    },
    {
        day: "Friday",
        isActive: true,
        operationalHours : {
            start: "",
            end: ""
        },
        hours: []
    },
    {
        day: "Saturday",
        isActive: true,
        operationalHours : {
            start: "",
            end: ""
        },
        hours: []
    },
]

export default ServiceSchedule
import React, { useState, useEffect } from "react";
import { Grid, Box } from "@mui/material";
import { TCLabel } from "components/TopCourtComponents/Label/TopCourtLabel";
import { currencyFormat } from "helper/numberFormat";
import { color } from "helper/constant";

import DeleteIcon from '@mui/icons-material/Delete';
import ChecklistIcon from "../../assets/checklist.png";
import { cancelValuePackUse, usePackageinCart } from "./Function";
import { UserPackageHeader } from "helper/user";

function UseValuePack ({
    user,
    coupon,
    packUsageInfo,
    // packUsageInfo,
    setOpenModal,
    setPackUsageInfo,
    setSelectValuePack,
    navigate,
    calculateSummary
}) {

    const cancelValuePackUsage = async () => {
        cancelValuePackUse(setPackUsageInfo, setSelectValuePack)
    }

    return (
        <Grid container mb={1} sx={{ height: 80 }}>
            {
                packUsageInfo.selectedSession <= 0 &&
                <Grid item sx={{
                    pl:2,
                    pr:2,
                    // borderTop: '1px black'
                }} container justifyContent={"flex-start"} >
                    <>
                        {(user && user.sessions > 0) && 
                            <div onClick={() => { if(!coupon) { setOpenModal(true) } }}><TCLabel bold>{`Pay with sessions: ${user.sessions} session${user.sessions > 1  ? 's' : ''}`}</TCLabel></div>}
                        {(user && !user.sessions) && 
                            <div onClick={() => { navigate("/packages")}}><TCLabel bold>{`Buy Value Pack`}</TCLabel></div>}
                    </>
                </Grid>
            }
            {
                (packUsageInfo.selectedSession <= 0 && (user && user.sessions > 0)) &&
                <Grid item xs={12} onClick={() => { if(!coupon) { setOpenModal(true) } }}>
                    <UserPackageHeader/>
                </Grid>
            }
            {
                packUsageInfo.selectedSession > 0 &&
                <>
                    <Grid item xs={12} sx={{
                        pl:2,
                        pr:2,
                        // borderTop: '1px black'
                    }} >
                        <ValuePackUsed data={packUsageInfo} cancelValuePackUsage={cancelValuePackUsage}/>
                    </Grid>
                    <Grid sx={{
                        pt: 1,
                        pr: 2
                    }} container direction={"column"} 
                        textAlign={'right'}
                        justifyContent={'flex-end'}>
                        <Grid item>
                            <TCLabel>{packUsageInfo.totalSession} {packUsageInfo.totalSession > 1 ? "Sessions" : "Session"}</TCLabel>
                        </Grid>
                        <Grid item>
                            <TCLabel style={{color: color.pink}}><span>-</span>{packUsageInfo.selectedSession} {packUsageInfo.selectedSession > 1 ? "Sessions" : "Session"}</TCLabel> 
                        </Grid>
                    </Grid>
                </>
            }
        </Grid>
    )
}


function ValuePackUsed({
    data,
    cancelValuePackUsage
}) {
    return(
        <div style={{display:'flex', alignItems: 'flex-end' }}>
            <div style={{width: '100%'}}>
                <div className="">
                    <div style={{borderStyle: 'solid', 
                        borderColor: color.green,
                        borderRadius: 10,
                        // borderTopLeftRadius: 10, borderBottomLeftRadius: 10,
                        // backgroundColor: 'rgb(125, 207, 182, 0.5)'
                    }}>
                        <Grid container alignItems={"center"} pl={1} pr={1} pt={0.2} pb={0.2}>
                            <Grid item xs={1} md={1} alignItems={"center"}>
                                <img src={ChecklistIcon} style={{width: 30, height: 30, marginTop: 10}}/>
                            </Grid>
                            <Grid item xs={9} md={9} pl={1}>
                                {/* <div><TCLabel bold>{data.selectedSession} {data.selectedSession > 1 ? "Sessions" : "Session"}</TCLabel></div> */}
                                <div><TCLabel bold>Sessions redeemed</TCLabel></div>
                                <div><TCLabel subtitle>{data.selectedSession} {data.selectedSession > 1 ? "Sessions" : "Session"}</TCLabel></div>
                            </Grid>
                            <Grid item xs={2} md={2}>
                                <Box textAlign={"right"}>
                                    <div onClick={(e) => {
                                        e.stopPropagation()
                                        cancelValuePackUsage()
                                    }}>
                                        <DeleteIcon style={{width: 20, height: 20, color: color.pink}}/>
                                    </div>
                                </Box>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UseValuePack
import React, { useEffect, useState } from "react";


import MKButton from "components/MKButton";
import { Grid } from "@mui/material";
import { TCLabel } from "components/TopCourtComponents/Label/TopCourtLabel";

import { setUserInfo } from "helper/localStorage";

// import { auth } from "../../service/firebase";
// import { signInWithPhoneNumber, RecaptchaVerifier } from "firebase/auth";
import { changeNumberService, login, sendOTPService, checkOTPService } from "../../service/authService";

import OTP from "./OTP";

import MuiPhone from "components/TopCourtComponents/Input/CountryPickerInput";
import "react-international-phone/style.css";
import Loading from "components/TopCourtComponents/Loading/TCLoadingComponent";
import { Confirm } from "helper/showAlert";

function ChangeNumber ({
    isOpen,
    reset,
    dismissPopup
}) {

    const [ phoneNumber, setPhoneNumber ] = useState("+62");
    const [ otp, setOTP ] = useState("");
    const [ showOTP, setShowOTP ] = useState(false);
    const [ loading, showLoading ] = useState(false);

    useEffect(() => {
        // windowVerifier()
    }, [])

    useEffect(() => {
        if (!showOTP) { setPhoneNumber("+62") }
        setOTP("")
    }, [isOpen])

    useEffect(() => {
        setShowOTP(showOTP && !reset)
        if (reset) { setPhoneNumber("+62") }
    }, [reset])

    useEffect(() => {
        if (otp.length == 6) {
            checkOTP()
        }
    }, [otp])

    const reqChangeNumber = async () => {
        try {
            let { data, error } = await changeNumberService(phoneNumber)
            if (error) { Confirm("", error) }
            if (data) { setUserInfo(data); Confirm("", "Change number success"); dismissPopup(); }
        } catch (error) {
            console.log(error)
        }
    }

    // const checkOTP = async () => {
    //     showLoading(true)
    //     window.confirmationResult.confirm(otp).then( async (result) => {
    //         showLoading(false)
    //         await reqChangeNumber()
    //     }).catch((error) => {
    //         showLoading(false);
    //         let firebaseErrorMapping = error.message
    //         if (firebaseErrorMapping.includes('auth/invalid-verification-code') || firebaseErrorMapping.includes('auth/code-expired')) {
    //             firebaseErrorMapping = "Wrong OTP. Please check your OTP or request again"
    //         }
    //         Confirm("", firebaseErrorMapping)
    //     });
    // }

    // const windowVerifier = () => {
    //     if (!window.appVerifier && isOpen) {
    //         console.log("check verifier - 1")
    //         window.appVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
    //             'size': 'invisible',
    //         });
    //         window.appVerifier.render().then((widgetId) => {
    //             window.recaptchaWidgetId = widgetId;
    //         });
    //     }
    // }

    // const requestOTP = async () => {
    //     showLoading(true)
    //     var appVerifier = window.appVerifier
    //     console.log('check verifier - 1 ', appVerifier)
    //     if (!appVerifier) {
    //         windowVerifier()
    //         appVerifier = window.appVerifier
    //     }
    //     console.log('check verifier - 2', appVerifier)
    //     signInWithPhoneNumber(auth, phoneNumber, appVerifier)
    //         .then((confirmationResult) => {
    //             showLoading(false)
    //             setShowOTP(true)
    //             window.confirmationResult = confirmationResult;
    //         }).catch((error) => {
    //             showLoading(false)
    //             Confirm("", "Something unexpected happen. Try Again")
    //         });
    // }

    const requestOTP = async () => {
        setShowOTP(true)
        try {
            // showLoading(false)
            // var appVerifier = window.appVerifier
            // if (!appVerifier) {
            //     windowVerifier()
            //     appVerifier = window.appVerifier
            // }
            // signInWithPhoneNumber(auth, phoneNumber, appVerifier)
            // .then((confirmationResult) => {
            //     showLoading(false)
            //     window.confirmationResult = confirmationResult;
            // }).catch((error) => {
            //     showLoading(false)
            //     Confirm("", JSON.stringify(error))
            // });
            let { data, error } = await sendOTPService({
                phoneNumber: phoneNumber
            })
            showLoading(false);
            if (error) { console.log("error") }
            if (data) { console.log(data) }
        } catch(error) {
            showLoading(false)
        }
    }

    const checkOTP = async () => {
        showLoading(true)
        // window.confirmationResult.confirm(otp).then( async (result) => {
        //     // User signed in successfully.
        //     // const user = result.user;
        //     // check if user exist or not in API, if name is empty then show register view
        //     let { data, error } = await login({
        //         phoneNumber: phoneNumber
        //     })
        //     if (error) { throw error }
        //     if (data) {
        //         if (!data.name || !data.password) {
        //             // setUserInfo(data)
        //             showLoading(false)
        //             setCurrUserInfo(data)
        //         } else {
        //             showLoading(false)
        //             if (initialPassword) {
        //                 setShowOTP(false)
        //                 setShowPassword(true)
        //             } else {
        //                 // navigate(0)
        //                 setUserInfo(data)
        //                 dismissPopup()
        //                 window.location.reload()
        //                 // setShowOTP(false)
        //                 // setShowPassword(true)
        //             }
        //         }
        //     } 
        // }).catch((error) => {
        //     showLoading(false);
        //     console.log(error.message)
        //     let firebaseErrorMapping = error.message
        //     if (firebaseErrorMapping.includes('auth/invalid-verification-code') || firebaseErrorMapping.includes('auth/code-expired')) {
        //         firebaseErrorMapping = "Wrong OTP. Please check your OTP or request again"
        //     }
        //     // alert(error.message)
        //     Confirm("", firebaseErrorMapping)
        // });
        let otpServiceData = await checkOTPService({
            phoneNumber: phoneNumber, 
            OTP: otp
        })
        console.log('here check OTP Data', otpServiceData.data, otpServiceData.error)
        showLoading(false)
        if (otpServiceData.error) { console.log(otpServiceData.error);
            Confirm("", otpServiceData.error)
        }
        if (otpServiceData.data) { console.log(otpServiceData.data);
            await reqChangeNumber()
        }
    }

    return (
        <Grid container justifyContent="center">
            {
                showOTP &&
                <Grid>
                    {/* <Grid sx={{
                        mb: 2
                    }}
                    onClick={() => {
                        setShowOTP(false)
                        setPhoneNumber("+62")
                    }}>
                        <TCLabel style={{fontSize: 10, fontWeight: 'bold'}}>Wrong number ? <span style={{textDecorationLine: 'underline'}}>Change number</span></TCLabel>
                    </Grid> */}
                    <OTP otp={otp} setOtp={setOTP} submit={checkOTP}/>
                </Grid>
            }
            {
                (!showOTP) &&
                <>
                    <Grid xs={12} md={12} lg={12} item style={{marginTop: 8 }}>
                        <TCLabel>Enter your new mobile number</TCLabel> 
                    </Grid>
                    <Grid xs={12} md={12} lg={12} pt={1} item container>
                        <MuiPhone value={phoneNumber} onChange={setPhoneNumber}/>
                    </Grid>
                    <Grid xs={12} md={12} lg={12} item pt={2} textAlign={"center"}>
                        <MKButton style={{width: '100%'}} color="primary" onClick={requestOTP}
                            disabled={phoneNumber.length < 12 || loading}
                        >Continue</MKButton>
                    </Grid>
                </>
            }
            {/* <div id="recaptcha-container"/> */}
            {
                loading &&
                <Loading/>
            }
        </Grid>
    )
}

export default ChangeNumber;
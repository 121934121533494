import React from "react"
import { Typography } from "@mui/material";

function TCTitleLabel ({
    variant,
    children,
    style,
    inheritColor = false
}) {
    const styles  = {
        // fontSize: 20, fontWeight: 30,
        fontSize: 18, fontWeight: 500,
        ...style,
    }
    return (
        <Typography variant={variant} color={inheritColor ? "inherit" : "primary"}
            style={{...styles}}>
            {children}
        </Typography>
    )
}

function TCLabel ({
    variant,
    children,
    style,
    subtitle = false,
    bold = false,
    inheritColor = false
}) {
    const styles  = {
        // fontSize: 14, fontWeight: 30,
        fontSize: subtitle ? 11 : 13, fontWeight: bold ? 500 : 400,
        ...style,
    }
    return (
        <Typography variant={variant} color={inheritColor ? "inherit" : "primary"}
            style={{...styles}}>
            {children}
        </Typography>
    )
}

export {
    TCLabel,
    TCTitleLabel
}
import { client, errorValidation } from "./service";

export const getPlaces = async function () {
  try {
    let response = await client.get('/places');
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data };
  } catch (e) {
    // return { error: e.response.data.errorCode };
    return errorValidation(e)
  }
}

export const getPlaceDetail = async function (id) {
    try {
      let response = await client.get(`/place/${id}`);
      if (response.data.statusCode !== 200) { throw response.data.data; }
      let data = response.data.data;
      return { data };
    } catch (e) {
      // return { error: e.response.data.errorCode };
      return errorValidation(e)
    }
}

export const getPlaceDefault = async function () {
    try {
      let response = await client.get('/place/653f5d9006520fccd4ab4604');
      if (response.data.statusCode !== 200) { throw response.data.data; }
      let data = response.data.data;
      return { data };
    } catch (e) {
      // return { error: e.response.data.errorCode };
      return errorValidation(e)
    }
}
const ChevronUpSvg = () => {
    return (
        // <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        //     width="12px" height="12px" viewBox="0 0 512.000000 512.000000"
        //     preserveAspectRatio="xMidYMid meet">

        //     <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        //     fill="#025597" stroke="none">
        //     <path d="M2468 4005 c-26 -7 -67 -24 -90 -38 -24 -14 -556 -540 -1184 -1169
        //     -1283 -1286 -1188 -1178 -1188 -1338 -1 -72 4 -94 27 -145 71 -153 234 -238
        //     393 -205 33 7 77 21 99 32 26 13 388 368 1038 1016 l997 997 998 -997 c649
        //     -648 1011 -1003 1037 -1016 186 -92 405 -15 492 173 23 51 28 73 27 145 0 160
        //     95 52 -1188 1338 -628 629 -1160 1155 -1183 1169 -84 49 -184 63 -275 38z"/>
        //     </g>
        // </svg>
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width="12px" height="12px" viewBox="0 0 512.000000 512.000000"
            preserveAspectRatio="xMidYMid meet">
            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                fill="#0198E1" stroke="none">
                <path d="M2370 5113 c-371 -35 -653 -114 -961 -269 -406 -203 -782 -548 -1029
                -944 -179 -286 -309 -655 -362 -1025 -17 -118 -17 -512 0 -630 42 -295 120
                -553 242 -800 137 -280 272 -468 494 -691 221 -220 412 -357 681 -489 188 -92
                309 -137 500 -185 500 -126 1002 -102 1490 71 150 53 408 183 540 271 302 202
                573 480 769 788 72 113 188 353 235 486 235 662 194 1372 -115 1993 -124 250
                -263 447 -458 648 -214 222 -430 379 -711 518 -296 146 -572 225 -900 255
                -102 9 -333 11 -415 3z m545 -342 c628 -106 1158 -448 1511 -977 179 -267 296
                -573 351 -909 24 -153 24 -497 0 -650 -108 -668 -474 -1222 -1042 -1580 -243
                -153 -537 -261 -850 -312 -154 -24 -497 -24 -650 1 -657 107 -1198 456 -1557
                1006 -168 257 -281 557 -335 885 -24 153 -24 497 0 650 81 497 291 912 636
                1255 382 381 862 605 1401 654 108 10 418 -4 535 -23z"/>
            </g>
            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
            fill="#0198E1" stroke="none">
                <path d="M2495 3666 c-16 -8 -366 -351 -777 -763 -661 -663 -747 -752 -753
                    -785 -15 -80 23 -156 92 -184 48 -21 2958 -21 3006 0 69 28 107 104 92 183 -6
                    34 -92 124 -753 787 -434 434 -760 754 -779 762 -40 17 -89 17 -128 0z"/>
            </g>
        </svg>
    )
}

const ChevronDownSvg = () => {
    return (
        // <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        //     width="12px" height="12px" viewBox="0 0 512.000000 512.000000"
        //     preserveAspectRatio="xMidYMid meet">

        //     <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        //     fill="#025597" stroke="none">
        //     <path d="M277 4009 c-103 -24 -197 -103 -244 -204 -23 -51 -28 -73 -27 -145 0
        //     -160 -96 -52 1192 -1342 777 -778 1160 -1155 1191 -1172 73 -39 158 -53 234
        //     -37 34 7 83 24 108 37 31 17 414 394 1191 1172 1288 1290 1192 1182 1192 1342
        //     0 72 -4 94 -28 147 -84 184 -308 262 -491 171 -26 -13 -388 -368 -1037 -1016
        //     l-998 -997 -998 997 c-652 651 -1011 1003 -1037 1016 -76 37 -170 49 -248 31z"/>
        //     </g>
        // </svg>
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width="12px" height="12px" viewBox="0 0 512.000000 512.000000"
            preserveAspectRatio="xMidYMid meet">
            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                fill="#0198E1" stroke="none">
                <path d="M2370 5113 c-371 -35 -653 -114 -961 -269 -406 -203 -782 -548 -1029
                -944 -179 -286 -309 -655 -362 -1025 -17 -118 -17 -512 0 -630 42 -295 120
                -553 242 -800 137 -280 272 -468 494 -691 221 -220 412 -357 681 -489 188 -92
                309 -137 500 -185 500 -126 1002 -102 1490 71 150 53 408 183 540 271 302 202
                573 480 769 788 72 113 188 353 235 486 235 662 194 1372 -115 1993 -124 250
                -263 447 -458 648 -214 222 -430 379 -711 518 -296 146 -572 225 -900 255
                -102 9 -333 11 -415 3z m545 -342 c628 -106 1158 -448 1511 -977 179 -267 296
                -573 351 -909 24 -153 24 -497 0 -650 -108 -668 -474 -1222 -1042 -1580 -243
                -153 -537 -261 -850 -312 -154 -24 -497 -24 -650 1 -657 107 -1198 456 -1557
                1006 -168 257 -281 557 -335 885 -24 153 -24 497 0 650 81 497 291 912 636
                1255 382 381 862 605 1401 654 108 10 418 -4 535 -23z"/>
            </g>
            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
            fill="#0198E1" stroke="none">
                <path d="M1055 3066 c-67 -29 -105 -105 -90 -183 6 -34 92 -123 763 -795 822
                    -822 775 -780 870 -764 33 6 122 92 794 764 671 671 757 761 763 795 15 79
                    -23 155 -92 183 -48 20 -2963 20 -3008 0z"/>
            </g>
        </svg>

    )
}

export { ChevronUpSvg, ChevronDownSvg }
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Grid, Box, Icon, Card, Button, Avatar } from "@mui/material";

import TCNavigationBar from "components/TopCourtComponents/NavigationBar/TopCourtNavigationBar";
import { TCLabel, TCTitleLabel } from "components/TopCourtComponents/Label/TopCourtLabel";

import { getUserInfo } from "helper/localStorage";
import MKButton from "components/MKButton";
import { getTournament } from "service/tournamentService";
import { TCSelect } from "components/TopCourtComponents/Input/TopCourtInput";
import { NumericFormat } from "react-number-format";
import { color } from "helper/constant";

const statusOptions = [
    {label: "Idling", value: "idle"},
    {label: "Running", value: "running"},
    {label: "Finished", value: "finish"},
    {label: "All", value: "all"}
]

function TournamentList({

}) {

    const navigate = useNavigate()
    const [ tournaments, setTournaments ] = useState([])
    const [ userInfo, setUserInfo ] = useState(null)

    useEffect(() => {
        let JSONUserInfo = getUserInfo ? JSON.parse(getUserInfo) : null
        setUserInfo(JSONUserInfo)
        loadTournaments()
    }, [])

    const loadTournaments = async () => {
        let { data, error } = await getTournament()
        console.log(error)
        console.log(data)
        if (error) { console.log(error) }
        if (data) { setTournaments(data) }
    }

    return (
        <>
            <TCNavigationBar transparent={false} title={"Tournament"}/>
            <Grid sx={{p: 2}} direction={"column"}>
                {/* Running Tournament ?
                Idle Tournament ?
                Finsihed Tournament ?
                Create tournament can copy from old tournament */}
                <TCSelect
                    placeHolder={"Filter Status"}
                    options={statusOptions}
                />
                <Grid>
                    {
                        (userInfo && userInfo.club) &&
                        <Grid>                    
                            {/* <TCLabel bold>Create Tournament</TCLabel> */}
                            <div>
                                <MKButton sx={{fontSize: 10, p: 0, pl: 1, pr: 1}} color={"green"} 
                                onClick={() => 
                                    navigate("/tournament/start")
                                }>Start Tournament</MKButton>
                            </div>
                        </Grid>
                    }
                    <Grid>
                        {
                            tournaments.map( value => {
                                return (
                                    <TournamentCard data={value}/>
                                )
                            })
                        }
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

function TournamentCard ({
    data
}) {
    return (
        <Card key={data._id} sx={{
            mt: 1,
            p: 1
        }}  onClick={() => {
            navigate(`/payment/${data._id}/booking`)
        }}>
            <Grid container>
                <Grid item container xs={12}>
                    <Grid xs={8} lg={8}>
                        <TCTitleLabel>{data.name}</TCTitleLabel>
                    </Grid>
                    <Grid xs={4} lg={4} textAlign={"right"}>
                        <Box display="flex" justifyContent="flex-end">
                            <div className='custom-body' style={{
                                padding: '1px 5px',
                                borderRadius: 5,
                                // color: paymentStatus ? color.darkgreen : data.status != "failed" ? color.darkorange : 'white',
                                color: color.darkgreen,
                                fontSize: 10,
                                fontWeight: 'bold',
                                width: 'fit-content',
                                // backgroundColor: paymentStatus ? color.green : data.status != "failed" ? color.orange : color.red
                                backgroundColor: color.green
                            }}>
                                Running
                            </div> 
                        </Box>
                    </Grid>
                </Grid>
                <Grid item container xs={12}>
                    <Grid item container>
                        <Grid xs={8}>
                            <TCLabel>Start Date : 2024-02-12</TCLabel>
                            {
                                // if belum mulai
                                <TCLabel>Last Register Date : 2024-02-12</TCLabel>
                            }
                        </Grid>
                        <Grid xs={4} textAlign={"right"}>
                            <TCLabel>Participant: 10</TCLabel>
                        </Grid>
                    </Grid>
                    <Grid textAlign={"center"} xs={12} lg={12}
                        pt={1} pb={1}
                    >
                        {/* Winner Prize IDR {data.prizePool.first.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")} */}
                    </Grid>
                </Grid>
                <Grid item container xs={12} textAlign={"center"}>
                    <Grid xs={6} lg={6}>
                        <Grid xs={12}>
                            <TCLabel bold>Latest Match</TCLabel>
                            <TCLabel>Albert Wu vs Ben Shelton</TCLabel>
                        </Grid>
                    </Grid>
                    <Grid xs={6} lg={6} textAlign={"center"}>
                        <Grid xs={12}>
                            <TCLabel bold>Court Event</TCLabel>
                            <TCLabel>Semporna</TCLabel>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    )
}

export default TournamentList
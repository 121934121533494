import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Grid, Box, Icon, Card, Button, AppBar } from "@mui/material";
import { getPackages, addCartPackage } from "service/packagesService";

import { getUserInfo, setPackageCart, getPackageCart } from "helper/localStorage";

import TCNavigationBar from "components/TopCourtComponents/NavigationBar/TopCourtNavigationBar";
import { TCLabel, TCTitleLabel } from "components/TopCourtComponents/Label/TopCourtLabel";

import { currencyFormat } from "helper/numberFormat";
import Login from "pages/Auth/Login";
import SlideAlert from "components/SlideInAlert/SlideAlert";
import Loading from "components/TopCourtComponents/Loading/TCLoadingComponent";
import { color } from "helper/constant";
import MKButton from "components/MKButton";
import { UserPackageHeader } from "helper/user";
import { courtPackageTypeObj } from "helper/constant";
import { checkoutPackage } from "service/packagesService";
import { Confirm } from "helper/showAlert";
import PackCard from "./PackCard";
import { TCInput } from "components/TopCourtComponents/Input/TopCourtInput";
import { redeemPackage } from "service/packagesService";
import SlideDialog from "components/SlideDialog/SlideDialog";
import DialogHeader from "components/SlideDialog/DialogHeader";

import TCTabs from "components/TopCourtComponents/Tabs/TopCourtTabs";
import TCTabPanel from "components/TopCourtComponents/Tabs/TopCourtTabPanel";
import SessionHistory from "./SessionHistory";
import { memorizeTabs, getTabMemos } from "helper/tabsMemo";
import { Confirmation } from "helper/showAlert";
import { getMe } from "service/authService";
import EmptyData from "components/TopCourtComponents/Placeholder/EmptyData";

function Packages({
    
}) {

    const navigate = useNavigate()
    const userInfo = getUserInfo ? JSON.parse(getUserInfo) : null
    const cartInfo = getPackageCart ? JSON.parse(getPackageCart) : null
    const [ packages, setPackages ] = useState([])
    const [ showLogin, setShowLogin ] = useState(false)
    const [ showLoading, setShowLoading ] = useState(true)
    const [ showRedeemPage, setShowRedeemPage ] = useState(false)
    const [ rerender, setRerender ] = useState(Date.now())
    const [ activeTab, setActiveTab ] = useState(Number(getTabMemos('packages')) || 0)

    useEffect(() => {
        loadPackage()
    }, [])

    useEffect(() => {

    }, [rerender])

    useEffect(() => {
        if (userInfo && (cartInfo && cartInfo._id)) {
            onClickBuy(cartInfo)
        }
    }, [userInfo])

    const loadPackage = async () => {
        let { data, error } = await getPackages({
            userId: userInfo ? userInfo._id : null
        })
        if (error) { console.log(error) }
        if (data) { console.log(data); setPackages(data) }
        setShowLoading(false)
    }

    const onClickBuy = async (value) => {
        if (!userInfo) {
            // setPackageCart(value)
            setShowLogin(true)
            return;
        }
        if (!value) { return 'Select Package First'; }
        setShowLoading(true)
        let { data, error } = await addCartPackage({
            package: value ? value._id : "",
            nominal: value ? value.price : 0,
            user: userInfo._id
        })
        if (error) { console.log(error); setShowLoading(false) }
        setPackageCart(null)
        console.log(data)
        if (data) { 
            let { data, error } = await checkoutPackage({user: userInfo._id})
            if (error) { Confirm("", error) }
            if (data) { navigate(`/payment/${data.newOrder._id}/value-pack`) }
        }
    }

    return(
        <>
            <TCNavigationBar transparent={false} title={"Value Pack"} showLogin={showLogin} setShowLogin={setShowLogin}/>
            <AppBar position="static">
                <TCTabs
                    activeTab={activeTab}
                    tabs={[
                        'My Session',
                        'Voucher',
                        'History'
                    ]}
                    onChange={(event, newValue) => {
                        memorizeTabs('packages', newValue)
                        setActiveTab(newValue)
                    }}
                />
                <TCTabPanel boxPadding={0} value={activeTab} index={0}>
                    <Grid pt={1}>
                        <UserPackageHeader onClick={() => {
                            navigate('/court-booking')
                        }} label={"My Session"} rerender={rerender}/>
                    </Grid>
                    <div style={{overflow: 'auto', height: (userInfo && userInfo.activeBenefits) ? 'calc(100vh - 230px)' : 'calc(100vh - 150px)', marginTop: (userInfo && userInfo.activeBenefits) ? 1 : 0}}>            
                        <Grid container sx={{p: 0, pt: (userInfo && userInfo.activeBenefits) ? 1 : 0, pl: '16px', pr: '16px'}}>
                        {
                            packages.map(value => {
                                return (<PackCard value={value} onClickBuy={() => {
                                    Confirmation("", "Proceed to payment ?", () => {}, () => {
                                        onClickBuy(value)
                                    })
                                }}/>)
                            })
                        }
                        </Grid>
                        {
                            (!showLoading && packages.length <= 0) &&
                            <EmptyData />
                        }
                    </div>
                </TCTabPanel>
                <TCTabPanel boxPadding={0} value={activeTab} index={1}>
                    <div style={{overflow: 'auto', height: '84vh', marginTop: 15, paddingRight: 16, paddingLeft: 16}}>
                        <RedeemVoucher userInfo={userInfo}
                            showLogin={() => {
                                setShowRedeemPage(false)
                                setShowLogin(true)
                            }}
                        />
                    </div>
                </TCTabPanel>
                <TCTabPanel boxPadding={0} value={activeTab} index={2}>
                    <div style={{overflow: 'auto', height: '84vh', marginTop: 15}}>
                        <SessionHistory/>
                    </div>
                </TCTabPanel>
            </AppBar>
            {/* <SlideAlert
                isOpen={showLogin} 
                setIsOpen={() => {
                    if (showLogin) setShowLogin()
                }}
                maxWidth={"xs"}
            >
                {
                    showLogin &&
                    <Login isOpen={showLogin} dismissPopup={() => setShowLogin(false)}/>
                }
            </SlideAlert> */}
            <SlideDialog 
                isOpen={showRedeemPage} 
                setIsOpen={() => {
                    if (showRedeemPage) setShowRedeemPage()
                }}
                minHeight={'10vh'}
                maxWidth={"xs"}
            >
                {
                    showRedeemPage &&
                    <RedeemVoucher userInfo={userInfo} showLogin={() => {}} setOpen={(e) => {
                        setRerender(Date.now())
                        setShowRedeemPage(e)
                    }}/>
                }
            </SlideDialog>
            {
                showLoading &&
                <Loading />
            }
        </>
    )
}

function RedeemVoucher ({
    // setOpen
    userInfo,
    showLogin
}) {

    const [ voucherCode, setVoucherCode ] = useState("")

    useEffect(() => {
        if (!userInfo) { showLogin() }
    }, [userInfo])

    const useVoucher = async () => {
        let { data, error } = await redeemPackage({
            voucherCode
        })
        // setOpen(false)
        if (error) { Confirm("", error); setVoucherCode("") }
        if (data) { Confirm("", "Voucher redeemed");}
    }

    return (
        <>
            <Grid container>
                {/* <DialogHeader title={""} closeDialog={()=> { setOpen(false) }}/> */}
                <Grid item xs>
                    <TCInput label={"Voucher Code"} placeHolder={"Input min 9 digits of voucher code"}
                        value={voucherCode}
                        onChange={(e) => {
                            setVoucherCode(e.currentTarget.value.toUpperCase().trim())
                        }}
                    />
                </Grid>
                {/* <Grid item>
                <MKButton sx={{mt: 2}} fullWidth 
                    disabled={voucherCode.length < 9}
                    color="primary"
                    onClick={() => {
                        
                    }}>Redeem</MKButton>
                </Grid> */}
                <Grid sx={{ml: 3, mt: 2.3}}>
                    <MKButton color="white" fullWidth 
                    // disabled={selectValuePack || loading} 
                    disabled={voucherCode.length < 9}
                    sx={{
                        border: 1,
                        color: color.primary
                    }}
                    onClick={() => {
                        if (!userInfo) { showLogin(); return; }
                        useVoucher()
                    }}>
                        Redeem
                    </MKButton>
                </Grid>
            </Grid>
            {/* <Grid sx={{
                position: 'absolute',
                bottom: 16,
                left: 16,
                right: 16,
            }}>
                <MKButton sx={{mt: 2}} fullWidth 
                    disabled={voucherCode.length < 9}
                    color="primary"
                    onClick={() => {
                        if (!userInfo) { showLogin(); return; }
                        useVoucher()
                    }}>Redeem</MKButton>
            </Grid> */}
        </>
    )
}

export default Packages
import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import MKButton from "components/MKButton";
import { TCTitleLabel } from "components/TopCourtComponents/Label/TopCourtLabel";
import { TCLabel } from "components/TopCourtComponents/Label/TopCourtLabel";
import { color } from "helper/constant";
import { UserPackageHeader } from "helper/user";
import { courtPackageType } from "helper/constant";
import { getMe } from "service/authService";
import { setUserInfo } from "helper/localStorage";
import { loadUserCart, usePackageinCart } from "./Function";
import { Confirm } from "helper/showAlert";
import DialogHeader from "components/SlideDialog/DialogHeader";
import Loading from "components/TopCourtComponents/Loading/TCLoadingComponent";

function BookingPackSelection ({
    bookingData,
    setOpen,
    setSelectValuePack,
    setPackUsageInfo
}) {

    const type = courtPackageType
    const [ user, setUser ] = useState(null)
    const [ bookings, setBookings ] = useState({
        data: [],
        indoorSessions: 0,
        outdoorSessions: 0,
        hittingRoomSessions: 0
    })
    const [ selectedPack, setSelectedPack ] = useState([])
    const [ packSession, setPackSession ] = useState({
        allSession: {
            indoor: 0,
            outdoor: 0,
            hittingRoom: 0
        },
        outdoorSessions: 0,
        hittingRoomSessions: 0
    })
    const [ loading, setLoading ] = useState(false)


    useEffect(() => {
        loadUser()
        setBookingInformation()
        getUserCart()
    }, [])

    useEffect(() => {
        console.log(selectedPack)
    }, [selectedPack])

    const getUserCart = async () => {
        try {
            let { data, error } = await loadUserCart()
            if (error) { console.log(error) }
            if (data) { setPackSession(data.rawSession);
                let { allSession, outdoorSessions, hittingRoomSessions } = data.rawSession
                let { indoor, outdoor, hittingRoom } = allSession
                let allSessionTotal = indoor + outdoor + hittingRoom
                let allSessionSelected = []
                for (let i = 0; i < allSessionTotal; i ++) {
                    allSessionSelected.push(`all_court-${i}`)
                }
                for (let i = 0; i < outdoorSessions; i ++) {
                    allSessionSelected.push(`outdoor-${i}`)
                }
                for (let i = 0; i < hittingRoomSessions; i ++) {
                    allSessionSelected.push(`hitting_room-${i}`)
                }
                console.log('here user', data)
                setPackUsageInfo({
                    totalBefore: data.subTotal,
                    totalOrder: data.totalPrice,
                    selectedSession: data.totalSessionRedeemed,
                    totalSession: data.totalSessions
                })
                setSelectedPack(allSessionSelected)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const setBookingInformation = () => {
        let newBookings = []
        let indoorSessions = 0
        let outdoorSessions = 0
        let hittingRoomSessions = 0
        for (let i = 0; i < bookingData.length; i ++) {
            let { hours, court } = bookingData[i]
            if (hours.length > 0) {
                newBookings.push(bookingData[i])
                if (court.courtType === "indoor") {
                    indoorSessions += hours.length
                }
                if (court.courtType === "outdoor") {
                    outdoorSessions += hours.length
                }
                if (court.courtType === "hitting_room") {
                    hittingRoomSessions += hours.length
                }
            }
        }
        setBookings({
            data: newBookings,
            indoorSessions: indoorSessions,
            outdoorSessions: outdoorSessions,
            hittingRoomSessions: hittingRoomSessions
        })
    }

    const loadUser = async () => {
        let { data, error } = await getMe()
        if (error) { console.log(error) }
        if (data) { setUser(data); setUserInfo(data) }
    }

    const checkValuePackEnabled = (
        id,
        courtType,
        index
    ) => {
        // check if court Type is same
        // check if sessions is available
        if (selectedPack.length > 0) {
            if (selectedPack.indexOf(id) >= 0) { return 'selection-selected' }
        }
        let { allSession, outdoorSessions, hittingRoomSessions } = packSession
        let totalSessions = bookings.indoorSessions + bookings.outdoorSessions + bookings.hittingRoomSessions
        let totalUsed = totalSessions - (outdoorSessions + hittingRoomSessions)

        if (selectedPack.length >= totalSessions) {
            return 'selection-disabled-click'
        }

        if (courtType == "all_court") {
            if (index < (totalUsed)) {
                return 'selection-available'
            }
            else return 'selection-disabled-click'
        }
        if (courtType == "outdoor") {
            if (index < (bookings.outdoorSessions - allSession.outdoor)
            ) {
                return 'selection-available'
            }
            else return 'selection-disabled-click'
        }
        if (courtType == "hitting_room") {
            if (index < (bookings.hittingRoomSessions - allSession.hittingRoom)) {
                return 'selection-available'
            }
            else return 'selection-disabled-click'
        }
    }

    const selectValuePack = (e, courtType) => {
        let packs = selectedPack
        let exist = packs.indexOf(e)
        let addValue = 1
        if (exist >= 0) { packs.splice(exist, 1); addValue = -1 } else { packs.push(e) }
        let courtPack = packSession
        if (courtType == "all_court") {
            // use for indoor first
            // if indoor is full then use for outdoor
            // if outdoor is full then use for hitting room
            if (addValue < 0) {
                if (courtPack.allSession.hittingRoom > 0) { courtPack.allSession.hittingRoom += addValue }
                else if (courtPack.allSession.outdoor > 0) { courtPack.allSession.outdoor += addValue }
                else if (courtPack.allSession.indoor > 0) { courtPack.allSession.indoor += addValue }
            } else {
                if (bookings.indoorSessions !== courtPack.allSession.indoor) {
                    courtPack.allSession.indoor += addValue
                } else if (bookings.outdoorSessions >= 0
                    && bookings.outdoorSessions != (courtPack.allSession.outdoor + courtPack.outdoorSessions)) {
                    courtPack.allSession.outdoor += addValue   
                } else if (bookings.hittingRoomSessions >= 0
                    && bookings.hittingRoomSessions != (courtPack.allSession.hittingRoom + courtPack.hittingRoomSessions)) {
                    courtPack.allSession.hittingRoom += addValue   
                }
            }
        }
        if (courtType == "outdoor") {
            courtPack.outdoorSessions += addValue 
        }
        if (courtType == "hitting_room") {
            courtPack.hittingRoomSessions += addValue
        }
        setPackSession(prev => ({
            ...prev,
            ...courtPack
        }))
        setSelectedPack(prev => ([...packs]))
    }

    const useValuePack = async () => {
        if (selectedPack.length <= 0) { Confirm("", "Please select your pack"); return; }
        setLoading(true)
        let { data, error } = await usePackageinCart(packSession)
        if (error) { Confirm("", "Failed to use session") }
        if (data) { 
            // Confirm("", `Success use sessions. You save up to ${ data.totalBefore - data.totalOrder }`); 
            setPackUsageInfo({
                totalBefore: data.totalBefore,
                totalOrder: data.totalOrder,
                selectedSession: data.totalSessionRedeemed,
                totalSession: data.totalSessions
            })
            setSelectValuePack(true)
            setOpen(false)
        }
        setLoading(false)
    }

    const clearValuePack = async () => {
        setPackSession({
            allSession: {
                indoor: 0,
                outdoor: 0,
                hittingRoom: 0
            },
            outdoorSessions: 0,
            hittingRoomSessions: 0
        })
        setSelectedPack([])
        let { data, error } = await usePackageinCart({
            allSession: {
                indoor: 0,
                outdoor: 0,
                hittingRoom: 0
            },
            outdoorSessions: 0,
            hittingRoomSessions: 0
        })
        if (error) { Confirm("", "Try again") }
        if (data) {
            setPackUsageInfo({
                totalBefore: data.totalBefore,
                totalOrder: data.totalOrder,
                selectedSession: data.totalSessionRedeemed,
                totalSession: data.totalSessions
            })
            setSelectValuePack(false)
        }
        setOpen(false)
    }

    return (
        <>
            <Grid container p={2} pb={0} style={{
                backgroundColor: 'white',
                // minHeight: '85vh'
            }}>
                <DialogHeader title={"My Session"} closeDialog={()=>{setOpen(false)}}/>
                <Grid item xs container pb={1}>
                    <UserPackageHeader/>
                </Grid>
            </Grid>
            <Grid container p={2} pb={10} sx={{ overflow: 'auto' }}>
            {
                (user && user.activeBenefits) && type.map(value => {
                    if (user.activeBenefits.sessions[value.value] > 0) {
                        return ( 
                            <>
                                <TCLabel subtitle bold>{value.label}</TCLabel>
                                <Grid item container xs={12} pt={1} pb={1} textAlign={"center"} spacing={0.1} fullWidth>
                                    {
                                        Array(user.activeBenefits.sessions[value.value]).fill(1).map( (v, index) => {
                                            return (
                                                <Grid xs={3} item pb={0.5} key={`${value.value}-${index}`}>
                                                    <div 
                                                        // className={form.court === value._id ? 'selection-selected' : 'selection-available'} 
                                                        className={ checkValuePackEnabled(`${value.value}-${index}`, value.value, index) } 
                                                        style={{textAlign: 'center', padding: 2,
                                                        paddingLeft: 0, paddingRight: 0, marginRight: 2, borderRadius: 8}}
                                                        onClick={() => {
                                                            selectValuePack(`${value.value}-${index}`, value.value)
                                                        }}
                                                    >
                                                        <TCLabel inheritColor>{v} session</TCLabel>
                                                    </div>
                                                </Grid>
                                            )
                                        })
                                    }
                                </Grid> 
                            </>
                        )
                    }
                })
            }
            </Grid>
            <Grid pt={1} pr={2} pb={2} textAlign={"right"} sx={{
                width: '100%', background:'white', right: '0px', bottom: '0px', position: 'absolute'}}>
                <MKButton sx={{fontSize: 10, p: 0, pl: 1, pr: 1}} color={"white"} 
                    onClick={() => {clearValuePack()}}>Clear</MKButton>
                <MKButton sx={{fontSize: 10, p: 0, pl: 1, pr: 1}} color={"primary"} 
                    onClick={() => {useValuePack()}}>Use</MKButton>
            </Grid>
            {
                loading &&
                <Loading/>
            }
        </>
    )
}

export default BookingPackSelection
import React, { useState, useEffect } from "react";
// import Lottie from "react-lottie";
// import loadingTennis from "../../../assets/lottiejson/tennis_loading.json";
import loadingScreen from "../../../assets/loading-screen.gif";
import { Container } from "@mui/material";

export default function Loading({

}) {

    const [ isVisible, setIsVisible ] = useState(false)

    useEffect(() => {
        setTimeout(() => {
            setIsVisible(true)
        }, 500)
    }, [])

    // const defaultOptions = {
    //     loop: true,
    //     autoplay: true,
    //     animationData: loadingTennis,
    //     rendererSettings: {
    //       preserveAspectRatio: "xMidYMid slice"
    //     }
    // };

    return (
        <Container sx={{maxWidth: 'none !important', padding: '0px !important', margin: '0px !important'}}>
            <div style={{
                backgroundColor: '#1d4e8920',
                // backgroundColor: 'transparent',
                position: 'fixed',
                zIndex: 999999,
                top: 0,
                width: '100%',
                left: 0,
                height: '100vh',
                paddingTop: '50vh',
                backdropFilter: 'blur(5px)',
                display: isVisible ? '' : 'none'
            }}>
                {/* <Lottie 
                    options={defaultOptions}
                    height={'60vh'}
                    width={'50vh'}
                /> */}
                <div style={{textAlign: 'center'}}>
                    <img src={loadingScreen} height={'80px'} width={'80px'}/>
                </div>
            </div>
        </Container>
    )
}
import React, { useState } from "react";

import { AppBar } from "@mui/material";

import TCNavigationBar from "components/TopCourtComponents/NavigationBar/TopCourtNavigationBar";

import OnProgressOrders from "./Orders/OnProgress";
import CompleteOrders from "./Orders/Complete";
import TCTabs from "components/TopCourtComponents/Tabs/TopCourtTabs";
import TCTabPanel from "components/TopCourtComponents/Tabs/TopCourtTabPanel";
import { getTabMemos, memorizeTabs } from "helper/tabsMemo";


function Orders ({

}) {

    const [ activeTab, setActiveTab ] = useState(Number(getTabMemos('orders')) || 0)

    return(
        <>
            <TCNavigationBar transparent={false} title={"My Order"}/>
            <AppBar position="static">
                <TCTabs
                    activeTab={activeTab}
                    tabs={[
                        'On Going',
                        'Completed'
                    ]}
                    onChange={(event, newValue) => {
                        memorizeTabs('orders', newValue)
                        setActiveTab(newValue)
                    }}
                />
                <TCTabPanel boxPadding={0} value={activeTab} index={0}>
                    <div style={{overflow: 'auto', height: '84vh', marginTop: 15, paddingRight: 16, paddingLeft: 16}}>
                        <OnProgressOrders/>
                    </div>
                </TCTabPanel>
                <TCTabPanel boxPadding={0} value={activeTab} index={1}>
                    <div style={{overflow: 'auto', height: '84vh', marginTop: 15, paddingRight: 16, paddingLeft: 16}}>
                        <CompleteOrders/>
                    </div>
                </TCTabPanel>
            </AppBar>
        </>
    )
}

export default Orders
import React, { useEffect, useState } from "react"
import { Grid } from "@mui/material"
import { TCLabel } from "components/TopCourtComponents/Label/TopCourtLabel"
import { TCInput } from "components/TopCourtComponents/Input/TopCourtInput"
import { color } from "helper/constant"
import { currencyFormat } from 'helper/numberFormat'

import MKButton from "components/MKButton"
import Coupon from "components/TopCourtComponents/Coupon/Coupon"
import { checkCouponAvaibilityReturn, loadCouponPopup } from "./Function"

function UseCoupon ({
    calculateSummary,
    setCoupon,
    coupon,
    loading,
    setLoading,
    booking
}) {

    const [ couponCode, setCouponCode ] = useState("")
    const [ couponAvailable, setCouponAvailable ] = useState(null)
    const [ couponError, setCouponError ] = useState("")
    const [ totalSession, setTotalSession ] = useState(0)

    useEffect(() => {
        loadCouponPopup(setCouponAvailable)
    }, [])
    
    useEffect(() => {
        if (couponCode == "") { setCouponError("") } else {
            let { totalSession } = calculateSummary()
            setTotalSession(totalSession)
        }
    }, [couponCode])

    const checkCoupon = async () => {
        let { totalSession, totalPrice, totalPack } = calculateSummary()
        let { data, error } = await checkCouponAvaibilityReturn(couponCode, totalSession, 
            totalPrice, setLoading)
        if (data) {
            setCoupon(data)
        }
        if (error) {
            setCouponError(error)
        }
    }

    return (
        <Grid container mt={1} mb={1} justifyContent={"flex-start"} 
            style={{paddingLeft: 16, height: 80}}>
                {
                    coupon &&
                    <>
                        <Grid xs={12} sx={{ pr: 2 }}>
                            <Coupon data={coupon} setCoupon={setCoupon} setCouponCode={setCouponCode}/>
                        </Grid>
                        <Grid sx={{
                            pt: 1,
                            pr: 2
                        }} container direction={"column"} 
                            textAlign={'right'}
                            justifyContent={'flex-end'}>
                            <Grid item>
                                <TCLabel>{currencyFormat(calculateSummary().totalPrice + coupon.amount)}</TCLabel>
                            </Grid>
                            <Grid item>
                                <TCLabel style={{color: color.pink}}><span style={{fontSize: 10}}>({coupon.promoCode})</span> <span>-</span>{currencyFormat(coupon.amount)}</TCLabel> 
                            </Grid>
                        </Grid>
                    </>
                }
                {
                    !coupon &&
                    <>
                        <Grid xs={8}>
                            <TCLabel>Promo Code</TCLabel>
                            <TCInput label={""} 
                                // disabled={selectValuePack}
                                value={couponCode}
                                placeHolder={"Input Promo Code"}
                                onChange={(e) => {
                                    setCouponCode(e.currentTarget.value.toUpperCase().trim())
                                }}
                            />
                            {
                                (couponAvailable && couponError == "") &&
                                <TCLabel subtitle>
                                    Use {couponAvailable.promoCode} to get {currencyFormat(couponAvailable.amount)} off
                                </TCLabel>   
                            }
                            {
                                (couponError != "") &&
                                <TCLabel subtitle style={{color: color.pink}}>
                                    {couponError}
                                </TCLabel>   
                            }
                        </Grid>
                        <Grid sx={{ml: 3, mt: 2.5}}>
                            <MKButton color="white" fullWidth 
                            // disabled={selectValuePack || loading} 
                            disabled={loading || !couponCode || totalSession <= 0} 
                            sx={{
                                border: 1,
                                color: color.primary
                            }}
                            onClick={() => {
                                let { totalSession, totalPrice, totalPack } = calculateSummary()
                                if (totalSession <= 0) { 
                                    setCouponError("Select your schedule"); 
                                    return;
                                }
                                if (!couponCode) {
                                    // Confirm("", "Promo code is empty")
                                    setCouponError("Promo code is empty")
                                    return;
                                }
                                checkCoupon()
                            }}>
                                Apply
                            </MKButton>
                        </Grid>
                    </>
                }
        </Grid>
    )
}

export default UseCoupon
import { Badge, FormControlLabel, Grid, Icon, Switch } from "@mui/material";
import MKButton from "components/MKButton";
import { TCInput } from "components/TopCourtComponents/Input/TopCourtInput";
import { TCLabel } from "components/TopCourtComponents/Label/TopCourtLabel";
import TCNavigationBar from "components/TopCourtComponents/NavigationBar/TopCourtNavigationBar";
import React, { useEffect, useState } from "react";
import { color } from "helper/constant"; 
import { createClub, getClubDetail, updateClub } from "service/clubService";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import clubPlaceholder from "../../assets/club-placeholder.png";
import { Action } from "helper/showAlert";
import { handleUpload } from "helper/S3";
import Loading from "components/TopCourtComponents/Loading/TCLoadingComponent";
import { validation } from "helper/form";

var toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
];

function AddClub ({
    isPopup = false,
    club_id,
}) {

    // upload image
    // save information
    const url = window.location.href.split('/')
    const clubid = club_id ? club_id : url[url.length - 1] 

    const [ form, setForm ] = useState({
        logo: "",
        name: "",
        highlightDescription: "",
        description: "",
        private: false,
        members: []
    })

    const [ loading, setLoading ] = useState(false)
    const [ joi, setJOI ] = useState({
        name: "",
        highlightDescription: ""
    })

    useEffect(() => {
        if (clubid !== "add-club") { loadClubDetail() }
    }, [])

    useEffect(() => {
        if (!validation.isNotEmpty(form.name)) {
            setJOI({ name: "*" }); 
            return
        }
        if (!validation.userName(form.name)) {
            setJOI({ name: "Club name maximum character is 20" }); 
            return
        } else { setJOI({ name: "" }); }
        if (!validation.charaCount(form.highlightDescription, 200)) {
            setJOI({ highlightDescription: "Description maximum character is 200" }); 
            return
        } else { setJOI({ highlightDescription: "" }); }
    }, [form])

    const onChange = (e) => {
        setForm(prev => ({
            ...prev,
            ...e
        }))
    }

    const loadClubDetail = async() => {
        try {
            let { data, error } = await getClubDetail(clubid);
            if (error) { throw error }
            if (data) {
                console.log(data)
                setForm(prev => ({
                    ...prev,
                    ...data
                }))
            }
        } catch (error) {
            console.log(error)
        }
    }

    const saveClubInformation = async () => {
        try {
            setLoading(true)
            let addClubForm = form
            console.log('here addClub', form)
            if (form._id) {
                let { data, error } = await updateClub(addClubForm)
                if (error) { throw error }
                console.log(data)
                Action("", "Club updated", () => { window.location.reload() })
                setLoading(false)
            } else {
                if (form.logo) {
                    let imageUrl = await handleUpload({
                        selectedFile: form.logo, dirName: `topcourt-staging/club-logo`
                    })
                    addClubForm.logo = imageUrl[0]
                }
                console.log('here addClub', addClubForm)
                let { data, error } = await createClub(addClubForm)
                if (error) { throw error }
                console.log(data)
                Action("", "Club created", () => { window.location.reload() })
                setLoading(false)
            }
        } catch (error) {
            if (error == 'Club name already exist') {
                setJOI(prev =>({
                    ...prev,
                    name: error
                }))
            }
            setLoading(false)
        }
    }


    return (
        <>
            {
                !isPopup &&
                <>
                    <TCNavigationBar transparent={false} title={form._id ? "Club Info" : "Add New Club"}/>
                    {/* <Grid sx={{pt: 7}} justifyContent={"center"} textAlign={"center"}/> */}
                </>
            }
            <Grid sx={ !isPopup ? {p: 2} : {}}>
                {
                    !club_id &&
                    <Grid xs={12} textAlign={"center"}>
                        {/* Product Images */}
                        <Badge overlap="circular"
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right'}}
                            badgeContent={<label htmlFor="photo-create" className="fas" style={{backgroundColor: color.primary, color: 'white', borderRadius: 10, width: 20, height: 20, 
                                display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <Icon>create</Icon>
                            </label>}>
                            <label htmlFor="photo-create" className="fas">
                                <div className="img-wrap img-upload" >
                                <img for="photo-create" 
                                    accept="image/*"
                                    style={{width: 'auto', height: '100%'}}
                                    src={form.logo ? typeof form.logo == "string" ? form.logo : URL.createObjectURL(form.logo) : clubPlaceholder}/>
                                </div>
                                <input id="photo-create" type="file" style={{display: 'none'}} 
                                accept="image/*"
                                onChange={(e) => {
                                    onChange({logo: e.target.files[0]})
                                }}/> 
                            </label>
                        </Badge>
                    </Grid>
                }
                <Grid item pt={3}>
                    <TCInput label={"Club Name"} 
                        showAlert={joi.name}
                        placeHolder={"Input club name"}
                        value={form.name}
                        onChange={(e) => {
                            onChange({ name: e.currentTarget.value })
                        }}
                    />
                </Grid>
                <Grid>
                    <FormControlLabel control={<Switch 
                    sx={{
                        // ".MuiSwitch-thumb": {
                        //   backgroundColor: color.blueIndoor_2
                        // },
                        // ".MuiSwitch-track": {
                        //   backgroundColor: color.blueIndoor_2
                        // },
                        "& .MuiSwitch-switchBase": {
                          "&.Mui-checked": {
                            "+ .MuiSwitch-track": {
                                backgroundColor: `#0255971A !important`,
                                borderColor: `#0255971A !important`
                            },
                            ".MuiSwitch-thumb": {
                              backgroundColor: color.blueIndoor_1,
                              border: 'none'
                            }
                          }
                        }
                    }}
                    checked={form.private} onChange={() => {
                        onChange({private: !form.private})
                    }} />} label={<TCLabel>{form.private ? "Private" : "Public"}</TCLabel>} />
                </Grid>
                <Grid>
                    <TCInput showCharacter={
                        joi.highlightDescription ?
                        <TCLabel style={{fontSize: 10, color: color.pink}}>{`${form.highlightDescription.length}/200`}</TCLabel>
                        :
                        <TCLabel style={{fontSize: 10, color: color.grey}}>{`${form.highlightDescription.length}/200`}</TCLabel>
                    } label="Descriptions" 
                        value={form.highlightDescription}
                        type={"textarea"} rows={4} onChange={(e) => {
                            onChange({ highlightDescription: e.currentTarget.value })
                    }} />
                </Grid>
                <Grid sx={{pt: 1}}>
                    <TCLabel>Rules</TCLabel>
                    <ReactQuill theme="snow" value={form.description}
                        modules={{toolbar: toolbarOptions}}
                        onChange={(e) => {
                            onChange({ description: e })
                        }}
                        
                    />
                </Grid>
                {/* <Grid sx={{pt: 1}}>
                    <TCInput label={"Club's Court"} 
                        placeHolder={"Input club court"}
                        onChange={(e) => {
                            onChange({ name: e })
                        }}
                    />
                </Grid> */}
            </Grid>
            <Grid style={{
                position: 'absolute',
                bottom: 16,
                left: 16,
                right: 16
            }}>
                <MKButton sx={{fontSize: 10, p: 0, mt: 2}} color={"primary"} 
                    disabled={loading || joi.name || joi.highlightDescription}
                    fullWidth onClick={() => 
                        saveClubInformation()
                    }>{form._id ? "Update" : "Create"}</MKButton>
            </Grid>
            {
                loading &&
                <Loading/>
            }
            {/* <div>Hello World</div> */}
        </>
    )
}

export default AddClub
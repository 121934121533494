import { bubbleSort } from "helper/sort";

const groupSession = (
    userSessions
) => {
    let newOrder = []
    for (let i = 0 ; i < userSessions.length; i ++) {
        if (newOrder.length == 0) {
            newOrder.push({
                date: userSessions[i].date,
                courts: [{
                    ...userSessions[i].court,
                    hours: [ ...userSessions[i].hours ]
                }],
            })
        } else {
            let similiarDate = false
            for (let j = 0; j < newOrder.length; j ++) {
                console.log(newOrder[j])
                if (newOrder[j].date === userSessions[i].date) {
                    similiarDate = true
                    let courts = newOrder[j].courts
                    let similiarCourts = false
                    for (let k = 0; k < courts.length; k++) {
                        console.log('here new order - check null', courts[k], userSessions[i])
                        console.log('here new order - check null', courts[k]._id, userSessions[i].court._id)
                        if (courts[k]._id.toString() == userSessions[i].court._id.toString()){
                            courts[k].hours = courts[k].hours.concat(userSessions[i].hours)
                            courts[k].hours = bubbleSort(courts[k].hours)
                            similiarCourts = true
                        }
                    }
                    if (!similiarCourts) {
                        newOrder[j].courts.push({
                            ...userSessions[i].court,
                            hours: [ ...userSessions[i].hours ]
                        })
                    }
                }
            }
            if (!similiarDate) {
                newOrder.push({
                    date: userSessions[i].date,
                    courts: [{
                        ...userSessions[i].court,
                        hours: [ ...userSessions[i].hours ]
                    }],
                })
            }
        }
    }
    for (let i = 0; i < newOrder.length; i ++) {
        newOrder[i].courts.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
    }
    newOrder.sort((a,b) => (a.date > b.date) ? 1 : ((b.date > a.date) ? -1 : 0))
    return newOrder
}

export { groupSession }
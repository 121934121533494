import React, { useEffect, useState } from "react"
import { Card, Grid } from "@mui/material"
import { TCLabel } from "components/TopCourtComponents/Label/TopCourtLabel"
import moment from "moment"
import DeleteIcon from '@mui/icons-material/Delete';
import { courtTypeObj } from "helper/constant"
import { color } from "helper/constant";
import { deleteCart } from "../Function";
import { timeStringFormat } from "helper/constant";
import { currencyFormat } from "helper/numberFormat";
import { getUserAvatar } from "helper/user";

function ItemList ({
    items,
    loadData,
    bottomSheetHeight
}) {
    
    const [ itemBooking, setItemBooking ] = useState([])

    useEffect(() => {
        groupByDate()
    }, [items])

    useEffect(() => {
        console.log('here check item booking', itemBooking)
    }, [itemBooking])

    // group by user
    const groupByDate = () => {
        let newItems = []
        for (let i = 0; i < items.length; i ++) {
            // check session
            let currSession = items[i].sessions
            for (let j = 0; j < currSession.length; j ++) {
                if (newItems.length == 0) {
                    newItems.push({
                        date: currSession[j].date,
                        data: [{
                            ...items[i],
                            sessions: currSession[j]
                        }],
                    })
                } else {
                    // check if exist 
                    let exist = false
                    let existIndex = 0
                    for (let k = 0; k < newItems.length; k ++) {
                        if (newItems[k].date === currSession[j].date) {
                            exist = true
                            existIndex = [k]
                        }
                    }
                    if (exist) {
                        newItems[existIndex].data = newItems[existIndex].data.concat({
                            ...items[i],
                            sessions: currSession[j]
                        })
                    } else {
                        newItems.push({
                            date: currSession[j].date,
                            data: [{
                                ...items[i],
                                sessions: currSession[j]
                            }],
                        })
                    }
                }
            }
        }
        newItems.sort((a,b) => (a.date > b.date) ? 1 : ((b.date > a.date) ? -1 : 0))
        setItemBooking(newItems)
    }

    let ratio = bottomSheetHeight / window.innerHeight
    let height = (bottomSheetHeight * ratio)
    let newHeight = (bottomSheetHeight - height)

    return (
        <Grid container alignItems="center" style={{
            marginTop: 0.5, paddingLeft: 16, paddingRight: 16, paddingBottom: 8, 
            marginBottom: newHeight,
            overflow: 'auto'}}>
            {
                itemBooking.map((value, index) => {
                    return (
                        <Grid key={value.date} xs={12} md={12} lg={12} item style={{textAlign: 'left', marginTop: 5}}>
                            <Card>
                                <div className='custom-body' style={{padding: 10}}>
                                    <TCLabel style={{fontSize: 13, fontWeight: 'bold'}}>{moment(value.date).format("ddd DD/MM/YYYY")}</TCLabel>
                                    {
                                        value.data.map( (v, idx) => {
                                            return(
                                                <>
                                                    <div style={{display: 'flex', marginTop: 2}} onClick={() => {
                                                        // onSelectBooking(v)
                                                    }}>
                                                        <div style={{display: 'flex', width: 100, margin: 8, marginTop:0, marginLeft: 0, alignItems: 'top'}}>
                                                            <img src={v.productId.itemImages.length > 0 ? v.productId.itemImages[0] : getUserAvatar(v.productId.user)} 
                                                                width={'100%'}
                                                                style={{
                                                                    objectFit: 'contain', minHeight: 80, maxHeight: 80
                                                                }}
                                                            />
                                                        </div>
                                                        <div style={{width: '100%'}}>
                                                        <TCLabel style={{fontSize: 12, fontWeight: 'bold'}}>{`${v.productId.category.name} : ${v.productId.name}`}</TCLabel>
                                                        <TCLabel style={{fontSize: 12, fontWeight: 'bold'}}>{courtTypeObj[v.sessions.court.courtType]} {v.sessions.court.name}</TCLabel>
                                                        <Grid container direction={'row'}>
                                                        {
                                                            v.sessions.hours.map((h, index) => {
                                                                return <TCLabel style={{marginRight: 3, fontSize: 12}}>{`${h} - ${timeStringFormat(Number(h.split(':')[0]) + 1)} 
                                                                    ${index < (v.sessions.hours.length - 1) ? " | " : ""}`}</TCLabel>
                                                            })
                                                        }   
                                                        </Grid>
                                                        <TCLabel>Total: <span style={{fontWeight: 'bold'}}>{currencyFormat(v.productId.price * v.sessions.hours.length)}</span></TCLabel>
                                                            {/* 
                                                            
                                                             */}
                                                        </div>
                                                        <div style={{alignItems: 'right'}} onClick={(e) => {
                                                            e.stopPropagation()
                                                            deleteCart({...v, isExpired: false}, loadData)
                                                        }}>
                                                            <DeleteIcon style={{width: 20, height: 20, color: color.pink}}/>
                                                        </div>
                                                    </div>
                                                    {(idx < (value.data.length - 1)) && <div className="separation-line" />}
                                                </>
                                            )
                                        })
                                    }
                                </div>
                            </Card>
                        </Grid>
                    )
                })
            }
        </Grid>
    )
}

export default ItemList
import Main from "pages/Main";

import Packages from "pages/Packages/Packages";
// import SessionHistory from "pages/Packages/SessionHistory";

import BookingCourt from "pages/Booking/BookingCourt";
import Profile from "pages/Profile/Profile";
import ProfileDetail from "pages/Profile/ProfileDetail";

import Notifications from "pages/Notification/Notifications";

import AddClub from "pages/Club/AddClub";
import AddOns from "pages/AddOns/AddOns";
import Bookings from "pages/Orders/Bookings";
import Club from "pages/Club/Club";
import Class from "pages/Class/Class";
import Clubs from "pages/Club/Clubs";
import Orders from "pages/Orders/Orders";
import ClassDetail from "pages/Class/ClassDetail";
import Payment from "pages/Payment/Payment";
import Players from "pages/Players/Players";
import Player from "pages/Players/Player";
import TournamentList from "pages/Tournament/TournamentList";
import Tournament from "pages/Tournament/Tournament";
import ServiceSchedule from "pages/Profile/ServiceSchedule";

const tennisRoutes = [
  {
    name: "Home",
    route: "/",
    component: <Main />,
  },
  {
    name: "Packages",
    route: "/packages",
    component: <Packages />
  },
  // {
  //   name: "Session History",
  //   route: "/session-history",
  //   component: <SessionHistory />
  // },
  {
    name: "Court Booking",
    route: "/court-booking",
    component: <BookingCourt />
  },
  {
    name: "Payment",
    route: "/payment/:type",
    component: <Payment />
  },
  {
    name: "Payment",
    route: "/payment/:id/:type",
    component: <Payment />
  },
  {
    name: "Orders",
    route: "/orders",
    component: <Orders />
  },
  {
    name: "Booking",
    route: "/bookings",
    component: <Bookings />
  },
  {
    name: "Players",
    route: "/players",
    component: <Players />
  },
  {
    name: "Players",
    route: "/player/:id",
    component: <Player />
  },
  {
    name: "Clubs",
    route: "/clubs",
    component: <Clubs />
  },
  {
    name: "Add Club",
    route: "/add-club",
    component: <AddClub />
  },
  {
    name: "Club",
    route: "/club/:id",
    component: <Club />
  },
  {
    name: "Tournament",
    route: "/tournament",
    component: <TournamentList />
  },
  {
    name: "Tournament",
    route: "/tournament/:id",
    component: <Tournament />
  },
  {
    name: "AddOns",
    route: "/add-ons",
    component: <AddOns />
  },
  {
    name: "Class",
    route: "/class",
    component: <Class />,
  },
  {
    name: "Class Detail",
    route: "/class/d/:id",
    component: <ClassDetail/>
  },
  {
    name: "Class Detail",
    route: "/class/detail/:id",
    component: <ClassDetail/>
  },
  {
    name: "Profile",
    route: "/profile",
    component: <Profile />
  },
  {
    name: "Profile",
    route: "/profile-detail",
    component: <ProfileDetail />
  },
  {
    name: "Schedule",
    route: "/my-schedule",
    component: <ServiceSchedule />
  },
  {
    name: "Notifications",
    route: "/notifications",
    component: <Notifications />
  }
];

export default tennisRoutes;

import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Checkbox, FormControlLabel, Grid } from "@mui/material"
import { checkBooking } from "service/classService"
import DropZoneComponent from "components/TopCourtComponents/Dropzone/DropzoneComponent"
import { TCLabel } from "components/TopCourtComponents/Label/TopCourtLabel"
import MKButton from "components/MKButton"
import { TCInput } from "components/TopCourtComponents/Input/TopCourtInput"
import { color } from "helper/constant"
import { BookingScheduleList } from "helper/schedule"
import { bubbleSort } from "helper/sort"
import { NumericFormat } from "react-number-format"
import { createClass } from "service/classService"
import { Confirm } from "helper/showAlert"
import { handleUpload } from "helper/S3"
import { groupSession } from "helper/booking"
import TCAccordion from "components/TopCourtComponents/Accordion/TopCourtAccordion"
import moment from "moment"
import ReactQuill from "react-quill"
import Loading from "components/TopCourtComponents/Loading/TCLoadingComponent"
import { validation } from "helper/form"


var toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],

    // [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    // [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
    // [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
    // [{ 'direction': 'rtl' }],                         // text direction

    // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

    // [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    // [{ 'font': [] }],
    // [{ 'align': [] }],

    // ['clean']                                         // remove formatting button
];

function ClassForm ({
    setOpenForm
}) {

    const [ form, setForm ] = useState({
        classImage: null,
        classImages: [],
        name: "",
        date: "",
        court: "",
        hours: [],
        playersCapacity: "",
        minAge: "",
        maxAge: "",
        fee: "",
        gender: ["male", "female"],
        feeNominal: null,
        description: "",
        paymentMethod: "direct",
    })
    const navigate = useNavigate()
    const [ bookingList, setBookingList ] = useState([])
    const [ currentClass, setCurrentClass ] = useState([])
    const [ loading, setLoading ] = useState(false)
    const [ joiForm, setJoiForm ] = useState({
        name: null,
        date: null,
        court: null,
        hours: null,
        playersCapacity: null,
        minAge: null,
        maxAge: null,
        fee: null,
    })

    useEffect(() => {
        loadBooking()
    }, [])

    useEffect(() => {
        console.log('here check form', !form.minAge)
        setJoiForm({
            name: form.name ? null : "*",
            date: form.date ? null : "*",
            court: form.court ? null : "*",
            hours: form.hours && form.hours.length > 0 ? null : "*",
            playersCapacity: form.playersCapacity ? (form.playersCapacity > form.hours.length * 20) ? `Maximum players for ${form.hours.length} sessions is ${form.hours.length * 20}` : null : "*",
            minAge: !form.minAge || (form.minAge && Number(form.minAge) > 0) ? null : "*",
            maxAge: (!form.minAge || !form.maxAge) || ((form.minAge && form.maxAge) && (form.minAge <= form.maxAge)) ? null : "*",
            fee: form.fee ? null : "*",
            description: form.description.length < 500 ? null : "*"
        })
    }, [form])

    const onChange = (e) => {
        setForm(prev => ({
            ...prev,
            ...e
        }))
    }

    const deleteClassImages = (index) => {
        let currImages = form.classImages
        classImages.splice(index, 1);
        onChange({ classImages: currImages })
      }

    // load user booking Information check if there is booking in 1 month advance
    const loadBooking = async () => {
        const { data, error } = await checkBooking()
        if (data) { console.log(data); 
            let newBookings = []
            newBookings = groupSession(data.userSessions)
            setBookingList(newBookings)
            setCurrentClass(data.userClass)
        }
    }

    const submitClass = async () => {
        let newBody = {
            ...form,
            fee: form.feeNominal,
            minAge: form.minAge > 0 ? form.minAge: null,
            maxAge: form.maxAge > 0 ? form.maxAge: null,
            gender: form.gender.length > 1 ? "all" : form.gender[0]
        }

        setLoading(true)

        if (form.classImage) {
            for (let i = 0; i < form.classImage.length; i ++) {
                let imageUrl = await handleUpload({
                    selectedFile: form.classImage[0], dirName: `topcourt-staging/class`
                })
                newBody.classImages.push(imageUrl[0])
            }
            newBody.classImage = newBody.classImages[0]
        }

        let { data, error } = await createClass(newBody);
        if (data) { Confirm("", "Class created"); setOpenForm(false);}
        
        setLoading(false)

    } 

    const disabledForm = () => {
        return loading || joiForm.name || joiForm.date || joiForm.hours || joiForm.playersCapacity ||
        joiForm.maxAge || joiForm.fee
    }

    return (
        <>
            <Grid container xs={12}>
                <Grid xs={10} p={2} textAlign={"left"} sx={{zIndex: 10, backgroundColor: 'white'}}>
                    <TCLabel bold style={{
                        fontSize: 15
                    }}>Create Event</TCLabel>
                </Grid>
                <Grid xs={2} p={2} textAlign={"right"} sx={{zIndex: 10, backgroundColor: 'white'}} onClick={() => {
                        setOpenForm(false)
                    }}>
                    <TCLabel bold>x</TCLabel>
                </Grid>
            </Grid>
            { 
                bookingList.length <= 0 && 
                <Grid xs={12} p={1} textAlign={"center"}>
                    <TCLabel>There is no booking in the next {30} days.</TCLabel>
                    <TCLabel>Please book your court first</TCLabel>
                    <MKButton color="white" 
                        onClick={() => {
                            navigate("/court-booking")
                        }}
                        sx={{
                            marginTop: 2,
                            border: 1,
                            color: color.primary
                        }}
                    >
                        <span style={{marginTop: 2}}>{"Book Court"}</span>
                    </MKButton>
                </Grid>
            }
            {
                bookingList.length > 0 && 
                <>
                    <Grid container p={2} pt={0} rowSpacing={1} style={{overflow: 'auto'}}>
                        <Grid item xs={12}>
                            <TCInput showAlert={joiForm.name} label={"Class Title"} value={form.name} onChange={(e) => { 
                                if (e.currentTarget.value.length > 50) { return; }
                                onChange({name: e.currentTarget.value}) 
                            }}/>
                        </Grid>
                        <Grid item container xs={12} rowSpacing={1}>
                            <Grid item container spacing={1}>
                                <Grid item>
                                    <TCLabel>Select Class Session Time</TCLabel>
                                </Grid>
                                <Grid item>
                                    <TCLabel style={{color: color.pink}}>{joiForm.hours}</TCLabel>
                                </Grid>
                            </Grid>
                            {
                                bookingList.map(value => {
                                    return(
                                        <Grid item xs={12} mb={1}>
                                            <TCAccordion title={moment(value.date).format("DD/MM/YYYY")}>
                                                {
                                                    value.courts.map(v => {
                                                        return (
                                                            <BookingScheduleList data={{
                                                                ...v, date: value.date
                                                            }} court={form.court} date={form.date} 
                                                                allowMultipleCourt={false}
                                                                allowMultiple={false} 
                                                                disableSelectionClass={{
                                                                    currentClass: currentClass,
                                                                    court: v._id, 
                                                                    date: value.date
                                                                }}
                                                                onChangeSchedule={(e) => {
                                                                onChange({
                                                                    courtInfo: {
                                                                        name: v.name,
                                                                        courtType: v.courtType,
                                                                    },
                                                                    court: v._id,
                                                                    courts: [v._id],
                                                                    courtsInfo: [{
                                                                        name: v.name,
                                                                        courtType: v.courtType,
                                                                    }],
                                                                    date: e.date,
                                                                    hours: e.hours
                                                                })
                                                            }}/>
                                                        )
                                                    })    
                                                }
                                            </TCAccordion>
                                        </Grid>
                                    )
                            })}
                        </Grid>
                        <Grid item xs={12}>
                            <TCInput showAlert={joiForm.playersCapacity} type="tel" value={form.playersCapacity} label={"Max Players"} onChange={(e) => { 
                                if (!validation.isNumber(e.currentTarget.value)) { return; }
                                if (Number(e.currentTarget.value) < 0) { return;}
                                onChange({playersCapacity: !e.currentTarget.value ? null : Number(e.currentTarget.value)}) 
                            }}/>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid item container spacing={1}>
                                <Grid item>
                                    <TCLabel>Class Fee per Player (in IDR)</TCLabel>
                                </Grid>
                                <Grid item>
                                    <TCLabel style={{color: color.pink}}>{joiForm.fee}</TCLabel>
                                </Grid>
                            </Grid>
                            <NumericFormat value={form.fee}
                                onValueChange={(values) => {
                                    const {formattedValue, value, floatValue} = values;
                                    // do something with floatValue
                                    onChange({ fee: formattedValue.replace(/^0+/, ''), feeNominal: value.replace(/^0+/, '') })
                                }}
                                allowLeadingZeros={false}
                                allowedDecimalSeparators={false}
                                allowNegative={false}
                                thousandSeparator
                                customInput={TCInput}/>
                        </Grid>
                        <Grid item xs={12} >
                            <TCLabel>Gender</TCLabel>
                            <Grid item container style={{marginTop: -8}} columnSpacing={1}>
                                <Grid item>
                                    <FormControlLabel control={<Checkbox checked={form.gender.indexOf("male") >= 0 ? true : false} onChange={() => {
                                        let genders = form.gender
                                        if (genders.indexOf("male") >= 0) {
                                            onChange({gender: ["female"]})
                                        } else {
                                            onChange({gender: ["male", "female"]})
                                        }
                                    }} />} label={<TCLabel>Male</TCLabel>} />
                                </Grid>
                                <Grid item>
                                    <FormControlLabel control={<Checkbox checked={form.gender.indexOf("female") >= 0 ? true : false} onChange={() => {
                                        let genders = form.gender
                                        if (genders.indexOf("female") >= 0) {
                                            onChange({gender: ["male"]})
                                        } else {
                                            onChange({gender: ["male", "female"]})
                                        }
                                    }} />} label={<TCLabel>Female</TCLabel>} /> 
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item container xs={12} columnSpacing={1}>
                            <Grid item xs={6}>
                                <TCInput showAlert={joiForm.minAge} type="tel" value={form.minAge} label={"Min Age"} onChange={(e) => { 
                                    if (!validation.isNumber(e.currentTarget.value)) { return; }
                                    if (e.currentTarget.value.length > 2) { return; }
                                    onChange({minAge: !e.currentTarget.value ? null : Number(e.currentTarget.value)}) 
                                }}/>
                            </Grid>
                            <Grid item xs={6}>
                                <TCInput showAlert={joiForm.maxAge} type="tel" value={form.maxAge} label={"Max Age"} onChange={(e) => { 
                                    if (!validation.isNumber(e.currentTarget.value)) { return }
                                    if (e.currentTarget.value.length > 2) { return; }
                                    onChange({maxAge: !e.currentTarget.value ? null : Number(e.currentTarget.value)}) 
                                }}/>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <TCInput label={"Payment Method"} disabled={true} value={"Direct to owner"}/>
                        </Grid>
                        <Grid item xs={12}>
                            <TCLabel>Class Description</TCLabel>
                            <ReactQuill theme="snow" value={form.description}
                                modules={{toolbar: toolbarOptions}}
                                onChange={(e) => {
                                    onChange({description: e})
                                }}
                            />
                            <Grid sx={{
                                textAlign: 'right'
                            }}>
                                {
                                    joiForm.description ?
                                    <TCLabel style={{fontSize: 10, color: color.pink}}>{`${form.description.length}/500`}</TCLabel>
                                    :
                                    <TCLabel style={{fontSize: 10, color: color.grey}}>{`${form.description.length}/500`}</TCLabel>
                                }
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <TCLabel>Upload Picture</TCLabel>
                            <DropZoneComponent
                                className="drop-zone"
                                uploadName={"classImage"}
                                file={form.classImage}
                                isMultiple={false}
                                onDrop={(files, event) => {
                                    if (files.length >= 4) { return; }
                                    let currImages = form.classImage ? form.classImage : []
                                    for (let i = 0; i < files.length; i++) {
                                        currImages = currImages.concat(files[i])
                                    }
                                    onChange({ classImage: currImages })
                                }}
                            >
                                <div className='dropzone mb-0' style={{
                                    textAlign: 'center',
                                    minHeight: '150px'
                                }}>
                                <div style={{ padding: 10, alignItems: 'center' }}>
                                    <label
                                        htmlFor={"classImage"}
                                        style={{ cursor: 'pointer' }}
                                        className='dropzone-child mb-0'>
                                        <i className='fa fa-upload mr-1' />
                                        Upload Class Image
                                    </label>
                                    {/* <div style={{marginTop: 10}}></div>
                                    <label>
                                        Use your professional photo
                                    </label> */}
                                    <div style={{ overflowX: 'auto' }}>
                                    {
                                        (form.classImage && form.classImage.length > 0) &&
                                        <div style={{ textAlign: 'left' }}>
                                        Uploaded file :
                                        {
                                            form.classImage.map((value, index) => {
                                            return (
                                                <div key={`${value.name}${index}`} className='d-flex mb-1' style={{ textAlign: 'left' }}>
                                                <img src={(!value.name && value.includes("https")) ? value : URL.createObjectURL(value)} style={{ minWidth: '100px', maxHeight: '50px', objectFit: 'contain' }}
                                                    onClick={() => {
                                                    }}
                                                />
                                                <label className='mt-1 mb-0 ml-1' style={{ alignItems: 'flex-end' }}>
                                                    {value.name}
                                                    <span style={{ marginLeft: 5, color: 'gray', cursor: 'pointer' }} onClick={() => {
                                                        deleteClassImages(index)
                                                    }}>X</span>
                                                </label>
                                                </div>
                                            )
                                            })
                                        }
                                        </div>
                                    }
                                    </div>
                                </div>
                                </div>
                            </DropZoneComponent>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} p={2} textAlign={"right"}>
                        <MKButton sx={{fontSize: 10, p: 0, pl: 1, pr: 1}} color={"white"} 
                            onClick={() => {setOpenForm(false)}}>Cancel</MKButton>
                        <MKButton sx={{fontSize: 10, p: 0, pl: 1, pr: 1}} color={"primary"} 
                            disabled={disabledForm()}
                            onClick={() => { submitClass();}}>Create</MKButton>
                    </Grid>
                </>
            }
            {
                loading &&
                <Loading />
            }
        </>
    )
}

export default ClassForm
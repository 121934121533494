import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import court from "../assets/logo/court.png";
import tournament from "../assets/logo/tournament.png";
import badge from "../assets/logo/badge.png";
import classLogo from "../assets/logo/class.png";
import players from "../assets/logo/players.png";
import academy from "../assets/logo/academy.png";

import { getUserInfo } from 'helper/localStorage';
import { getMe } from 'service/authService';

import SlideAlert from '../components/SlideInAlert/SlideAlert';
import Login from './Auth/Login';
import { Confirm } from "helper/showAlert";
import { Box } from '@mui/material';
import { TCLabel } from 'components/TopCourtComponents/Label/TopCourtLabel';

import ReactCardFlip from 'react-card-flip';
import { forgetTabBookingMemos } from 'helper/tabsMemo';

function MainMenu ({
    setShowLogin
}) {

    const navigate = useNavigate()
    const [ userInfo, setUserInfo] = useState(getUserInfo ? JSON.parse(getUserInfo) : null)
    const [ flip, setFlip ] = useState({
        academy: false,
        tournament: false
    })

    useEffect(() => {
        loadUserInfo()
    }, [])

    useEffect(() => {
        if (flip.academy == true) {
            setTimeout(() => {
                setFlip((prev) => ({
                    ...prev,
                    academy: false
                }))
            }, 2000);
        }
    }, [flip.academy])

    useEffect(() => {
        if (flip.tournament == true) {
            setTimeout(() => {
                setFlip((prev) => ({
                    ...prev,
                    tournament: false
                }))
            }, 2000);
        }
    }, [flip.tournament])

    const checkIsLogin = () => {
        const currentUserString = getUserInfo;
        if (!currentUserString) {
            setShowLogin(true)
            return false
        }
        return true
    }

    const loadUserInfo = async () => {
        // getMe
        let { data, error } = await getMe()
        setUserInfo(data)
    }

    return (
        <>
            <MKBox pb={2} style={{
                // backgroundColor: '#F8FAFC', 
                backgroundColor: '#FFFFFF',
                borderRadius: '0px 0px 16px 16px'}}>
                <Grid container alignItems="center">
                    <Grid item xs={4} lg={4} textAlign={"center"}>
                        <Box style={{
                            marginLeft: 8, marginRight: 2,
                            borderRadius: 6,
                            padding: 2,
                            backgroundColor: '#0198E1',
                            cursor: 'pointer'
                        }}>
                            <MKTypography style={{fontSize: 14, fontWeight: 500, color: 'white'}} my={1} onClick={() => {
                                forgetTabBookingMemos('packages')
                                navigate('/packages')
                            }}>{
                                // userInfo && userInfo.sessions > 0 ? `My Pack : ${userInfo.sessions} session${userInfo.sessions > 1 ? 's':''}`
                                userInfo && userInfo.sessions > 0 ? `My Pack` : 'Value Pack'
                            }</MKTypography>
                        </Box>
                    </Grid>
                    <Grid item xs={4} lg={4} textAlign={"center"}>
                        <Box style={{
                            marginLeft: 8, marginRight: 2,
                            borderRadius: 6,
                            padding: 2,
                            backgroundColor: '#0198E1',
                            cursor: 'pointer'
                        }}>
                            <MKTypography style={{fontSize: 14, fontWeight: 500, color: 'white'}} my={1} onClick={() => {
                                forgetTabBookingMemos('add-ons')
                                navigate('/add-ons')
                            }}>Add-On</MKTypography>
                        </Box>
                    </Grid>
                    <Grid item xs={4} lg={4} textAlign={"center"}>
                        <Box style={{
                            marginLeft: 8, marginRight: 8,
                            borderRadius: 6,
                            padding: 2,
                            backgroundColor: '#0198E1',
                            cursor: 'pointer'
                        }}>
                            <MKTypography style={{fontSize: 14, fontWeight: 500, color: 'white'}} my={1} onClick={() => {
                                if (checkIsLogin()) { 
                                    forgetTabBookingMemos('bookings')
                                    navigate('/bookings') 
                                }
                            }}
                            >My Booking</MKTypography>
                        </Box>
                    </Grid>
                </Grid>
            </MKBox>
            <MKBox pb={4}>
                <Grid container alignItems="center">
                    <Grid item xs={4} lg={4} textAlign={"center"}
                        style={{
                            cursor: 'pointer'
                        }}
                        onClick={() => {
                            navigate('/court-booking')
                        }}
                        >
                            <div style={{borderRadius: 30, paddingTop: 20, paddingBottom: 10}}>
                                <img src={court} width={'64'} height={'64'}/>
                                <div style={{marginTop: -5}}>
                                    <TCLabel>Court Booking</TCLabel>
                                </div>
                            </div>
                    </Grid>
                    <Grid item xs={4} lg={4} textAlign={"center"}
                        style={{
                            cursor: 'pointer'
                        }}
                        onClick={() => {
                            forgetTabBookingMemos('class')
                            forgetTabBookingMemos('class-filter')
                            navigate('/class')
                        }}
                        >
                            <div style={{borderRadius: 30, paddingTop: 20, paddingBottom: 10}}>
                                <img src={classLogo} width={'64'} height={'64'}/>
                                <div style={{marginTop: -5}}>
                                    {/* <TCLabel>Class & Coach</TCLabel> */}
                                    {/* <TCLabel>Class</TCLabel> */}
                                    <TCLabel>Class & Event</TCLabel>
                                </div>
                            </div>
                    </Grid>
                    <Grid item xs={4} lg={4} textAlign={"center"} 
                        style={{
                            cursor: 'pointer'
                        }}
                        onClick={() => {
                            setFlip((prev) => ({
                                ...prev,
                                academy: !prev.academy
                            }))
                        }}
                        >
                            <ReactCardFlip isFlipped={flip.academy} >
                                <div style={{borderRadius: 30, paddingTop: 10, paddingBottom: 0}}>
                                    <img src={academy} width={'64'} height={'64'}/>
                                </div>
                                <div style={{borderRadius: 30, paddingTop: 19, paddingBottom: 0}}>
                                    <div style={{display: 'flex', width: '100%', height: 64,
                                        justifyContent: 'center', alignItems: 'center'}}>
                                            <div style={{width: '100%'}}>
                                                <TCLabel>Coming Soon</TCLabel>
                                            </div>
                                    </div>
                                </div>
                            </ReactCardFlip>
                            <div style={{marginTop: -5}}>
                                <TCLabel>Academy</TCLabel>
                            </div>
                    </Grid>
                    <Grid item xs={4} lg={4} textAlign={"center"}
                        style={{
                            cursor: 'pointer'
                        }}
                        onClick={() => {
                            navigate('/players')
                        }}
                        >
                            <div style={{borderRadius: 30, paddingTop: 20, paddingBottom: 10}}>
                                <img src={players} width={'64'} height={'64'}/>
                                <div style={{marginTop: -5}}>
                                    <TCLabel>Players</TCLabel>
                                </div>
                            </div>
                    </Grid>
                    <Grid item xs={4} lg={4} textAlign={"center"}
                        style={{
                            cursor: 'pointer'
                        }}
                        onClick={() => {
                            // if (userInfo && userInfo.club) {
                            //     console.log(userInfo.club)
                            //     navigate(`/club/${userInfo.club}`)
                            // } else { navigate('/clubs') }
                            forgetTabBookingMemos('clubs')
                            navigate('/clubs')
                        }}
                        >
                            <div style={{borderRadius: 30, paddingTop: 20, paddingBottom: 10}}>
                                <img src={badge} width={'64'} height={'64'}/>
                                <div style={{marginTop: -5}}>
                                    <TCLabel>Club</TCLabel>
                                </div>
                            </div>
                    </Grid>
                    <Grid item xs={4} lg={4} textAlign={"center"}
                        style={{
                            cursor: 'pointer'
                        }}
                        onClick={() => {
                            // navigate('/tournament')
                            setFlip((prev) => ({
                                ...prev,
                                tournament: !prev.tournament
                            }))
                        }}
                        >
                            {/* <div style={{borderRadius: 30, paddingTop: 20, paddingBottom: 10}}>
                                <img src={tournament} width={'64'} height={'64'}/>
                                <div style={{marginTop: -5}}>
                                    <TCLabel>Tournament</TCLabel>
                                </div>
                            </div> */}
                            <ReactCardFlip isFlipped={flip.tournament} >
                                <div style={{borderRadius: 30, paddingTop: 10, paddingBottom: 0}}>
                                    <img src={tournament} width={'64'} height={'64'}/>
                                </div>
                                <div style={{borderRadius: 30, paddingTop: 19, paddingBottom: 0}}>
                                    <div style={{display: 'flex', width: '100%', height: 64,
                                        justifyContent: 'center', alignItems: 'center'}}>
                                            <div style={{width: '100%'}}>
                                                <TCLabel>Coming Soon</TCLabel>
                                            </div>
                                    </div>
                                </div>
                            </ReactCardFlip>
                            <div style={{marginTop: -5}}>
                                <TCLabel>Tournament</TCLabel>
                            </div>
                    </Grid>
                </Grid>
            </MKBox>
        </>
    )
}

export default MainMenu
import React, { useEffect, useState } from "react";

import { Grid, Box, Icon, Card, Button, Avatar } from "@mui/material";
import TCNavigationBar from "components/TopCourtComponents/NavigationBar/TopCourtNavigationBar";
import { TCLabel, TCTitleLabel } from "components/TopCourtComponents/Label/TopCourtLabel";
import { TCInput } from "components/TopCourtComponents/Input/TopCourtInput";
import MKButton from "components/MKButton";
import { TCSelect } from "components/TopCourtComponents/Input/TopCourtInput";
import { NumericFormat } from "react-number-format";
import { genderOptions, categoryOptions, optionsParticipant, typeOptions } from "helper/constant"
import { getPlaces } from "service/mainService";
import { createTournament } from "service/tournamentService";
import { Confirm } from "helper/showAlert";
import { toast } from 'react-toastify';

function Tournament({

}) {

    const [ tournament, setTournament ] = useState({
        name: '',
        description: '',
        season: '1',
        totalParticipants: null,
        gender: null,
        category: null,
        startDate: '',
        lastRegisterDate: '',
        court: null,
        prizePool: {
            first: 0,
            second: 0,
            third: 0
        },
        isPublic: {value: true, label: "Public"}
    })

    const [ places, setPlaces ] = useState([])

    useEffect(() => {
        loadObjectPlaces()
    }, [])

    const onChange = (e) => {
        let object = {}
        if (e.prizePool) { 
            object = { 
                prizePool: {
                    ...tournament.prizePool,
                    ...e.prizePool 
                }
            }
        }
        setTournament( prev => ({
            ...prev,
            ...e,
            ...object
        }))
    }

    const saveTournament = async () => {
        console.log('hello world')
        console.log(tournament)
        let newTournament = {
            ...tournament,
            totalParticipants: tournament.totalParticipants ? tournament.totalParticipants.value : null,
            gender: tournament.gender ? tournament.gender.value : null,
            category: tournament.category ? tournament.category.value : null,
            court: tournament.court ? tournament.court.value : null,
            isPublic: tournament.isPublic ? tournament.isPublic.value : null
        }
        let { data, error } = await createTournament(newTournament)
        if (error) { Confirm("", error) }
        if (data) { 
            toast('Tournament created', {
              toastId: "tournament-success-api",
              type: "success"
            });
            setTournamentData(data)
        }
    }

    const setTournamentData = async (data) => {
        let newData = {
            ...data,
            totalParticipants: optionsParticipant.find(value => {
                if (value.value === data.totalParticipants) { return value; }
            }),
            gender: genderOptions.find(value => {
                if (value.value === data.gender) { return value; }
            }),
            category: categoryOptions.find(value => {
                if (value.value === data.category) { return value; }
            }),
            court: places.find(value => {
                console.log(value)
                if (value.value === data.court) { return value; }
            }),
            isPublic: typeOptions.find(value => {
                if (value.value === data.isPublic) { return value; }
            })
        }
        console.log(newData)
        setTournament(prev => ({
            ...prev,
            ...newData
        }))
    }

    const loadObjectPlaces = async () => {
        let { data, error } = await getPlaces()
        console.log(data)
        console.log(error)
        if (data) { 
            let newData = []
            data.map(value => {
                newData.push({
                    label: value.name,
                    value: value._id,
                    info: value
                })
            })
            setPlaces(newData) 
        }
    }

    return (
        <>
            <TCNavigationBar transparent={false} title={"New Tournament"}/>
            <Grid sx={{p: 2}} direction={"column"}>
                {/* <Grid>Header Informasi Player</Grid>  */}
                {/* Header ikutin kyk Linkedin */}
                <Grid container spacing={1.5}>
                    {/* <Grid>Upload Tournament Image</Grid> */}
                    <Grid item xs={12} lg={12}>
                        <TCSelect label={"Copy From Last Season"} placeHolder={"Select Tournament"}/>
                    </Grid>
                    <Grid item xs={12} lg={12}>
                        <TCInput label={"Tournament Name"} 
                        value={tournament.name}
                        onChange={(e) => {
                            onChange({name: e.currentTarget.value})
                        }} />
                    </Grid>
                    <Grid item xs={12} lg={12}>
                        <TCInput label={"Tournament Description"} 
                        value={tournament.description}
                        // type="textarea"
                        onChange={(e) => {
                            onChange({description: e.currentTarget.value})
                        }} />
                    </Grid>
                    <Grid item xs={6} lg={6}>
                        <TCSelect label={"Type"}
                        value={tournament.isPublic}
                        options={typeOptions}
                        onChange={(e) => {
                            onChange({isPublic: e})
                        }} />
                    </Grid>
                    <Grid item xs={6} lg={6}>
                        <TCSelect label={"Total Participant"}
                        value={tournament.totalParticipants}
                        options={optionsParticipant}
                        onChange={(e) => {
                            onChange({totalParticipants: e})
                        }} />
                    </Grid>
                    <Grid item pr={1} xs={6} lg={6}>
                        <TCSelect label={"Gender"} placeHolder={"Select Gender"} 
                        value={tournament.gender}
                        options={genderOptions}
                        onChange={(e) => {
                            onChange({gender: e})
                        }} />
                    </Grid>
                    <Grid item pr={1} xs={6} lg={6}>
                        <TCSelect label={"Category"} placeHolder={"Select Category"} 
                        value={tournament.category}
                        options={categoryOptions}
                        onChange={(e) => {
                            onChange({category: e})
                        }} />
                    </Grid>
                    <Grid item pr={1} xs={6} lg={6}>
                        <TCInput type="date" label={"Start Date"} value={tournament.startDate} onChange={(e) => {
                            onChange({startDate: e.currentTarget.value})
                        }} />
                    </Grid>
                    <Grid item xs={6} lg={6}>
                        <TCInput type="date" label={"Last Registration Date"} value={tournament.lastRegisterDate} onChange={(e) => {
                            onChange({lastRegisterDate: e.currentTarget.value})
                        }} />
                    </Grid>
                    <Grid item pr={1} xs={6} lg={6}>
                        <TCInput label={"Season"} 
                        value={tournament.season}
                        onChange={(e) => {
                            onChange({season: e.currentTarget.value})
                        }} />
                    </Grid>
                    <Grid item xs={12} lg={12}>
                        <TCSelect label={"Court"} placeHolder={"Select Court"} 
                        options={places}
                        value={tournament.court} onChange={(e) => {
                            onChange({court: e})
                        }} />
                    </Grid>
                    <Grid item xs={12} lg={12} style={{
                        padding: 20,
                        paddingTop: 10,
                        backgroundColor: `rgba(125, 207, 182, 0.5)`,
                        marginLeft: 10,
                        marginTop: 20,
                        borderRadius: 5
                    }}>
                        {/* <TCInput label={"Pool Prize"} placeHolder={"Select Court"} onChange={(e) => {
                            onChange({court: e.currentTarget.value})
                        }} /> */}
                        <TCLabel bold={true}>{"Pool Prize"} <span style={{fontSize: 10}}>*in IDR</span></TCLabel>
                        <TCLabel>Winner</TCLabel>
                        <NumericFormat value={tournament.prizePool.first}
                            onChange={(e) => {
                                onChange({
                                    prizePool: {
                                        first: e.currentTarget.value
                                    }
                                })
                            }}
                            thousandSeparator
                            customInput={TCInput}/>
                        <TCLabel>Runner Up</TCLabel>
                        <NumericFormat value={tournament.prizePool.second}
                            onChange={(e) => {
                                onChange({
                                    prizePool: {
                                        second: e.currentTarget.value
                                    }
                                })
                            }}
                            thousandSeparator
                            customInput={TCInput}/>
                        <TCLabel>Third Place</TCLabel>
                        <NumericFormat value={tournament.prizePool.third}
                            onChange={(e) => {
                                onChange({
                                    prizePool: {
                                        third: e.currentTarget.value
                                    }
                                })
                            }}
                            thousandSeparator
                            customInput={TCInput}/>
                    </Grid>
                    <Grid item p={2} xs={12} lg={12}>
                        <div style={{textAlign: 'center'}}>
                            <MKButton sx={{fontSize: 10, p: 0, pl: 1, pr: 1}} color={"green"} 
                            onClick={() => 
                                saveTournament()
                            }>Save Tournament</MKButton>
                        </div>
                    </Grid>
                    {
                        tournament._id && 
                        <div>Participant</div>
                    }
                </Grid>
            </Grid>
        </>
    )
}

export default Tournament
import React from "react"
import { currencyFormat } from "helper/numberFormat"
import { color } from "helper/constant"
import { Grid } from "@mui/material"
import { TCLabel } from "components/TopCourtComponents/Label/TopCourtLabel"
import MKButton from "components/MKButton"

function DrawerTitle ({
    setShowDetailAddOn,
    setRerender, 
    countSummary
}) {
    return (
        <>
            <Grid container mt={2} justifyContent={"flex-end"} 
                style={{paddingLeft: 16, paddingRight: 16}}>
                    <Grid xs={6} md={6} lg={6} item style={{textAlign: 'left'}}>
                        <TCLabel>
                            Total {countSummary().totalSession || 0} {countSummary().totalSession > 1 ? "Sessions" : "Session"}
                        </TCLabel>
                    </Grid>
                    <Grid xs={6} md={6} lg={6} item style={{textAlign: 'right'}}>
                        <TCLabel bold>
                            Total {currencyFormat(countSummary().totalPrice || 0)}
                        </TCLabel>
                    </Grid>
            </Grid>
            <Grid container mb={0} pb={2} justifyContent={"flex-end"} 
                style={{
                    backgroundColor:'white',
                    paddingLeft: 16, paddingRight: 16 }}
                onTouchStart={(e) => { e.stopPropagation(true) }}
            >
                <Grid mt={1} xs={12} md={12} lg={12} item style={{textAlign: 'center'}}>
                    <MKButton 
                        color="primary" 
                        disabled={countSummary().totalSession == 0}
                        fullWidth 
                        onClick={() => {
                            setShowDetailAddOn(true)
                            setRerender(Date.now())
                        }}
                        sx={{
                            backgroundColor: color.primary
                        }}
                    >
                        Checkout
                    </MKButton>
                </Grid>
            </Grid>
        </>
    )
}

export default DrawerTitle
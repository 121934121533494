import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { toGMT7, toLocalGMT, momentFormat } from 'helper/time';
import { addMonths, subMonths } from "date-fns";
import { useSwipeable } from "react-swipeable";

import { Card, Grid, Icon } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import { TCLabel } from "components/TopCourtComponents/Label/TopCourtLabel";
import { currencyFormat } from "helper/numberFormat";
import MKButton from "components/MKButton";
import moment from "moment";
import { timeStringFormat } from "helper/constant";
import { courtTypeObj } from "helper/constant";
import { getServiceSchedule, addCart, submitItemOrder } from "./Function";
import { Confirm } from "helper/showAlert";
import { color } from "helper/constant";
import { getItemAvailbility } from "service/addOnService";
import { BookingScheduleList } from "helper/schedule";
import TCAccordion from "components/TopCourtComponents/Accordion/TopCourtAccordion";
import { groupSession } from "helper/booking";
import DialogHeader from "components/SlideDialog/DialogHeader";
import MKBox from "components/MKBox";
import { loadImages } from "helper/loadImages";
import { getUserAvatar } from "helper/user";
import { Confirmation } from "helper/showAlert";
import Calendar from "react-calendar";
import { Hours } from "helper/constant";
import TCNumberInput from "components/TopCourtComponents/Input/TopCourtCounterNumber";
import { useDebounce } from "use-debounce";

function ProductComponent ({
    type,
    data,
    disabled
}) {
    return (
        <Card>
            {disabled && <div className="overlay"/>}
            <img src={type === "service" ? data.itemImages.length > 0 ? loadImages(data.itemImages[0]) : getUserAvatar(data.user) : loadImages(data.itemImages[0])} width={'100%'} 
            style={{
                objectFit: 'contain',
                borderTopLeftRadius: 8, borderTopRightRadius: 8, minHeight: 160, maxHeight: 160
            }}/>
            <Grid p={1} pt={1}>
                {
                    type === "service" &&
                    // <TCLabel bold>{data.user.rallyPartnerStatus ? "Rally Partner" : data.user.ballBoyStatus ? "Ball Boy" : "-"}</TCLabel>
                    <TCLabel bold>{data.category.name}</TCLabel>
                }
                {
                    (type === "rent" || type == "sell") &&
                    <TCLabel bold>{data.category.name}</TCLabel>
                }
                <Grid sx={{
                    // minHeight: data.type === "service" ? 20 : 40,
                    // maxHeight: data.type === "service" ? 20 : 40
                    minHeight: (type === "rent" || type == "sell") ? 40 : 20,
                    maxHeight: (type === "rent" || type == "sell") ? 40 : 20
                }} alignContent={"top"}>
                    <TCLabel>{data.name}</TCLabel>
                </Grid>
                <TCLabel bold>{currencyFormat(data.price)}</TCLabel>
            </Grid>
        </Card>
    )
}

function ProductDetail ({
    onClose,
    productId,
    loadData,
    loadCart,
    disabled
}) {

    const myRef = useRef(null)
    const navigate = useNavigate()

    // load user item cart
    
    const [ loading, setLoading ] = useState(true)
    const [ data, setData ] = useState(null)
    const [ selectedSession, setSelectedSession ] = useState([])
    const [ description, setDescription ] = useState(false)
    const [ showMore, setShowMore ] = useState(false)
    const [ number, setNumber ] = useState(1)

    const [debounceNumber] = useDebounce(number,200)

    useEffect(() => {
      (async()=>{
        loadProductInfo()
      })()
  }, [debounceNumber])
    
    useEffect(() => {
        setLoading(true)
    }, [number])

    useEffect(() => {
    }, [selectedSession])

    const loadProductInfo = async () => {
        setLoading(true)
        let { data, userSessions, itemsOrder, error } = await loadData(productId, number)
        setLoading(false)
        if (error) { console.log(error) }
        if (data) { 
            let newOrder = []
            if (userSessions) {
                newOrder = groupSession(userSessions)
            }
            console.log('here data Info', data)
            setData({
                item: data,
                userSessions: data.type != "sell" ? newOrder : [],
                itemsOrder: itemsOrder || null
            })
            // if cart exist
            if (data.cart) {
                console.log('here loaded data.cart', data, data.cart)
                let sessions = []
                for (let i = 0; i < data.cart.length; i ++) {
                    let currSession = data.cart[i].sessions
                    for (let j = 0; j < currSession.length; j ++) {
                        sessions.push(currSession[j])
                    }
                }
                setSelectedSession(sessions)
            }
            if (data.type == "sell" && number == 1) { 
                if (data.cart && data.cart.qty) {
                    setNumber(data.cart.qty || 1)
                }
                setDescription(true) 
            }
        }
    }

    const selectSession = async (e) => {
        let newSelectedSession = []
        if (selectedSession.length > 0) {
            selectedSession.push(e)
            for (let i = 0; i < selectedSession.length; i ++) {
                if (selectedSession[i].date == e.date && selectedSession[i].court.toString() != e.court.toString()) {
                    // loop the hours
                    let newHours = []
                    let existHour = []
                    newHours = e.hours.filter(function(v) {
                        return !selectedSession[i].hours.includes(v);
                    })
                    existHour = selectedSession[i].hours.filter(function(v) {
                        return !e.hours.includes(v);
                    })
                    console.log('new Hours', newHours, 'exist Hours', existHour)
                    if (newHours.length > 0) {
                        let similiar = false
                        for (let j = 0; j < newSelectedSession.length; j ++) {
                            if (newSelectedSession[j].date == e.date && 
                                newSelectedSession[j].court.toString() == e.court.toString()) {
                                similiar = true
                                newSelectedSession[j].hours = existHour
                            }
                        }
                        if (!similiar) {
                            newSelectedSession.push({
                                ...e,
                                hours: newHours
                            })
                        }
                        console.log('new Hours - 0', newSelectedSession)
                    }
                    if (existHour.length > 0) {
                        let similiar = false
                        for (let j = 0; j < newSelectedSession.length; j ++) {
                            if (newSelectedSession[j].date == e.date && 
                                newSelectedSession[j].court.toString() == selectedSession[i].court.toString()) {
                                similiar = true
                                newSelectedSession[j].hours = existHour
                            }
                        }
                        if (!similiar) {
                            newSelectedSession.push({
                                ...selectedSession[i],
                                hours: existHour
                            })
                        }
                        console.log('new Hours - 1', newSelectedSession)
                    }
                    console.log('new Hours - 2', newSelectedSession)
                } else if (selectedSession[i].date == e.date && selectedSession[i].court.toString() == e.court.toString()) {
                    let similiar = false
                    for (let j = 0; j < newSelectedSession.length; j ++) {
                        if (newSelectedSession[j].date == e.date && 
                            newSelectedSession[j].court.toString() == e.court.toString()) {
                            similiar = true
                            newSelectedSession[j].hours = e.hours
                        }
                    }
                    if (!similiar) {
                        newSelectedSession.push({
                            ...selectedSession[i],
                            hours: e.hours
                        })
                    }
                } else { 
                    newSelectedSession.push(selectedSession[i]) 
                }
            }
        } else {
            newSelectedSession.push(e)
        }
        setSelectedSession(newSelectedSession)
    }

    const countSessions = (sessions) => {
        let totalHours = 0
        for (let i = 0; i < sessions.length; i ++) {
            totalHours += sessions[i].hours.length
        }
        let price = totalHours * data.item.price
        return { totalHours, price }
    }

    const addItemCart = async () => {
        try {
            let newSelectedSession = []
            if (selectedSession) {
                for (let i = 0; i < selectedSession.length; i ++) {
                    if (selectedSession[i].hours.length > 0) { 
                        newSelectedSession.push(selectedSession[i])
                    }
                }
            }
            if (!newSelectedSession || newSelectedSession.length <= 0) {
                Confirm("", "Select your booking first")
                return;
            }
            console.log('here check add cart', {
                type: data.item.type,
                productId: data.item._id,
                sessions: newSelectedSession
            })
            addCart({
                type: data.item.type,
                productId: data.item._id,
                sessions: newSelectedSession
            }, () => {
                onClose()
                loadCart()
            })
        } catch (error) {
            console.log('here check add cart error', error)
        }
    }

    const addItemCartQty = async () => {
        try {
            
            console.log('here check add cart', {
                type: data.item.type,
                productId: data.item._id,
                qty: number
            })
            addCart({
                type: data.item.type,
                productId: data.item._id,
                qty: number
            }, () => {
                onClose()
                loadCart()
            })
        } catch (error) {
            console.log('here check add cart error', error)
        }
    }

    const maxValue = () => {
        console.log(data ? data.item : null)
        return data ? data.item.stock || 0 : 0
    }

    const disableSellButton = () => {
        if (!number || number < 1)  { return true }
        if (number > data.item.stock) { return true }
        let stock = data ? data.item.stock || 0 : 0
        if (!data.item.available) { stock = 0 }
        return (stock <= 0 || loading) ? true : false
    }

    const executeScroll = () => {
        setTimeout(function() {
            myRef.current.scrollIntoView({
                behavior: 'smooth'
            })   
        }, 200);
    }

    return (
        <Grid container spacing={1} direction="column">
            <DialogHeader title={(data && data.item) && data.item.category.name} closeDialog={() => { onClose() }}/>
            {
                data &&
                <Grid item container xs={12}>
                    <Grid item container p={1} pb={0}>
                        <Grid item xs={4} pr={1}>
                            <img src={data.item.itemImages[0]} width={"100%"} height={"auto"}/>
                        </Grid>
                        <Grid item container xs={8} textAlign={"left"} spacing={1}>
                            <Grid pl={1} pt={0.5} xs={12}>
                                <Grid pt={0}>
                                    <TCLabel bold>{data.item.name}</TCLabel>
                                </Grid>
                                <div style={{display: 'flex'}}>
                                    <TCLabel bold>{`${currencyFormat(data.item.price)} / `}</TCLabel>
                                    <TCLabel style={{paddingTop: 2, paddingLeft: 5}} subtitle>{data.item.type != "sell" ? "Session" : "Item"}</TCLabel>
                                </div>
                                { data.item.type == "others" ?
                                    <div className='mini-btn-200px' style={{
                                        marginTop: 8,
                                        marginLeft: '0px !important',
                                    }}>
                                        <TCLabel style={{fontSize: 12}}>Place your order at counter</TCLabel>
                                    </div>
                                :
                                    data.item.user && data.item.user.ballBoyStatus ?
                                    <div style={{
                                        // border: `1px solid ${color.blueIndoor_2}`,
                                        borderRadius: 8,
                                        marginTop: 8,
                                        marginLeft: '0px !important',
                                        textAlign: 'left',
                                        display: 'flex'
                                    }}>
                                        <Icon>information</Icon>
                                        <TCLabel style={{fontSize: 10}}>
                                            1 ball boy is exclusive for 1 court only</TCLabel>
                                    </div>
                                    :
                                    data.item.user && data.item.user.rallyPartnerStatus ?
                                    <>
                                        <div style={{
                                            // border: `1px solid ${color.blueIndoor_2}`,
                                            borderRadius: 8,
                                            // padding: 4,
                                            marginTop: 8,
                                            marginLeft: '0px !important',
                                            textAlign: 'left',
                                            display: 'flex'
                                        }}>
                                            <Icon>information</Icon>
                                            <TCLabel style={{fontSize: 10}}>-1 rally partner is limited to 2 players only</TCLabel>
                                        </div>
                                        <div style={{
                                            // border: `1px solid ${color.blueIndoor_2}`,
                                            borderRadius: 8,
                                            // padding: 4,
                                            marginTop: 2,
                                            marginLeft: '0px !important',
                                            textAlign: 'left',
                                            display: 'flex'
                                        }}>
                                            <Icon sx={{
                                                visibility: 'hidden'
                                            }}>information</Icon>
                                            <TCLabel style={{fontSize: 10}}>-balls for practice included</TCLabel>
                                        </div>
                                        <div style={{
                                            // border: `1px solid ${color.blueIndoor_2}`,
                                            borderRadius: 8,
                                            // padding: 4,
                                            marginTop: 2,
                                            marginLeft: '0px !important',
                                            textAlign: 'left',
                                            display: 'flex'
                                        }}>
                                            <Icon sx={{
                                                visibility: 'hidden'
                                            }}>information</Icon>
                                            <TCLabel style={{fontSize: 10}}>-book early at least 1 hour before</TCLabel>
                                        </div>
                                    </>
                                    :
                                    data.item.user && (data.item.user.developmentCoachStatus || data.item.user.leadCoachStatus || 
                                        data.item.user.seniorCoachStatus || data.item.user.headCoachStatus
                                    ) ?
                                    <>
                                        <div style={{
                                            // border: `1px solid ${color.blueIndoor_2}`,
                                            borderRadius: 8,
                                            // padding: 4,
                                            marginTop: 8,
                                            marginLeft: '0px !important',
                                            textAlign: 'left',
                                            display: 'flex'
                                        }}>
                                            <Icon>information</Icon>
                                            <TCLabel style={{fontSize: 10}}>-1 coach is limited to 4 players only</TCLabel>
                                        </div>
                                        <div style={{
                                            // border: `1px solid ${color.blueIndoor_2}`,
                                            borderRadius: 8,
                                            // padding: 4,
                                            marginTop: 2,
                                            marginLeft: '0px !important',
                                            textAlign: 'left',
                                            display: 'flex'
                                        }}>
                                            <Icon sx={{
                                                visibility: 'hidden'
                                            }}>information</Icon>
                                            <TCLabel style={{fontSize: 10}}>-balls for practice included</TCLabel>
                                        </div>
                                        <div style={{
                                            // border: `1px solid ${color.blueIndoor_2}`,
                                            borderRadius: 8,
                                            // padding: 4,
                                            marginTop: 2,
                                            marginLeft: '0px !important',
                                            textAlign: 'left',
                                            display: 'flex'
                                        }}>
                                            <Icon sx={{
                                                visibility: 'hidden'
                                            }}>information</Icon>
                                            <TCLabel style={{fontSize: 10}}>-book early at least 1 hour before</TCLabel>
                                        </div>
                                    </>
                                    : data.item.type == "sell" ? <TCLabel style={{fontSize: 10}}>Stock: {data.item.stock}</TCLabel> : '-'
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    {
                        data.item.description &&
                        <Grid xs={12} pt={1} pb={1}>
                            <Grid px={1} sx={{
                                // border: `1px solid ${color.blueIndoor_2}`,
                                // borderRadius: 2
                            }}>
                                {/* <div>{data.item.description ? data.item.description : "No Description"}</div> */}
                                <TCLabel>{data.item.description ? 
                                    <div>
                                        {(data.item.description.length > 200 && !showMore) ?
                                            <div dangerouslySetInnerHTML={{__html: data.item.description.slice(0, 200)}}/>
                                            :
                                            <div dangerouslySetInnerHTML={{__html: data.item.description}}/>
                                        }
                                        {data.item.description.length > 200 && 
                                            <Grid textAlign={"right"} onClick={() => {
                                                setShowMore(!showMore)
                                            }}>
                                                <TCLabel style={{fontSize: 10, fontWeight: 'bold'}}>{
                                                    !showMore ? '... Show more' : 'Show Less'
                                                }</TCLabel>
                                            </Grid>
                                        }
                                    </div>
                                : "No Description"}</TCLabel>
                            </Grid>
                        </Grid>
                    }
                    <div ref={myRef} className="separation-line"/>
                    {/* Here show calendar ? or filter date */}
                    {
                        data.item.type !== "sell" &&
                        <Grid item xs={12} mb={1}>
                            <TCAccordion title={<Grid onClick={() => { executeScroll() }}><TCLabel>{"Check Available Schedule"}</TCLabel></Grid>}
                                bodyOnClick={() => { executeScroll() }}
                                outsideFunction={() => { executeScroll() }}
                            >
                                <AddOnServiceCalendar item={data.item} navigate={navigate}/>
                            </TCAccordion>
                        </Grid>
                    }
                    {
                        (data.item.type !== "sell" && !description) ?
                        <>
                        {(data.userSessions.length > 0) &&
                            <Grid item container xs={12} pl={0} pr={0}>
                                <Grid item xs={12}>
                                    <TCLabel bold>Select Add-Ons Session Time</TCLabel>
                                </Grid>
                                <Grid item container xs={12}>
                                    <div style={{height: '75vh', overflow: 'auto', width: '100%', paddingBottom: 80}}>
                                    {
                                        data.userSessions.map( value => {
                                            return(
                                                <Grid item xs={12} mb={1}>
                                                    {/* <TCAccordion title={moment(value.date).format("ddd DD/MM/YYYY")} outsideFunction={() => { executeScroll() }}> */}
                                                    <TCAccordion title={<Grid onClick={() => { executeScroll() }}><TCLabel>{moment(value.date).format("ddd DD/MM/YYYY")}</TCLabel></Grid>} 
                                                        bodyOnClick={() => { executeScroll() }}
                                                        outsideFunction={() => { executeScroll() }}>
                                                    {/* <TCAccordion title={moment(value.date).format("DD/MM/YYYY")}> */}
                                                        {
                                                            value.courts.map( v => {
                                                                return (
                                                                    <BookingScheduleList 
                                                                        data={v} 
                                                                        clearSelectionFormOnStateChange={true}
                                                                        allowMutipleCourt={false} 
                                                                        allowMultiple={true} 
                                                                        checkTime={false}
                                                                        date={value.date} 
                                                                        court={v._id}
                                                                        disableSelectionProduct={data.itemsOrder ? data.itemsOrder : null}
                                                                        detailData={{
                                                                            court: v._id,
                                                                            date: value.date,
                                                                            item: data.item
                                                                        }}
                                                                        selectedSession={selectedSession}
                                                                        onChangeSchedule={(e) => {
                                                                            selectSession({
                                                                                court: v._id,
                                                                                date: value.date,
                                                                                hours: e.hours
                                                                            })
                                                                        }}
                                                                        executeScroll={() => {
                                                                            executeScroll()
                                                                        }}
                                                                    />
                                                                )
                                                            })    
                                                        }
                                                    </TCAccordion>
                                                </Grid>
                                            )
                                        })
                                    }
                                    </div>
                                </Grid>
                            </Grid>
                        }
                        {(data.userSessions.length <= 0) &&
                            <Grid xs={12} p={1} textAlign={"center"}>
                                {/* <TCLabel>There is no booking in {data.item.validity} days. Please book your court first</TCLabel> */}
                                <TCLabel>There is no booking in the next {data.item.validity} days.</TCLabel>
                                <TCLabel>Please book your court first</TCLabel>
                                <MKButton color="white" 
                                    onClick={() => {
                                        navigate("/court-booking")
                                    }}
                                    sx={{
                                        marginTop: 2,
                                        border: 1,
                                        color: color.primary
                                    }}
                                >
                                    <span style={{marginTop: 2}}>{"Court Booking"}</span>
                                </MKButton>
                            </Grid>
                        }
                        </>
                        :
                        <>
                        </>
                    }
                </Grid>
            }
            {
                ((data && data.userSessions.length > 0) && !description) &&
                <Grid pt={1} pl={2} pr={2} pb={2} sx={{
                    width: '100%', background:'white', right: '0px', bottom: '0px', position: 'absolute'}}>
                    {/* calculate selected session */}
                    <Grid container pb={1}>
                        <Grid item xs={6}>
                            <TCLabel>{`${countSessions(selectedSession).totalHours} ${countSessions(selectedSession).totalHours > 1 ? 'sessions' : 'session'}`}</TCLabel>
                        </Grid>
                        <Grid item xs={6} textAlign={"right"}>
                            <TCLabel>{currencyFormat(countSessions(selectedSession).price)}</TCLabel>
                        </Grid>
                    </Grid>
                    <MKButton fullWidth color="primary" disabled={countSessions(selectedSession).totalHours <= 0}
                    onClick={() => {
                        // Confirmation("", "Proceed to payment ?", () => {}, () => {
                        //     submitOrder()
                        // })
                        addItemCart()
                    }}>Add</MKButton>
                </Grid>
            }
            {
                (data && data.item.type == "sell") &&
                <Grid pt={1} pl={2} pr={2} pb={2} sx={{
                    width: '100%', background:'white', right: '0px', bottom: '0px', position: 'absolute'}}>
                    {/* calculate selected session */}
                    <Grid container pb={1}>
                        {/* <Grid item xs={6}>
                            <TCLabel>{`${countSessions(selectedSession).totalHours} ${countSessions(selectedSession).totalHours > 1 ? 'sessions' : 'session'}`}</TCLabel>
                        </Grid>
                        <Grid item xs={6} textAlign={"right"}>
                            <TCLabel>{currencyFormat(countSessions(selectedSession).price)}</TCLabel>
                        </Grid> */}
                        {/* Quantity */}
                        <Grid item container xs={12} p={0} mb={1} alignItems={"center"} justifyContent={"center"}>
                            <Grid item xs textAlign={"right"} pr={2}>
                                <TCLabel>Quantity : </TCLabel>
                            </Grid>
                            <Grid item xs={4}>
                                <TCNumberInput
                                    value={number}
                                    maxNumber={maxValue()}
                                    onChange={(e) => { 
                                        setNumber(e) 
                                    } }
                                />
                            </Grid>
                        </Grid>
                        {/* Check Stock */}
                        {/* Calculate Stock */}
                    </Grid>
                    <MKButton fullWidth color="primary" disabled={disableSellButton()}
                        onClick={() => {
                            if (disableSellButton()) { return; }
                            // Confirmation("", "Proceed to payment ?", () => {}, () => {
                            //     submitOrder()
                            // })
                            addItemCartQty()
                        }}>Add</MKButton>
                </Grid>
            }
        </Grid>
    )
}

function AddOnServiceCalendar ({
    item,
    navigate
}) {

    const [ availableHours, setAvailableHours ] = useState(Hours)
    const [ swipeState, setSwipeState ] = useState(0)
    const [ selectedDefaultDate, setSelectedDefaultDate ] = useState(new Date())
    const [ form, setForm ] = useState({
        selectedDate: toLocalGMT(new Date()),
        date: toGMT7(new Date()),
        month: toGMT7(new Date(), 'MM'),
        year: toGMT7(new Date(), 'YYYY'),
        court: null,
        hours: [],
        totalSessions: 0,
        totalPrice: 0,
    })

    const onChange = (e) => {
        setForm(prev => ({
            ...prev,
            ...e
        }))
    }

    useEffect(() => {
        loadAddOnSchedule(form)
    }, [form])

    useEffect(() => {
    }, [availableHours])

    const handlers = useSwipeable({
        onSwipedLeft: () => {
            if (swipeState == 1) { return; }
            setSwipeState(swipeState + 1)

            let monthPlus1 = swipeState + 1
            let month = momentFormat(addMonths(selectedDefaultDate, monthPlus1), 'MM')
            let year = momentFormat(addMonths(selectedDefaultDate, monthPlus1), 'YYYY')

            onChange({
                month: month,
                year: year
            })
        },
        onSwipedRight: () => {
            if (swipeState == 0) { return; }
            setSwipeState(swipeState - 1)

            let monthPlus1 = swipeState - 1
            let month = momentFormat(addMonths(selectedDefaultDate, monthPlus1), 'MM')
            let year = momentFormat(addMonths(selectedDefaultDate, monthPlus1), 'YYYY')

            onChange({
                month: month,
                year: year
            })
        },
    });

    const loadAddOnSchedule = async () => {
        console.log('here form info', {
            ...form,
            itemId: item._id
        })
        let { data, error } = await getServiceSchedule({
            ...form,
            itemId: item._id
        })
        console.log(data)
        if (data) { 
            setAvailableHours(data.hours)
        }
    }

    return (
        <>
            <Grid item container justifyContent={"flex-start"} >
                <Grid item xs={6} textAlign={"center"}>
                    <div className={form.month === momentFormat(selectedDefaultDate, 'MM') ? 'calendar-custom-header-selected' : 'calendar-custom-header'}
                        onClick={() => {
                            onChange({
                                month: momentFormat(selectedDefaultDate, 'MM'),
                                year: momentFormat(selectedDefaultDate, 'YYYY')
                            })
                            setSwipeState(0)
                        }}
                    >
                        {momentFormat(selectedDefaultDate, 'MMM YY')}
                    </div>
                </Grid>
                <Grid item xs={6} textAlign={"center"}>
                    <div className={form.month === momentFormat(addMonths(selectedDefaultDate, 1), 'MM') ? 'calendar-custom-header-selected' : 'calendar-custom-header'}
                        onClick={() => {
                            onChange({
                                month: momentFormat(addMonths(selectedDefaultDate, 1), 'MM'),
                                year: momentFormat(addMonths(selectedDefaultDate, 1), 'YYYY')
                            })
                            setSwipeState(1)
                        }}
                    >
                        {momentFormat(addMonths(selectedDefaultDate, 1), 'MMM YY')}
                    </div>
                </Grid>
            </Grid>
            <div {...handlers} style={{width: '100%'}}>
                <Calendar 
                    className={"react-calendar"}
                    onChange={(e) => {
                        onChange({
                            selectedDate: toLocalGMT(e),
                            date: toGMT7(e)
                        });
                        // if (scheduleListDate.indexOf(moment(e).format('YYYY-MM-DD')) >= 0) {
                        //     // console.log('here info', scheduleListDate, scheduleList)
                        //     let scheduleData = []
                        //     for (let i = 0; i < scheduleList.length; i ++) {
                        //         if (scheduleList[i].date === moment(e).format('YYYY-MM-DD')) {
                        //             scheduleData.push(scheduleList[i])
                        //         }
                        //     }
                        //     scheduleData.sort((a,b) => (a.startHour > b.startHour) ? 1 : ((b.startHour > a.startHour) ? -1 : 0))
                        //     setShowDetail({
                        //         open: true,
                        //         scheduleData: scheduleData
                        //     })
                        // }
                    }}
                    tileClassName={(({date}) => {
                        if (moment(date).format('YYYY-MM-DD') < moment(subMonths(new Date(), 1)).format('YYYY-MM-DD')) {
                            return;
                        }
                        if (moment(date).format('YYYY-MM-DD') == moment(form.selectedDate).format('YYYY-MM-DD')) {
                            return;
                        }
                        // if (scheduleListDate.indexOf(moment(date).format('YYYY-MM-DD')) >= 0) {
                        //     return 'dotted'
                        // }
                    })}
                    minDate={new Date()}
                    maxDate={checkAdvancedDays(item.type)}
                    defaultValue={form.selectedDate} 
                    value={form.selectedDate}
                    showNavigation={false}
                    activeStartDate={new Date(form.year, (form.month - 1), 1)}
                />
            </div>
            {/* <div style={{border: '0.5px solid #3447671F', height: '0.5px', marginTop: 10, width: '100%'}}/> */}
            <div className="separation-line"/>
            <HoursComponent availableHours={availableHours} 
                onChange={onChange} form={form} navigate={navigate} />
        </>
    )
}

function HoursComponent ({
    form,
    onChange,
    availableHours,
    isNotActiveCalendar,
    navigate
}) {

    const updateSelectedTime = (value) => {

        let index = form.hours.indexOf(value.label);
        let newForm = form.hours;
        let sessions = form.totalSessions;
        let total = form.totalPrice;
        
        if (value.active && index < 0) {
            
            newForm = [ ...form.hours, value.label ];
            sessions += 1;
            total += value.nominee;

        } else if (index >= 0) {
            
            newForm.splice(index, 1);
            sessions -= 1;
            total -= value.nominee;

        }

        onChange({hours: newForm, totalSessions: sessions, totalPrice: total})
    }

    const getEventName = (e) => {
        console.log('here check info', e)
        let name = "Event"
        if (e.tournamentBooked === true && e.tournamentObject.length > 0) {
            for (let i = 0; i < e.tournamentObject.length; i ++) {
                if (e.tournamentObject[i].hours.indexOf(e.label) >= 0) {
                    return e.tournamentObject[i].eventName
                }
            }
        }
        return name
    }

    return (
        <Grid container>
            <Grid mb={1} item>
                <TCLabel>Time</TCLabel>
            </Grid>
            <Grid container spacing={0.8}>
            {
                availableHours.map( value => {
                    return (
                        <Grid item>
                            <div className={
                                    !value.active ? 'selection-disabled' :
                                    !value.isAvailable ? 'selection-disabled' :
                                    isNotActiveCalendar ? 'selection-disabled' :
                                    value.tournamentBooked ? 'selection-booked-others' : 
                                    value.idle ? 'selection-idle' : 
                                    value.booked ? value.ownBooked ? 'selection-booked-own' : 
                                    value.classBooked ? 'selection-booked-own' : 'selection-booked' : 
                                    value.active ? 
                                        form.hours.indexOf(value.label) >= 0 ? 
                                        'selection-selected' : 
                                        'selection-available' 
                                    : 'selection-disabled'
                                } 
                                style={{textAlign: 'center', padding: 5, 
                                    minWidth: 25, borderRadius: 8
                                }}
                                onClick={() => {
                                    if (isNotActiveCalendar) { return; }
                                    if (!value.active) { return; }
                                    if (value.idle || value.booked) { 
                                        Confirm("", value.idle ? "Awaiting payment. Please check again" : value.ownBooked ? "My booking" 
                                        : value.classBooked ? "Class Schedule" : value.tournamentBooked ?  `${getEventName(value)}` : "Booked")
                                        return; }
                                    if (!value.isAvailable) {
                                        Confirm("", "Not Available")
                                        return;
                                    }
                                    // navigate(`/court-booking#${form.date}`)
                                    // updateSelectedTime(value)
                                    Confirm("", "Available")
                                }}
                            >
                                {/* <MKTypography variant="small" fontWeight="regular" color="inherit">{value.label}</MKTypography> */}
                                <div>{value.label}</div>
                                {/* {
                                    value.idle ? <Icon>access_time</Icon> : 
                                    value.tournamentBooked ? <Icon>event_available</Icon> : 
                                    value.ownBooked && value.classBooked ? <span>
                                        <Icon>event_available</Icon>
                                        <Icon>sports_tennis</Icon>
                                    </span> :
                                    value.ownBooked ? <Icon>event_available</Icon> : 
                                    value.classBooked ? <Icon>sports_tennis</Icon> : 
                                    value.price &&
                                    <div>{value.price}</div>
                                } */}
                            </div>
                        </Grid>
                    )
                })
            }
            </Grid>
        </Grid>
    )
}

function checkAdvancedDays (type = "rent") {
    if (type === "service") {
        return moment(new Date()).add(14, 'days').toDate()
    } else if (type === "rent") {
        return moment(new Date()).add(7, 'days').toDate()
    } else {
        return moment(new Date()).add(14, 'days').format('YYYY-MM-DD')
    }
}

export { ProductComponent, ProductDetail }
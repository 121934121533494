import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
import moment from "moment";

import { reactLocalStorage } from "reactjs-localstorage";
import { LOCALSTORAGECONSTANT } from '../helper/constant'; 

import { getMe } from "service/authService"
import { setUserInfo } from "./localStorage";
import { Card, Grid, Icon } from "@mui/material";
import { TCLabel } from "components/TopCourtComponents/Label/TopCourtLabel";
import { TCTitleLabel } from "components/TopCourtComponents/Label/TopCourtLabel";
import { color, courtPackageType } from "./constant";

import tennisMale from "assets/logo/tennis_player_male.png";
import tennisFemale from "../assets/logo/tennis_player_female.png";
import { loadImages } from "./loadImages";

{/* <AvatarGroup
  renderSurplus={(surplus) => <span>+{surplus.toString()[0]}k</span>}
  total={4251}
>
  <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
  <Avatar alt="Travis Howard" src="/static/images/avatar/2.jpg" />
  <Avatar alt="Agnes Walker" src="/static/images/avatar/4.jpg" />
  <Avatar alt="Trevor Henderson" src="/static/images/avatar/5.jpg" />
</AvatarGroup> */}

export const checkClubLeader = () => {
  return false
}

export const getUserInfo = async () => {
  let { data, error } = await getMe();
  if (error) { return error }
  setUserInfo(data)
  return data
}

export const getUserName = (data) => {
  let name = ""
  if (data) {
    name = data.coachStatus ? `Coach ${data.name}` : `${data.name || data.systemName}`
  }
  return name
}

export const getUserAvatar = (userDetail) => {
  return userDetail.profilePic ? loadImages(userDetail.profilePic) : userDetail.gender === "male" ? tennisMale : tennisFemale
}

export function UserPackageHeader ({
  onClick,
  label,
  history = false,
  rerender
}) {

  let localStorageUser = reactLocalStorage.get(LOCALSTORAGECONSTANT.TOP_COURT_USER)
  const userInfo = localStorageUser ? JSON.parse(localStorageUser) : null
  const [ user, setUser ] = useState(userInfo)
  // const navigate = useNavigate()
  
  useEffect(() => {
    loadUserInfo()
  }, [rerender])

  const loadUserInfo = async () => {
    let { data, error } = await getMe();
    if (error) { console.log(error) }
    if (data) { 
      setUserInfo(data)
      setUser(data)
    }
  }

  return (
    <>
      {user && user.activeBenefits &&
        <Grid className="gradient-Indoor-1" xs={12} sx={{
          paddingTop: 1,
          paddingLeft: 2,
          paddingRight: 2
        }} onClick={() => {
          if (onClick) {
            onClick()
          }
        }}>
          <Grid container direction={"row"}>
            {label && 
              <Grid container xs={10} alignItems={"flex-end"}>
                <Grid item><TCLabel style={{fontSize: 14, color: 'white'}}>{label}</TCLabel></Grid>
                <Grid item><TCLabel inheritColor={true} subtitle style={{paddingBottom: 2, paddingLeft: 3, color: 'white'}}>valid until {
                    (user.packageEnd && user.packageEnd > moment(user.activeBenefits.validityStart).add(user.activeBenefits.validity, "month").format("DD/MM/YYYY")) ? moment(user.packageEnd).format("DD/MM/YYYY") :
                    moment(user.activeBenefits.validityStart).add(user.activeBenefits.validity, "month").format("DD/MM/YYYY")
                  }</TCLabel></Grid>
              </Grid>
            }
            {/* {history && <Grid xs={2} textAlign={"right"}><Icon onClick={() => {
              navigate("/session-history")
            }} sx={{color: color.primary}}>description</Icon></Grid>} */}
          </Grid>
          <Grid container pt={1} pb={1} textAlign={"center"} spacing={1}>
            {
              courtPackageType.map(value => {
                return (
                  <Grid item xs={4}>
                    <Card>
                      <TCLabel>{value.label}</TCLabel>
                      <TCLabel bold style={{fontSize: 14, fontWeight: 'bold'}}>{user.activeBenefits.sessions[value.value]}<span></span></TCLabel>
                    </Card>
                  </Grid>
                )
              })
            }
          </Grid>
        </Grid>
      }
    </>
  )
}
import React, { useEffect, useState } from "react";

import moment from "moment";
import { Grid, Icon } from "@mui/material";

import barcodeSVG from "../../../assets/barcode.svg";
import { color, timeStringFormat, courtTypeObj } from "helper/constant";

import { TCLabel } from "components/TopCourtComponents/Label/TopCourtLabel";
import { orderStatusMap } from "helper/constant";
import { sortInformation } from "helper/sortItem";

function ItemScheduleCard ({
    value,
    orderInfo,
    setShowQR
}) {
    console.log('here check value in itemschedule', value, orderInfo)
    return (
        <Grid style={{marginTop: 5}} sx={{
            border: `0.5px solid ${color.primary}`,
            borderRadius: 2
        }}>
            <div className='custom-body' style={{padding: 10}}>
                {
                    value.date &&
                    <TCLabel style={{fontSize: 12, fontWeight: 'bold'}}>{moment(value.date).format("ddd DD/MM/YYYY")}</TCLabel>   
                }
                {
                    value.data && sortInformation(value.data).map( (v, index) => {
                        return(
                            <>
                                <div style={{display: 'flex', alignItems: "top", marginTop: 2}}>
                                    <div style={{width: '90%'}}>
                                        <TCLabel style={{fontSize: 12, fontWeight: 'bold'}}>{
                                            (orderInfo && orderInfo.orderType == "rent" || orderInfo && orderInfo.orderType == "sell") ?
                                            `${orderInfo.orderType == "rent" ? "Rent " : "Buy "} ${v.productInfo.category} : ${v.productInfo.name}`
                                            :
                                            `${v.productInfo.category} : ${v.productInfo.name}`
                                        }</TCLabel>
                                        {
                                            value.court &&
                                            <TCLabel style={{fontSize: 12, fontWeight: 'bold' }}>{courtTypeObj[v.court.courtType]} {v.court.name}</TCLabel>
                                        }
                                        {
                                            orderInfo && orderInfo.orderType == "sell" &&
                                            <TCLabel style={{fontSize: 12}}>Qty {v.qty}</TCLabel>
                                        }
                                        <Grid container>
                                        {
                                            v.hours.map((h, index) => {
                                                return <Grid item>
                                                    <TCLabel style={{fontSize: 12}}>{`${h} - ${timeStringFormat(Number(h.split(':')[0]) + 1)}`}{index < (v.hours.length - 1) ? <span style={{padding: 3, fontFamily: 'roboto', fontWeight: 50}}>{"|"}</span> : ""}
                                                    {/* ${index < (v.hours.length - 1) ? " | " : ""} */}
                                                    </TCLabel>
                                                </Grid>
                                            })
                                        }
                                        </Grid>
                                        {/* <TCLabel>Total: <span style={{fontWeight: 'bold' }}>{currencyFormat(v.total)}</span></TCLabel> */}
                                    </div>
                                    <div style={{width: '12%'}}>
                                        {
                                            ((orderInfo.isPaid && orderStatusMap(orderInfo) != "Completed") && (index === 0 && orderInfo && (orderInfo.orderType == "rent" || orderInfo.orderType == "sell"))) &&
                                            <div>
                                                <Grid className="element element-1" xs={12}
                                                    sx={{
                                                        color: orderInfo.isPaid ? '#ffff' : color.grey,
                                                        width: 40,
                                                        height: 40,
                                                    }} 
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        let courtInformation = {}
                                                        let hoursInformation = {}
                                                        if (v.productInfo) {
                                                            courtInformation = v.court
                                                            hoursInformation = {
                                                                date: v.date,
                                                                hours: v.hours
                                                            }
                                                        }
                                                        if (orderInfo.orderType == "sell") {
                                                            hoursInformation = {
                                                                date: orderInfo.createdDate
                                                            }
                                                        }
                                                        console.log('here qrpage', courtInformation, hoursInformation)
                                                        if (orderInfo.isPaid) {
                                                            setShowQR({
                                                                open: true,
                                                                courtInfo: {
                                                                    ...courtInformation,
                                                                    ...orderInfo.court,
                                                                    ...orderInfo.classObj.courtInfo,
                                                                    hours: orderInfo.classObj.hours,
                                                                    date: orderInfo.date
                                                                },
                                                                data: {
                                                                    ...orderInfo,
                                                                    eventName: orderInfo.classObj.name,
                                                                    date: orderInfo.classObj.date,
                                                                    hours: orderInfo.classObj.hours,
                                                                    ...hoursInformation,
                                                                },
                                                                uniqueCode: orderInfo.uniqueCode,
                                                                value: orderInfo.uniqueCode
                                                            })
                                                        } else {
                                                            Confirm("", "Make payment to unlock the Barcode.")
                                                        }
                                                    }
                                                }>
                                                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="4em" height="4em" viewBox="0 8 24 8"><path fill="currentColor" d="M2 6h2v12H2zm3 0h1v12H5zm2 0h3v12H7zm4 0h1v12h-1zm3 0h2v12h-2zm3 0h3v12h-3zm4 0h1v12h-1z"/></svg> */}
                                                    <img src={barcodeSVG} width={30} height={30} />
                                                </Grid>
                                            </div>
                                        }
                                    </div>
                                </div>
                                { 
                                    (index < (value.data.length - 1)) && 
                                    <div className="separation-line"/>
                                }
                            </>
                        )
                    })
                }
            </div>
        </Grid>
    )
}

export default ItemScheduleCard
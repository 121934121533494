import React, { useState } from "react"
import { Badge, Icon } from "@mui/material"
import { color } from "helper/constant"

function NotificationButton ({

}) {
    const [ badge, setBadge ] = useState(0)

    return (
        <>
        {
            badge ?
            <Badge
                sx={{mt: -0.5}}
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right'}}
                badgeContent={<label htmlFor="photo-upload" className="fas" style={{backgroundColor: color.blueIndoor_2, color: 'white', borderRadius: 10, width: 20, height: 20, 
                display: 'flex', justifyContent: 'center', alignItems: 'center'}}>{badge}</label>}
                >
                <Icon fontSize="xxl" sx={{color: color.primary}}>notifications</Icon>
            </Badge>
            :
            <Icon fontSize="xxl" sx={{color: color.primary}}>notifications</Icon>
        }
        </>
    )
}

export default NotificationButton
import React, { useState, useEffect } from "react";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { Grid } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function SlideAlert ({
    title,
    isOpen,
    maxWidth,
    setIsOpen,
    children,
    action,
    disableBackdrop = false,
    useDialogContent = true
}) {

    const handleClose = (event, reason) => {
      if (disableBackdrop && (reason && reason == "backdropClick")) {
        return;
      }  
      setIsOpen(false)
    }

    useEffect(() => {
      if (isOpen) { document.body.style.overflow = 'hidden' }
      if (!isOpen) { document.body.style.overflow = 'unset' }
    }, [isOpen])

    return (
        <Dialog
            fullWidth={true}
            // maxWidth={window.innerWidth >= 444 ? 'xs' : maxWidth}
            maxWidth={maxWidth}
            open={isOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
          >
            {
              title &&
              <DialogTitle>{title}</DialogTitle>
            }
            {
              !useDialogContent && children
            }
            {
              useDialogContent &&
              <DialogContent>
                {/* <DialogContentText id="alert-dialog-slide-description">
                  {children}
                </DialogContentText> */}
                {children}
              </DialogContent>
            }
            {
              action &&
              <DialogActions>
                {action}
              </DialogActions>
            }
        </Dialog>
    )
}

export default SlideAlert;
import React, { useEffect, useState } from "react"

import Calendar from "react-calendar"
import DialogHeader from "components/SlideDialog/DialogHeader"
import moment from "moment-timezone";

import { toGMT7, toLocalGMT, momentFormat } from 'helper/time';
import { addMonths, subMonths } from "date-fns";
import { useSwipeable } from "react-swipeable";
import { Hours } from "helper/constant";
import { Card, Grid } from "@mui/material"
import { TCLabel } from "components/TopCourtComponents/Label/TopCourtLabel";
import { getServiceHours, getServiceSummarySchedule } from "service/addOnService";
import { ProductComponent } from "../ProductComponent";
import { currencyFormat } from "helper/numberFormat";
import { getUserAvatar } from "helper/user";
import { loadImages } from "helper/loadImages";
import { getCourtInfo } from "helper/localStorage";
import { Confirm } from "helper/showAlert";
import Loading from "components/TopCourtComponents/Loading/TCLoadingComponent";

function ServiceSummaryCalendar ({
    setOpen,
    selectedCalendarDate,
    setSelectedCalendarDate,
    selectService
}) {

    let defaultDate = selectedCalendarDate ? selectedCalendarDate : new Date()
    const [ availableHours, setAvailableHours ] = useState(Hours)
    const [ swipeState, setSwipeState ] = useState(0)
    const [ selectedDefaultDate, setSelectedDefaultDate ] = useState(new Date())
    const [ form, setForm ] = useState({
        selectedDate: toLocalGMT(defaultDate),
        date: toGMT7(defaultDate),
        month: toGMT7(defaultDate, 'MM'),
        year: toGMT7(defaultDate, 'YYYY'),
        court: null,
        hours: [],
        hour: moment(new Date()).tz('Asia/Jakarta').format('HH:00'),
        totalSessions: 0,
        totalPrice: 0,
    })
    const [ serviceList, setServiceList ] = useState([])
    const [ loading, setLoading ] = useState(false)
    let placeInfo = getCourtInfo ? JSON.parse(getCourtInfo) : null

    const onChange = (e) => {
        setForm(prev => ({
            ...prev,
            ...e
        }))
    }

    useEffect(() => {
        loadSummarySchedule(form)
        loadServiceHours(form)
    }, [form])

    const loadServiceHours = async (form) => {
        let { data, error } = await getServiceHours(form.date)
        if (error) { console.log(error) }
        if (data) { setAvailableHours(data) }
    }

    const loadSummarySchedule = async (form) => {
        setLoading(true)
        let { data, error } = await getServiceSummarySchedule(
            form.date, form.hour, placeInfo._id
        )
        setLoading(false)
        if (error) { console.log(error) }
        if (data) { setServiceList(data) }
    }

    const handlers = useSwipeable({
        onSwipedLeft: () => {
            if (swipeState == 1) { return; }
            setSwipeState(swipeState + 1)

            let monthPlus1 = swipeState + 1
            let month = momentFormat(addMonths(selectedDefaultDate, monthPlus1), 'MM')
            let year = momentFormat(addMonths(selectedDefaultDate, monthPlus1), 'YYYY')

            onChange({
                month: month,
                year: year
            })
        },
        onSwipedRight: () => {
            if (swipeState == 0) { return; }
            setSwipeState(swipeState - 1)

            let monthPlus1 = swipeState - 1
            let month = momentFormat(addMonths(selectedDefaultDate, monthPlus1), 'MM')
            let year = momentFormat(addMonths(selectedDefaultDate, monthPlus1), 'YYYY')

            onChange({
                month: month,
                year: year
            })
        },
    });

    return (
        <Grid>
            <DialogHeader title={"Schedule Summary"} closeDialog={()=>{setOpen(false)}}/>
            <Grid item container justifyContent={"flex-start"} >
                <Grid item xs={6} textAlign={"center"}>
                    <div className={form.month === momentFormat(selectedDefaultDate, 'MM') ? 'calendar-custom-header-selected' : 'calendar-custom-header'}
                        onClick={() => {
                            onChange({
                                month: momentFormat(selectedDefaultDate, 'MM'),
                                year: momentFormat(selectedDefaultDate, 'YYYY')
                            })
                            setSwipeState(0)
                        }}
                    >
                        {momentFormat(selectedDefaultDate, 'MMM YY')}
                    </div>
                </Grid>
                <Grid item xs={6} textAlign={"center"}>
                    <div className={form.month === momentFormat(addMonths(selectedDefaultDate, 1), 'MM') ? 'calendar-custom-header-selected' : 'calendar-custom-header'}
                        onClick={() => {
                            onChange({
                                month: momentFormat(addMonths(selectedDefaultDate, 1), 'MM'),
                                year: momentFormat(addMonths(selectedDefaultDate, 1), 'YYYY')
                            })
                            setSwipeState(1)
                        }}
                    >
                        {momentFormat(addMonths(selectedDefaultDate, 1), 'MMM YY')}
                    </div>
                </Grid>
            </Grid>
            <div {...handlers} style={{width: '100%'}}>
                <Calendar 
                    className={"react-calendar"}
                    onChange={(e) => {
                        setSelectedCalendarDate(e)
                        if (moment(e).format('YYYY-MM-DD') === moment(new Date()).format('YYYY-MM-DD')) {
                            onChange({
                                selectedDate: toLocalGMT(e),
                                date: toGMT7(e),
                                hour: moment(new Date()).tz('Asia/Jakarta').format('HH:00')
                            });
                            return;   
                        }
                        onChange({
                            selectedDate: toLocalGMT(e),
                            date: toGMT7(e)
                        });
                    }}
                    tileClassName={(({date}) => {
                        if (moment(date).format('YYYY-MM-DD') < moment(subMonths(new Date(), 1)).format('YYYY-MM-DD')) {
                            return;
                        }
                        if (moment(date).format('YYYY-MM-DD') == moment(form.selectedDate).format('YYYY-MM-DD')) {
                            return;
                        }
                    })}
                    minDate={new Date()}
                    maxDate={moment(new Date()).add(14, 'days').toDate()}
                    defaultValue={form.selectedDate} 
                    value={form.selectedDate}
                    showNavigation={false}
                    activeStartDate={new Date(form.year, (form.month - 1), 1)}
                />
            </div>
            <div className="separation-line"/>
            <HoursComponent availableHours={availableHours} 
                onChange={(e) => {
                    onChange(e)
                }} form={form} />
            <div className="separation-line" style={{marginTop: '10px'}}/>
            <Grid>
                <TCLabel>Available Service</TCLabel>
                <Grid container pt={1}>
                    {
                        serviceList.map( value => {
                            if (value.isAvailable == true && !value.booked) {
                                return (
                                    <Grid item xs={12} lg={12} mb={2}
                                        sx={{ paddingTop: '0px !important'}}
                                        onClick={() => { 
                                            if (!value.isAvailable) { Confirm("", "Not Available"); return; }
                                            if (value.booked) { Confirm("", "Booked"); return; }
                                            setOpen(false)
                                            selectService(value._id)
                                        }}>
                                        <Card>
                                            {
                                                (value.booked || !value.isAvailable) &&
                                                <div style={{
                                                    backgroundColor: '#f0f0f04D',
                                                    position: 'absolute',
                                                    top: 0, bottom: 0, left: 0, right: 0
                                                }}/>
                                            }
                                            <Grid container xs={12} p={1}>
                                                <div style={{display: 'flex'}}>
                                                    <div style={{ marginRight: 8}}>
                                                        <img src={
                                                            value.itemImages.length > 0 ? loadImages(value.itemImages[0]) : getUserAvatar(value.user)
                                                        } width={60} height={80} style={{
                                                            objectFit: 'contain'
                                                        }} />
                                                    </div>
                                                    <div>
                                                        <Grid item container xs>
                                                            <Grid item xs={12}>
                                                                <TCLabel bold>{value.category.name}</TCLabel>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <TCLabel>{value.name}</TCLabel>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <TCLabel bold>{currencyFormat(value.price)}</TCLabel>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <TCLabel>{!value.isAvailable ? 'Not Available' : value.booked ? 'Booked' : 'Available'}</TCLabel>
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                </div>
                                            </Grid>
                                        </Card>
                                    </Grid>
                                )
                            }
                        })
                    }
                </Grid>
            </Grid>
            {
                // (loading || loadingTimer) &&
                loading &&
                <Loading/>
            }
        </Grid>
    )
}

function HoursComponent ({
    form,
    onChange,
    availableHours,
    isNotActiveCalendar
}) {

    const updateSelectedTime = (value) => {
        onChange({ hour: value })
        // let index = form.hours.indexOf(value.label);
        // let newForm = form.hours;
        // let sessions = form.totalSessions;
        // let total = form.totalPrice;
        
        // if (value.active && index < 0) {
            
        //     newForm = [ ...form.hours, value.label ];
        //     sessions += 1;
        //     total += value.nominee;

        // } else if (index >= 0) {
            
        //     newForm.splice(index, 1);
        //     sessions -= 1;
        //     total -= value.nominee;

        // }

        // onChange({hours: newForm, totalSessions: sessions, totalPrice: total})
    }

    return (
        <Grid container>
            <Grid mb={1} item>
                <TCLabel>Available Time</TCLabel>
            </Grid>
            <Grid container spacing={0.8}>
            {
                availableHours.map( value => {
                    return (
                        <Grid item>
                            <div className={
                                    value.value == form.hour ? 'selection-selected' :
                                    !value.isAvailable ? 'selection-disabled' : 
                                    'selection-available'
                                } 
                                style={{textAlign: 'center', padding: 5, 
                                    minWidth: 25, borderRadius: 8
                                }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    if (!value.isAvailable) { return; }
                                    updateSelectedTime(value.label)
                                }}
                            >
                                <div>{value.label}</div>
                            </div>
                        </Grid>
                    )
                })
            }
            </Grid>
        </Grid>
    )
}

export default ServiceSummaryCalendar
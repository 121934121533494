import React, { useEffect, useState } from "react";

import { Avatar, Card, Checkbox, FormControlLabel, Grid, Icon, Rating } from "@mui/material";
import MKButton from "components/MKButton";
import DialogHeader from "components/SlideDialog/DialogHeader";
import { TCInput } from "components/TopCourtComponents/Input/TopCourtInput";
import { color } from "helper/constant";
import { TCLabel } from "components/TopCourtComponents/Label/TopCourtLabel";
import { TCTitleLabel } from "components/TopCourtComponents/Label/TopCourtLabel";
import EmptyData from "components/TopCourtComponents/Placeholder/EmptyData";
import { Female, Male } from "@mui/icons-material";
import { capitalizeFirstLetter } from "helper/string";
import { currencyFormat } from "helper/numberFormat";
import { WhatsApp } from "@mui/icons-material";
import { giveCoachRatingService, getCoachRatingList } from "service/coachService";
import { Confirm } from "helper/showAlert";
import { getUserInfo } from "helper/localStorage";
import TCStars from "components/TopCourtComponents/Ratings/TopCourtRatings";
import moment from "moment";
import { updateCoachRatingService } from "service/coachService";
import { getUserName } from "helper/user";
import { loadImages } from "helper/loadImages";
import { getUserAvatar } from "helper/user";
import { getMe } from "service/authService";
import { setUserInfo } from "helper/localStorage";


function Coach ({
    data,
    setOpen,
    checkIsLogin
}) {

    const coachLevel = {
        "1" : "Beginner",
        "2" : "Intermediate",
        "3" : "Advanced"
    }

    const userInfo = getUserInfo ? JSON.parse(getUserInfo) : null
    const [ rating, setRating ] = useState({
        value: 5,
        comment: null,
        isAnonymus: true
    })
    const [ ratingList, setRatingLists ] = useState([])
    const [ myRatings, setMyRatings ] = useState(null)
    const [ ratingSummary, setRatingSummary ] = useState({
        rating: 0,
        total: 0
    })
    const [ updateRating, setUpdateRating ] = useState(false)
    const [ userDetailInfo, setUserDetailInfo ] = useState(null)

    const ratingChanged = (newRating) => {
        onChange({value: newRating})
    }

    const onChange = (e) => {
        setRating(prev => ({
            ...prev,
            ...e
        }))
    }

    useEffect(() => {
        loadUserDetailInfo()
    }, [])

    useEffect(() => {
        console.log(userDetailInfo)
        if (userDetailInfo) {
            loadReview()
        }
    }, [userDetailInfo])

    const loadUserDetailInfo = async (e) => {
        let { data, error } = await getMe()
        if (error) { console.log(error) }
        if (data) {  setUserDetailInfo(data); setUserInfo(data); }
    }

    const disableEdit = () => {
        let disabled = false
        if (!userDetailInfo) { disabled = true } else {
            if (userDetailInfo.totalOrder <= 0) { disabled = true }
        }
        return disabled
    }

    const loadReview = async (e) => {
        let ratings = await getCoachRatingList(data._id, userInfo ? userInfo._id : null)
        if (ratings.data) {
            console.log(ratings.data)
            setRatingLists(ratings.data.ratings)
            setMyRatings(ratings.data.myRatings)
            let summary = ratings.data.ratingSummary
            console.log(summary)
            setRatingSummary(prev => ({
                ...prev,
                ...summary
            }))
        }
    }

    const submitReview = async (e) => {
        // check login
        if (updateRating) {
            let updateRatingService = await updateCoachRatingService({
                ...rating
            })
            if (updateRatingService.error) { Confirm("", ratingService.error) }
            if (updateRatingService.data) { Confirm("", "Review updated") }
            setUpdateRating(false)
            await loadReview()
        } else {
            let ratingService = await giveCoachRatingService({
                ...rating,
                coach: data._id
            })
            if (ratingService.error) { Confirm("", ratingService.error) }
            if (ratingService.data) { Confirm("", "Review submitted");  }
            await loadReview()
        }
    }

    return (
        <>
            <Grid p={2} pb={0}><DialogHeader title={"Coach Detail"} closeDialog={()=>{setOpen()}}/></Grid>
            <Grid container p={2} pt={0} sx={{overflow: 'auto'}}>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        {/* <img src={data.profilePic} width={'100px'} maxWidth={'100px'} height={'auto'}/> */}
                        <Avatar src={getUserAvatar(data)} 
                            sx={{ width: 'auto', height: 100 }}
                         />
                    </Grid>
                    <Grid item xs={8}>
                        <TCTitleLabel style={{fontSize: 14}}>{getUserName(data)}{data.gender == "male" ? <Male style={{color: color.blue}}/> : <Female style={{color: 'pink'}}/>}</TCTitleLabel>
                        <Grid item container spacing={0.2}>
                            {/* <Grid item p={0} sx={{mt: -0.8}}>
                                <img src={levelUp} width={13} height={13} />
                            </Grid> */}
                            <Grid pb={0} item>
                                <TCLabel bold subtitle>{"Level : "}</TCLabel>
                            </Grid>
                            <Grid pt={0} item>
                                <TCLabel subtitle>{
                                    data.coachDetail.trainingLevels.map((v, index) => {
                                        if (index > 0) { return `, ${coachLevel[v]}` }
                                        else return coachLevel[v]
                                    })
                                }</TCLabel>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item container spacing={0.2} xs={6}>
                                <Grid item>
                                    <TCLabel subtitle bold>Min Player :</TCLabel>
                                </Grid>    
                                <Grid item>
                                    <TCLabel subtitle>{data.coachDetail.minPlayer}</TCLabel>
                                </Grid>   
                            </Grid> 
                            <Grid item container spacing={0.2} xs={6}>
                                <Grid item>
                                    <TCLabel subtitle bold>Max Player :</TCLabel>
                                </Grid>    
                                <Grid item>
                                    <TCLabel subtitle>{data.coachDetail.maxPlayer}</TCLabel>
                                </Grid>  
                            </Grid>   
                        </Grid>
                        <Grid container spacing={0.3}>
                            <Grid item>
                                <TCLabel bold subtitle>Type : </TCLabel>
                            </Grid>
                            <Grid item>
                            {
                                data.coachDetail.coachingTypes.map(v => {
                                    return (
                                        <Grid item xs={12}>
                                            <TCLabel subtitle>{capitalizeFirstLetter(v)} {currencyFormat(data.coachDetail[`${v}Fee`])} / hr</TCLabel> 
                                        </Grid>
                                    )
                                })
                            }
                            </Grid>
                        </Grid>
                        {/* <TCLabel subtitle>{capitalizeFirstLetter(data.coachDetail.coachingType)} - Fee {currencyFormat(data.coachDetail.fee)} / Player</TCLabel> */}
                        {/* <Grid item container xs={12}>
                            <Grid item container xs={12} justifyContent={"flex-start"} spacing={0.5} onClick={(e) => {
                                e.stopPropagation()
                                let replacePhoneNumber = value.phoneNumber ? value.phoneNumber.substring(1) : ""
                                window.open(`https://wa.me/${replacePhoneNumber}`, '_blank')
                            }}>
                                <Grid item>
                                    <WhatsApp style={{color: '#25D366', fontWeight: 'bold'}}/>
                                </Grid>
                                <Grid item sx={{marginTop: 0.4}}>
                                    <TCLabel>Whatsapp</TCLabel>
                                </Grid>
                            </Grid>
                            <Grid item container xs={12} justifyContent={"flex-start"} spacing={0.5} onClick={(e) => {
                                e.stopPropagation()
                                window.open(value.phoneNumber)
                            }} >
                                <Grid item sx={{color: color.primary}}>
                                    <Icon>phone</Icon>
                                </Grid>
                                <Grid item sx={{marginTop: 0.4}}>
                                    <TCLabel>Call</TCLabel>
                                </Grid>
                            </Grid>
                        </Grid> */}
                    </Grid>
                </Grid>
                {
                   ((!myRatings || updateRating) && (userInfo && (data._id.toString() != userInfo._id.toString()))) &&
                    <>
                        <Grid item xs={12} pt={1}>
                            <div className="separation-line"/>
                            <TCLabel bold>Rate and Review :</TCLabel>
                        </Grid>
                        <Grid item xs={12} pt={1}>
                            {/* <Card> */}
                                {
                                    !userInfo &&
                                    <Grid container position={"absolute"} sx={{
                                        zIndex: 999,
                                        backgroundColor: '#FFFFFFE6',
                                        width: '100%',
                                        height: '100%'
                                    }} alignItems={"center"} justifyContent={"center"}>
                                        <Grid item xs={12} textAlign={"center"}>
                                            <TCLabel bold>Please login to write review</TCLabel>
                                            <MKButton color="primary" sx={{mt: 1}}
                                                onClick={() => {
                                                    setOpen(false);
                                                    checkIsLogin();
                                                }}
                                            >Login</MKButton>
                                        </Grid>
                                    </Grid>
                                }
                                <Grid container justifyContent={"center"}>
                                    <Grid item p={0} mt={-2}>
                                        <TCStars isEdit={!disableEdit()} size={50} value={rating.value} onChange={ratingChanged}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid p={0} sx={{
                                            border: `0.5px solid ${color.blueIndoor_2}`,
                                            borderRadius: 2
                                        }}>
                                            <TCInput type={"textarea"} 
                                            rows={3}
                                            value={rating.comment} 
                                            onChange={(e) => {
                                                onChange({comment: e.currentTarget.value})
                                            }}
                                            disabled={disableEdit()}
                                            placeHolder={"Share your experience"}></TCInput> 
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} p={2} pl={0.2} pt={0} pb={0}>
                                        <FormControlLabel control={<Checkbox checked={rating.isAnonymus == true} onChange={disableEdit() ? () => {} : () => {
                                            onChange({isAnonymus: !rating.isAnonymus})
                                        }} />} label={<TCLabel style={{fontSize: 12, marginLeft: -8}}>No Name</TCLabel>} />
                                    </Grid>
                                </Grid>
                                <Grid container p={2} pt={0} pl={0.2} pr={0.2} spacing={1}>
                                    {
                                        updateRating &&
                                        <Grid item xs={6}>
                                            <MKButton fullWidth color="white" sx={{
                                                border: 1,
                                                color: color.primary
                                            }} onClick={() => {
                                                setUpdateRating(false)
                                            }}>Cancel</MKButton>
                                        </Grid>
                                    }
                                    <Grid item xs={updateRating ? 6 : 12} onClick={() => {
                                        if (disableEdit()) {
                                            Confirm("", "This account has not met the minimum eligibility requirements to write a review")
                                        }
                                    }}>
                                        <MKButton fullWidth color="primary" 
                                        disabled={disableEdit()}
                                        onClick={() => {
                                            submitReview()
                                        }}>Submit</MKButton>
                                    </Grid>
                                </Grid>
                            {/* </Card> */}
                        </Grid>
                    </>
                }
                {
                    (myRatings && !updateRating) &&
                    <Grid item xs={12} pb={2}>
                        <RatingCard data={myRatings} allowUpdate={true} updateFunction={() => {
                            setRating(prev => ({
                                ...prev,
                                _id: myRatings._id,
                                comment: myRatings.comment,
                                value: myRatings.value,
                                isAnonymus: myRatings.isAnonymus
                            }))
                            setUpdateRating(true)
                        }}/>
                    </Grid>
                }
                <Grid item xs={12}>
                    <div className="separation-line"/>
                    <TCLabel bold>Reviews</TCLabel>
                    {/* <Grid item container xs={12} alignItems={"center"} spacing={0.5}>
                        <Grid item>
                            <TCStars isEdit={false} value={ratingSummary.rating}/>
                        </Grid>
                        <Grid item>
                            <TCLabel subtitle>({ratingSummary.total} reviews)</TCLabel>
                        </Grid>
                    </Grid>  */}
                    {
                        (ratingList.length == 0) &&
                        <EmptyData marginTop={'10%'}/>
                    }
                    <Grid item container spacing={2}>
                    {
                        ratingList.map( value => {
                            return (<RatingCard data={value}/>)
                        })
                    }
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

function RatingCard ({
    data,
    allowUpdate = false,
    updateFunction
}) {
    return (
        <Grid item xs={12}>
            <Card>
                <Grid container alignItems={"center"}>
                    <Grid item p={2} xs={allowUpdate ? 8 : 12}>
                        <Grid container>
                            <Grid>
                                {data.isAnonymus ? <TCLabel bold subtitle>No Name</TCLabel>
                                : <TCLabel bold>{data.user.name}</TCLabel>}
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item>
                                <TCStars size={15} isEdit={false} value={data.value} />
                            </Grid>
                            {
                                data.isUpdated &&
                                <Grid item pt={0.6} pl={0.5}>
                                    <TCLabel subtitle>
                                        Updated on
                                    </TCLabel>
                                </Grid>
                            }
                            <Grid item pt={0.6} pl={0.5}>
                                <TCLabel subtitle>
                                    {
                                        data.isUpdated ?
                                        moment(data.updatedDate).format("MMM YYYY")
                                        :
                                        moment(data.createdDate).format("MMM YYYY")
                                    }
                                </TCLabel>
                            </Grid>
                        </Grid>
                        <TCLabel subtitle>{data.comment}</TCLabel>
                    </Grid>
                    {
                        allowUpdate &&
                        <Grid item pr={2} mt={1} xs={4} textAlign={"right"}>
                            <MKButton 
                            color="white"
                            sx={{
                                fontSize: 10, p: 0,
                                border: 1,
                                pl: 1, pr: 1,
                                color: color.primary
                            }}
                            onClick={() => {
                                updateFunction()
                            }}>Update</MKButton>
                        </Grid>
                    }
                </Grid>
            </Card>
        </Grid>
    )
}

export default Coach
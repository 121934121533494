import { client, errorValidation } from "./service";

export const getClubs = async function (user) {
  try {
    let response = await client.get(`/clubs?user=${user || ''}`);
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data };
  } catch (e) {
    // return { error: e.response.data.errorCode }
    return errorValidation(e)
  }
}
export const createClub = async function (form) {
  try {
    let response = await client.post('/club/create', {
      ...form
    });
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data }
  } catch (e) {
    // return { error: e.response.data.errorCode }
    return errorValidation(e)
  }
}
  
export const updateClub = async function (body) {
  try {
    let response = await client.post('/club/update', {
      ...body
    });
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data }
  } catch (e) {
    // return { error: e.response.data.errorCode }
    return errorValidation(e)
  }
}

export const getClubDetail = async function (clubId) {
  try {
    let response = await client.get(`/club/${clubId}`);
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data };
  } catch (e) {
    // return { error: e.response.data.errorCode };
    return errorValidation(e)
  }
}

export const applyClubMember = async function (body) {
  try {
    let response = await client.post(`/club/join`, {
      ...body
    });
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data };
  } catch (e) {
    // return { error: e.response.data.errorCode };
    return errorValidation(e)
  }
}

export const transferLeaderService = async function (body) {
  try {
    let response = await client.post(`/club/transfer-leader`, {
      ...body
    });
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data };
  } catch (e) {
    // return { error: e.response.data.errorCode };
    return errorValidation(e)
  }
}

export const leaveClubMember = async function (body) {
  try {
    let response = await client.post(`/club/leave`, {
      ...body
    });
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data };
  } catch (e) {
    // return { error: e.response.data.errorCode };
    return errorValidation(e)
  }
}

export const kickMember = async function (body) {
  try {
    let response = await client.post(`/club/kick`, {
      ...body
    });
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data };
  } catch (e) {
    // return { error: e.response.data.errorCode };
    return errorValidation(e)
  }
}

export const approveApplicantService = async function (body) {
  try {
    let response = await client.post(`/club/applicant-approve`, {
      ...body
    });
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data };
  } catch (e) {
    return errorValidation(e)
  }
}

export const rejectApplicantService = async function (body) {
  try {
    let response = await client.post(`/club/applicant-reject`, {
      ...body
    });
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data };
  } catch (e) {
    return errorValidation(e)
  }
}

export const promoteCoordinator = async function (body) {
  try {
    let response = await client.post(`/club/promote-coordinator`, {
      ...body
    });
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data };
  } catch (e) {
    return errorValidation(e)
  }
}

export const demoteCoordinatorService = async function (body) {
  try {
    let response = await client.post(`/club/demote-coordinator`, {
      ...body
    });
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data };
  } catch (e) {
    return errorValidation(e)
  }
}
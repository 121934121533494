import React, { useEffect, useState } from "react"
import { Card, Grid } from "@mui/material"
import { TCLabel } from "components/TopCourtComponents/Label/TopCourtLabel"
import moment from "moment"
import DeleteIcon from '@mui/icons-material/Delete';
import { courtTypeObj } from "helper/constant"
import { color } from "helper/constant";
import { deleteCart } from "../Function";
import { timeStringFormat } from "helper/constant";
import { currencyFormat } from "helper/numberFormat";
import { getUserAvatar } from "helper/user";

function SellItemList ({
    items,
    loadData,
    bottomSheetHeight
}) {
    
    const [ itemBooking, setItemBooking ] = useState([])

    useEffect(() => {
        console.log('here check item booking - items', items)
        setItemBooking(items)
    }, [items])

    useEffect(() => {
        console.log('here check item booking', itemBooking)
    }, [itemBooking])

    let ratio = bottomSheetHeight / window.innerHeight
    let height = (bottomSheetHeight * ratio)
    let newHeight = (bottomSheetHeight - height)

    return (
        <Grid container alignItems="center" style={{
            marginTop: 0.5, paddingLeft: 16, paddingRight: 16, paddingBottom: 8, 
            marginBottom: newHeight,
            overflow: 'auto'}}>
            {
                itemBooking.map((value, index) => {
                    return (
                        <Grid item xs={12} style={{textAlign: 'left', marginTop: 5}}>
                            <Card>
                                <div className='custom-body' style={{padding: 10}}>
                                    {/* <TCLabel style={{fontSize: 13, fontWeight: 'bold'}}>{moment(value.date).format("ddd DD/MM/YYYY")}</TCLabel> */}
                                    <>
                                        <div style={{display: 'flex', marginTop: 2}} onClick={() => {
                                            // onSelectBooking(v)
                                        }}>
                                            <div style={{display: 'flex', width: 100, margin: 8, marginTop:0, marginLeft: 0, alignItems: 'top'}}>
                                                <img src={value.productId.itemImages.length > 0 ? value.productId.itemImages[0] : getUserAvatar(value.productId.user)} 
                                                    width={'100%'}
                                                    style={{
                                                        objectFit: 'contain', minHeight: 80, maxHeight: 80
                                                    }}
                                                />
                                            </div>
                                            <div style={{width: '100%'}}>
                                            <TCLabel style={{fontSize: 12, fontWeight: 'bold'}}>{`${value.productId.category.name} : ${value.productId.name}`}</TCLabel>
                                            <TCLabel style={{fontSize: 12, fontWeight: 'bold'}}>{`Qty : ${value.qty}`}</TCLabel>
                                            {/* <TCLabel style={{fontSize: 12, fontWeight: 'bold'}}>{courtTypeObj[v.sessions.court.courtType]} {v.sessions.court.name}</TCLabel> */}
                                            {/* <Grid container direction={'row'}>
                                            {
                                                v.sessions.hours.map((h, index) => {
                                                    return <TCLabel style={{marginRight: 3, fontSize: 12}}>{`${h} - ${timeStringFormat(Number(h.split(':')[0]) + 1)} 
                                                        ${index < (v.sessions.hours.length - 1) ? " | " : ""}`}</TCLabel>
                                                })
                                            }   
                                            </Grid> */}
                                            <TCLabel>Total: <span style={{fontWeight: 'bold'}}>{currencyFormat(value.productId.price * value.qty)}</span></TCLabel>
                                                {/* 
                                                
                                                    */}
                                            </div>
                                            <div style={{alignItems: 'right'}} onClick={(e) => {
                                                e.stopPropagation()
                                                deleteCart({...value, isExpired: false}, loadData)
                                            }}>
                                                <DeleteIcon style={{width: 20, height: 20, color: color.pink}}/>
                                            </div>
                                        </div>
                                    </>
                                </div>
                            </Card>
                        </Grid>
                    )
                })
            }
        </Grid>
    )
}

export default SellItemList
import React, { useEffect, useState } from 'react'

import { Icon, Tooltip } from '@mui/material';
import { color } from 'helper/constant';
// import FileCopyIcon from '@material-ui/icons/FileCopy';

export function CopyShareLinkComponent({
  title = "Share",
  data,
  message,
  style,
  icon = <Icon style={{color: color.primary}}>share</Icon>,
  children,
  className,
  position = 'right',
  hover = false
}) {
  const [show, setShow] = useState(!hover);
  const [copied, setCopied] = useState(false);
  const [alerTitle, setAlertTitle] = useState("");

  const copyToClipboard = (data, message = 'Value has been copied') => {
    if (navigator && navigator.clipboard) {
      navigator.clipboard.writeText(data);
    }
    setCopied(true)
    setAlertTitle("Copied")
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
        setCopied(false);
      }, 2000);

    return () => clearInterval(intervalId);
  }, [])

  const styles = {
    group: {
      alignItems: 'center'
    },
    tooltip: {
      color: '#00000099', padding: '0 2px', margin: '0 4px',
      visibility: show ? 'visible' : 'hidden',
    },
    ...style,
  };
  const customClass = {
    group: '',
    tooltip: '',
    ...className,
  }

  return (
    <div
      className={`d-flex ${customClass.group || ''}`}
      style={styles.group}
      onMouseOver={() => hover ? setShow(true) : null}
      onMouseOut={() => hover ? setShow(false) : null}>

      {position === 'right' && children}

      {data &&
        <Tooltip
            open={copied}
            title={alerTitle || title}
            // className={`cursor-pointer ml-1 ${customClass.tooltip || ''}`}
            // style={{ ...styles.tooltip, }}
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: "#00000099",
                  color: "white",
                  fontSize: 10
                },
              },
              arrow: {
                sx: {
                  color: '#00000099'
                }
              },
            }}
            PopperProps={{
              modifiers: [
                {
                  name: "offset",
                  options: {
                    offset: [0, -14],
                  },
                },
              ],
            }}          
            onClick={() => copyToClipboard(data, message)}>
            {icon}
        </Tooltip>
      }

      {position === 'left' && children}
      {/* {copied && <div style={{
        position: 'fixed',
        zIndex: 10,
        fontSize: 10,
        color: 'white',
        borderRadius: 10,
        right: 30,
        backgroundColor: '#00000099',
        padding: 6
      }}>Copied</div>} */}

    </div>
  )
}
import React from "react";
import ReactStars from "react-rating-stars-component";

function TCStars ({
    count = 5,
    value = value,
    size = 24,
    isEdit = false, 
    onChange = () => {}
}) {
    return(
        <ReactStars
            count={count}
            value={value}
            edit={isEdit}
            onChange={onChange}
            size={size}
            isHalf={true}
            // emptyIcon={<i className="fa fa-star"/>}
            // halfIcon={<i className="fa fa-star-half-alt"/>}
            // filledIcon={<i className="fa fa-star"/>}
            activeColor="#ffd700"
        />
    )
}

export default TCStars
export const LOCALSTORAGECONSTANT = {
    TOP_COURT_USER : "topCourtUser",
    TOP_COURT_PLACE : "topCourtCurrentPlace",
    CART_PACKAGE: "topCourtCartPackage",
    CART_BOOKING_FORM: "topCourtBookingCart"
}

export const color = {
    purple: "#663399",
    orange: "#f79256",
    darkorange: "#623A22",
    darkgreen: "#3E675B",
    blue: "#1d4e89",
    grey: "#9da6ab",
    red: "#ff2115",
    blueIndoor_1: "#025597",
    blueIndoor_2: "#0198E1",
    primary: "#025597",
    green: "#4DBD74",
    pink: "#E35480",
}

export const Hours = [
    {
        label: "00:00",
        active: false
    },
    {
        label: "01:00",
        active: false
    },
    {
        label: "02:00",
        active: false
    },
    {
        label: "03:00",
        active: false
    },
    {
        label: "04:00",
        active: false
    },
    {
        label: "05:00",
        active: false
    },
    {
        label: "06:00",
        active: false
    },
    {
        label: "07:00",
        active: false
    },
    {
        label: "08:00",
        active: false
    },
    {
        label: "09:00",
        active: false
    },
    {
        label: "10:00",
        active: false
    },
    {
        label: "11:00",
        active: false
    },
    {
        label: "12:00",
        active: false
    },
    {
        label: "13:00",
        active: false
    },
    {
        label: "14:00",
        active: false
    },
    {
        label: "15:00",
        active: false
    },
    {
        label: "16:00",
        active: false
    },
    {
        label: "17:00",
        active: false
    },
    {
        label: "18:00",
        active: false
    },
    {
        label: "19:00",
        active: false
    },
    {
        label: "20:00",
        active: false
    },
    {
        label: "21:00",
        active: false
    },
    {
        label: "22:00",
        active: false
    },
    {
        label: "23:00",
        active: false
    }
]


export const timeOptions = [
    {
        label: "00:00",
        value: "00:00"
    },
    {
        label: "01:00",
        value: "01:00"
    },
    {
        label: "02:00",
        value: "02:00"
    },
    {
        label: "03:00",
        value: "03:00"
    },
    {
        label: "04:00",
        value: "04:00"
    },
    {
        label: "05:00",
        value: "05:00"
    },
    {
        label: "06:00",
        value: "06:00"
    },
    {
        label: "07:00",
        value: "07:00"
    },
    {
        label: "08:00",
        value: "08:00"
    },
    {
        label: "09:00",
        value: "09:00"
    },
    {
        label: "10:00",
        value: "10:00"
    },
    {
        label: "11:00",
        value: "11:00"
    },
    {
        label: "12:00",
        value: "12:00"
    },
    {
        label: "13:00",
        value: "13:00"
    },
    {
        label: "14:00",
        value: "14:00"
    },
    {
        label: "15:00",
        value: "15:00"
    },
    {
        label: "16:00",
        value: "16:00"
    },
    {
        label: "17:00",
        value: "17:00"
    },
    {
        label: "18:00",
        value: "18:00"
    },
    {
        label: "19:00",
        value: "19:00"
    },
    {
        label: "20:00",
        value: "20:00"
    },
    {
        label: "21:00",
        value: "21:00"
    },
    {
        label: "22:00",
        value: "22:00"
    },
    {
        label: "23:00",
        value: "23:00"
    }
]

export const operationalHours = [
    {
        day: "Monday",
        isActive: true,
        operationalHours: {
            start: "08:00",
            end: "23:00"
        },
        hours: []
    },
    {
        day: "Tuesday",
        isActive: true,
        operationalHours: {
            start: "08:00",
            end: "23:00"
        },
        hours: []
    },
    {
        day: "Wednesday",
        isActive: true,
        operationalHours: {
            start: "08:00",
            end: "23:00"
        },
        hours: []
    },
    {
        day: "Thursday",
        isActive: true,
        operationalHours: {
            start: "08:00",
            end: "23:00"
        },
        hours: []
    },
    {
        day: "Friday",
        isActive: true,
        operationalHours: {
            start: "08:00",
            end: "23:00"
        },
        hours: []
    },
    {
        day: "Saturday",
        isActive: true,
        operationalHours: {
            start: "08:00",
            end: "23:00"
        },
        hours: []
    },
    {
        day: "Sunday",
        isActive: true,
        operationalHours: {
            start: "08:00",
            end: "23:00"
        },
        hours: []
    },
]

export const timeStringFormat = (e) => {
    let value = e
    return `${value < 10 ? `0${value}` : value}:00`
}

export const iOS = () =>{
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

export const isPhone = () => {
    return window.innerWidth < 900
}

export const genderOptions = [
    { value: "any" , label: "Any"},
    { value: "male" , label: "Male"},
    { value: "female" , label: "Female"},
]

export const categoryOptions = [
    { value: "single" , label: "Single"},
    { value: "double" , label: "Double"},
    { value: "mixeddouble" , label: "Mixed Double"},
]

export const optionsParticipant = [
    { value: "8" , label: "8"},
    { value: "16" , label: "16"},
    { value: "24" , label: "24"},
    { value: "32" , label: "32"}
]

export const typeOptions = [
    { value: true , label: "Public"},
    { value: false , label: "Private"},
]

export const courtType = [
    {label: "Indoor", value: "indoor"},
    {label: "Outdoor", value: "outdoor"},
    {label: "Hitting Room", value: "hitting_room"},
]

export const courtPackageType = [
    {label: "All Court", value: "all_court"},
    {label: "Outdoor", value: "outdoor"},
    {label: "Hitting Room", value: "hitting_room"},
]

export const courtTypeObj = {
    all_court: "All Court",
    indoor: "Indoor",
    outdoor: "Outdoor",
    hitting_room: "Hitting Room"
}

export const courtTypeObjVoucher = {
    all_court: "All Court",
    indoor: "Indoor",
    outdoor: "Outdoor Court",
    hitting_room: "Hitting Room"
}

export const courtPackageTypeObj = {
    indoor: "All Court",
    outdoor: "Outdoor Court",
    hitting_room: "Hitting Room"
}

export const centerBox = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '0.8em',
    borderWidth: 0,
    padding: 1,
    width: '80%',
    maxWidth: '80%',
    outline: 'none', // add this in your code 
}

export const serviceStatus = [
    { label: "All", value: "" },
    { label: "Rally Partner", value: "rally_partner" },
    { label: "Ball Boy", value: "ball_boy" },
    // { label: "", value: "" }
]

export const orderStatus = {
    a: "Awaiting Payment",
    failed: "Payment Failed",
    paid: "Paid",
    completed: "Completed", // Session habis
    // "Counter Pickup" // Tentative - Khusus untuk buy
}

export const orderStatusMap = (data) => {
    if (data.orderStatus) {
        let message = "Awaiting Payment"
        let { orderStatus, orderType } = data;
        if (orderStatus[0].date != null) { message = "Awaiting Payment" }
        if (orderStatus[1].date != null) { message = "Paid" }
        // if (orderStatus[1].date != null && orderType == 'sell') { message = "Counter Pick Up" }
        if (orderStatus[1].date != null && orderType == 'rent' && data.lastStatus == "rent") { message = "Rent" }
        if (orderStatus[2].date != null) { message = "Completed" }
        if (orderStatus[3].date != null) { message = "Payment Failed" }
        return message
    } else return 'Awaiting Payment'
}

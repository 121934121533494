import React, { useEffect, useState } from "react";

import { Grid } from "@mui/material";
import { getPlayers } from "service/playerService";

import { getUserInfo } from "helper/localStorage";

import TCNavigationBar from "components/TopCourtComponents/NavigationBar/TopCourtNavigationBar";

import Loading from "components/TopCourtComponents/Loading/TCLoadingComponent";
import UserCard from "components/TopCourtComponents/User/UserCard";
import EmptyData from "components/TopCourtComponents/Placeholder/EmptyData";
import InfiniteScroll from "react-infinite-scroll-component";
import { TCLabel } from "components/TopCourtComponents/Label/TopCourtLabel";
import LoadingMini from "components/TopCourtComponents/Loading/TCMiniLoadingComponent";

function Players ({

}) {

    // const userInfo = getUserInfo ? JSON.parse(getUserInfo) : null
    const [ players, setPlayers ] = useState([])
    const [ showLoading, setShowLoading ] = useState(true)
    const [ pagination, setPagination ] = useState({
        page: 0,
        limit: 20
    })
    const [ endOfPage, setEndOfPage ] = useState(false)

    useEffect(() => {
        loadPlayers()
    }, [pagination])

    const loadPlayers = async () => {
        let { data, error } = await getPlayers(pagination)
        if (error) { console.log(error) }
        if (data) { console.log(data); 
            if (data.length == 0) { setEndOfPage(true) }
            let newData = players.concat(data)
            setPlayers(newData)
        }
        setShowLoading(false)
    }

    return(
        <>
            <TCNavigationBar transparent={false} title={"Players"}/>
            {/* <Grid sx={{p: 2, pb: 0}}>Filter Player</Grid> */}
            <Grid sx={{p: 2}} direction={"column"}>
            <InfiniteScroll
                dataLength={players.length}
                next={() => {
                    setPagination(prev => ({
                        page: prev.page + 1,
                        limit: 20
                    }))
                }}
                hasMore={true}
                loader={
                    (pagination.page > 0 && !endOfPage) ?
                    <Grid>
                        <LoadingMini/>
                        {/* <TCLabel>Loading</TCLabel> */}
                    </Grid>
                    : <></>
                }

            >
            {
                players.map(value => {
                    return (<UserCard key={value._id} value={value}/>)
                })
            }
            </InfiniteScroll>
            </Grid>
            {
                (!showLoading && players.length <= 0) &&
                <EmptyData />
            }
            {
                showLoading &&
                <Loading />
            }
        </>
    )
}

export default Players
import React, { useEffect, useState } from "react";

import moment from "moment";
import { Grid, Icon, Box } from "@mui/material";

import { color, timeStringFormat, courtTypeObj } from "helper/constant";
import { Confirm } from "helper/showAlert";

import { TCLabel } from "components/TopCourtComponents/Label/TopCourtLabel";
import ScheduleCard from "./ScheduleCard";
import { orderStatusMap } from "helper/constant";
import { ChevronDownSvg, ChevronUpSvg } from "./Chevron";
import ItemScheduleCard from "./ItemScheduleCard";
import ClassScheduleCard from "./ClassScheduleCard";

function OrdersCard ({
    data,
    setQRCodePopup,
    navigate,
    onProgress
}) {

    const [ onProgressData, setOnProgressData ] = useState([])
    const [ onCompleteData, setOnCompleteData ] = useState([])
    const [ onProgressItemData, setOnProgresItemData ] = useState([])
    const [ onCompleteItemData, setCompleteItemData ] = useState([])
    const [ onProgressClass, setOnProgressClass ] = useState([])
    const [ onCompleteClass, setOnCompleteClass ] = useState([])
    const [ showCompleteData, setShowCompleteData ] = useState(false)
    // console.log('here check data', data, onProgress)

    useEffect(() => {
        let progressData = []
        let completeData = []
        if (onProgress && data.orderSessions) {
            for (let i = 0; i < data.orderSessions.length; i ++) {
                let sessionsData = data.orderSessions[i].data
                let complete = []
                let progress = []
                for (let j = 0; j < sessionsData.length; j++) {
                    console.log('let sessionsDate', sessionsData)
                    // if (sessionsData[j].status != "complete") {
                    if (moment(sessionsData[j].date).format("YYYY-MM-DD") >= moment(new Date()).format("YYYY-MM-DD")) {
                        progress.push(sessionsData[j])
                    } else {
                        if (sessionsData[j].isPaid == true) {
                            complete.push(sessionsData[j])
                        } else {
                            progress.push(sessionsData[j])
                        }
                    }
                }
                if (complete.length > 0) {
                    completeData.push({
                        date: data.orderSessions[i].date,
                        data: complete
                    })
                }
                if (progress.length > 0) {
                    progressData.push({
                        date: data.orderSessions[i].date,
                        data: progress
                    })
                }
                console.log('here check data', data.orderSessions[i].date, complete, progress)
            }
        }
        setOnProgressData(progressData)
        setOnCompleteData(completeData)

        let progressItemData = []
        let completeItemData = []
        if (onProgress && (data.orderItems && data.orderType === "service")) {
            for (let i = 0; i < data.orderItems.length; i ++) {
                let itemsData = data.orderItems[i].data
                let complete = []
                let progress = []
                for (let j = 0; j < itemsData.length; j++) {
                    // if (sessionsData[j].status != "complete") {
                    if (moment(itemsData[j].date).format("YYYY-MM-DD") >= moment(new Date()).format("YYYY-MM-DD")) {
                        progress.push(itemsData[j])
                    } else {
                        if (itemsData[j].isPaid == true) {
                            complete.push(itemsData[j])
                        } else {
                            progress.push(itemsData[j])
                        }
                    }
                }
                if (complete.length > 0) {
                    completeItemData.push({
                        date: data.orderItems[i].date,
                        data: complete
                    })
                }
                if (progress.length > 0) {
                    progressItemData.push({
                        date: data.orderItems[i].date,
                        data: progress
                    })
                }
                console.log('here check data', data.orderItems[i].date, complete, progress)
            }
        } else {
            if (data.lastStatus != "complete" && data.lastStatus != "failed") {
                for (let i = 0; i < data.orderItems.length; i ++) {
                    let itemsData = data.orderItems[i].data
                    let complete = []
                    let progress = []
                    for (let j = 0; j < itemsData.length; j++) {
                        progress.push(itemsData[j])
                    }
                    if (progress.length > 0) {
                        progressItemData.push({
                            date: data.orderItems[i].date,
                            data: progress
                        })
                    }
                    console.log('here check data', data.orderItems[i].date, complete, progress)
                }
            }
        }
        setOnProgresItemData(progressItemData)
        setCompleteItemData(completeItemData)

        let progressClassData = []
        let completeClassData = []
        if (onProgress && (data.classLists && data.classLists.length > 0)) {
            for (let i = 0; i < data.classLists.length; i ++) {
                let classData = data.classLists[i]
                console.log('here class Data', classData)
                // let complete = []
                // let progress = []
                if (moment(classData.date).format("YYYY-MM-DD") >= moment(new Date()).format("YYYY-MM-DD")) {
                    // progress.push(classData)
                    progressClassData.push(classData)
                } else {
                    if (data.isPaid == true) {
                        // complete.push(classData)
                        completeClassData.push(classData)
                    } else {
                        // progress.push(classData)
                        progressClassData.push(classData)
                    }
                }
                // if (complete.length > 0) {
                //     progressClassData.push({
                //         date: classData.date,
                //         data: complete
                //     })
                // }
                // if (progress.length > 0) {
                //     completeClassData.push({
                //         date: classData.date,
                //         data: progress
                //     })
                // }
                // console.log('here check data', classData.date, complete, progress)
            }
        }
        setOnCompleteClass(completeClassData)
        setOnProgressClass(progressClassData)

    }, [])

    const paymentStatus = data && data.lastStatus === "payment_received" || data.lastStatus === 'paid' || data.lastStatus === "complete" ? true : false
    const dataClassObj = (data && data.classObj && data.classObj.courtsInfo && data.classObj.courtsInfo.length > 0) ? data.classObj.courtsInfo.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)) : null
    return (
        <Grid className="element element-outer" sx={{
            mt: 1,
            p: 1
        }}  onClick={() => {
            if (data.orderType == "booking") {
                navigate(`/payment/${data._id}/booking`)
            } else if (data.orderType == "value_pack") {
                navigate(`/payment/${data._id}/value-pack`)
            } else if (data.orderType == 'rent' || data.orderType == 'sell' || data.orderType == 'service' || data.orderType == 'class') {
                navigate(`/payment/${data._id}/add-ons`)
            }
        }}>
            {/* <div className="element "></div> */}
            <Grid container>
                <Grid item xs={12}>
                    <div className='custom-body' style={{marginTop: 2, color: color.primary}}>
                        <Grid container>
                            <Grid xs={6} lg={6} item>
                                <TCLabel inheritColor={true} bold>{data.createdDate ? `${moment(data.createdDate).format("DD/MM/YYYY")}` : ``}</TCLabel>
                                <TCLabel inheritColor={true} style={{fontSize: 12}}>Details :</TCLabel>
                            </Grid>
                            <Grid xs={6} lg={6} item textAlign={"right"}>
                                <Box display="flex" justifyContent="flex-end" style={{marginBottom: 2}}>
                                    <div className='custom-body' style={{
                                        padding: '1px 5px',
                                        borderRadius: 5,
                                        // color: paymentStatus ? color.darkgreen : data.lastStatus != "failed" ? color.darkorange : 'white',
                                        color: paymentStatus ? 'white' : data.lastStatus != "failed" ? 'white' : 'white',
                                        fontSize: 10,
                                        fontWeight: 'bold',
                                        width: 'fit-content',
                                        backgroundColor: paymentStatus ? color.green : data.lastStatus != "failed" ? color.orange : color.pink}}>
                                        {orderStatusMap(data)}
                                        {/* {paymentStatus ? "Paid" : data.lastStatus != "failed" ? "Waiting Payment" : orderStatus.failed} */}
                                    </div> 
                                </Box>
                                <TCLabel inheritColor={true} subtitle>{data.bookingId ? `#${data.bookingId}` : ``}</TCLabel>
                            </Grid>
                        </Grid>
                    </div>
                    {/* <div className="separation-line"/> */}
                    {/* Normal */}
                    {
                        (!onProgress && data.orderSessions.length > 0) && data.orderSessions.map( (value, index) => {
                            return (
                                <ScheduleCard order={data} value={value} currIndex={index} setShowQR={setQRCodePopup}/>
                            )
                        })
                    }
                    {
                        (!onProgress && data.orderItems && data.orderItems.length >= 0) && data.orderItems.map( (value, index) =>  { 
                            return (
                                <ItemScheduleCard value={value} orderInfo={data} setShowQR={setQRCodePopup}/>
                            )
                        })
                    }
                    {
                        (!onProgress && (data && data.classLists) && data.classLists.length > 0) &&
                        <>
                        {/* On Progress */}
                        {/* Complete */}
                        {/* <Grid style={{marginTop: 5}} sx={{
                            border: `0.5px solid ${color.primary}`,
                            borderRadius: 2
                        }}>
                            <div className='custom-body' style={{padding: 10, paddingBottom: 0}}>
                                <TCLabel bold>{moment(value.date).format("ddd DD/MM/YYYY")}</TCLabel>
                            </div>
                            <div className='custom-body' style={{display: 'flex', alignItems: "top", padding: 10, paddingTop: 0}}>
                                <div style={{width: '100%'}}>
                                    {
                                        <TCLabel style={{fontSize: 12, fontWeight: 'bold'}}>{value.courtInfo && `${courtTypeObj[value.courtInfo.courtType]} ${value.courtInfo.name}`} : {value.name}</TCLabel>
                                    }
                                    <Grid container direction={'row'}>
                                    {
                                        value.hours && value.hours.map((h, index) => {
                                            return <TCLabel style={{marginRight: 3, fontSize: 12}}>{`${h} - ${timeStringFormat(Number(h.split(':')[0]) + 1)} 
                                                ${index < (value.hours.length - 1) ? " | " : ""}`}</TCLabel>
                                        })
                                    }
                                    </Grid>
                                </div>
                                {
                                    ((data.isPaid && orderStatusMap(data) != "Completed")) &&
                                    <div>
                                        <Grid className="element element-1" xs={12}
                                            sx={{
                                                color: data.isPaid ? '#ffff' : color.grey,
                                                width: 40,
                                                height: 40,
                                                mt: 0.5
                                            }} 
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                if (data.isPaid) {
                                                    setQRCodePopup({
                                                        open: true,
                                                        courtInfo: {
                                                            ...data.court,
                                                            ...value.courtInfo,
                                                            hours: value.hours,
                                                            date: value.date
                                                        },
                                                        data: {
                                                            ...data,
                                                            eventName: value.name,
                                                            date: value.date,
                                                            hours: value.hours,
                                                        },
                                                        uniqueCode: value.uniqueCode ? value.uniqueCode : data.uniqueCode,
                                                        value: value.uniqueCode ? value.uniqueCode : data.uniqueCode
                                                    })
                                                } else {
                                                    Confirm("", "Make payment to unlock the QR.")
                                                }
                                            }
                                        }>
                                            <Icon fontSize={'large'}>qr_code_2</Icon>
                                        </Grid>
                                    </div>
                                }
                            </div>
                        </Grid> */}
                        {
                            data.classLists.map(value => {
                                return (
                                    <ClassScheduleCard data={data} value={value} setQRCodePopup={setQRCodePopup} />
                                )
                            })
                        }
                        </>
                    }
                    {/* Normal */}
                    {
                        (onProgress && (onCompleteData.length > 0 || onCompleteItemData.length > 0 || onCompleteClass.length > 0) && !showCompleteData) &&
                        <div style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            position: 'relative'
                        }}>
                            <div onClick={(e) => {
                                e.stopPropagation()
                                setShowCompleteData(true)
                            }} style={{
                                position: 'absolute',
                                backgroundColor: '#FFFFFF',
                                marginTop: -4,
                                height: 40,
                            }}>
                                <ChevronDownSvg/>
                            </div>
                        </div>
                    }
                    {
                        (onProgress && showCompleteData) &&
                        <div style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            position: 'relative'
                        }}>
                            <div onClick={(e) => {
                                e.stopPropagation()
                                setShowCompleteData(false)
                            }} style={{
                                position: 'absolute',
                                backgroundColor: '#FFFFFF',
                                marginTop: -4,
                                height: 40,
                            }}>
                                <ChevronUpSvg/>
                            </div>
                        </div>
                    }
                    {
                        (onProgress && showCompleteData) &&
                        <div style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            position: 'relative'
                        }}>
                            <div onClick={(e) => {
                                e.stopPropagation()
                                setShowCompleteData(false)
                            }} style={{
                                position: 'absolute',
                                backgroundColor: '#FFFFFF',
                                marginTop: -4,
                                height: 40,
                            }}>
                                <ChevronUpSvg/>
                            </div>
                        </div>
                    }
                    {
                        (showCompleteData && onCompleteData && onCompleteData.length > 0) && onCompleteData.map( (value, index) => {
                            return (
                                <ScheduleCard order={data} value={value} currIndex={index} setShowQR={setQRCodePopup} hideQR={true}/>
                            )
                        })
                    }
                    {
                        (onProgressData && onProgressData.length > 0) && onProgressData.map( (value, index) => {
                            return (
                                <ScheduleCard order={data} value={value} currIndex={index} setShowQR={setQRCodePopup}/>
                            )
                        })
                    }
                    {
                        (showCompleteData && onCompleteItemData && onCompleteItemData.length > 0) && onCompleteItemData.map( (value, index) => {
                            return (
                                <ItemScheduleCard value={value} orderInfo={data} setShowQR={setQRCodePopup}/>
                            )
                        })
                    }
                    {
                        (onProgressData && onProgressItemData.length > 0) && onProgressItemData.map( (value, index) => {
                            return (
                                <ItemScheduleCard value={value} orderInfo={data} setShowQR={setQRCodePopup}/>
                            )
                        })
                    }
                    {
                        (showCompleteData && onCompleteClass && onCompleteClass.length > 0) && onCompleteClass.map( (value, index) => {
                            return ( <ClassScheduleCard data={data} value={value} setQRCodePopup={setQRCodePopup} /> )
                        })
                    }
                    {
                        (onProgressData && onProgressClass.length > 0) && onProgressClass.map( (value, index) => {
                            return ( <ClassScheduleCard data={data} value={value} setQRCodePopup={setQRCodePopup} /> )
                        })
                    }
                    {
                        data.packages.length > 0 && data.packages.map( (value, index) =>  {
                            return (
                                <Grid style={{marginTop: 5}} sx={{
                                    border: `0.5px solid ${color.primary}`,
                                    borderRadius: 2
                                }}>
                                    <div className='custom-body' style={{padding: 10, paddingBottom: 0}}>
                                        <TCLabel style={{fontSize: 12}}>{data.packages[0].packageName} : <span style={{fontWeight: 'bold'}}>{data.packages[0].packageSession}</span> Sessions</TCLabel>
                                    </div>
                                    <div className='custom-body' style={{padding: 10, paddingTop: 0}}>
                                        <Grid container spacing={2}>
                                            <Grid item textAlign={"center"}>
                                                <TCLabel style={{fontSize: 12, fontWeight: 'bold' }}>All Court</TCLabel>
                                                <TCLabel style={{fontSize: 12}}>{data.packages[0].packageId && data.packages[0].packageId.court == "all_court" ? data.packages[0].packageSession : 0}</TCLabel>
                                            </Grid>
                                            <Grid item textAlign={"center"}>
                                                <TCLabel style={{fontSize: 12, fontWeight: 'bold' }}>Outdoor</TCLabel>
                                                <TCLabel style={{fontSize: 12}}>{data.packages[0].packageId && data.packages[0].packageId.court == "outdoor" ? data.packages[0].packageSession : 0}</TCLabel>
                                            </Grid>
                                            <Grid item textAlign={"center"}>
                                                <TCLabel style={{fontSize: 12, fontWeight: 'bold' }}>Hitting Room</TCLabel>
                                                <TCLabel style={{fontSize: 12}}>{data.packages[0].packageId && data.packages[0].packageId.court == "hitting_room" ? data.packages[0].packageSession : 0}</TCLabel>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                            )
                        })
                    }
                    {
                        (data.orderType == 'class' && data.classObj && data.classObj.hours.length > 0) &&
                        <Grid style={{marginTop: 5}} sx={{
                            border: `0.5px solid ${color.primary}`,
                            borderRadius: 2
                        }}>
                            <div className='custom-body' style={{padding: 10, paddingBottom: 0}}>
                                <TCLabel bold>{moment(data.classObj.date).format("ddd DD/MM/YYYY")}</TCLabel>
                            </div>
                            <div className='custom-body' style={{display: 'flex', alignItems: "top", padding: 10, paddingTop: 0}}>
                                <div style={{width: '100%'}}>
                                    {
                                        dataClassObj ?
                                        <TCLabel style={{fontSize: 12, fontWeight: 'bold'}}>{`${courtTypeObj[dataClassObj[0].courtType]} ${dataClassObj[0].name}`} : {data.classObj.name}</TCLabel>
                                        :
                                        <TCLabel style={{fontSize: 12, fontWeight: 'bold'}}>{data.classObj.courtInfo && `${courtTypeObj[data.classObj.courtInfo.courtType]} ${data.classObj.courtInfo.name}`} : {data.classObj.name}</TCLabel>
                                    }
                                    <Grid container direction={'row'}>
                                    {
                                        data.classObj.hours && data.classObj.hours.map((h, index) => {
                                            return <TCLabel style={{marginRight: 3, fontSize: 12}}>{`${h} - ${timeStringFormat(Number(h.split(':')[0]) + 1)} 
                                                ${index < (data.classObj.hours.length - 1) ? " | " : ""}`}</TCLabel>
                                        })
                                    }
                                    </Grid>
                                </div>
                                {
                                    ((data.isPaid && orderStatusMap(data) != "Completed") && (data.classSession && data.classSession.session <= 0)) &&
                                    <div>
                                        <Grid className="element element-1" xs={12}
                                            sx={{
                                                color: data.isPaid ? '#ffff' : color.grey,
                                                width: 40,
                                                height: 40,
                                                mt: 0.5
                                            }} 
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                if (data.isPaid) {
                                                    setQRCodePopup({
                                                        open: true,
                                                        courtInfo: {
                                                            ...data.court,
                                                            ...data.classObj.courtInfo,
                                                            hours: data.classObj.hours,
                                                            date: data.date
                                                        },
                                                        data: {
                                                            ...data,
                                                            eventName: data.classObj.name,
                                                            date: data.classObj.date,
                                                            hours: data.classObj.hours,
                                                        },
                                                        uniqueCode: data.uniqueCode,
                                                        value: data.uniqueCode
                                                    })
                                                } else {
                                                    Confirm("", "Make payment to unlock the QR.")
                                                }
                                            }
                                        }>
                                            <Icon fontSize={'large'}>qr_code_2</Icon>
                                        </Grid>
                                    </div>
                                }
                            </div>
                        </Grid>
                    }
                    {
                        ((data && data.classSession) && data.classSession.session > 0) &&
                        <Grid style={{marginTop: 5}} sx={{
                            border: `0.5px solid ${color.primary}`,
                            borderRadius: 2
                        }}>
                            {/* <div className='custom-body' style={{padding: 10, paddingBottom: 0}}>
                                <TCLabel style={{fontWeight: 'bold' }}>{moment(data.classObj.date).format("ddd DD/MM/YYYY")}</TCLabel>
                            </div> */}
                            <div className='custom-body' style={{display: 'flex', alignItems: "top", padding: 10}}>
                                <div style={{width: '100%'}}>
                                    <TCLabel bold>{data.classObj.name}</TCLabel>
                                    <TCLabel>Class Bundle: {data.classSession.session ? <span><span style={{fontWeight: 'bold'}}>{`${data.classSession.session}`}</span> Session{data.classSession.session > 1 ? 's' : ''}</span> : '' }</TCLabel>
                                    <TCLabel>Valid Until: {data.classSession.validUntil ? <span>{`${moment(data.classSession.validUntil.split('T')[0]).format('DD/MM/YYYY')}`}</span> : '' }</TCLabel>
                                </div>
                            </div>
                        </Grid>
                    }
                </Grid>
            </Grid>
        </Grid>
    )
}

export default OrdersCard
import React, { useEffect, useState } from "react"

export function UseWindowDimension ({
    forceCheckWidth
}) {

    const [ windowDimension, setWindowDimension] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    })
    
    const changeResolution = () => {
        setWindowDimension({
            width: window.innerHeight,
            height: window.innerWidth
        })
        forceCheckWidth(windowDimension)
    }
    
    useEffect(() => {
        const debouncedResizeHandler = debounce(() => {
            console.log('***** debounced resize'); // See the cool difference in console
            changeResolution() 
          }, 100); // 100ms
        window.addEventListener('resize', debouncedResizeHandler)
        return () =>  window.removeEventListener('resize', debouncedResizeHandler)
    }, [])

    return (
        <div></div>
    )
}

function debounce(fn, ms) {
    let timer;
    return _ => {
      clearTimeout(timer);
      timer = setTimeout(_ => {
        timer = null;
        fn.apply(this, arguments);
      }, ms);
    };
}
import { Grid } from "@mui/material"
import MKButton from "components/MKButton"
import { TCInput } from "components/TopCourtComponents/Input/TopCourtInput"
import { TCTitleLabel } from "components/TopCourtComponents/Label/TopCourtLabel"
import { TCLabel } from "components/TopCourtComponents/Label/TopCourtLabel"
import { color } from "helper/constant"

// import { auth } from "../../service/firebase";
// import { signInWithPhoneNumber, RecaptchaVerifier } from "firebase/auth";
import tennis from "../../assets/logo/tennis.png"

import { validation } from "helper/form"
import { setUserInfo } from "helper/localStorage"
import { Confirm } from "helper/showAlert"
import React, { useEffect, useState } from "react"
import { setCredential, checkPasswordService } from "service/authService"
import OTP from "./OTP"
import Loading from "components/TopCourtComponents/Loading/TCLoadingComponent"
import { sendOTPService, checkOTPService } from "service/authService"


function Password ({
    phoneNumber,
    isLoginPassword,
    setForgetPass
}) {

    //
    const [ forgetPassword, setForgetPassword ] = useState(false)
    const [ fromForgetPassword, setFromForgetPassword ] = useState(false)
    const [ loginPassword, setLoginPassword ] = useState(isLoginPassword)
    //
    const [ password, setPassword ] = useState("")
    const [ confirmPassword, setConfirmPassword ] = useState("")
    const [ otp, setOTP ] = useState("")
    const [ loading, showLoading] = useState(false)

    const [ joi, setJOI ] = useState({
        confirmPassword: "",
    })

    useEffect(() => {
        if (otp.length == 6) {
            checkOTP()
        }
    }, [otp])

    useEffect(() => {
        setJOI({
            confirmPassword: (password && !validation.isSame(password, confirmPassword)) ? "password confirmation not match" : null
        })
    }, [confirmPassword])

    useEffect(() => {
        // if (forgetPassword) { 
        //     windowVerifier(); 
        // }
    }, [forgetPassword])

    const savePassword = async () => {
        let { data, error } = await setCredential(phoneNumber, password)
        if (error) { Confirm("", error) }
        if (data) { setUserInfo(data); window.location.reload(); }
    }

    const checkPassword = async () => {
        let { data, error } = await checkPasswordService(phoneNumber, password)
        if (error) { Confirm("", error) }
        if (data) { setUserInfo(data); window.location.reload(); }
    }

    const setDisableButton = () => {
        if (loginPassword) {
            if (!password) { return true }
        } else {
            if (!password || !confirmPassword) { return true }
            if (password.length < 6) { return true }
            if (!validation.isSame(password, confirmPassword)) { return true }
        }
    }

    // const windowVerifier = () => {
    //     try {
    //         if (!window.appVerifier) {
    //             console.log("verifier")
    //             window.appVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
    //                 'size': 'invisible',
    //                 'callback': () => {
    //                     window.recaptchaVerifier.recaptcha.reset();
    //                 },
    //                 'expired-callback': () => {
    //                     window.recaptchaVerifier.recaptcha.reset();
    //                 },
    //                 'error-callback': () => {
    //                     window.recaptchaVerifier.recaptcha.reset();
    //                 },
    //             });
    //             window.appVerifier.render().then((widgetId) => {
    //                 window.recaptchaWidgetId = widgetId;
    //             });
    //         }
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    const requestOTP = async () => {
        showLoading(true)
        setForgetPass(true)
        setPassword("")
        setForgetPassword(true)
        try {
            // firebase
            // var appVerifier = window.appVerifier
            // if (!appVerifier) {
            //     windowVerifier()
            //     appVerifier = window.appVerifier
            // }
            // signInWithPhoneNumber(auth, phoneNumber, appVerifier)
            // .then((confirmationResult) => {
            //     showLoading(false)
            //     // setForgetPassword(true)
            //     window.confirmationResult = confirmationResult;
            // }).catch((error) => {
            //     showLoading(false)
            //     console.log(error)
            //     Confirm("", "Something unexpected happen. Try Again")
            // });
            let { data, error } = await sendOTPService({
                phoneNumber: phoneNumber
            })
            showLoading(false);
            if (error) { console.log("error") }
            if (data) { console.log(data) }
        } catch(error) {
            showLoading(false)
        }
    }

    const checkOTP = async () => {
        showLoading(true)
        // window.confirmationResult.confirm(otp).then( async (result) => {
        //     // show confirmation password
        //     setFromForgetPassword(true)
        //     setForgetPassword(false)
        //     setLoginPassword(false)
        // }).catch((error) => {
        //     console.log(error.message)
        //     let firebaseErrorMapping = error.message
        //     if (firebaseErrorMapping.includes('auth/invalid-verification-code') || firebaseErrorMapping.includes('auth/code-expired')) {
        //         firebaseErrorMapping = "Wrong OTP. Please check your OTP or request again"
        //     }
        //     // alert(error.message)
        //     Confirm("", firebaseErrorMapping)
        // });
        let { data, error } = await checkOTPService({
            phoneNumber: phoneNumber, 
            OTP: otp
        })
        showLoading(false)
        console.log('here check OTP Data', data, error)
        if (error) { console.log(error); Confirm("", error) }
        if (data) { console.log('here check OTP Data', data);
            setFromForgetPassword(true)
            setForgetPassword(false)
            setLoginPassword(false)
        }
    }

    return (
        <>
        {
            !forgetPassword &&
            <Grid container>
                <Grid>
                {
                    fromForgetPassword &&
                    <Grid container xs={12} md={12} lg={12} item style={{marginTop: 8 }} justifyItems={"center"}>
                        <Grid item><img src={tennis} width={50} height={50}/></Grid>
                        <Grid item pt={1.8}><TCTitleLabel>Welcome Back</TCTitleLabel></Grid>
                    </Grid>
                }
                {
                    !loginPassword &&
                    <TCLabel bold>Please confirm your {fromForgetPassword ? "new" : ""} password</TCLabel>
                }
                </Grid>
                <Grid xs={12} pt={1}>
                    <TCInput type={"password"} label={ loginPassword ? "Enter Password" : "New Password (minimum 6 character)"} value={password} 
                    enterAction={() => {
                        if (loginPassword) {
                            checkPassword()
                        } else {
                            if (!joi.confirmPassword) { savePassword() }
                        }
                    }}
                    onChange={(e) => {
                        setPassword(e.currentTarget.value)
                    }} />
                </Grid>
                {
                    !loginPassword &&
                    <Grid xs={12} pt={1}>
                        <TCInput type={"password"} showAlert={joi.confirmPassword} label={"Confirm Password"} value={confirmPassword} 
                        enterAction={() => {
                            if (loginPassword) {
                                checkPassword()
                            } else {
                                savePassword()
                            }
                        }}
                        onChange={(e) => {
                            setConfirmPassword(e.currentTarget.value)
                        }} />
                    </Grid>
                }
                <Grid xs={12} pt={1}>
                    <MKButton fullWidth 
                        disabled={setDisableButton()}
                        color="primary"
                        onClick={() => {
                            if (loginPassword) {
                                checkPassword()
                            } else {
                                savePassword()
                            }
                        }}
                    >Confirm</MKButton>
                </Grid>
                {
                    loginPassword &&
                    <Grid pt={2} textAlign={"left"}
                        style={{
                            cursor: 'pointer'
                        }}
                    >
                        <span onClick={() => {
                            requestOTP()
                        }}>
                            <TCLabel subtitle>Forget your password ?</TCLabel>
                        </span>
                    </Grid>
                }
            </Grid>
        }
        {
            forgetPassword &&
            <Grid container justifyContent={"center"}>
                {/* Show OTP */}
                {/* <TCInput label={"Mobile Number"} disabled={true} value={phoneNumber}/> */}
                <OTP phoneNumber={phoneNumber} otp={otp} setOtp={setOTP} submit={checkOTP}/>
            </Grid>
        }
        {
            loading &&
            <Loading/>
        }
        {/* <div id="recaptcha-container"/> */}
        </>
    )
}

export default Password
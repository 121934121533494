import { client, errorValidation } from "./service";

export const sendOTPService = async function (body) {
  try {
    let response = await client.post('/send-otp', {
      phoneNumber: body.phoneNumber || "",
      resend: body.resend || "",
      resendWithDevice: body.resendWithDevice || ""
    });
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data };
  } catch (e) {
    // return { error: e.response.data.errorCode };
    return errorValidation(e)
  }
}

export const checkOTPService = async function (body) {
  try {
    let response = await client.post('/check-otp', {
      phoneNumber: body.phoneNumber || "",
      OTP: body.OTP
    });
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data };
  } catch (e) {
    // return { error: e.response.data.errorCode };
    return errorValidation(e)
  }
}

export const login = async function (body) {
  try {
    let response = await client.post('/login', {
      phoneNumber: body.phoneNumber || ""
    });
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data };
  } catch (e) {
    // return { error: e.response.data.errorCode };
    return errorValidation(e)
  }
}

export const registerUser = async function(body) {
  try {
    let response = await client.post(`/register-user/${body._id}`, {
      name: body.name || "",
      phoneNumber: body.phoneNumber || "",
      alternativePhoneNumber: body.alternativePhoneNumber || "",
      gender: body.gender || "", // Male
      birthYear: body.birthYear || "",
      password: body.password || ""
    });
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data };
  } catch (e) {
    // return { error: e.response.data.errorCode };
    return errorValidation(e)
  }
};


export const checkCredential = async function (phoneNumber) {
  try {
    let response = await client.post(`/check-credential`, {
      phoneNumber: phoneNumber || ""
    });
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data };
  } catch (e) {
    return errorValidation(e)
  }
}

export const setCredential = async function (
  phoneNumber, password
) {
  try {
    let response = await client.post(`/set-credential`, {
      phoneNumber: phoneNumber || "",
      password: password
    });
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data };
  } catch (e) {
    return errorValidation(e)
  }
}

export const checkPasswordService = async function (
  phoneNumber, password
) {
  try {
    let response = await client.post(`/check-password`, {
      phoneNumber: phoneNumber || "",
      password: password
    });
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data };
  } catch (e) {
    return errorValidation(e)
  }
}

export const getMe = async function() { // id sementara sebelum pakai validate
  try {
    let response = await client.get(`/profile`);
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data };
  } catch (e) {
    return errorValidation(e)
  }
}

export const changeNumberService = async function (
  phoneNumber
) {
  try {
    let response = await client.post(`/change-number`, {
      phoneNumber: phoneNumber || "",
    });
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data };
  } catch (e) {
    return errorValidation(e)
  }
}
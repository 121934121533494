import React, { useEffect, useState } from "react";
import { AvatarGroup, Card, Dialog, DialogActions, DialogContent, DialogContentText, Grid, Icon, Input, Popover } from "@mui/material";
import { TCLabel } from "components/TopCourtComponents/Label/TopCourtLabel";
import SlideDialog from "components/SlideDialog/SlideDialog";
import ClassForm from "./ClassForm";
import EmptyData from "components/TopCourtComponents/Placeholder/EmptyData";
import moment from "moment";
import { currencyFormat } from "helper/numberFormat";
import MKAvatar from "components/MKAvatar";
import { color } from "helper/constant";
import MKButton from "components/MKButton";
import DialogHeader from "components/SlideDialog/DialogHeader";
import { getClasses, applyClassService, cancelApplyClassService, deleteClassService, submitOrderClass } from "service/classService";
import { getUserInfo } from "helper/localStorage";
import Loading from "components/TopCourtComponents/Loading/TCLoadingComponent";
import { getUserName } from "helper/user";
import UserCard from "components/TopCourtComponents/User/UserCard";
import { Confirm, Confirmation, ConfirmationYesNo } from "helper/showAlert";
import * as NumericInput from "react-numeric-input";

import equality from "../../../assets/equality.svg";
import { submitItemOrder } from "pages/AddOns/Function";
import { useNavigate } from "react-router-dom";
import { TCInput } from "components/TopCourtComponents/Input/TopCourtInput";
import { courtTypeObj } from "helper/constant";
import { timeStringFormat } from "helper/constant";
import SlideAlert from "components/SlideInAlert/SlideAlert";
import QRPage from "pages/Orders/QRPage";
import { getUserAvatar } from "helper/user";
import TCNumberInput from "components/TopCourtComponents/Input/TopCourtCounterNumber";

import Slide from '@mui/material/Slide';
import { CopyShareLinkComponent } from "components/TopCourtComponents/Share/ShareComponent";
// import ImagesDetail from "components/TopCourtComponents/Images/ImagesDetail";
// import ClassDetailv2Component from "./ClassDetailv2Component";
import { getTabMemos } from "helper/tabsMemo";
import { memorizeTabs } from "helper/tabsMemo";
import TCAccordion from "components/TopCourtComponents/Accordion/TopCourtAccordion";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const spacing = 0.5

function Classes ({
    classType,
    checkIsLogin
}) {

    const [ openForm, setOpenForm ] = useState(false)
    const [ detailDialog, setDetailDialog ] = useState({
        open: false,
        data: null
    })
    const [ loading, setLoading ] = useState(true)
    const [ classList, setClassList] = useState([])
    const [ myClass, setMyClass ] = useState([])
    const [ appliedClass, setAppliedClass ] = useState([])
    const [ playerListDialog, setPlayerListDialog ] = useState({
        open: false,
        data: null
    })
    
    const [ filter30Days, setFilter30Days ] = useState(Number(getTabMemos('class-filter')) == 1 ? true : false)
    const [ classQty, setClassQty ] = useState(1)
    const navigate = useNavigate()
    let windowLocationHash = window.location.hash ? window.location.hash.substring(1) : null

    useEffect(() => {
        loadClasses()
    }, [filter30Days])

    const loadClasses = async () => {
        const userInfo = getUserInfo ? JSON.parse(getUserInfo) : null
        let { data, error } = await getClasses(userInfo ? userInfo._id : null, filter30Days, classType)
        if (data) { 
            setClassList(data.classes) 
            setAppliedClass(data.appliedClass)
            setMyClass(data.myClass)
            // check hash
            if (windowLocationHash && classType !== "class") {
                for (let i = 0; i < data.classes.length; i ++) {
                    if (data.classes[i].href) {
                        if (windowLocationHash == data.classes[i].href.toLowerCase().replace(/ /g, "-")) {
                            setDetailDialog({
                                open: true,
                                data: data.classes[i]
                            })
                        }
                    } else {
                        if (windowLocationHash == data.classes[i].name.toLowerCase().replace(/ /g, "-")) {
                            setDetailDialog({
                                open: true,
                                data: data.classes[i]
                            })
                        }
                    }
                }
            }
        }
        setLoading(false)
    }

    const applyClass = async (e) => {
        if (checkIsLogin()) {
            // logic utk apply class
            let { data, error } = await applyClassService({
                classId: e,
                qty: classQty
            })
            if (data) {  
                await loadClasses(); 
                setDetailDialog({
                    open: false,
                    data: null
                })
                Confirm("", "Owner will contact you soon to collect payment");
            }
        }
    }

    const buyClass = async (e) => {
        if (checkIsLogin()) {
            setLoading(false)
            await createOrder(e, classQty, navigate)
        }
    }

    const deleteClass = async (e) => {
        let { data, error } = await deleteClassService({
            classId: e
        })
        if (error) { Confirm("", error); 
            await loadClasses(); 
            return; 
        }
        if (data) {  
            await loadClasses(); 
            Confirm("", "Class Deleted");
        }
    }

    const cancelApplyClass = async (e) => {
        let { data, error } = await cancelApplyClassService({
            classId: e
        })
        if (data) { await loadClasses(); 
            setDetailDialog({
                open: false,
                data: null
            })
            Confirm("", "Your class is cancelled") }
    }

    const checkUserPending = (e) => {
        console.log('here check data', e.myWaitingPayment, e.totalPending, e.playersCapacity, 
        e.totalParticipant, e.disableJoin)
        if (e.disableJoin) {return {
            disable: true, 
            waitingPayment: false,
            full: (e.totalParticipant >= e.playersCapacity)
        } }
        if (e.isAdmin && (e.totalParticipant >= e.playersCapacity)) { return {
            disable: true, 
            waitingPayment: false,
            full: true
        } }
        if (e.myWaitingPayment) { return {
            disable: false,
            waitingPayment: true,
            full: false
        } }
        if ((e.totalPending + e.totalParticipant) >= e.playersCapacity) { 
            if (e.totalPending) {
                return {
                    disable: false, 
                    waitingPayment: true,
                    full: false
                } 
            } else {
                return {
                    disable: true, 
                    waitingPayment: false,
                    full: false
                } 
            }
        }
        return { disable: false, waitingPayment: false, full: false }
    }

    const checkAgeandGender = (
        minAge, maxAge, gender, data
    ) => {
        const userInfo = getUserInfo ? JSON.parse(getUserInfo) : null
        if (!userInfo) { return false }
        let myAge = moment().year() - userInfo.birthYear
        if ((gender == "male" || gender == "female") && (userInfo.gender != gender)) { return true }
        if (!minAge && !maxAge) { return false
        } else if (minAge && maxAge) {
            if (myAge > minAge && myAge < maxAge) { 
                return false
            }
        } else if (minAge) {
            if (myAge > minAge) { 
                return false
            }
        } else if (maxAge) {
            if (myAge < maxAge) { 
                return false
            }
        }
        return true
    }

    return (
        <>
            <Grid container xs={12}>
                <Grid item container>
                    <Grid item xs={6} textAlign={"left"}>
                        <div className='mini-btn-200px_right_auto' style={{width: 130, maxWidth: 130}} 
                            onClick={() => {
                                // filter class last 30 days 
                                setClassList([])
                                setFilter30Days(!filter30Days)
                                memorizeTabs('class-filter', filter30Days ? 2 : 1)
                                setLoading(true)
                            }}
                        >
                            {
                                filter30Days ? 
                                <TCLabel>On Going {classType == "class" ? "Class" : "Event"}</TCLabel> 
                                :
                                <TCLabel>Last 30 Days {classType == "class" ? "Class" : "Event"}</TCLabel> 
                            }
                        </div>
                    </Grid>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={4} textAlign={"right"} onClick={() => {
                        // if (checkIsLogin()) setOpenForm(true)
                    }}>
                        {/* <div className='mini-btn-200px' style={{width: 100, maxWidth: 100}} >
                            <TCLabel>Create Event</TCLabel> 
                        </div> */}
                    </Grid>
                    {
                        (classList.length == 0 && !loading) &&
                        <EmptyData/>
                    }
                    <Grid container>
                    {
                        classList.length >= 0 &&
                        classList.map(value => {
                            return (<ClassCard data={value}
                                checkIsLogin={checkIsLogin}
                                deleteClass={() => { 
                                    Confirmation("", "Delete Class ?", () => {}, async () => { deleteClass(value._id) })
                                }}
                                applyClass={() => {applyClass(value._id)}} 
                                buyClass={() => {buyClass(value)}}
                                setDetailDialog={(e) => { 
                                    setDetailDialog(prev => ({
                                        ...prev,
                                        ...e
                                    }))
                                }} 
                                setClassQty={setClassQty}
                                appliedClass={appliedClass} myClass={myClass} 
                                setPlayerListDialog={(e) => {
                                    setPlayerListDialog(prev => ({
                                        ...prev,
                                        ...e
                                    }))
                                }}
                                cancelApplyClass={() => { cancelApplyClass(value._id) }}
                                checkAgeandGender={checkAgeandGender}
                                checkUserPending={checkUserPending}
                                classType={classType}
                                navigate={navigate}
                                filter30Days={filter30Days}
                            />
                            )
                        })
                    }
                    </Grid>
                </Grid>
            </Grid>
            <SlideDialog
                isOpen={openForm || detailDialog.open || playerListDialog.open} 
                setIsOpen={(e) => {
                    if (openForm) { setOpenForm(e) }
                    if (detailDialog.open) { 
                        window.location.hash = ""
                        setDetailDialog({
                        open: e,
                        data: null
                    }) }
                    if (playerListDialog.open) { setPlayerListDialog({
                        open: e,
                        data: null
                    }) }
                }}
                useDialogContent={false}
                maxWidth={"lg"}
            >
                {
                    
                    openForm &&
                    <ClassForm setOpenForm={(e) => {
                        loadClasses()
                        setOpenForm(e)
                    }}/>
                }
                {
                    // (detailDialog.open && classType == "event") &&
                    (detailDialog.open) &&
                    <ClassDetail closeDialog={() => { window.location.hash = ""; setDetailDialog({
                        open: false,
                        data: null
                    })}} data={detailDialog.data} 
                        buyClass={buyClass}
                        applyClass={applyClass} appliedClass={appliedClass} myClass={myClass} 
                        cancelApplyClass={cancelApplyClass}
                        setLoading={setLoading}
                        checkIsLogin={checkIsLogin}
                        setClassQty={setClassQty}
                        checkAgeandGender={checkAgeandGender}
                        checkUserPending={checkUserPending}
                        classType={classType}
                    />
                }
                {
                    playerListDialog.open &&
                    <PlayersList closeDialog={() => { setPlayerListDialog({
                        open: false,
                        data: null
                    })}} data={playerListDialog.data}/>
                }
            </SlideDialog>
            {
                loading &&
                <Loading/>
            }
        </>
    )

}

function ClassCard ({
    data,
    setDetailDialog,
    setPlayerListDialog,
    buyClass,
    applyClass,
    appliedClass,
    myClass,
    cancelApplyClass,
    deleteClass,
    checkIsLogin,
    setClassQty,
    checkAgeandGender,
    checkUserPending,
    classType,
    navigate,
    filter30Days
}) {

    const [ loading, setLoading ] = useState(false);
    const [ selectedUser, setSelectedUser ] = useState(null)
    
    const [anchorEl, setAnchorEl] = useState(null);
    const [isOpen, setIsOpen] = useState(false);

    const handleClick = (event, value) => {
        setSelectedUser(value)
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    
    const open = anchorEl ? true : false;
    const id = open ? 'simple-popover' : undefined;

    let endTime = data.hours[data.hours.length - 1]
    let endTimeNumber = Number(endTime.split(":")[0]) + 1
    let endTimeTime = `${endTimeNumber > 9 ? '' : '0'}${endTimeNumber}:00`
    let userPending = checkUserPending(data)

    const isApplied = appliedClass.indexOf(data._id.toString()) >= 0
    const isMyClass = myClass.indexOf(data._id.toString()) >= 0

    useEffect(() => {
        setLoading(false)
    }, [data])

    const submitBundleOrder = async () => {
        setLoading(true)
        let submitOrderData = await submitOrderClass({
            classInfo: data._id,
            type: 'bundle',
            sessionBundle: data.classSchedule.bundle ? data.classSchedule.bundle.bundleSession : 0,
            qty: 1,
            price: data.classSchedule.bundle ? data.classSchedule.bundle.bundlePrice : 0,
            validity: data.classSchedule ? data.classSchedule.endClassDate : 0
        })
        if (submitOrderData.error) {
            setLoading(false)
            console.log(submitOrderData.error)
        }
        if (submitOrderData.data) {
            setLoading(false)
            navigate(`/payment/${submitOrderData.data._id}/add-ons`)
        }
    }

    return (
        <Grid item container xs={12}>
            <Card sx={{ width: '100%', m: 0.5, mt: 0, mb: 2, borderRadius: 1.5}}>
                <Grid p={2} pb={1}>
                    <Grid item container xs={12}>
                        <Grid item xs={11}>
                            <TCLabel bold>{data.name}</TCLabel>
                            <Grid container spacing={0.5}>
                                <Grid item>
                                    <TCLabel subtitle bold>Owner :</TCLabel>
                                </Grid>
                                <Grid item>
                                    <TCLabel subtitle>{data.createdBy ? getUserName(data.createdBy) : data.adminCreatedBy ? 'STC' : ""}</TCLabel>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* {
                            !filter30Days &&
                            <Grid item xs={1} textAlign={"right"}>
                                {
                                    (classType == "class") ?
                                    <CopyShareLinkComponent data={`${window.location.protocol}//${window.location.hostname}/class/d/${data.href ? data.href.toLowerCase().replace(/ /g, "-") : data.name.toLowerCase().replace(/ /g, "-")}`}/>
                                    :
                                    <CopyShareLinkComponent data={`${window.location.protocol}//${window.location.hostname}/class#${data.href ? data.href.toLowerCase().replace(/ /g, "-") : data.name.toLowerCase().replace(/ /g, "-")}`}/>
                                }
                            </Grid>
                        } */}
                        <Grid item xs={1} textAlign={"right"}>
                            {
                                ((classType == "class" && !filter30Days) || (classType == "class" && (filter30Days && isMyClass))) ?
                                <CopyShareLinkComponent data={`${window.location.protocol}//${window.location.hostname}/class/d/${data.href ? data.href.toLowerCase().replace(/ /g, "-") : data.name.toLowerCase().replace(/ /g, "-")}`}/>
                                :
                                <CopyShareLinkComponent data={`${window.location.protocol}//${window.location.hostname}/class?event#${data.href ? data.href.toLowerCase().replace(/ /g, "-") : data.name.toLowerCase().replace(/ /g, "-")}`}/>
                            }
                        </Grid>
                        {/* <TCLabel subtitle>#Tennis</TCLabel> */}
                    </Grid>
                    <Grid item container xs={12} spacing={spacing}>
                        <Grid item>
                            <TCLabel style={{marginTop: 1.5}} subtitle bold>
                                <Icon sx={{fontWeight: 'bold'}}>access_time</Icon>
                            </TCLabel>
                        </Grid>
                        <Grid item>
                            <TCLabel subtitle>
                                {
                                    data.classSchedule ?
                                    `${moment(data.classSchedule.startClassDateString).format("DD MMM YYYY")} - ${moment(data.classSchedule.endClassDateString).format("DD MMM YYYY")} | ${data.hours[0]} - ${endTimeTime}`
                                    :
                                    `${moment(data.date).format("DD MMM YYYY (dddd)")} ${data.hours[0]} - ${endTimeTime}`
                                }
                            </TCLabel>
                        </Grid>
                    </Grid>
                    <Grid item container xs={12} spacing={0.5}>
                        {
                            !data.classSchedule &&
                            <Grid item>
                                <TCLabel style={{marginTop: 1.5}} subtitle bold>
                                    <Icon sx={{fontWeight: 'bold'}}>people</Icon>
                                </TCLabel>
                            </Grid>
                        }
                        {
                            !data.classSchedule &&
                            <Grid item><TCLabel subtitle>{`${data.playersCapacity} • ${data.totalParticipant} going`}</TCLabel></Grid>
                        }
                        <Grid item ml={data.classSchedule ? 0 : 1 }>
                            <TCLabel style={{marginTop: 0.5}} subtitle bold>
                                {/* <Icon sx={{fontWeight: 'bold'}}>people</Icon> */}
                                <img src={equality} width={10} height={10}/>
                            </TCLabel>
                        </Grid>
                        <Grid item>
                            <TCLabel subtitle>
                                {data.gender == "female" ? "Female Only" : data.gender == "male" ? "Male Only" : "All"}
                            </TCLabel>
                        </Grid>
                        <Grid item ml={1}>
                            <TCLabel subtitle>Age: </TCLabel>
                        </Grid>
                        <Grid item>
                        {
                            (data.minAge && data.maxAge) &&
                            <TCLabel subtitle>{(data.minAge == data.maxAge) ? `${data.minAge}` : `${data.minAge} - ${data.maxAge}`}</TCLabel>
                        }
                        {
                            (data.minAge && !data.maxAge) &&
                            <TCLabel subtitle>{`> ${data.minAge}`}</TCLabel>
                        }
                        {
                            (!data.minAge && data.maxAge) &&
                            <TCLabel subtitle>{`< ${data.maxAge}`}</TCLabel>
                        }
                        {
                            (!data.minAge && !data.maxAge) &&
                            <TCLabel subtitle>{`All age`}</TCLabel>
                        }
                        </Grid>
                    </Grid>
                    {
                        (data.participant.length > 0) &&
                        <Grid item xs={12}>
                            <AvatarGroup spacing={8} max={8} sx={{ justifyContent: 'flex-end' }}>
                                {
                                    data.participant.map(value => {
                                        return (
                                            <MKAvatar 
                                                onClick={(e) => {
                                                    e.stopPropagation(true)
                                                    if (data.hideUsername) { return; }
                                                    handleClick(e, {
                                                        ...value.user,
                                                        appliedDate: value.appliedDate || null
                                                    })
                                                }}
                                                bgColor={"transparent"}
                                                src={getUserAvatar(value.user)} size="sm"
                                            />
                                        )
                                    })
                                }
                                <div></div>
                            </AvatarGroup>
                            <Popover
                                id={id}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                onClick={(e) => { e.stopPropagation() }}
                            >
                                {
                                    selectedUser &&
                                    <UserCard mb={0} key={selectedUser._id} classCard={true} value={selectedUser}/>
                                }
                            </Popover>
                            {/* ini hanya pemilik class yang bisa tengok */}
                            {
                                (isMyClass && (data.participant && data.participant.length > 0)) && data.createdBy &&
                                <Grid container spacing={0.5} pb={1}>
                                    <Grid item>
                                        <TCLabel subtitle bold>Note : </TCLabel>
                                    </Grid>
                                    <Grid item>
                                        <TCLabel subtitle>contact participants to collect payment & share QR</TCLabel>
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                    }
                    <Grid item container xs={12}>
                        <Grid item container xs spacing={spacing} alignItems={"center"}>
                            <Grid item>
                                <TCLabel bold>
                                    <Icon sx={{fontWeight: 'bold'}}>payments</Icon>
                                </TCLabel>
                            </Grid>
                            <Grid item><TCLabel bold>{`${currencyFormat(data.fee)} ${classType == "class" ? " / Session" : ""}`}</TCLabel></Grid>
                        </Grid>
                        <Grid item container xs spacing={spacing} alignItems={"flex-end"} justifyContent={"flex-end"}>
                            <Grid item>
                                <MKButton color="white" 
                                    onClick={() => {
                                        // console.log('here create hash', data._id, data.name.toLowerCase().replace(" ", "-"))
                                        // window.location.hash = data.name.toLowerCase().replace(/ /g, "-")
                                        if (classType !== "class" || (filter30Days && !isMyClass)) {
                                            window.location.hash = data.href ? data.href.toLowerCase().replace(/ /g, "-") : data.name.toLowerCase().replace(/ /g, "-")
                                            console.log('here classInfo', data)
                                            setDetailDialog({
                                                open: true,
                                                data: data,
                                                isFromDetail: true
                                            })
                                        } else {
                                            navigate(`/class/detail/${data.href ? data.href.toLowerCase().replace(/ /g, "-") : data.name.toLowerCase().replace(/ /g, "-")}`)
                                        }
                                    }}
                                    sx={{
                                        fontSize: 10, p: 0,
                                        border: 1,
                                        pl: 1, pr: 1,
                                        color: color.primary
                                    }}
                                >
                                    <Icon>visibility</Icon>
                                    <span style={{marginTop: 2}}>{"Detail"}</span>
                                </MKButton>
                            </Grid>
                            {
                                !isMyClass &&
                                <Grid item>
                                    {
                                        classType == "class" ?
                                        <MKButton sx={{fontSize: 10, p: 0, pl: 1, pr: 1}} 
                                            disabled={loading || filter30Days || checkAgeandGender(data.minAge, data.maxAge, data.gender, data)}
                                            // disabled={loading || filter30Days || checkAgeandGender(data.minAge, data.maxAge, data.gender, data) || userPending.disable || data.expired}
                                            // color={userPending.waitingPayment ? "warning" : "primary"} 
                                            color={"primary"}
                                            onClick={(e) => {
                                                if (classType !== "class") {
                                                    window.location.hash = data.href ? data.href.toLowerCase().replace(/ /g, "-") : data.name.toLowerCase().replace(/ /g, "-")
                                                    setDetailDialog({
                                                        open: true,
                                                        data: data
                                                    })
                                                } else {
                                                    navigate(`/class/d/${data.href ? data.href.toLowerCase().replace(/ /g, "-") : data.name.toLowerCase().replace(/ /g, "-")}`)
                                                }
                                            }}>
                                                <Icon>person_add_alt_1</Icon>
                                                <span style={{marginTop: 2}}>{"Join"}</span>
                                        </MKButton>
                                        :
                                        isApplied ?
                                        <MKButton sx={{fontSize: 10, p: 0, pl: 1, pr: 1}} color={"pink"} 
                                            disabled={loading || filter30Days || data.isAdmin}
                                            onClick={() => {
                                                ConfirmationYesNo("", "Are you sure cancel class ?", () => {}, () => {
                                                    setLoading(true)
                                                    cancelApplyClass()
                                                })
                                            }}>
                                                <Icon>event_busy</Icon>
                                                <span style={{marginTop: 2}}>{"Cancel"}</span>
                                        </MKButton>
                                        : 
                                        <MKButton sx={{fontSize: 10, p: 0, pl: 1, pr: 1}} 
                                            disabled={loading || filter30Days || checkAgeandGender(data.minAge, data.maxAge, data.gender, data) || userPending.disable || data.expired}
                                            color={userPending.waitingPayment ? "warning" : "primary"} 
                                            onClick={(e) => {
                                                e.stopPropagation(true)
                                                if (!checkIsLogin()) {
                                                    return;
                                                }
                                                if (userPending.waitingPayment) {
                                                    Confirm("", "Awaiting payment. Please check again")
                                                    return;
                                                }
                                                setIsOpen(true)
                                            }}>
                                                {/* <Icon>create</Icon> */}
                                                <Icon>person_add_alt_1</Icon>
                                                <span style={{marginTop: 2}}>{userPending.full ? "Full" : "Join"}</span>
                                        </MKButton>
                                    }
                                </Grid>
                            }
                            {
                                isMyClass && ((data.participant && data.participant.length > 0) || data.adminCreatedBy) &&
                                <Grid item>
                                    <MKButton sx={{fontSize: 10, p: 0, pl: 1, pr: 1}} color={"secondary"} 
                                        onClick={(e) => { 
                                            e.stopPropagation()
                                            setPlayerListDialog({
                                                open: true,
                                                data: data
                                            })
                                        }}
                                        disabled={filter30Days}
                                    >
                                        <Icon sx={{fontWeight: 'bold'}}>people</Icon>
                                        <span style={{marginTop: 2}}>{"View"}</span>
                                    </MKButton>
                                </Grid>
                            }
                            {
                                isMyClass && (data.participant && data.participant.length <= 0) && data.createdBy &&
                                <Grid item>
                                    <MKButton sx={{fontSize: 10, p: 0, pl: 1, pr: 1}} color={"pink"} 
                                        onClick={() => { 
                                            deleteClass()
                                        }}>
                                        <Icon>delete</Icon>
                                        <span style={{marginTop: 2}}>{"Delete"}</span>
                                    </MKButton>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                </Grid>
                {
                    (!isMyClass && !filter30Days && (data.classSchedule && data.classSchedule.bundle && data.classSchedule.bundle.bundleSession) && !data.bundle) &&
                    <Grid container xs={12} mb={1} justifyContent={"center"}>
                        <Grid container p={0.5} xs={11} sx={{
                            border: data.disableBundle? `1px solid #eaeaea` : data.waitingPaymentBundle ? `1px solid #f79256` : `1px solid ${color.primary}`,
                            color: data.disableBundle ? "#c0c0c0" : 'white',
                            backgroundColor: data.disableBundle ? "#eaeaea" : data.waitingPaymentBundle ? '#f79256' : color.primary,
                            borderRadius: 2
                        }} justifyContent={"center"} onClick={() => {   
                            if (data.disableBundle) { Confirm("", "Sold Out"); return; }      
                            if (data.waitingPaymentBundle) {
                                Confirm("", "Awaiting payment")
                                return;
                            }
                            Confirmation("", "Proceed to payment ?", () => {}, () => {
                                // onClickBuy(value)
                                if (!checkIsLogin()) {
                                    return;
                                }
                                submitBundleOrder()
                            })
                        }}>
                            <Grid item sx={{pl: 0.5}}>
                                <TCLabel style={{ color: data.disableBundle ? 'rgba(16, 16, 16, 0.2)' : 'white' }} bold>BUY BUNDLE :</TCLabel>
                            </Grid>
                            <Grid item sx={{pl: 0.5}}><TCLabel style={{
                                color: data.disableBundle ? 'rgba(16, 16, 16, 0.2)' : 'white'
                            }} bold>{`${currencyFormat(data.classSchedule.bundle.bundlePrice)} / ${data.classSchedule.bundle.bundleSession} Session`}</TCLabel></Grid>
                        </Grid>
                    </Grid>
                }
                {
                    (!isMyClass && data.bundle > 0 && !filter30Days) &&
                    <Grid container xs={12} mb={1} justifyContent={"center"}>
                        <Grid container p={0.5} xs={11} sx={{
                            border: `1px solid ${color.primary}`,
                            color: 'white',
                            // backgroundColor: color.primary,
                            // borderBottomLeftRadius: 8,
                            // WebkitBorderBottomRightRadius: 8
                            borderRadius: 2
                        }} justifyContent={"center"}>
                            {/* <Grid item sx={{pl: 0.5, pt: 0.2}}>
                                <TCLabel bold>
                                    <Icon sx={{fontWeight: 'bold'}}>payments</Icon>
                                </TCLabel>
                            </Grid> */}
                            <Grid item sx={{pl: 0.5}}>
                                <TCLabel style={{ color: color.primary }} bold>Remaining Balance : {data.bundle} Sessions</TCLabel>
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </Card>
            <ClassConfirmation 
                okAction={() => {
                    setIsOpen(false)
                    setLoading(true)
                    if (data.isAdmin) { 
                        buyClass(data)
                    } else {
                        applyClass(data._id)
                    }
                }}
                setClassQty={setClassQty}
                setIsOpen={setIsOpen}
                isOpen={isOpen}
                data={data}
            />
        </Grid>
    )
}

function ClassDetail ({
    data,
    closeDialog,
    buyClass,
    applyClass,
    appliedClass,
    myClass,
    cancelApplyClass,
    setLoading,
    checkIsLogin,
    setClassQty,
    checkAgeandGender,
    checkUserPending,
    classType
}) {

    const [ selectedUser, setSelectedUser ] = useState(null)
    const [isOpen, setIsOpen] = useState(false);
    const [openImageDetail, setOpenImageDetail] = useState({
        open: false,
        image: null
    })

    const [anchorEl, setAnchorEl] = useState(null);
    const [openPopOver, setOpenPopOver] = useState(false);

    const handleClick = (event, value) => {
        setSelectedUser(value)
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    
    const open = anchorEl ? true : false;
    const id = openPopOver ? 'simple-popover' : undefined;

    let endTime = data.hours[data.hours.length - 1]
    let endTimeNumber = Number(endTime.split(":")[0]) + 1
    let endTimeTime = `${endTimeNumber > 9 ? '' : '0'}${endTimeNumber}:00`
    let userPending = checkUserPending(data)

    const isApplied = appliedClass.indexOf(data._id.toString()) >= 0
    const isMyClass = myClass.indexOf(data._id.toString()) >= 0

    return (
        <Grid container p={2}>
            <DialogHeader title={<TCLabel bold style={{fontSize: 15, overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: '2',
                    WebkitBoxOrient: 'vertical',}}>{data.name}</TCLabel>} 
                closeDialog={()=> { closeDialog() }}/>
            <Grid item container>
                <Grid item xs={12}>
                    <Grid item xs={12}>
                        {/* <TCLabel bold>{data.name}</TCLabel> */}
                        <Grid container spacing={0.5}>
                            <Grid item>
                                <TCLabel subtitle bold>Owner :</TCLabel>
                            </Grid>
                            <Grid item>
                                <TCLabel subtitle>{
                                    !data.isAdmin ? getUserName(data.createdBy) : 'STC'
                                }</TCLabel>
                            </Grid>
                        </Grid>
                        {/* <TCLabel subtitle>#Tennis</TCLabel> */}
                    </Grid>
                    <Grid item container xs={12} spacing={spacing}>
                        <Grid item>
                            <TCLabel style={{marginTop: 1.5}} subtitle bold>
                                <Icon sx={{fontWeight: 'bold'}}>access_time</Icon>
                            </TCLabel>
                        </Grid>
                        <Grid item>
                            {
                                classType == "event" ?
                                <TCLabel subtitle>
                                    {`${moment(data.date).format("DD MMM YYYY (dddd)")} ${data.hours[0]} - ${endTimeTime}`}
                                </TCLabel>
                                :
                                <TCLabel subtitle>
                                    {moment(data.classSchedule.startClassDateString).format("DD MMM YYYY")} - {moment(data.classSchedule.endClassDateString).format("DD MMM YYYY")} | {data.hours[0]} - {endTimeTime}
                                </TCLabel>
                            }
                        </Grid>
                    </Grid>
                    {
                        classType == "event" ?
                        <Grid item container xs={12} spacing={0.5}>
                            <Grid item>
                                <TCLabel style={{marginTop: 1.5}} subtitle bold>
                                    <Icon sx={{fontWeight: 'bold'}}>people</Icon>
                                </TCLabel>
                            </Grid>
                            <Grid item><TCLabel subtitle>{`${data.playersCapacity} • ${data.totalParticipant} going`}</TCLabel></Grid>
                            <Grid item ml={1}>
                                <TCLabel style={{marginTop: 0.5}} subtitle bold>
                                    {/* <Icon sx={{fontWeight: 'bold'}}>people</Icon> */}
                                    <img src={equality} width={10} height={10}/>
                                </TCLabel>
                            </Grid>
                            <Grid item>
                                <TCLabel subtitle>
                                    {data.gender == "female" ? "Female Only" : data.gender == "male" ? "Male Only" : "All"}
                                </TCLabel>
                            </Grid>
                            <Grid item ml={1}>
                                <TCLabel subtitle>Age: </TCLabel>
                            </Grid>
                            <Grid item>
                            {
                                (data.minAge && data.maxAge) &&
                                <TCLabel subtitle>{(data.minAge == data.maxAge) ? `${data.minAge}` : `${data.minAge} - ${data.maxAge}`}</TCLabel>
                            }
                            {
                                (data.minAge && !data.maxAge) &&
                                <TCLabel subtitle>{`> ${data.minAge}`}</TCLabel>
                            }
                            {
                                (!data.minAge && data.maxAge) &&
                                <TCLabel subtitle>{`< ${data.maxAge}`}</TCLabel>
                            }
                            {
                                (!data.minAge && !data.maxAge) &&
                                <TCLabel subtitle>{`All age`}</TCLabel>
                            }
                            </Grid>
                        </Grid>
                        :
                        <Grid item container xs={12} spacing={0.5}>
                            <Grid item>
                                <TCLabel style={{marginTop: 0.5}} subtitle bold>
                                    {/* <Icon sx={{fontWeight: 'bold'}}>people</Icon> */}
                                    <img src={equality} width={10} height={10}/>
                                </TCLabel>
                            </Grid>
                            <Grid item>
                                <TCLabel subtitle>
                                    {data.gender == "female" ? "Female Only" : data.gender == "male" ? "Male Only" : "All"}
                                </TCLabel>
                            </Grid>
                            <Grid item ml={1}>
                                <TCLabel subtitle>Age: </TCLabel>
                            </Grid>
                            <Grid item>
                            {
                                (data.minAge && data.maxAge) &&
                                <TCLabel subtitle>{(data.minAge == data.maxAge) ? `${data.minAge}` : `${data.minAge} - ${data.maxAge}`}</TCLabel>
                            }
                            {
                                (data.minAge && !data.maxAge) &&
                                <TCLabel subtitle>{`> ${data.minAge}`}</TCLabel>
                            }
                            {
                                (!data.minAge && data.maxAge) &&
                                <TCLabel subtitle>{`< ${data.maxAge}`}</TCLabel>
                            }
                            {
                                (!data.minAge && !data.maxAge) &&
                                <TCLabel subtitle>{`All age`}</TCLabel>
                            }
                            </Grid>
                        </Grid>
                    }
                    {
                        (data.participant.length > 0 && classType == "event") &&
                        <Grid item container xs={12} sx={{
                            marginLeft: 1
                        }}>
                            {
                                data.participant.map((value, index) => {
                                    return(
                                        <Grid item sx={{
                                            marginLeft: -0.8,
                                            zIndex: (data.participant.length - index)
                                        }}>
                                            <MKAvatar 
                                                bgColor={"transparent"}
                                                onClick={(e) => {
                                                    e.stopPropagation(true)
                                                    if (data.hideUsername) { return; }
                                                    handleClick(e, {
                                                        ...value.user,
                                                        appliedDate: value.appliedDate || null
                                                    })
                                                }}
                                                src={getUserAvatar(value.user)} 
                                                size="sm"
                                                style={{ border: '1.5px solid white', width: 40, height: 40 }} 
                                            />
                                        </Grid>
                                    )
                                })
                            }
                            <Popover
                                id={id}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                            >
                                {
                                    selectedUser &&
                                    <UserCard mb={0} key={selectedUser._id} classCard={true} value={selectedUser}/>
                                }
                            </Popover>
                            {/* ini hanya pemilik class yang bisa tengok */}
                            {
                                (isMyClass && (data.participant && data.participant.length > 0)) && data.createdBy &&
                                <Grid container spacing={0.5} pb={1}>
                                    <Grid item>
                                        <TCLabel subtitle bold>Note : </TCLabel>
                                    </Grid>
                                    <Grid item>
                                        <TCLabel subtitle>contact participants to collect payment & share QR</TCLabel>
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                    }
                    <Grid item container xs={12}>
                        <Grid item container xs={classType == "class" ? 12 : 5} pt={classType == "class" ? 0 : 1.5} spacing={spacing}>
                            <Grid item sx={{mt: 0.2}}>
                                <TCLabel bold>
                                    <Icon sx={{fontWeight: 'bold'}}>payments</Icon>
                                </TCLabel>
                            </Grid>
                            <Grid item><TCLabel bold>{currencyFormat(data.fee)}{classType == "class" ? " / Session" : ""}</TCLabel></Grid>
                        </Grid>
                        <Grid item container xs={7} spacing={spacing} alignItems={"flex-end"} justifyContent={"flex-end"}>
                        {
                            (!isMyClass && classType == "event") &&
                            <Grid item>
                                {
                                    isApplied ?
                                    <MKButton sx={{fontSize: 10, p: 0, pl: 1, pr: 1}} color={"pink"} 
                                        disabled={data.isAdmin}
                                        onClick={() => { 
                                            ConfirmationYesNo("", "Are you sure cancel class ?", () => {}, () => {
                                                cancelApplyClass(data._id)
                                            })
                                        }}>
                                            <Icon>event_busy</Icon>
                                            <span style={{marginTop: 2}}>{"Cancel"}</span>
                                    </MKButton>
                                    : 
                                    <MKButton 
                                        disabled={checkAgeandGender(data.minAge, data.maxAge, data.gender, data) || userPending.disable || data.expired}
                                        sx={{fontSize: 10, p: 0, pl: 1, pr: 1}} 
                                        color={userPending.waitingPayment ? "warning" : "primary"} 
                                        onClick={(e) => { 
                                            e.stopPropagation(true)
                                            if (!checkIsLogin()) {
                                                closeDialog()
                                                return;
                                            }
                                            if (userPending.waitingPayment) {
                                                Confirm("", "Awaiting payment. Please check again")
                                                return;
                                            }
                                            setIsOpen(true)
                                        }}>
                                        <Icon>person_add_alt_1</Icon>
                                        <span style={{marginTop: 2}}>{userPending.full ? "Full" : "Join"}</span>
                                    </MKButton>
                                }
                            </Grid>
                        }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <div className="separation-line"/>
            {/* <Grid item container sx={{height: '60vh', overflow: 'auto'}}> */}
            <Grid item container>
                <Grid item>
                    {/* <TCLabel>{data.description}</TCLabel> */}
                    <TCLabel>
                        <div dangerouslySetInnerHTML={{__html: data.description}}/>
                    </TCLabel>
                </Grid>
                <Grid item container pt={1}>
                    {
                        data.classImages && data.classImages.map(value => {
                            return(<Grid item xs={6} style={{height: 'auto', width: '100%'}}
                                onClick={() => {
                                    setOpenImageDetail({
                                        open: true,
                                        image: value
                                    })
                                }}
                            >
                                <img src={value} style={{
                                    objectFit: 'contain'
                                }} width={"100%"} height={"100%"}/>
                            </Grid>)
                        })
                    }
                </Grid>
            </Grid>
            <ClassConfirmation 
                okAction={() => {
                    setIsOpen(false)
                    setLoading(true)
                    if (data.isAdmin) { 
                        buyClass(data)
                    } else {
                        applyClass(data._id)
                    }
                }}
                setClassQty={setClassQty}
                setIsOpen={setIsOpen}
                isOpen={isOpen}
                data={data}
            />
            {/* <ImagesDetail
                open={openImageDetail.open}
                setOpen={(e) => {
                    setOpenImageDetail({
                        open: e,
                        image: null
                    })
                }}
                src={openImageDetail.image}
            /> */}
        </Grid>
    )
}

function PlayersList ({
    data,
    closeDialog
}) {

    const [ showQR, setShowQR ] = useState({
        open: false
    })

    let endTime = data.hours[data.hours.length - 1]
    let endTimeNumber = Number(endTime.split(":")[0]) + 1
    let endTimeTime = `${endTimeNumber > 9 ? '' : '0'}${endTimeNumber}:00`

    const userInfo = getUserInfo ? JSON.parse(getUserInfo) : null
    const isCoach = (data.coach && userInfo) ? data.coach.indexOf(userInfo._id) >= 0 : false
    console.log('playerList', data)
    let dateList = data.classSchedule ? data.classSchedule.dates : null
    let scheduleExist = dateList ? dateList.indexOf(moment(new Date()).format("YYYY-MM-DD")) >= 0 : false

    const checkPartcipantDate = () => {
        let exist = false
        let currentDate = moment(new Date()).format("YYYY-MM-DD")
        data.classParticipant && data.classParticipant.map( value => {
            if (value.date == currentDate) {
                exist = true
            }
        })
        return exist
    }

    const checkParticipantData = (hours) => {
        let exist = false
        let count = 0
        data.classParticipant && data.classParticipant.map( value => {
            let currentDate = moment(dateList[dateList.indexOf(moment(new Date()).format("YYYY-MM-DD"))]).format("YYYY-MM-DD")
            if (value.date == currentDate && value.hours.indexOf(hours) >= 0) {
                exist = true
                count += value.pax
            }
        })
        return { exist, count }
    }

    return (
        <Grid container p={2}>
            <DialogHeader title={<TCLabel bold style={{fontSize: 15}}>Participants</TCLabel>} closeDialog={()=> { closeDialog() }}/>
            {
                (!scheduleExist && dateList) ?
                <>
                    <Grid xs={12}>
                        <TCLabel>No Schedule today</TCLabel>
                    </Grid>
                    <Grid xs={12}>
                        <EmptyData/>
                    </Grid>
                </>
                :
                <>
                    <Grid style={{marginTop: 5}} xs={12} sx={{
                        border: `0.5px solid ${color.primary}`,
                        borderRadius: 2
                    }} justifyContent={"top"}>
                        <div className='custom-body' style={{padding: 10}}>
                            <>
                                <div style={{display: 'flex', alignItems: "top", marginTop: 2}}>
                                    {
                                        dateList ?
                                        <div style={{width: '100%'}}>
                                            <TCLabel style={{fontSize: 12, fontWeight: 'bold'}}>{moment(dateList[dateList.indexOf(moment(new Date()).format("YYYY-MM-DD"))]).format("ddd DD/MM/YYYY")}</TCLabel>
                                            {/* <TCLabel style={{fontSize: 12, fontWeight: 'bold' }}>{courtTypeObj[data.courtInfo.courtType]} {data.courtInfo.name}</TCLabel> */}
                                            <TCLabel style={{fontSize: 12}}>{`${data.hours[0]} - ${endTimeTime}`}</TCLabel>
                                            {/* <TCLabel>Total: <span style={{fontWeight: 'bold' }}>{currencyFormat(v.total)}</span></TCLabel> */}
                                        </div>
                                        :
                                        <div style={{width: '100%'}}>
                                            <TCLabel style={{fontSize: 12, fontWeight: 'bold'}}>{moment(data.date).format("ddd DD/MM/YYYY")}</TCLabel>
                                            {/* <TCLabel style={{fontSize: 12, fontWeight: 'bold' }}>{courtTypeObj[data.courtInfo.courtType]} {data.courtInfo.name}</TCLabel> */}
                                            <TCLabel style={{fontSize: 12}}>{`${data.hours[0]} - ${endTimeTime}`}</TCLabel>
                                            {/* <TCLabel>Total: <span style={{fontWeight: 'bold' }}>{currencyFormat(v.total)}</span></TCLabel> */}
                                        </div>
                                    }
                                    <div>
                                    <Grid className={
                                        (dateList && (data.participant.length <= 0 || !checkPartcipantDate() || data.hoursExpired || !data.courtInfo)) ? 
                                        "element-disabled element-1-disabled" : "element element-1"
                                    } xs={12}
                                        sx={{
                                            color: '#ffff',
                                            width: 40,
                                            height: 40,
                                            mt: 0.5
                                        }} 
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            if (dateList && (data.participant.length <= 0 || !checkPartcipantDate() || data.hoursExpired || !data.courtInfo)) {
                                                return;
                                            }
                                            setShowQR({
                                                open: true,
                                                courtInfo: {
                                                    ...data.courtInfo,
                                                    hours: data.hours,
                                                    date: moment(new Date()).format('DD/MM/YYYY')
                                                },
                                                data: {
                                                    ...data,
                                                    date: new Date()
                                                },
                                                uniqueCode: data.barcode ? data.barcode.uniqueCode : 
                                                    (data.courtInfo && data.courtInfo.uniqueCode) ? data.courtInfo.uniqueCode : '-',
                                                value: data.barcode
                                            })
                                        }
                                    }>
                                        <Icon fontSize={'large'}>qr_code_2</Icon>
                                    </Grid>
                                    </div>
                                </div>
                            </>
                        </div>
                    </Grid>
                    <Grid xs={12} mt={0.5}>
                        {
                            dateList? 
                            <>
                            {
                                data.hours && data.hours.map( v => {
                                    console.log('here check participant', v)
                                    return (
                                        <Grid mb={1}>
                                            <TCAccordion title={<Grid 
                                                    onClick={() => { 
                                                        // executeScroll() 
                                                    }}
                                                >
                                                    <TCLabel bold>{v} - {(Number(v.split(':')[0]) + 1 > 9) ? `${Number(v.split(':')[0]) + 1}:00` : `0${Number(v.split(':')[0]) + 1}:00`}{` (${checkParticipantData(v).count})`}</TCLabel>
                                                </Grid>
                                            }>
                                                <Grid>
                                                    {
                                                        checkParticipantData(v).exist ?
                                                        <>
                                                            {
                                                                data.classParticipant && data.classParticipant.map( value => {
                                                                    console.log('here check value', value.date, moment(new Date()).format('YYYY-MM-DD'))
                                                                    if (value.date == moment(new Date()).format('YYYY-MM-DD')) {
                                                                        if (dateList.indexOf(value.date) >= 0 && value.hours.indexOf(v) >= 0) {
                                                                            console.log('value info', value, v, dateList.indexOf(value.date) >= 0 && value.hours.indexOf(v) >= 0)
                                                                            return (
                                                                                <UserCard key={value._id} value={value.user} hideClub={false} classInfo={value} classCard={true} showContact={(data.createdBy && !isCoach) ? true : false}/>
                                                                            )
                                                                        }
                                                                    }
                                                                })
                                                            }
                                                        </>
                                                        :
                                                        <TCLabel>There is no data</TCLabel>
                                                    }
                                                </Grid>
                                            </TCAccordion>
                                        </Grid>
                                    )
                                })
                            }
                            </>
                            :
                            <>
                            {
                                data.participant && data.participant.map( value => {
                                    return (
                                        <UserCard key={value._id} value={value.user} hideClub={false} classInfo={value} classCard={true} showContact={(data.createdBy && !isCoach) ? true : false}/>
                                    )
                                })
                            }
                            </>
                        }
                    </Grid>
                </>
            }
            <SlideAlert
                isOpen={showQR.open} 
                setIsOpen={() => {
                    if (showQR.open) {
                        setShowQR(prev => ({
                            ...prev,
                            open: false
                        }))
                    }
                }}
                maxWidth={"xs"}
            >
                {
                    showQR.open &&
                    <QRPage courtInfo={showQR.courtInfo} qrValue={showQR.uniqueCode} orderInfo={showQR.data} />
                }
            </SlideAlert>
        </Grid>
    )
}

function ClassConfirmation ({
    okAction,
    setClassQty,
    setIsOpen,
    isOpen,
    data
}) {

    const [ number, setNumber ] = useState(1)

    useEffect(() => {
        setClassQty(number)
    }, [number])

    // check max value
    const maxValue = () => {
        console.log('here check data2', data)
        if (data.isAdmin) {
            let count = data.playersCapacity - data.totalParticipant - data.totalPending
            return count > 0 ? count : 0
        }
        return data.isAdmin ? data.playersCapacity : 99
    }

    return (
        <Dialog
            fullWidth={false}
            maxWidth={'sm'}
            open={isOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => {
                setIsOpen(false)
            }}
            aria-describedby="alert-dialog-slide-description"
            PaperProps={{
                style: {backgroundColor: '#FFFFFFF2', minWidth: window.innerWidth >= 600 ? window.innerWidth >= 900 ? '40%' : '40%' : '50%', textAlign: 'center'}
            }}
          >
            <DialogContent style={{ paddingTop: 10}}>
                <DialogContentText>
                    <TCLabel>
                        <Grid container xs={12} spacing={1} alignItems={"center"} justifyContent={"center"}>
                            <Grid item xs={5}>
                                <TCLabel>{"Join Class for"}</TCLabel>
                            </Grid>
                            <Grid item container xs={4} p={0}>
                                <Grid item xs>
                                    <TCNumberInput
                                        maxNumber={maxValue()}
                                        onChange={(e) => { 
                                            setNumber(e) 
                                        } }
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={2}>
                                <TCLabel>{"Pax"}</TCLabel>
                            </Grid>
                        </Grid>
                    </TCLabel>
                </DialogContentText>
            </DialogContent>
            <DialogActions style={{padding: 0}}>
                <div style={{display: 'flex', width: '100%'}}>
                    <div 
                        onClick={() => {
                            setIsOpen(false)
                        }} 
                        style={{fontSize: 10, width: '50%', textAlign: 'center', color: 'red', borderRight: '0.01em solid #344767', borderTop: '0.01em solid #344767'}}>
                        <div style={{padding: 6}}><TCLabel>{"Cancel"}</TCLabel></div>
                    </div>
                    <div
                        onClick={() => {
                            if (number > 0) { okAction() }
                        }} 
                        style={{width: '50%', textAlign: 'center', borderTop: '0.01em solid #344767'}}>
                        <div style={{padding: 6}}><TCLabel style={ number <= 0 ? {fontWeight: 'bold', color: '#c0c0c0'} : {fontWeight: 'bold'}}>{"OK"}</TCLabel></div>
                    </div>
                </div>
            </DialogActions>
        </Dialog>
    )
}

const createOrder = async ( classObj, qty = 1, navigate ) => {
    await submitItemOrder({
        type: "class",
        classObj,
        qty
    }, navigate)
}

export default Classes
import React from "react";
import { Box } from "@mui/material";
import { TCLabel } from "../Label/TopCourtLabel";

export default function TCTabPanel(props) {
    const { children, value, index, boxPadding = 3, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: boxPadding }}>
            <TCLabel>{children}</TCLabel>
          </Box>
        )}
      </div>
    );
}
import React,  { Children, useEffect, useState } from "react"

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Popover from "@mui/material/Popover";

import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// position : bottom, left, right, top
const popOverOrigin = {
    "bottom": {
        anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
        },
        transformOrigin: {
            vertical: "top",
            horizontal: "center",
        },
    },
    "left": {
        anchorOrigin: {
            vertical: "center",
            horizontal: "left",
        },
        transformOrigin: {
            vertical: "center",
            horizontal: "right",
        }
    },
    "right": {
        anchorOrigin: {
            vertical: "center",
            horizontal: "right",
        },
        transformOrigin: {
            vertical: "center",
            horizontal: "left",
        }
    },
    "top": {
        anchorOrigin: {
            vertical: "top",
            horizontal: "center",
        },
        transformOrigin: {
            vertical: "bottom",
            horizontal: "center",
        },
    }
}

function TopCourtPopOver({
    title,
    titleComponent,
    position = "bottom",
    children,
    maxWidth,
}) {

    let styles = {}

    if (maxWidth) {
        styles = {
            ...styles,
            maxWidth
        }
    }

    const [popover, setPopover] = useState(null);
    const [popoverOrigin, setPopoverOrigin] = useState(popOverOrigin[position])

    const togglePopover = ({ currentTarget }) => setPopover(currentTarget);
    const closePopover = () => setPopover(null);

    return (
        <MKBox>
            <div onClick={(event) => {
                togglePopover(event);
                setPopoverOrigin({
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                    },
                    transformOrigin: {
                        vertical: "top",
                        horizontal: "center",
                    },
                });
            }}>
                {titleComponent && React.cloneElement(titleComponent)}
            </div>
            {/* <Container>
                <Grid container justifyContent="center">
                <Stack direction="row" spacing={1}>
                    <MKButton
                        variant="gradient"
                        color="info"
                        onClick={(event) => {
                            togglePopover(event);
                            setPopoverOrigin({
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "center",
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "center",
                            },
                            });
                        }}
                    >
                    {title}
                    </MKButton>
                    
                </Stack>
                </Grid>
                
            </Container> */}
            <Popover open={Boolean(popover)} 
                PaperProps={{
                    style: { ...styles, width: '80%' },
                }}
                anchorEl={popover} onClose={closePopover} {...popoverOrigin}>
                <MKBox bgColor="white" py={1.5} px={2} lineHeight={0.8}>
                    {
                        children && React.cloneElement(children)
                    }
                </MKBox>
            </Popover>
        </MKBox>
    )
}

export default TopCourtPopOver
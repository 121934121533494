import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import moment from "moment";
import { Grid, Icon, Card, Box } from "@mui/material";

import { getScheduleBookingList } from "service/orderService";
import { color, timeStringFormat, orderStatus, courtTypeObj } from "helper/constant";
import { Confirm } from "helper/showAlert";
import { getUserInfo } from "helper/localStorage";

import TCNavigationBar from "components/TopCourtComponents/NavigationBar/TopCourtNavigationBar";
import { TCLabel, TCTitleLabel } from "components/TopCourtComponents/Label/TopCourtLabel";
import SlideAlert from "components/SlideInAlert/SlideAlert";
import Loading from "components/TopCourtComponents/Loading/TCLoadingComponent";
import EmptyData from "components/TopCourtComponents/Placeholder/EmptyData";
import QRPage from "../QRPage";
import { currencyFormat } from "helper/numberFormat";
import OrdersCard from "../Component/OrderCard";

function OnProgressBooking ({

}) {

    const navigate = useNavigate()
    const userInfo = getUserInfo ? JSON.parse(getUserInfo) : null
    const [ orders, setOrders ] = useState([])
    const [ qrCodePopup, setQRCodePopup ] = useState({
        open: false,
        value: ""
    })
    const [ showLogin, setShowLogin ] = useState(false)
    const [ showLoading, setShowLoading ] = useState(false)

    useEffect(() => {
        loadOrders()
    }, [])

    const loadOrders = async () => {
        setShowLoading(true)
        let { data, error } = await getScheduleBookingList({
            status: "on_progress"
        })
        if (error) { console.log(error) }
        if (data) { console.log('here new Bookings', data); 
            for (let i = 0; i < data.length; i ++) {
                let newBookings = []
                let newItemsData = []
                let bookingData = data[i].orderSessions || []
                let itemsData = data[i].orderItems || []
                for (let j = 0; j < bookingData.length; j ++) {
                    if (newBookings.length == 0) {
                        newBookings.push({
                            date: bookingData[j].date,
                            data: [ bookingData[j] ]
                        })
                    } else {
                        let isSimiliar = false
                        for (let k = 0; k < newBookings.length; k ++) {
                            if (newBookings[k].date == bookingData[j].date) {
                                isSimiliar = true
                                newBookings[k].data.push(bookingData[j])
                                break;
                            }
                        }
                        if (!isSimiliar) {
                            newBookings.push({
                                date: bookingData[j].date,
                                data: [ bookingData[j] ]
                            })
                        }
                    }
                }
                for (let j = 0; j < itemsData.length; j ++) {
                    if (newItemsData.length == 0) {
                        newItemsData.push({
                            date: itemsData[j].date,
                            data: [ itemsData[j] ]
                        })
                    } else {
                        let isSimiliar = false
                        for (let k = 0; k < newItemsData.length; k ++) {
                            if (newItemsData[k].date == itemsData[j].date) {
                                isSimiliar = true
                                newItemsData[k].data.push(itemsData[j])
                                break;
                            }
                        }
                        if (!isSimiliar) {
                            newItemsData.push({
                                date: itemsData[j].date,
                                data: [ itemsData[j] ]
                            })
                        }
                    }
                }
                data[i].orderSessions = newBookings
                data[i].orderItems = newItemsData.sort((a,b) => (a.date > b.date) ? 1 : ((b.date > a.date) ? -1 : 0))
                console.log('here new Bookings', newBookings)
            }
            setOrders(data) 
        }
        setShowLoading(false)
    }

    return(
        <>
            {/* {
                !profile &&
                <Grid sx={{pt: 7}} justifyContent={"center"} textAlign={"center"}/>
            } */}
            {
                (!showLoading && orders.length <= 0) &&
                <EmptyData/>
            }
            <Grid direction={"column"}>
            {
                orders.length > 0 && orders.map(value => {
                    return (
                        <Grid sx={{mb: 2}} >
                            <OrdersCard data={value} setQRCodePopup={setQRCodePopup} navigate={navigate} onProgress={true}/>
                        </Grid>
                    )
                })
            }
            </Grid>
            <SlideAlert 
                isOpen={qrCodePopup.open} 
                setIsOpen={() => {
                    setQRCodePopup(prev => ({
                        ...prev,
                        open: !qrCodePopup.open
                    }))
                }}
                maxWidth={"xs"}
            >
                {
                    qrCodePopup.open &&
                    <QRPage courtInfo={qrCodePopup.courtInfo} qrValue={qrCodePopup.value} orderInfo={qrCodePopup.data} />
                }
            </SlideAlert>
            {
                showLoading &&
                <Loading />
            }
        </>
    )
}
export default OnProgressBooking
import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import moment from "moment";
import { Card } from "@mui/material";

import DeleteIcon from '@mui/icons-material/Delete';

import { color, courtTypeObj } from "../../../helper/constant";

import { currencyFormat } from 'helper/numberFormat';
import { timeStringFormat } from "helper/constant";
import { TCLabel } from "components/TopCourtComponents/Label/TopCourtLabel";
import { deleteClassCart } from "service/classService";
import { getCourtInfo } from "helper/localStorage";


function ClassBookingDetail ({
    bookingData, deleteBookings, onSelectBooking,
    bottomSheetHeight, loadUserCart
}) {

    // group the cart
    const [ bookings, setBookings ] = useState([])
    const [ expiredBookings, setExpiredBookings] = useState([])
    const [ expiredSessions, setExpiredSessions] = useState(0)
    const [ seeAll, setSeeAll] = useState(false)

    useEffect(() => {
        let newBookings = []
        let expiredBookings = []
        const bookingDataInfo = JSON.parse(JSON.stringify(bookingData));
        for (let i = 0; i < bookingDataInfo.length; i ++) {
            if (newBookings.length == 0) {
                if (bookingDataInfo[i].hours.length > 0) {
                    newBookings.push({
                        date: bookingDataInfo[i].date,
                        data: [ bookingDataInfo[i] ]
                    })   
                }
            } else {
                let isSimiliar = false
                for (let j = 0; j < newBookings.length; j ++) {
                    if (newBookings[j].date == bookingDataInfo[i].date) {
                        isSimiliar = true
                        if (bookingDataInfo[i].hours.length > 0) {
                            newBookings[j].data.push(bookingDataInfo[i])
                        }
                        break;
                    }
                }
                if (!isSimiliar) {
                    if (bookingDataInfo[i].hours.length > 0) {
                        newBookings.push({
                            date: bookingDataInfo[i].date,
                            data: [ bookingDataInfo[i] ]
                        })   
                    }
                }
            }
        }
        let expiredHours = 0
        for (let i = 0; i < bookingDataInfo.length; i ++) {
            expiredHours += bookingDataInfo[i].expiredHours.length
            if (expiredBookings.length == 0) {
                if (bookingDataInfo[i].expiredHours && bookingDataInfo[i].expiredHours.length > 0) {
                    expiredBookings.push({
                        date: bookingDataInfo[i].date,
                        data: [ bookingDataInfo[i] ]
                    })
                }
            } else {
                let isSimiliar = false
                for (let j = 0; j < expiredBookings.length; j ++) {
                    if (expiredBookings[j].date == bookingDataInfo[i].date) {
                        isSimiliar = true
                        if (bookingDataInfo[i].expiredHours && bookingDataInfo[i].expiredHours.length > 0) {
                            expiredBookings[j].data.push(bookingDataInfo[i])
                        }
                        break;
                    }
                }
                if (!isSimiliar) {
                    if (bookingDataInfo[i].expiredHours && bookingDataInfo[i].expiredHours.length > 0) {
                        expiredBookings.push({
                            date: bookingDataInfo[i].date,
                            data: [ bookingDataInfo[i] ]
                        })
                    }
                }
            }
        }
        setBookings(newBookings)
    }, [bookingData])

    let ratio = bottomSheetHeight / window.innerHeight
    let height = (bottomSheetHeight * ratio)
    let newHeight = (bottomSheetHeight - height)

    const deleteCart = async (cartId) => {
        let placeInfo = getCourtInfo ? JSON.parse(getCourtInfo) : null
        let { data, error } = deleteClassCart({
            cartId: cartId, placeId: placeInfo ? placeInfo._id : '', isExpired: false
        })
        loadUserCart()
    }

    return (
        <Grid container alignItems="center" style={{
            marginTop: 0.5, paddingLeft: 16, paddingRight: 16, paddingBottom: 8, 
                marginBottom: newHeight, overflow: 'auto'}}>
            {
                bookings.map( (value, index) => {
                    return <Grid key={value.court} xs={12} md={12} lg={12} item style={{textAlign: 'left', marginTop: 5}}>
                        <Card>
                            <div className='custom-body' style={{padding: 10}}>
                                <TCLabel style={{fontSize: 13, fontWeight: 'bold'}}>{moment(value.date).format("ddd DD/MM/YYYY")}</TCLabel>
                                {
                                    value.data.sort((a, b) => a.hours > b.hours ? 1 : -1).map( (v, idx) => {
                                        return(
                                            <>
                                                <div style={{display: 'flex', marginTop: 2}} onClick={() => {
                                                    onSelectBooking(v)
                                                }}>
                                                    <div style={{width: '100%'}}>
                                                        <TCLabel style={{fontSize: 12, fontWeight: 'bold'}}>{v.court ? `${courtTypeObj[v.court.courtType]} ${v.court.name}` : ''}</TCLabel>
                                                        {(v.coachDetail && v.coachDetail.length > 0) && <TCLabel style={{fontSize: 12, fontWeight: 'bold'}}>Coach: {v.coachDetail.map((value, i) => {
                                                            return `${value.name}${(i == (v.coachDetail.length - 1) ? '' : ',')}`
                                                        })}</TCLabel>}
                                                        <Grid container direction={'row'}>
                                                        {
                                                            v.hours.map((h, index) => {
                                                                return <TCLabel style={{marginRight: 3, fontSize: 12}}>{`${h} - ${timeStringFormat(Number(h.split(':')[0]) + 1)} 
                                                                    ${index < (v.hours.length - 1) ? " | " : ""}`}</TCLabel>
                                                            })
                                                        }    
                                                        </Grid>
                                                        <TCLabel>Total: <span style={{fontWeight: 'bold'}}>{currencyFormat(v.totalPrice / v.pax || 0)}</span> x <span style={{fontWeight: 'bold'}}>{v.pax} Pax</span></TCLabel>
                                                    </div>
                                                    <div style={{alignItems: 'right'}} onClick={(e) => { e.stopPropagation() 
                                                        deleteCart(v._id)
                                                    }}>
                                                        <DeleteIcon style={{width: 20, height: 20, color: color.pink}}/>
                                                    </div>
                                                </div>
                                                {(idx < (value.data.length - 1)) && <div className="separation-line" />}
                                            </>
                                        )
                                    })
                                }
                            </div>
                        </Card>
                    </Grid>
                })
            }
        </Grid>
    )
}

export default ClassBookingDetail;
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Grid } from "@mui/material";
import { getOrderList } from "service/orderService";

import Login from "pages/Auth/Login";
import SlideAlert from "components/SlideInAlert/SlideAlert";
import Loading from "components/TopCourtComponents/Loading/TCLoadingComponent";
import EmptyData from "components/TopCourtComponents/Placeholder/EmptyData";
import OrdersCard from "../Component/OrderCard";
import QRPage from "../QRPage";

function OnProgressOrders ({

}) {

    const navigate = useNavigate()
    const [ orders, setOrders ] = useState([])
    const [ showLogin, setShowLogin ] = useState(false)
    const [ showLoading, setShowLoading ] = useState(false)

    const [ qrCodePopup, setQRCodePopup ] = useState({
        open: false,
        value: ""
    })

    useEffect(() => {
        loadOrders()
    }, [])

    const loadOrders = async () => {
        setShowLoading(true)
        let { data, error } = await getOrderList({status: 'on_progress'})
        if (error) { console.log(error) }
        if (data) { 
            for (let i = 0; i < data.length; i ++) {
                let newBookings = []
                let newItemsData = []
                let bookingData = data[i].orderSessions || []
                let itemsData = data[i].orderItems || []
                for (let j = 0; j < bookingData.length; j ++) {
                    if (newBookings.length == 0) {
                        newBookings.push({
                            date: bookingData[j].date,
                            data: [ bookingData[j] ]
                        })
                    } else {
                        let isSimiliar = false
                        for (let k = 0; k < newBookings.length; k ++) {
                            if (newBookings[k].date == bookingData[j].date) {
                                isSimiliar = true
                                newBookings[k].data.push(bookingData[j])
                                break;
                            }
                        }
                        if (!isSimiliar) {
                            newBookings.push({
                                date: bookingData[j].date,
                                data: [ bookingData[j] ]
                            })
                        }
                    }
                }
                for (let j = 0; j < itemsData.length; j ++) {
                    if (newItemsData.length == 0) {
                        newItemsData.push({
                            date: itemsData[j].date,
                            data: [ itemsData[j] ]
                        })
                    } else {
                        let isSimiliar = false
                        for (let k = 0; k < newItemsData.length; k ++) {
                            if (newItemsData[k].date == itemsData[j].date) {
                                isSimiliar = true
                                newItemsData[k].data.push(itemsData[j])
                                break;
                            }
                        }
                        if (!isSimiliar) {
                            newItemsData.push({
                                date: itemsData[j].date,
                                data: [ itemsData[j] ]
                            })
                        }
                    }
                }
                data[i].orderSessions = newBookings
                data[i].orderItems = newItemsData.sort((a,b) => (a.date > b.date) ? 1 : ((b.date > a.date) ? -1 : 0))
            }
            setOrders(data) 
        }
        setShowLoading(false)
    }

    return(
        <>
            {/* <TCNavigationBar transparent={false} title={"My Order"}/> */}
            {/* {!profile && <Grid sx={{pt: 7}} justifyContent={"center"} textAlign={"center"}/>} */}
            <Grid direction={"column"}>
            {
                !showLoading && orders.length <= 0 && <EmptyData />
            }
            {
                orders.length > 0 && orders.map(value => {
                    return (
                        <Grid sx={{mb: 2}} >
                            <OrdersCard data={value} setQRCodePopup={setQRCodePopup} navigate={navigate} onProgress={true}/>
                        </Grid>
                    )
                })
            }
            </Grid>
            <SlideAlert 
                isOpen={showLogin || qrCodePopup.open} 
                setIsOpen={(e) => {
                    if (showLogin) setShowLogin(!showLogin)
                    if (qrCodePopup.open) setQRCodePopup(prev => ({
                        ...prev,
                        open: !qrCodePopup.open
                    }))

                }}
                maxWidth={"xs"}
            >
                {
                    showLogin &&
                    <Login isOpen={showLogin} dismissPopup={() => setShowLogin(false)}/>
                }
                {
                    qrCodePopup.open &&
                    <QRPage courtInfo={qrCodePopup.courtInfo} qrValue={qrCodePopup.value} orderInfo={qrCodePopup.data} />
                }
            </SlideAlert>
            {
                showLoading &&
                <Loading />
            }
        </>
    )
}

export default OnProgressOrders
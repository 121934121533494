import { client, errorValidation } from "./service";

export const getPackages = async function ({
  advancedMonths,
  userId
}) {
  try {
    let response = await client.get(`/valuepacks?advancedMonths=${advancedMonths || ""}&userId=${userId || ""}`);
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data };
  } catch (e) {
    // return { error: e.response.data.errorCode };
    return errorValidation(e)
  }
}

export const getCartPackage = async function (user) {
  try {
    let response = await client.get(`/cart/valuepack?user=${user}`);
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data };
  } catch (e) {
    // return { error: e.response.data.errorCode };
    return errorValidation(e)
  }
}

export const addCartPackage = async function (body) {
  try {
    let response = await client.post('/valuepack/addcart', {
      ...body
    });
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data };
  } catch (e) {
    // return { error: e.response.data.errorCode };
    return errorValidation(e)
  }
}

export const checkoutPackage = async function (body) {
  try {
    let response = await client.post('/valuepack/checkout', {
      ...body
    });
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data };
  } catch (e) {
    // return { error: e.response.data.errorCode };
    return errorValidation(e)
  }
}

export const redeemPackage = async function (body) {
  try {
    let response = await client.post('/valuepack/redeem',{
      ...body
    })
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data }
  } catch (e) {
    return errorValidation(e)
  }
}

export const sessionBalance = async function () {
  try {
    let response = await client.get('/session-balance');
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data };
  } catch (e) {
    // return { error: e.response.data.errorCode };
    return errorValidation(e)
  }
}
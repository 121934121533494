import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";

import { Grid, Box, Icon, Card, Button, Avatar, Badge } from "@mui/material";
import EmailIcon from '@mui/icons-material/Email';

import { getClubDetail } from "service/clubService";

import TCNavigationBar from "components/TopCourtComponents/NavigationBar/TopCourtNavigationBar";
import { TCLabel, TCTitleLabel } from "components/TopCourtComponents/Label/TopCourtLabel";
import Loading from "components/TopCourtComponents/Loading/TCLoadingComponent";
import UserCard from "components/TopCourtComponents/User/UserCard";
import { color } from "helper/constant";
import { setUserInfo } from "helper/localStorage";
import { updateClub, applyClubMember, kickMember, promoteCoordinator } from "service/clubService";
import { Confirm } from "helper/showAlert";
import { handleUpload } from "helper/S3";
import SlideDialog from "components/SlideDialog/SlideDialog";
import DialogHeader from "components/SlideDialog/DialogHeader";
import { TCInput } from "components/TopCourtComponents/Input/TopCourtInput";
import AddClub from "./AddClub";
import Clubs from "./Clubs";
import { Confirmation } from "helper/showAlert";
import TCAccordion from "components/TopCourtComponents/Accordion/TopCourtAccordion";
import { getUserName } from "helper/user";
import moment from "moment";
import MKButton from "components/MKButton";
import { approveApplicantService } from "service/clubService";
import { rejectApplicantService, demoteCoordinatorService } from "service/clubService";
import EmptyData from "components/TopCourtComponents/Placeholder/EmptyData";
import { leaveClubMember } from "service/clubService";
import { getMe } from "service/authService";
import clubPlaceholder from "../../assets/club-placeholder.png";
import { getUserInfo } from "helper/localStorage";
import SlideAlert from "components/SlideInAlert/SlideAlert";

import padlock from "../../assets/padlock.png";
import { transferLeaderService } from "service/clubService";
import { loadImages } from "helper/loadImages";
import { getUserAvatar } from "helper/user";
import { CopyShareLinkComponent } from "components/TopCourtComponents/Share/ShareComponent";

function Club ({
    isPopup,
    isDetail = true,
    club_id,
    setShowLogin
}) {

    const navigate = useNavigate()
    const url = window.location.href.split('/')
    const clubId = club_id ? club_id : url[url.length - 1]
    const [ loading, setLoading ] = useState(false)
    const [ clubInfo, setClubInfo ] = useState(null)
    const [ isLeader, setisLeader ] = useState(false)
    const [ isCoordinator, setisCoordinator ] = useState(false)
    const [ userDetail, setUserDetail ] = useState(null)
    const [ readRules, setReadRules ] = useState(false)

    const [ applicantList, setApplicanList ] = useState({
        open: false,
        data: []
    })

    const [ clubDetail, setClubDetail ] = useState({
        open: false
    })

    const [ emptyClub, setEmptyClub ] = useState(true)

    useEffect(() => {
        loadClubDetail()
    }, [])

    const loadClubDetail = async () => {
        setClubInfo(prev => ({
            ...prev,
            members: [],
            memberId: []
        }))
        setLoading(true)
        let user = await getMe()
        let userInfo = user.data
        setUserDetail(userInfo)
        setUserInfo(userInfo)
        if (!clubId) { setLoading(false); setEmptyClub(true); return; }
        let { data, error } = await getClubDetail(clubId);
        if (data) { 
            if (!data._id) { setLoading(false); setEmptyClub(true); return; }
            setEmptyClub(false);
            if (userInfo && (userInfo._id.toString() == data.leader._id.toString())) { setisLeader(true) }
            if (userInfo && (data.coordinator && data.coordinator.indexOf(userInfo._id) >= 0)) { setisCoordinator(true) }
            setClubInfo({
                ...data,
                memberId: data.members.map(value => { return value._id })
            }) 
            setApplicanList({
                open: false,
                data: data.applicants
            })
        }
        console.log(data)
        setLoading(false)
    }
    
    const kickUser = async (e) => {
        try {
            let { data, error } = await kickMember({
                club: clubId,
                user: e
            })
            if (data) { 
                // Confirm("", "User kicked"); 
            loadClubDetail(); }
        } catch (error) {
            console.log(error)
        }
    }
    
    const transferLeader = async (e) => {
        try {
            let { data, error } = await transferLeaderService({
                clubId: clubId,
                user: e
            })
            if (data) { 
                // Confirm("", "User Promoted to Leader"); 
            navigate(0) }
        } catch (error) {
            console.log(error)
        }
    }

    const promoteUser = async (e) => {
        try {
            let { data, error } = await promoteCoordinator({
                club: clubId,
                user: e
            })
            if (data) { 
                // Confirm("", "User Promoted"); 
            loadClubDetail(); }
        } catch (error) {
            console.log(error)
        }
    }

    const demoteCoordinator = async (e) => {
        try {
            let { data, error } = await demoteCoordinatorService({
                clubId: clubId,
                user: e
            })
            if (data) { 
                // Confirm("", "User Demoted"); 
            loadClubDetail(); }
        } catch (error) {
            console.log(error)
        }
    }

    const leaveClub = async () => {
        try {
            let { data, error } = await leaveClubMember({
                club: clubId
            })
            if (data) { 
                // Confirm("", "Left the club"); 
                window.location.reload(); 
            }
        } catch (error) {
            console.log(error)
        }
    }

    // update club information
    const updateClubImage = async (image) => {
        setLoading(true)
        try {
            let imageUrl = await handleUpload({
                selectedFile: image, dirName: `topcourt-staging/${clubId}/logo-pic`
            })
            let { data, error } = await updateClub({
                _id: clubId,
                logo: imageUrl[0]
            })
            if (data) { Confirm("", "Logo uploaded") }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error)
        }
    }
    // update club image - update club banner
    const updateClubBanner = async (image) => {
        try {
            let imageUrl = await handleUpload({
                selectedFile: image, dirName: `topcourt-staging/${clubId}/banner-pic`
            })
            let { data, error } = await updateClub({
                _id: clubId,
                banner: imageUrl[0]
            })
            if (data) { Confirm("", "Banner uploaded") }
        } catch (error) {
            console.log(error)
        }
    }

    const applyMember = async (e) => {
        let { data, erorr } = await applyClubMember({
            club: e
        })
        if (data) { 
            if (data.join) Confirm("", "Joined the club")
            if (data.apply) Confirm("", "Please wait approval from club leader")
            loadClubDetail();
        }
    }

    return (
        <>
            <input id="photo-upload" type="file" accept="image/*" style={{display: 'none'}} onChange={(e) => {
                updateClubImage(e.target.files[0])
            }}/> 
            <input id="banner-upload" type="file" style={{display: 'none'}} onChange={(e) => {
                updateClubBanner(e.target.files[0])
            }}/>
            {
                isDetail &&
                <TCNavigationBar navigation={() => {
                    navigate("/clubs")
                }} transparent={false} title={clubInfo ? clubInfo.name : "Club Name"}/>
            }
            {
                emptyClub &&
                <></>
            }
            {
                !emptyClub &&
                <>
                <Grid sx={{p: 2, pl:2, pr:2, pb: 0}} justifyContent={"center"} textAlign={"center"} direction={"column"}>
                    <Grid xs={12}>
                        {
                            (!isPopup && isLeader) &&
                            <Grid xs={12} textAlign={"right"} onClick={() => {
                                if (isLeader) { 
                                    setClubDetail({
                                        open: true,
                                    })
                                }
                            }}>
                                <TCLabel color={!isLeader ? '#e0e0e0' : ''}>{"Edit"}</TCLabel>
                            </Grid>
                        }
                        <div style={{alignItems: "center"}}>
                            <Badge
                                overlap="circular"
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                badgeContent={isLeader ? 
                                <label htmlFor="photo-upload" className="fas" style={{backgroundColor: color.primary, color: 'white', borderRadius: 10, width: 20, height: 20, 
                                    display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <Icon>create</Icon>
                                </label> : <></>}
                            >
                                <Avatar sx={{ width: 120, height: 120, margin: 'auto' }} src={clubInfo && clubInfo.logo ? loadImages(clubInfo.logo) : clubPlaceholder}/>
                            </Badge>
                        </div>
                    </Grid>
                </Grid>
                <Grid p={2} pb={0} item xs={12} mt={0}>
                    <Grid item container>
                        <Grid item xs>
                            <TCTitleLabel>{clubInfo.name}</TCTitleLabel>
                        </Grid>
                        <Grid item xs={1}>
                            <CopyShareLinkComponent data={`${window.location.protocol}//${window.location.hostname}/clubs#${clubInfo.name.toLowerCase().replace(/ /g, "-")}`}/>
                        </Grid>
                    </Grid>
                    <TCLabel subtitle>{clubInfo.highlightDescription}</TCLabel>
                </Grid>
                <Grid item pl={2} pr={2} container xs={12} pt={1} textAlign={"right"} spacing={1}>
                    <Grid item xs={6}>
                        {
                            (userDetail && userDetail.club === clubId.toString()) ?
                                <Grid item container justifyContent={"center"} textAlign={"center"}>
                                    <MKButton fullWidth color="pink" onClick={() => {
                                        Confirmation("", "Leave the club ?", () => {}, async () => { leaveClub() })
                                    }}>
                                        <Icon style={{marginRight: 1, marginBottom: 1}}>meeting_room</Icon>
                                        Leave Club
                                    </MKButton>
                                </Grid>
                            :
                            (clubInfo && !clubInfo.private) ?
                                <MKButton fullWidth color="primary"
                                disabled={(userDetail && userDetail.club) ? true : false}
                                onClick={(e) => {
                                    e.stopPropagation(true)
                                    if (!userDetail) { setShowLogin(true); return; }
                                    if (userDetail.club) { Confirm("", "Leave your current club before join new club"); return; }
                                    Confirmation("", "Join this club ?", () => {}, async () => { applyMember(clubInfo._id) })
                                }}>
                                    <Icon>person_add_alt_1</Icon>
                                    <span style={{marginLeft: 2, paddingTop: 2}}>Join</span>
                                </MKButton>
                            :
                                <MKButton fullWidth color="primary" 
                                disabled={(userDetail && userDetail.club) ? true : userDetail ? 
                                    (clubInfo && clubInfo.queuingMembers.indexOf(userDetail._id) >= 0) ? true 
                                    : false : false
                                } onClick={(e) => {
                                    e.stopPropagation(true)
                                    if (!userDetail) { setShowLogin(true); return; }
                                    if (userDetail.club) { Confirm("", "Leave your current club before apply to new club"); return; }
                                    Confirmation("", "Join this club ?", () => {}, async () => { applyMember(clubInfo._id) })
                                }}>
                                    <Icon>person_add_alt_1</Icon>
                                    <span style={{marginLeft: 2, paddingTop: 2}}>{userDetail ? (clubInfo && clubInfo.queuingMembers.indexOf(userDetail._id) >= 0) ? "Pending" : "Join" : "Join"}</span>
                                </MKButton>
                        }
                    </Grid>
                    <Grid item xs={6}>
                        <MKButton fullWidth color="white"
                            sx={{
                                p: 0,
                                border: 1,
                                pl: 1, pr: 1,
                                color: color.primary
                            }}
                            onClick={(e) => {
                                e.stopPropagation()
                                setReadRules({
                                    open: e,
                                    data: clubInfo.description
                                })
                            }}
                        >
                            <Icon>rules</Icon>
                            <span style={{marginLeft: 2, paddingTop: 2}}>Read Rules</span>
                        </MKButton>
                    </Grid>
                </Grid>
                {
                    (!clubInfo.private || (userDetail && userDetail.club === clubId.toString())) &&
                    <>
                    {
                        (!isPopup && ((isLeader || clubInfo.memberId.indexOf(userDetail._id) >= 0) && applicantList.data && applicantList.data.length > 0)) &&
                            <Grid sx={{mt: 2, pl: 2, pr: 2, pt: 1, pb: 1}} style={{backgroundColor: color.blueIndoor_2, color: 'white'}} container
                                onClick={() => {
                                    setApplicanList(prev => ({
                                        ...prev,
                                        open: true
                                    }))
                                }}
                            >
                                <Grid xs={8}>
                                    <TCLabel inheritColor={true}>{applicantList.data.length} pending requests</TCLabel>
                                </Grid>
                                <Grid xs={4} textAlign={"right"}>
                                    <TCLabel inheritColor={true}>{`View`}</TCLabel>
                                </Grid>
                            </Grid>
                        }
                        <Grid sx={{p: 2, pt: !isPopup ? 1 : 2, pb: 0}} xs={12} container>
                            <TCLabel bold={true}>Club Member ({clubInfo.members.length})</TCLabel>
                            <Grid xs={12} md={12} item>
                                {
                                    (clubInfo && clubInfo.members) && clubInfo.members.map(value => {
                                        return(
                                            <UserCard key={value._id} value={value} detail={false} hideClub={true} 
                                                clubCard={true}
                                                clubLeader={isLeader}
                                                clubCoordinator={isCoordinator}
                                                userAsLeader={clubInfo && (value._id.toString() == clubInfo.leader._id.toString())}
                                                userAsCoordinator={clubInfo && (clubInfo.coordinator && clubInfo.coordinator.indexOf(value._id) >= 0)}
                                                kickUser={ () => {
                                                    Confirmation("", "Kick member ?", () => {}, async () => { await kickUser(value._id) })
                                                }}
                                                promoteLeader={ () => {
                                                    Confirmation("", "Promote user as leader ?", () => {}, async () => { await transferLeader(value._id) })
                                                }}
                                                promoteCoordinator={ () => {
                                                    Confirmation("", "Promote user as coordinator ?", () => {}, async () => { await promoteUser(value._id) })
                                                }}
                                                demoteCoordinator={ () => {
                                                    Confirmation("", "Demote user ?", () => {}, async () => { await demoteCoordinator(value._id) })
                                                }}
                                            />
                                        )
                                    })
                                }
                            </Grid>
                        </Grid>
                    </>
                }
                {
                    (
                        ((userDetail && userDetail.club !== clubId.toString()) 
                        && clubInfo.private)
                        || (!userDetail && clubInfo.private)
                    ) &&
                    <>
                        <Grid container justifyContent={"center"} pt={5}>
                            <Grid item xs={12} textAlign={"center"}>
                                <img src={padlock} width={80} height={80} />
                            </Grid>
                            <Grid item xs={12} textAlign={"center"}>
                                <TCLabel>Only members of this club can view</TCLabel>
                            </Grid>
                        </Grid>
                    </>
                }
                </>
            }
            {
                loading &&
                <Loading />
            }
            <SlideAlert
                isOpen={readRules}
                setIsOpen={(e) => {
                    setReadRules(e)
                }}
                useDialogContent={false}
                maxWidth={"xs"}
            >
                <Grid p={2}>
                    <DialogHeader title={clubInfo && clubInfo.name} closeDialog={() => { 
                        setReadRules(false)
                    }}/>
                    <Grid p={2} pt={0}>
                        <TCLabel><div dangerouslySetInnerHTML={{__html: clubInfo && clubInfo.description}}/></TCLabel>
                    </Grid>
                </Grid>
            </SlideAlert>
            <SlideDialog
                isOpen={clubDetail.open || applicantList.open}
                setIsOpen={(e) => {
                    if (clubDetail.open) { setClubDetail({
                        open: e,
                    }) }
                    // if (clubList.open) { setClubList({
                    //     open: e,
                    // }) }
                    if (applicantList.open) { setApplicanList({
                        open: e,
                    }) }
                }}
                useDialogContent={false}
                maxWidth={"lg"}
            >
                {
                    clubDetail.open &&
                    <ClubInfo closeDialog={() => { setClubDetail({
                        open: false
                    }); loadClubDetail()} } clubId={clubInfo._id}/>
                }
                {/* {
                    clubList.open &&
                    <ClubList closeDialog={() => { 
                        setClubList({
                            open: false
                        }); 
                        navigate(0)
                    } } />
                } */}
                {
                    applicantList.open &&
                    <ApplicantList 
                    isLeader={isLeader}
                    isCoordinator={isCoordinator}
                    data={applicantList.data} closeDialog={() => { loadClubDetail()} } loadClubDetail={() => loadClubDetail()}/>
                }
            </SlideDialog>
        </>
    )
}

function ClubInfo ({
    closeDialog,
    clubId
}) {

    return (
        <Grid container p={2}>
            <DialogHeader title={<TCLabel bold style={{fontSize: 15}}>Update Club Info</TCLabel>} closeDialog={()=> { closeDialog() }}/>
            <Grid xs={12}>
                <AddClub isPopup={true} club_id={clubId} />
            </Grid>
        </Grid>
    )
}

function ApplicantList ({
    isLeader,
    isCoordinator,
    data,
    loadClubDetail,
    closeDialog
}) {
    
    const rejectApplicant = async (club, userId, _id) => {
        let { data, error } = await rejectApplicantService({
            club: club, playerId: userId, historyId: _id
        })
        if (data) { 
            // Confirm("", "Application Rejected"); 
        loadClubDetail();}
    }

    const acceptApplicant = async (club, userId, _id) => {
        let { data, error } = await approveApplicantService({
            club: club, playerId: userId, historyId: _id
        })
        if (data) { 
            // Confirm("", "Application Approved"); 
        loadClubDetail();}
    }

    return (
        <Grid container p={2}>
            <DialogHeader title={<TCLabel bold style={{fontSize: 15}}>Pending Requests</TCLabel>} closeDialog={()=> { closeDialog() }}/>
            {
                !data &&
                <EmptyData />
            }
            {data.map(value => {
                let user = value.approval ? value.approval.user : null
                return (
                    <Grid xs={12} mb={1}>
                        {/* <TCAccordion title={user ? user.name : ""}>
                            {<TCLabel>Hello World</TCLabel>}
                        </TCAccordion> */}
                        <Card>
                            <Grid container xs={12} p={2} spacing={1}>
                                <Grid item xs={2}>
                                    <Avatar src={getUserAvatar(user)}/>
                                </Grid>
                                <Grid item container xs={10} direction={"column"}>
                                    <Grid item>
                                        <TCLabel>{getUserName(user)}</TCLabel>
                                    </Grid>
                                    <Grid item>
                                        <TCLabel subtitle>Joined {moment(user.createdDate).format("DD/MM/YYYY")}</TCLabel>
                                    </Grid>
                                </Grid>
                                <Grid item container xs={12} spacing={1}>
                                    <Grid item xs={6}>
                                        <MKButton fullWidth 
                                        disabled={!isLeader && !isCoordinator}
                                            color="pink" onClick={() => {
                                            rejectApplicant(value.club, user._id, value._id)
                                        }}>
                                            <TCLabel style={{color: 'white'}} subtitle>
                                                <Icon style={{paddingTop: 3}}>close</Icon>
                                                Reject
                                            </TCLabel>
                                        </MKButton>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <MKButton fullWidth 
                                        disabled={!isLeader && !isCoordinator}
                                        color="primary" onClick={() => {
                                            acceptApplicant(value.club, user._id, value._id)
                                        }}>
                                            <TCLabel style={{color: 'white'}} subtitle>
                                                <Icon style={{paddingTop: 3}}>done</Icon>
                                                Accept
                                            </TCLabel>
                                        </MKButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid> 
                )
            })}
        </Grid>
    )
}

export default Club
import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import moment from "moment";
import { Card } from "@mui/material";

import DeleteIcon from '@mui/icons-material/Delete';

import { color, courtTypeObj } from "../../helper/constant";

import { currencyFormat } from 'helper/numberFormat';
import { timeStringFormat } from "helper/constant";
import { TCLabel } from "components/TopCourtComponents/Label/TopCourtLabel";
import { TCTitleLabel } from "components/TopCourtComponents/Label/TopCourtLabel";
import { Confirm } from "helper/showAlert";

function BookingDetail ({
    bookingData, deleteBookings, onSelectBooking, setCoupon, setCouponCode,
    bottomSheetHeight
}) {

    // group the cart
    const [ bookings, setBookings ] = useState([])
    const [ expiredBookings, setExpiredBookings] = useState([])
    const [ expiredSessions, setExpiredSessions] = useState(0)
    const [ seeAll, setSeeAll] = useState(false)

    useEffect(() => {
        let newBookings = []
        let expiredBookings = []
        const bookingDataInfo = JSON.parse(JSON.stringify(bookingData));
        for (let i = 0; i < bookingDataInfo.length; i ++) {
            if (newBookings.length == 0) {
                if (bookingDataInfo[i].hours.length > 0) {
                    newBookings.push({
                        date: bookingDataInfo[i].date,
                        data: [ bookingDataInfo[i] ]
                    })   
                }
            } else {
                let isSimiliar = false
                for (let j = 0; j < newBookings.length; j ++) {
                    if (newBookings[j].date == bookingDataInfo[i].date) {
                        isSimiliar = true
                        if (bookingDataInfo[i].hours.length > 0) {
                            newBookings[j].data.push(bookingDataInfo[i])
                        }
                        break;
                    }
                }
                if (!isSimiliar) {
                    if (bookingDataInfo[i].hours.length > 0) {
                        newBookings.push({
                            date: bookingDataInfo[i].date,
                            data: [ bookingDataInfo[i] ]
                        })   
                    }
                }
            }
        }
        let expiredHours = 0
        for (let i = 0; i < bookingDataInfo.length; i ++) {
            console.log('expiredHours', bookingDataInfo[i])
            expiredHours += bookingDataInfo[i].expiredHours.length
            if (expiredBookings.length == 0) {
                if (bookingDataInfo[i].expiredHours && bookingDataInfo[i].expiredHours.length > 0) {
                    expiredBookings.push({
                        date: bookingDataInfo[i].date,
                        data: [ bookingDataInfo[i] ]
                    })
                }
            } else {
                let isSimiliar = false
                for (let j = 0; j < expiredBookings.length; j ++) {
                    if (expiredBookings[j].date == bookingDataInfo[i].date) {
                        isSimiliar = true
                        if (bookingDataInfo[i].expiredHours && bookingDataInfo[i].expiredHours.length > 0) {
                            expiredBookings[j].data.push(bookingDataInfo[i])
                        }
                        break;
                    }
                }
                if (!isSimiliar) {
                    if (bookingDataInfo[i].expiredHours && bookingDataInfo[i].expiredHours.length > 0) {
                        expiredBookings.push({
                            date: bookingDataInfo[i].date,
                            data: [ bookingDataInfo[i] ]
                        })
                    }
                }
            }
        }
        if (newBookings.length === 0) {
            setCoupon(null)
            setCouponCode("")
        }
        setBookings(newBookings)
        setExpiredBookings(expiredBookings)
        setExpiredSessions(expiredHours)
    }, [bookingData])

    let ratio = bottomSheetHeight / window.innerHeight
    let height = (bottomSheetHeight * ratio)
    let newHeight = (bottomSheetHeight - height)

    return (
        <Grid container alignItems="center" style={{
            marginTop: 0.5, paddingLeft: 16, paddingRight: 16, paddingBottom: 8, 
                marginBottom: newHeight, overflow: 'auto'}}>
            {
                bookings.map( (value, index) => {
                    return <Grid key={value.court} xs={12} md={12} lg={12} item style={{textAlign: 'left', marginTop: 5}}>
                        <Card>
                            <div className='custom-body' style={{padding: 10}}>
                                <TCLabel style={{fontSize: 13, fontWeight: 'bold'}}>{moment(value.date).format("ddd DD/MM/YYYY")}</TCLabel>
                                {
                                    value.data.map( (v, idx) => {
                                        return(
                                            <>
                                                <div style={{display: 'flex', marginTop: 2}} onClick={() => {
                                                    onSelectBooking(v)
                                                }}>
                                                    <div style={{width: '100%'}}>
                                                        <TCLabel style={{fontSize: 12, fontWeight: 'bold'}}>{courtTypeObj[v.court.courtType]} {v.court.name}</TCLabel>
                                                        <Grid container direction={'row'}>
                                                        {
                                                            v.hours.map((h, index) => {
                                                                return <TCLabel style={{marginRight: 3, fontSize: 12}}>{`${h} - ${timeStringFormat(Number(h.split(':')[0]) + 1)} 
                                                                    ${index < (v.hours.length - 1) ? " | " : ""}`}</TCLabel>
                                                            })
                                                        }    
                                                        </Grid>
                                                        <TCLabel>Total: <span style={{fontWeight: 'bold'}}>{currencyFormat(v.totalPrice)}</span></TCLabel>
                                                    </div>
                                                    <div style={{alignItems: 'right'}} onClick={(e) => {
                                                        e.stopPropagation()
                                                        deleteBookings({...v, isExpired: false})
                                                    }}>
                                                        <DeleteIcon style={{width: 20, height: 20, color: color.pink}}/>
                                                    </div>
                                                </div>
                                                {(idx < (value.data.length - 1)) && <div className="separation-line" />}
                                            </>
                                        )
                                    })
                                }
                            </div>
                        </Card>
                    </Grid>
                })
            }
            {/* {
                expiredBookings.length > 0 &&
                <Grid style={{
                    marginTop: 10,
                    marginLeft: -16, marginRight: -16,
                    paddingLeft: 16, paddingRight: 16,
                    backgroundColor: 'rgba(236, 236, 236)', 
                    width: '120%'
                }}>
                    <Grid style={{
                        borderRadius: 8,
                        marginTop: 10, marginBottom: 2, width: '100%', padding: 5, paddingBottom: 0}}>
                            <Grid container>
                                <Grid item xs={6}>
                                    <TCTitleLabel style={{fontSize: 14}}>Session not Valid</TCTitleLabel>
                                </Grid>
                                <Grid item xs={6} textAlign={"right"} justifyContent={"flex-end"}>
                                    <div style={{display: 'flex', justifyContent: 'flex-end'}} onClick={(e) => {
                                        e.stopPropagation()
                                        for (let i = 0; i < expiredBookings.length; i++) {
                                            let data = expiredBookings[i].data
                                            data.map( v => {
                                                deleteBookings({...v, isExpired: true})
                                            })}
                                    }}>
                                        <DeleteIcon style={{width: 20, height: 20, color: color.pink}}/>
                                        <TCLabel style={{fontSize: 10, marginTop: 2, color: color.pink}}>{`(${expiredSessions} session${expiredSessions > 1 ? 's' : ''})`}</TCLabel>
                                    </div>
                                </Grid>
                            </Grid>
                    </Grid>
                    {
                        expiredBookings.map( (value, index) => {
                            if (index < 3 || seeAll) { 
                                return <Grid key={value.court} xs={12} md={12} lg={12} item style={{textAlign: 'left', marginTop: 5}}>
                                    <Card style={{
                                        backgroundColor: 'rgba(256, 256, 256, 0.5)'
                                    }}>
                                        <div className='custom-body' style={{padding: 10}}>
                                            <TCLabel style={{fontSize: 13, fontWeight: 'bold', color: 'rgba(206, 206, 206, 1)'}}>{moment(value.date).format("ddd DD/MM/YYYY")}</TCLabel>
                                            {
                                                value.data.map( v => {
                                                    return(
                                                        <div style={{display: 'flex', marginTop: 2}} onClick={() => {
                                                            // onSelectBooking(v)
                                                            Confirm("", "Current booking date is already passed")
                                                        }}>
                                                            <div style={{width: '100%'}}>
                                                                <TCLabel style={{fontSize: 12, fontWeight: 'bold', color: 'rgba(206, 206, 206, 1)'}}>{courtTypeObj[v.court.courtType]} {v.court.name}</TCLabel>
                                                                <Grid container direction={'row'}>
                                                                {
                                                                    v.expiredHours.map((h, index) => {
                                                                        return <TCLabel style={{marginRight: 3, fontSize: 12, color: 'rgba(206, 206, 206, 1)'}}>{`${h} - ${timeStringFormat(Number(h.split(':')[0]) + 1)} 
                                                                            ${index < (v.expiredHours.length - 1) ? " | " : ""}`}</TCLabel>
                                                                    })
                                                                }    
                                                                </Grid>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </Card>
                                </Grid>
                            }
                        })
                    }
                    {
                        expiredBookings.length > 3 &&
                        <Grid pt={1} pb={1} xs={12} textAlign={"center"} onClick={() => {
                            setSeeAll(!seeAll)
                        }}>
                            <TCLabel bold >{seeAll ? "Hide" : "See all"}</TCLabel>
                        </Grid>
                    }
                </Grid> 
            } */}
        </Grid>
    )
}

export default BookingDetail;
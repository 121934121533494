import React, { useEffect, useState } from "react"
import { Badge, Icon } from "@mui/material"
import { color } from "helper/constant"

// import cart from "../../../assets/order.svg";
import cart from "../../../assets/checklist_round.svg";
import { getAwaitingPaymentOrder } from "service/orderService";

function CartButton ({

}) {
    const [ badge, setBadge ] = useState(0)

    useEffect(() => {
        loadAwaitingPayment()
    }, [])

    const loadAwaitingPayment = async () => {
        let {data, error} = await getAwaitingPaymentOrder()
        if (error) { console.log(error) }
        console.log('here loadAwaitingPayment', data)
        if (data) { setBadge(data) }
    }

    return (
        <>
        {
            badge ?
            <Badge
                // sx={{mt: -0.5}}
                overlap="circular"
                anchorOrigin={{ vertical: 'top', horizontal: 'right'}}
                badgeContent={<label htmlFor="photo-upload" className="pulser" style={{backgroundColor: color.pink, color: 'white', borderRadius: 10, width: 15, height: 15, 
                    display: 'flex', justifyContent: 'center', alignItems: 'center'}}>{""}</label>}
                >
                <img style={{cursor: 'pointer'}} width={23} height={22} src={cart}/>
            </Badge>
            :
            <img style={{cursor: 'pointer'}} width={23} height={22} src={cart}/>
            // <Icon fontSize="xxl" sx={{color: color.primary}}>shopping_cart</Icon>
        }
        </>
    )
}

export default CartButton
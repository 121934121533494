import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';

import { AppBar, Box, Grid } from "@mui/material";
import MKTypography from "components/MKTypography";
import { getUserInfo } from "helper/localStorage";

import { getMe } from "service/authService";

import { setUserInfo, deleteUserInfo } from "helper/localStorage";
import TCNavigationBar from "components/TopCourtComponents/NavigationBar/TopCourtNavigationBar";
import Coaches from "./Components/Coaches";
import Classes from "./Components/Classes";

import TCTabs from "components/TopCourtComponents/Tabs/TopCourtTabs";
import { memorizeTabs } from "helper/tabsMemo";
import { getTabMemos } from "helper/tabsMemo";


function CustomTabPanel(props) {
    const { children, value, index, boxPadding = 3, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: boxPadding }}>
            <MKTypography>{children}</MKTypography>
          </Box>
        )}
      </div>
    );
}

function Class ({

}) {

    const navigate = useNavigate();
    const [ showAlert, setShowAlert ] = useState(false)
    const [ activeTab, setActiveTab ] = useState(Number(getTabMemos('class')) || 0)
    const [ login, setLogin ] = useState(false)

    const userInfo = getUserInfo ? JSON.parse(getUserInfo) : null

    const [ userDetail, setUserDetail ] = useState(userInfo)

    useEffect(() => {
        loadMe()
        checkURL()
    }, [])

    const loadMe = async () => {
        let { data, error } = await getMe()
        if (error) { console.log(error) }
        if (data) {  setUserDetail(data); setUserInfo(data) }
    }

    const checkURL = () => {
        try {
            let url = new URL(window.location)
            if (url.search) {
                let newValue = url.search == "?class" ? 0 : url.search == "?event" ? 1 : 0
                memorizeTabs('class', newValue)
                setActiveTab(newValue)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const checkIsLogin = () => {
        const currentUserString = getUserInfo;
        if (!currentUserString) {
            setShowAlert(true)
            return false
        }
        return true
    }

    return (
        <>
            {/* <TCNavigationBar transparent={false} title={"Class & Coach"} showLogin={showAlert} setShowLogin={setShowAlert}/> */}
            {/* <TCNavigationBar transparent={false} title={"Class"} showLogin={showAlert} setShowLogin={setShowAlert}/> */}
            <TCNavigationBar transparent={false} title={"Class & Event"} showLogin={showAlert} setShowLogin={setShowAlert}/>
            <AppBar position="static">
                <TCTabs
                    activeTab={activeTab}
                    tabs={[
                        'Class',
                        'Event'
                    ]}
                    onChange={(event, newValue) => {
                        window.history.pushState({}, '', `?${newValue > 0 ? 'event' : 'class' }`)
                        memorizeTabs('class', newValue)
                        setActiveTab(newValue)
                    }}
                />
                <CustomTabPanel boxPadding={0} value={activeTab} index={0}>
                    <div style={{overflow: 'auto', height: '83vh', paddingTop: 8, paddingBottom: 16, paddingLeft: 16, paddingRight: 16}}>
                        <Classes classType={"class"} userDetail={userDetail} checkIsLogin={checkIsLogin}/>
                    </div>
                </CustomTabPanel>
                <CustomTabPanel boxPadding={0} value={activeTab} index={1}>
                    <div style={{overflow: 'auto', height: '83vh', paddingTop: 8, paddingBottom: 16, paddingLeft: 16, paddingRight: 16}}>
                        <Classes classType={"event"} userDetail={userDetail} checkIsLogin={checkIsLogin}/>
                    </div>
                </CustomTabPanel>
                {/* <CustomTabPanel boxPadding={1} value={activeTab} index={1}>
                    <div style={{overflow: 'auto', height: '83vh', marginLeft: 8, marginRight: 8}}>
                        <Coaches userDetail={userDetail} checkIsLogin={checkIsLogin} reloadUserDetail={() => { loadMe() }} setShowLogin={(e) => {setShowAlert(e)}}/>
                    </div>
                </CustomTabPanel> */}
            </AppBar>
            <Grid mt={2}/>
            {/* <div style={{overflow: 'auto', height: '90vh', marginLeft: 16, marginRight: 16}}>
                <Classes userDetail={userDetail} checkIsLogin={checkIsLogin}/>
            </div> */}
            {/* <Coaches userDetail={userDetail} checkIsLogin={checkIsLogin} reloadUserDetail={() => { loadMe() }} setShowLogin={(e) => {setShowAlert(e)}}/> */}
        </>
    )
}

export default Class
import React, { useState, useEffect } from "react";
import emptyBox from "../../../assets/empty-box.png";
import { TCLabel } from "../Label/TopCourtLabel";

export default function EmptyData({
    marginTop = '50%',
    width = 80,
    height = 80
}) {

    return (
        <div style={{margin: 'auto', textAlign: 'center', marginTop: marginTop}}>
            <div>
                <img src={emptyBox} width={width} height={height}/>
            </div>
            <TCLabel>There is no data</TCLabel>
        </div>
    )
}
import { reactLocalStorage } from "reactjs-localstorage"

const BOOKING_TABS = 'booking_tabs'
const ADD_ONS_TABS = 'add_ons_tabs'
const PACK_TABS = 'pack_tabs'
const CLASS_TABS = 'class_tabs'
const ORDER_TABS = 'order_tabs'
const CLUB_TABS = 'club_tabs'
const CLASS_FILTER = 'class_filter'

export const getTabMemos = (key) => {
    // reactLocalStorage.
    if (key === 'bookings') { return reactLocalStorage.get(BOOKING_TABS) }
    if (key === 'orders') { return reactLocalStorage.get(ORDER_TABS) }
    if (key === 'add-ons') { return reactLocalStorage.get(ADD_ONS_TABS) }
    if (key === 'packages') { return reactLocalStorage.get(PACK_TABS) }
    if (key === 'class') { return reactLocalStorage.get(CLASS_TABS) }
    if (key === 'clubs') { return reactLocalStorage.get(CLUB_TABS) }
    if (key === 'class-filter') { return reactLocalStorage.get(CLASS_FILTER) }
}

export const memorizeTabs = (key, value) => {
    if (key === 'bookings') { reactLocalStorage.set(BOOKING_TABS, value) }
    if (key === 'orders') { reactLocalStorage.set(ORDER_TABS, value) }
    if (key === 'add-ons') { reactLocalStorage.set(ADD_ONS_TABS, value) }
    if (key === 'packages') { reactLocalStorage.set(PACK_TABS, value) }
    if (key === 'class') { reactLocalStorage.set(CLASS_TABS, value) }
    if (key === 'clubs') { reactLocalStorage.set(CLUB_TABS, value) }
    if (key === 'class-filter') { return reactLocalStorage.set(CLASS_FILTER, value) }
    return true
}

export const forgetTabBookingMemos = (key) => {
    if (key === 'bookings') { reactLocalStorage.remove(BOOKING_TABS) }
    if (key === 'orders') { reactLocalStorage.remove(ORDER_TABS) }
    if (key === 'add-ons') { reactLocalStorage.remove(ADD_ONS_TABS) }
    if (key === 'packages') { reactLocalStorage.remove(PACK_TABS) }
    if (key === 'class') { reactLocalStorage.remove(CLASS_TABS) }
    if (key === 'clubs') { reactLocalStorage.remove(CLUB_TABS) }
    if (key === 'class-filter') { reactLocalStorage.remove(CLASS_FILTER) }
    return true
}
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";

import { Grid } from "@mui/material"
import { getPackages } from "service/packagesService";
import PackCard from "pages/Packages/PackCard";
import { TCLabel } from "components/TopCourtComponents/Label/TopCourtLabel";
import { addCartPackage } from "service/packagesService";
import { checkoutPackage } from "service/packagesService";
import { setPackageCart } from "helper/localStorage";
import { Confirm } from "helper/showAlert";

function PopupValuePack ({
    userInfo,
    closePopUp,
    advanceMonth = 2,
    setShowLogin,
}) {

    const navigate = useNavigate()
    const [ packages, setPackages ] = useState([])
    
    useEffect(() => {
        loadPackages()
    }, [])

    const loadPackages = async () => {
        let { data, error } = await getPackages({
            advancedMonths: advanceMonth,
            userId: userInfo ? userInfo._id : null
        })
        if (error) { console.log(error) }
        if (data) { setPackages(data) }
    }

    const onClickBuy = async (value) => {
        if (!userInfo) { setShowLogin(true); return; }
        if (!value) { return 'Select Package First'; }
        let { data, error } = await addCartPackage({
            package: value ? value._id : "",
            nominal: value ? value.price : 0,
            user: userInfo._id
        })
        setPackageCart(null)
        if (data) { 
            let { data, error } = await checkoutPackage({user: userInfo._id})
            if (error) { Confirm("", error) }
            if (data) { navigate(`/payment/${data.newOrder._id}/value-pack`) }
        }
    }

    return (
        <>
            <Grid container sx={{
                backgroundColor: 'white',
                p: 0, m: 0
            }}>
                <Grid container xs={12} p={2} pb={1}>
                        <Grid xs={10} textAlign={"left"} sx={{zIndex: 10, backgroundColor: 'white'}}>
                            <TCLabel bold>Buy value pack to unlock advanced booking</TCLabel>
                        </Grid>
                        <Grid xs={2} textAlign={"right"} sx={{zIndex: 10, backgroundColor: 'white'}} onClick={() => {
                                closePopUp()
                            }}>
                            <TCLabel bold>x</TCLabel>
                        </Grid>
                    </Grid>
                <Grid sx={{overflow: 'auto', paddingTop: 1,
                    width: '100%',
                    paddingRight: 2, paddingLeft: 2,
                    paddingBottom: 0, height: (packages.length > 1) ? '80vh' : '45vh' }}>
                {
                    packages.map(value => {
                        return (<PackCard value={value} onClickBuy={() => {
                            onClickBuy(value)
                        }}/>)
                    })
                }
                </Grid>
            </Grid>
        </>
    )
}

export default PopupValuePack
import { client, errorValidation } from "./service";


export const getPlayers = async function (
  query
) {
  try {
    let response = await client.get(`/players?limit=${query.limit}&page=${query.page}`);
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data };
  } catch (e) {
      // return { error: e.response.data.errorCode }
      return errorValidation(e)
  }
}

export const getPlayerDetail = async function (playerId) {
  try {
    let response = await client.get(`/player/${playerId}`);
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data };
  } catch (e) {
      // return { error: e.response.data.errorCode }
      return errorValidation(e)
  }
}

export const updateFirebaseToken = async function ( firebaseToken ) {
  try {
    let response = await client.post(`/notification/register`, {
      firebaseToken: firebaseToken || ""
    });
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data };
  } catch (e) {
      // return { error: e.response.data.errorCode }
      return errorValidation(e)
  }
}

export const updatePlayerInfo = async function (body) {
  try {
    let response = await client.post(`/player-update`, {
      ...body
    });
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data };
  } catch (e) {
      // return { error: e.response.data.errorCode }
      return errorValidation(e)
  }
}

export const generateBarcode = async function () {
  try {
    let response = await client.get(`/player-barcode`);
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data };
  } catch (error) {
    return errorValidation(error)
  }
}

export const getMyServiceSchedule = async function () {
  try {
    let response = await client.get(`/my-service-schedule`);
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data };
  } catch (e) {
      // return { error: e.response.data.errorCode }
      return errorValidation(e)
  }
}

export const deleteOffSchedule = async function (body) {
  try {
    let response = await client.post(`/delete-off-schedule`, {
      ...body
    });
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data };
  } catch (e) {
      // return { error: e.response.data.errorCode }
      return errorValidation(e)
  }
}

export const getMyOffSchedule = async function () {
  try {
    let response = await client.get(`/my-off-schedule`);
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data };
  } catch (e) {
      // return { error: e.response.data.errorCode }
      return errorValidation(e)
  }
}

export const setMySchedule = async function (body) {
  try {
    let response = await client.post(`/set-my-schedule`, {
      ...body
    });
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data };
  } catch (e) {
      // return { error: e.response.data.errorCode }
      return errorValidation(e)
  }
}

export const setMyOffSchedule = async function (body) {
  try {
    let response = await client.post(`/set-off-schedule`, {
      ...body
    });
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data };
  } catch (e) {
      // return { error: e.response.data.errorCode }
      return errorValidation(e)
  }
}
import { LOCALSTORAGECONSTANT } from '../helper/constant'; 
import { reactLocalStorage } from "reactjs-localstorage";

export const setCourtInfo = function(data) {
    reactLocalStorage.setObject(LOCALSTORAGECONSTANT.TOP_COURT_PLACE, data);
    return true
}
export const getCourtInfo = reactLocalStorage.get(LOCALSTORAGECONSTANT.TOP_COURT_PLACE)

// User Info
export const setUserInfo = function(data) {
    reactLocalStorage.setObject(LOCALSTORAGECONSTANT.TOP_COURT_USER, data);
    return true
}
export const getUserInfo = checkUserInfo()
export const deleteUserInfo = function() { reactLocalStorage.remove(LOCALSTORAGECONSTANT.TOP_COURT_USER); return true}

// Cart Package Info
export const setPackageCart = function(data) {
    reactLocalStorage.setObject(LOCALSTORAGECONSTANT.CART_PACKAGE, data);
    return true
}
export const getPackageCart = reactLocalStorage.get(LOCALSTORAGECONSTANT.CART_PACKAGE)

export const setLocalBookingForm = function(data) {
    reactLocalStorage.setObject(LOCALSTORAGECONSTANT.CART_BOOKING_FORM, data);
    return true
}
export const getLocalBookingForm = reactLocalStorage.get(LOCALSTORAGECONSTANT.CART_BOOKING_FORM)
export const deleteLocalBookingForm = function() { reactLocalStorage.remove(LOCALSTORAGECONSTANT.CART_BOOKING_FORM); return true}

// export const setLocalSessionUsage = function(data) {
//     reactLocalStorage.setObject(LOCALSTORAGECONSTANT.SESSION_USAGE, data);
//     return true
// }
// export const getLocalSessionUsage = reactLocalStorage.get(LOCALSTORAGECONSTANT.SESSION_USAGE)
// export const deleteLocalSessionUsage = function ()  { reactLocalStorage.remove(LOCALSTORAGECONSTANT.SESSION_USAGE); return true}

function checkUserInfo () {
    if (reactLocalStorage.get(LOCALSTORAGECONSTANT.TOP_COURT_USER) == undefined) { return null }
    if (reactLocalStorage.get(LOCALSTORAGECONSTANT.TOP_COURT_USER) == "undefined") { return null }
    return reactLocalStorage.get(LOCALSTORAGECONSTANT.TOP_COURT_USER) || null
}
import { client, errorValidation } from "./service";


export const getOrderList = async function ({
  page = 0, limit = 0, status
}) {
  try {
    let response = await client.get(`/order/list?page=${page}&limit=${limit}&status=${status || ""}`);
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data };
  } catch (e) {
      // return { error: e.response.data.errorCode }
      return errorValidation(e)
  }
}

export const getAwaitingPaymentOrder = async function () {
  try {
    let response = await client.get(`/order-waiting-payment`);
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data };
  } catch (e) {
      // return { error: e.response.data.errorCode }
      return errorValidation(e)
  }
}

export const getOrderDetail = async function (orderId) {
  try {
      let response = await client.get(`/order-detail?orderId=${orderId}`);
      if (response.data.statusCode !== 200) { throw response.data.data; }
      let data = response.data.data;
      return { data };
  } catch (e) {
      // return { error: e.response.data.errorCode }
      return errorValidation(e)
  }
}

export const getItemOrderDetail = async function (orderId) {
  try {
      let response = await client.get(`/item-order-detail?orderId=${orderId}`);
      if (response.data.statusCode !== 200) { throw response.data.data; }
      let data = response.data.data;
      return { data };
  } catch (e) {
      // return { error: e.response.data.errorCode }
      return errorValidation(e)
  }
}

export const getScheduleList = async function () {
  try {
    let response = await client.get(`/schedule`);
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data };
  } catch (e) {
    // return { error: e.response.data.errorCode }
    return errorValidation(e)
  }
}

export const getScheduleBookingList = async function ({
  status
}) {
  try {
    let response = await client.get(`/schedule-bookings?status=${status || ""}`);
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data };
  } catch (e) {
    // return { error: e.response.data.errorCode }
    return errorValidation(e)
  }
}

import React, { useEffect, useState } from "react";

import tennis from "../../assets/logo/tennis.png"
// import { useNavigate } from "react-router-dom";

import MKButton from "components/MKButton";
import { Grid } from "@mui/material";
import { TCLabel } from "components/TopCourtComponents/Label/TopCourtLabel";
import { TCTitleLabel } from "components/TopCourtComponents/Label/TopCourtLabel";

import { setUserInfo } from "helper/localStorage";

// import { auth } from "../../service/firebase";
// import { signInWithPhoneNumber, RecaptchaVerifier } from "firebase/auth";
import { checkCredential, checkOTPService, login, sendOTPService } from "../../service/authService";

import OTP from "./OTP";
import Register from "./Register";

import MuiPhone from "components/TopCourtComponents/Input/CountryPickerInput";
import "react-international-phone/style.css";
import Loading from "components/TopCourtComponents/Loading/TCLoadingComponent";
import { Confirm } from "helper/showAlert";
import Password from "./Password";

function Login ({
    isOpen,
    reset,
    dismissPopup
}) {  

    // const navigate = useNavigate()

    const [ phoneNumber, setPhoneNumber ] = useState("+62");
    const [ currUserInfo, setCurrUserInfo ] = useState(null);
    const [ otp, setOTP ] = useState("");
    const [ showOTP, setShowOTP ] = useState(false);
    const [ showPassword, setShowPassword ] = useState(false);
    const [ showRegister, setShowRegister ] = useState(false);
    const [ loading, showLoading ] = useState(false);
    const [ loginPassword, setLoginPassword ] = useState(false);
    const [ forgetPass, setForgetPass ] = useState(false);
    const [ initialPassword, setInitialPassword ] = useState(false);
    const [ rerender, setRerender ] = useState(Date.now())

    useEffect(() => {
        // windowVerifier()
    }, [])

    
    useEffect(() => {
        setShowPassword(false)
        setLoginPassword(false)
        setInitialPassword(false)
        setShowRegister(false)
        setForgetPass(false)
        if (!showOTP) { setPhoneNumber("+62") }
        setOTP("")
    }, [isOpen])

    useEffect(() => {
        setShowOTP(showOTP && !reset)
        if (reset) { setPhoneNumber("+62") }
    }, [reset])

    useEffect(() => {
        if (currUserInfo && !currUserInfo.name) {
            setShowOTP(false)
            setShowRegister(true)
        }
        if (currUserInfo && !currUserInfo.password) {
            setShowOTP(false)
            setShowRegister(true)
        }
    }, [currUserInfo])

    useEffect(() => {
        if (otp.length == 6) {
            checkOTP()
        }
    }, [otp])

    const checkOTP = async () => {
        showLoading(true)
        // window.confirmationResult.confirm(otp).then( async (result) => {
        //     // User signed in successfully.
        //     // const user = result.user;
        //     // check if user exist or not in API, if name is empty then show register view
        //     let { data, error } = await login({
        //         phoneNumber: phoneNumber
        //     })
        //     if (error) { throw error }
        //     if (data) {
        //         if (!data.name || !data.password) {
        //             // setUserInfo(data)
        //             showLoading(false)
        //             setCurrUserInfo(data)
        //         } else {
        //             showLoading(false)
        //             if (initialPassword) {
        //                 setShowOTP(false)
        //                 setShowPassword(true)
        //             } else {
        //                 // navigate(0)
        //                 setUserInfo(data)
        //                 dismissPopup()
        //                 window.location.reload()
        //                 // setShowOTP(false)
        //                 // setShowPassword(true)
        //             }
        //         }
        //     } 
        // }).catch((error) => {
        //     showLoading(false);
        //     console.log(error.message)
        //     let firebaseErrorMapping = error.message
        //     if (firebaseErrorMapping.includes('auth/invalid-verification-code') || firebaseErrorMapping.includes('auth/code-expired')) {
        //         firebaseErrorMapping = "Wrong OTP. Please check your OTP or request again"
        //     }
        //     // alert(error.message)
        //     Confirm("", firebaseErrorMapping)
        // });
        let otpServiceData = await checkOTPService({
            phoneNumber: phoneNumber, 
            OTP: otp
        })
        console.log('here check OTP Data', otpServiceData.data, otpServiceData.error)
        showLoading(false)
        if (otpServiceData.error) { console.log(otpServiceData.error);
            Confirm("", otpServiceData.error)
        }
        if (otpServiceData.data) { console.log(otpServiceData.data);
            let { data, error } = await login({
                phoneNumber: phoneNumber
            })
            if (error) { throw error }
            if (data) {
                if (!data.name || !data.password) {
                    // setUserInfo(data)
                    showLoading(false)
                    setCurrUserInfo(data)
                } else {
                    showLoading(false)
                    if (initialPassword) {
                        setShowOTP(false)
                        setShowPassword(true)
                    } else {
                        // navigate(0)
                        setUserInfo(data)
                        dismissPopup()
                        window.location.reload()
                        // setShowOTP(false)
                        // setShowPassword(true)
                    }
                }
            } 
        }
    }

    // const windowVerifier = () => {
    //     try {
    //         if (!window.appVerifier && isOpen) {
    //             console.log("verifier")
    //             window.appVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
    //                 'size': 'invisible',
    //                 'callback': () => {
    //                     window.recaptchaVerifier.recaptcha.reset();
    //                 },
    //                 'expired-callback': () => {
    //                     window.recaptchaVerifier.recaptcha.reset();
    //                 },
    //                 'error-callback': () => {
    //                     window.recaptchaVerifier.recaptcha.reset();
    //                 },
    //             });
    //             window.appVerifier.render().then((widgetId) => {
    //                 window.recaptchaWidgetId = widgetId;
    //             });
    //         }
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    const credentialCheck = async () => {
        showLoading(true)
        try {
            let { data, error } = await checkCredential(phoneNumber)
            if (error) { 
                if (error.includes('blocked')) {
                    showLoading(false)
                    dismissPopup(true)
                    Confirm("", error)
                    return; 
                } else {
                    showLoading(false)
                    await requestOTP(); return; 
                }
            }
            showLoading(false)
            if (data.password == false || data.expired == true) {
                if (data.password == false) {  
                    setInitialPassword(true); 
                    setLoginPassword(false) 
                } else { setLoginPassword(true) }
                await requestOTP()
            } else if (data.password == true) {
                setLoginPassword(true)
                setShowPassword(true)
            }
        } catch (error) {
            await requestOTP()
        }
    }

    const requestOTP = async () => {
        setShowOTP(true)
        try {
            // showLoading(false)
            // var appVerifier = window.appVerifier
            // if (!appVerifier) {
            //     windowVerifier()
            //     appVerifier = window.appVerifier
            // }
            // signInWithPhoneNumber(auth, phoneNumber, appVerifier)
            // .then((confirmationResult) => {
            //     showLoading(false)
            //     window.confirmationResult = confirmationResult;
            // }).catch((error) => {
            //     showLoading(false)
            //     Confirm("", JSON.stringify(error))
            // });
            let { data, error } = await sendOTPService({
                phoneNumber: phoneNumber
            })
            showLoading(false);
            if (error) { console.log("error") }
            if (data) { console.log(data) }
        } catch(error) {
            showLoading(false)
        }
    }

    return (
        <Grid container justifyContent="center">
            {
                showOTP &&
                <Grid>
                    {/* <Grid sx={{
                        mb: 2
                    }}
                    onClick={() => {
                        setShowOTP(false)
                        setPhoneNumber("+62")
                    }}>
                        <TCLabel style={{fontSize: 10, fontWeight: 'bold'}}>Wrong number ? <span style={{textDecorationLine: 'underline'}}>Change number</span></TCLabel>
                    </Grid> */}
                    <OTP phoneNumber={phoneNumber} otp={otp} setOtp={setOTP} submit={checkOTP}/>
                </Grid>
            }
            {
                (!showOTP && !showRegister && !showPassword) &&
                <>
                    <Grid container xs={12} md={12} lg={12} item style={{marginTop: 8 }} justifyItems={"center"}>
                        <Grid item><img src={tennis} width={50} height={50}/></Grid>
                        <Grid item pt={1.8}><TCTitleLabel>Welcome</TCTitleLabel></Grid>
                    </Grid>
                    <Grid xs={12} md={12} lg={12} item style={{marginTop: 8 }}>
                        <TCLabel>Enter your mobile number</TCLabel> 
                    </Grid>
                    <Grid xs={12} md={12} lg={12} item style={{marginTop: 4 }} container>
                        <MuiPhone 
                            disabled={showPassword}
                            value={phoneNumber} onChange={setPhoneNumber}
                            enterAction={credentialCheck}
                        />
                    </Grid>
                    <Grid xs={12} md={12} lg={12} item sx={{mt: 4}} textAlign={"center"}>
                        <MKButton style={{width: '100%'}} color="primary"
                            disabled={phoneNumber.length < 12 || loading}
                            onClick={credentialCheck}>Continue</MKButton>
                    </Grid>
                </>
            }
            {
                ((showPassword && loginPassword) && !forgetPass) &&
                <>
                    <Grid container xs={12} md={12} lg={12} item style={{marginTop: 8 }} justifyItems={"center"}>
                        <Grid item><img src={tennis} width={50} height={50}/></Grid>
                        <Grid item pt={1.8}><TCTitleLabel>Welcome Back</TCTitleLabel></Grid>
                    </Grid>
                    <Grid xs={12} md={12} lg={12} item style={{marginTop: 8 }}>
                        <TCLabel>Mobile number</TCLabel> 
                    </Grid>
                    <Grid xs={12} md={12} lg={12} item style={{marginTop: 4 }} container>
                        <MuiPhone 
                            disabled={showPassword}
                            value={phoneNumber} onChange={setPhoneNumber}
                        />
                    </Grid>
                </>
            }
            {
                showRegister &&
                <Register userInfo={currUserInfo} dismissPopup={dismissPopup}/>
            }
            {
                showPassword &&
                <Password phoneNumber={phoneNumber} isLoginPassword={loginPassword} setForgetPass={(e) => {
                    console.log('forget pass', e)
                    setForgetPass(true)
                    setRerender(Date.now())
                }}/>
            }
            {
                loading &&
                <Loading/>
            }
            {/* <div id="recaptcha-container"/> */}
        </Grid>
    )
}

export default Login;
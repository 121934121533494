import { useEffect } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Kit 2 React themes
import theme from "assets/theme";
import Presentation from "layouts/pages/presentation";

// Material Kit 2 React routes
import routes from "tennisRoutes";
import Main from "pages/Main";

import { ToastContainer, Zoom } from 'react-toastify';
import 'react-calendar/dist/Calendar.css';
import 'react-toastify/dist/ReactToastify.css';
import "./scss/custom-style.css";
import Register from "pages/Auth/Register";

import { getCourtInfo, setCourtInfo } from "helper/localStorage";
import { getPlaceDefault } from "service/mainService";

export default function App() {
  const { pathname } = useLocation();
  const courtInfoJSON = getCourtInfo ? JSON.parse(getCourtInfo) : null  

  // calling API ?
  useEffect(() => {
    loadPlaceDetail()
  }, [])

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  // default get sempurna first
  const loadPlaceDetail = async () => {
    if (!courtInfoJSON) {
      let { data, error } = await getPlaceDefault();
      if ( error ) { console.log(error); return; }
      if ( data ) { setCourtInfo({
        _id: data._id || "",
        name: data.name,
        ...data
      }) }
      window.location.reload()
    } else {
      // console.log(courtInfoJSON)
    }
  }

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
        // render={(props) => <UserPage {...props} />} />;
      }

      return null;
    });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ToastContainer transition={Zoom} />
      <Routes>
        {getRoutes(routes)}
        <Route path="/" element={<Main />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      {/* <div id="recaptcha-container"/> */}
    </ThemeProvider>
  );
}

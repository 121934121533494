import React, { useEffect, useState, useRef, useCallback } from "react";
import { Grid, Box, Icon, Card, Button } from "@mui/material";
import { TCLabel, TCTitleLabel } from "components/TopCourtComponents/Label/TopCourtLabel";
import { color } from "helper/constant";
import QRCode from "react-qr-code";
import MKBox from "components/MKBox";
import moment from "moment";

import { courtTypeObj } from "helper/constant";
import OrderInformation from "components/TopCourtComponents/AlertInfo/OrderInfo";

import { useScreenshot } from 'use-react-screenshot';
import { timeStringFormat } from "helper/constant";
import Barcode from "react-barcode";

function QRPage ({
    qrValue,
    courtInfo,
    orderInfo
}) {

    const ref = useRef(null)
    const [image, takeScreenshot] = useScreenshot()
    const [downloadQR, setDownloadQR] = useState(false)
    const getImage = () => takeScreenshot(ref.current)

    useEffect(() => {
        if (downloadQR == true) {
            if (image) {
                shareQR()
            } else { 
                getImage()
            }
        }
    }, [downloadQR])

    useEffect(() => {
        if (image) {
            shareQR()
        }
    }, [image])

    const shareQR = async () => {
        try {
            if (image && downloadQR) {
                var a = document.createElement("a"); //Create <a>
                // a.href = "data:image/png;base64," + image; //Image Base64 Goes here
                a.href = image; //Image Base64 Goes here
                if (orderInfo.orderType != "rent" && orderInfo.orderType != "sell") {
                    a.download = `${courtTypeObj[courtInfo.courtType]} Court 0${courtInfo.name}.png`; //File name Here    
                } else {
                    a.download = `${orderInfo.bookingId}.png`
                }
                a.click();
                setDownloadQR(false)
            }
        } catch (error) {
            console.log('here is error', error)
        }
    }

    return (
        <Grid container display={"flex"} justifyContent={"center"}>
            <Grid ref={ref} container zIndex={2}>
                {
                    (orderInfo && (orderInfo.orderType != "rent" && orderInfo.orderType != "sell")) &&
                    <>
                        <Grid width={"100%"} item display={"flex"} justifyContent={"center"}>
                            {
                                courtInfo && <TCTitleLabel style={{fontWeight: 'bold'}}>{`${courtTypeObj[courtInfo.courtType]} Court 0${courtInfo.name}` }</TCTitleLabel>
                            }
                        </Grid>
                        <Grid width={"100%"} item display={"flex"} justifyContent={"center"}>
                            {
                                courtInfo && <TCTitleLabel style={{fontSize: 12, fontWeight: 'bold'}}>Scan QR Code to enter the court</TCTitleLabel>
                            }
                        </Grid>
                    </>
                }
                {
                    (orderInfo && (orderInfo.orderType == "rent" || orderInfo.orderType == "sell")) &&
                    <>
                        <Grid width={"100%"} item display={"flex"} justifyContent={"center"}>
                            {
                                (courtInfo && orderInfo.orderType == "rent") && <TCTitleLabel style={{fontWeight: 'bold'}}>{`${courtTypeObj[courtInfo.courtType]} Court 0${courtInfo.name}` }</TCTitleLabel>
                            }
                        </Grid>
                        <Grid width={"100%"} item display={"flex"} justifyContent={"center"}>
                            {
                                courtInfo && <TCTitleLabel style={{fontSize: 12, fontWeight: 'bold'}}>Scan Barcode to pick up item</TCTitleLabel>
                            }
                        </Grid>
                    </>
                }
                <Grid mt={1} width={"100%"} item display={"flex"} justifyContent={"center"}>
                    {
                        orderInfo && <Grid pl={ (orderInfo.orderType == "rent" || orderInfo.orderType == "sell") ? 1 : 2} pr={2} xs={12} lg={12} item container>
                            {/* <Grid item xs={3.5}> */}
                            <Grid item>
                                <TCLabel bold>{orderInfo.date ? `${moment(orderInfo.date).format("DD/MM/YYYY")} ${orderInfo.eventName ? orderInfo.eventName : ''}` : ``}</TCLabel>
                            </Grid>
                            {/* <Grid item container xs pt={0.15}> */}
                            <Grid item container>
                                {
                                    orderInfo.hours.map((h, index) => {
                                        return <Grid item>
                                            <TCLabel style={{fontSize: 12}}>{`${h} - ${timeStringFormat(Number(h.split(':')[0]) + 1)}`}{index < (orderInfo.hours.length - 1) ? <span style={{padding: 3, fontFamily: 'roboto', fontWeight: 50}}>{"|"}</span> : ""}
                                            {/* ${index < (v.hours.length - 1) ? " | " : ""} */}
                                            </TCLabel>
                                        </Grid>
                                    })
                                }
                            </Grid>
                            {/* <TCLabel inheritColor={true} style={{fontSize: 12}}>Details :</TCLabel> */}
                        </Grid>
                    }
                </Grid>
                {
                    orderInfo &&
                    <Grid sx={{
                        mt: (orderInfo.orderType == "rent" || orderInfo.orderType == "sell") ? -3 : 1,
                        mb: (orderInfo.orderType == "rent" || orderInfo.orderType == "sell") ? -2 : 2,
                        pl: (orderInfo.orderType == "rent" || orderInfo.orderType == "sell") ? 1 : 0,
                        pr: (orderInfo.orderType == "rent" || orderInfo.orderType == "sell") ? 1 : 0
                    }} width={"100%"} item display={"flex"} justifyContent={"center"}>
                        {
                            (orderInfo.orderType != "rent" && orderInfo.orderType != "sell") && <QRCode value={qrValue} style={{width: '100%'}}/>
                        }
                        {
                            (orderInfo.orderType == "rent" || orderInfo.orderType == "sell") && <Barcode value={qrValue} displayValue={false}/> 
                        }
                    </Grid>
                }
                <Grid width={"100%"} item>
                    <OrderInformation orderInfo={orderInfo} takeScreenshot={downloadQR} mt={0} p={1}/>
                </Grid>
            </Grid>
            <Grid mt={2} width={"100%"} item display={"flex"} justifyContent={"center"} onClick={() => {
                setDownloadQR(true)
            }}>
                {
                    courtInfo && 
                    <TCLabel>
                        Download <Icon style={{paddingTop: 2}}>download</Icon>
                    </TCLabel>
                }
            </Grid>
        </Grid>
    )
}

export default QRPage
import React from "react"
import { Grid } from "@mui/material"
import { TCTitleLabel, TCLabel } from "components/TopCourtComponents/Label/TopCourtLabel"
import { color } from "helper/constant"

function DialogHeader ({
    title,
    closeDialog
}) {
    return (
        <Grid item xs={12} container pb={1} alignItems={"center"} >
            <Grid xs={11} item>
                {/* <TCTitleLabel>{title}</TCTitleLabel> */}
                <TCLabel bold style={{
                    fontSize: 15
                }}>{title}</TCLabel>
            </Grid>
            <Grid xs={1} item textAlign={"right"}>
                <span onClick={closeDialog}>
                    <TCLabel bold style={{color: color.blueIndoor_2}}>X</TCLabel>
                </span>
            </Grid>
        </Grid>
    )
}

export default DialogHeader
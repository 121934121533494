import React, { useEffect, useState } from "react"
import { TCLabel } from "../Label/TopCourtLabel"
import { Grid, Icon } from "@mui/material"
import { color } from "helper/constant"
import ImagesDetail from "../Images/ImagesDetail"

function OrderInformation ({
    orderInfo,
    takeScreenshot,
    p=2,
    mt=-1
}) {

    const [ openImageDetail, setOpenImageDetail ] = useState({
        open: false
    })
    const [ serviceType, setServiceType ] = useState({
        rallyPartner: false,
        ballBoy: false,
        coach: false,
        rent: false,
        sell: false
    })

    useEffect(() => {
       if (orderInfo && orderInfo.productOrder && orderInfo.productOrder.length > 0 ) {
            let rallyPartner = false
            let ballBoy = false
            let coach = false
            let rent = false
            let sell = false
            for (let i = 0; i < orderInfo.productOrder.length; i ++) {
                if (orderInfo.productOrder[i].productInfo.category == "Rally Partner") {
                    rallyPartner = true
                }
                if (orderInfo.productOrder[i].productInfo.category == "Ball Boy") {
                    ballBoy = true
                }
                if (orderInfo.productOrder[i].productInfo.category == "Development Coach") {
                    coach = true
                }
                if (orderInfo.productOrder[i].productInfo.category == "Lead Coach") {
                    coach = true
                }
                if (orderInfo.productOrder[i].productInfo.category == "Head Coach") {
                    coach = true
                }
                if (orderInfo.productOrder[i].productInfo.category == "Senior Coach") {
                    coach = true
                }
            }
            if (orderInfo.orderType == "rent") {
                rent = true
            }
            if (orderInfo.orderType == "sell") {
                sell = true
            }
            setServiceType({
                rallyPartner,
                ballBoy,
                coach,
                rent,
                sell
            })
       } else {
            if (orderInfo.orderType == "rent" || orderInfo.orderType == "sell") {
                setServiceType({
                    rallyPartner: false,
                    ballBoy: false,
                    coach: false,
                    rent: orderInfo.orderType == "rent",
                    sell: orderInfo.orderType == "sell"
                })
            }
       }
    }, [])
    
    return (
        <Grid p={takeScreenshot ? 2 : p} pt={0} mt={mt}>
            {
                !takeScreenshot &&
                <Grid>
                    {/* {
                        (orderInfo && orderInfo.productOrder && orderInfo.productOrder.length > 0 && 
                            (orderInfo.productOrder[0].productInfo.category == "Rally Partner" || orderInfo.productOrder[0].productInfo.category == "Ball Boy")) &&
                        <TCLabel subtitle><span><Icon>check</Icon></span> {
                            orderInfo.productOrder[0].productInfo.category == "Rally Partner" ?
                            "1 rally partner is limited to 2 players only."
                            : orderInfo.productOrder[0].productInfo.category == "Ball Boy" ?
                            "1 ball boy is exclusive for 1 court only."
                            : ''
                        }</TCLabel>
                    } */}
                    {
                        (serviceType.rallyPartner) &&
                        <TCLabel subtitle><span><Icon>check</Icon></span> {"1 rally partner is limited to 2 players only."}</TCLabel>
                    }
                    {
                        (serviceType.ballBoy) &&
                        <TCLabel subtitle><span><Icon>check</Icon></span> { "1 ball boy is exclusive for 1 court only."}</TCLabel>
                    }
                    {
                        (serviceType.coach) &&
                        <TCLabel subtitle><span><Icon>check</Icon></span> { "1 coach is limited to 4 players only."}</TCLabel>
                    }
                    {
                        (serviceType.rent) &&
                        <TCLabel subtitle><span><Icon>check</Icon></span> { "Return the equipment when session ends and in its original condition. You will be charge for the full retail price for any damages while in your possession."}</TCLabel>
                    }
                    {
                        (serviceType.sell) &&
                        <TCLabel subtitle><span><Icon>check</Icon></span> { "No Refund & No Exchange."}</TCLabel>
                    }
                    {
                        (!serviceType.sell) &&
                        <>
                            <TCLabel subtitle><span><Icon>check</Icon></span> No Refund & No Reschedule.</TCLabel>
                            <TCLabel subtitle><span><Icon>check</Icon></span> Use tennis court at your own risk.</TCLabel>
                            <TCLabel subtitle><span><Icon>check</Icon></span> Prepare Check-Out 5 mins prior to session changeover.</TCLabel>
                        </>
                    }
                    <TCLabel subtitle><span><Icon>check</Icon></span> By completing payment, you agree to STC Terms & Conditions and <span style={{cursor: 'pointer', textDecoration: 'underline'}} onClick={() => {
                        setOpenImageDetail({
                            open: true
                        })
                    }}>Court Rules</span>.</TCLabel>
                </Grid>
            }
            {
                takeScreenshot &&
                <Grid>
                    {
                        (serviceType.rallyPartner) &&
                        <TCLabel subtitle><span><Icon>check</Icon></span> {"1 rally partner is limited to 2 players only."}</TCLabel>
                    }
                    {
                        (serviceType.ballBoy) &&
                        <TCLabel subtitle><span><Icon>check</Icon></span> { "1 ball boy is exclusive for 1 court only."}</TCLabel>
                    }
                    {
                        (serviceType.coach) &&
                        <TCLabel subtitle><span><Icon>check</Icon></span> { "1 coach is limited to 4 players only."}</TCLabel>
                    }
                    {
                        (serviceType.rent) &&
                        <TCLabel subtitle><span><Icon>check</Icon></span> { "Return the equipment when session ends and in its original condition. You will be charge for the full retail price for any damages while in your possession."}</TCLabel>
                    }
                    {
                        (serviceType.sell) &&
                        <TCLabel subtitle><span><Icon>check</Icon></span> { "No Refund & No Exchange."}</TCLabel>
                    }
                    {
                        (!serviceType.sell) &&
                        <>
                            <TCLabel subtitle><span><Icon>check</Icon></span> No Refund & No Reschedule.</TCLabel>
                            <TCLabel subtitle><span><Icon>check</Icon></span> Use tennis court at your own risk.</TCLabel>
                            <TCLabel subtitle><span><Icon>check</Icon></span> Prepare Check-Out 5 mins prior to session changeover.</TCLabel>
                        </>
                    }
                    <TCLabel style={{fontSize: 8}}><span><Icon>check</Icon></span> By completing payment, you agree to STC Terms & Conditions and <span style={{cursor: 'pointer', textDecoration: 'underline'}}>Court Rules</span>.</TCLabel>
                </Grid>
            }
            <ImagesDetail
                disableZoom={true}
                open={openImageDetail.open}
                setOpen={(e) => {
                    setOpenImageDetail({
                        open: e,
                        image: null
                    })
                }}
                src={'https://top-court.s3.ap-southeast-1.amazonaws.com/topcourt-staging/tennis_courts_rules.jpg'}
            />
        </Grid>
    )
}

export default OrderInformation
import React, { useEffect, useState } from 'react';

import DefaultNavbar from '../examples/Navbars/DefaultNavbar';
import MKBox from "components/MKBox";
import bannerImages from "../assets/images/tennis-court.jpg";
import MainMenu from './MainMenu';
import TopCourtPopOver from '../components/TopCourtComponents/PopOverComponent/TopCourtPopOver';
import { Box, Grid, Stack, Tab, Tabs } from '@mui/material';

import { getCourtInfo, setCourtInfo } from 'helper/localStorage';

import { getPlaces, getPlaceDetail } from 'service/mainService';

import { TCLabel } from 'components/TopCourtComponents/Label/TopCourtLabel';
import { TCInput } from 'components/TopCourtComponents/Input/TopCourtInput';
import OperationalHours from './PagesComponents/OperationalHours';
import Loading from 'components/TopCourtComponents/Loading/TCLoadingComponent';

// import { requestForToken } from '../helper/firebase';
import { isPhone } from 'helper/constant';
import { UseWindowDimension } from 'helper/windowResize';
import LocationOnTwoToneIcon from '@mui/icons-material/LocationOnTwoTone';
import { color } from 'helper/constant';
import { getMe } from 'service/authService';
import { setUserInfo } from 'helper/localStorage';
import { Confirmation } from 'helper/showAlert';

import { Slide } from "react-slideshow-image";
import 'react-slideshow-image/dist/styles.css';
import { useNavigate } from 'react-router-dom';

import { memorizeTabs } from "helper/tabsMemo";


function Main ({

}) {

    const navigate = useNavigate()
    
    const [ place, setPlace ] = useState(getCourtInfo ? JSON.parse(getCourtInfo) : null)
    const [ loading, setLoading ] = useState(false)
    const [ rerender, setReRender ] = useState(Date.now)
    // const [ loadingTimer, setLoadingTimer ] = useState(true)
    const [ tab, setTab ] = useState(0)
    const [ showLogin, setShowLogin ] = useState(false)

    // load list courts
    const [ places, setPlaces ] = useState([
        {
            name: 'Sempurna Tennis Court'
        },
        {
            name: 'Ayahanda Tennis Court'
        }
    ])

    useEffect(() => {
        loadInformation()
        // requestForToken()
        if (place) {
            loadPlaceDetail(place ? place._id : "653f5d9006520fccd4ab4604")
        }
    }, [])

    const loadInformation = async () => {
        setLoading(true)
        let { data, error } = await getPlaces()
        if ( error ) { setLoading(false) }
        if ( data ) { setPlaces(data); }
        let userInfo = await getMe()
        if (userInfo.data) { setUserInfo(userInfo.data) }
    }

    const loadPlaceDetail = async (id) => {
        let { data, error } = await getPlaceDetail(id)
        if ( error ) { setLoading(false) }
        if ( data ) { setPlace(data); setCourtInfo(data); setLoading(false) }
    }

    return (
        <>
            <DefaultNavbar sticky hideArrow={true} showAvatar={true} showNotification={true}
                showLogin={showLogin} setShowLogin={setShowLogin}
            />
            {/* <TopCourtPopOver maxWidth={250} titleComponent={<DefaultNavbar sticky hideArrow={false}/>}>
                <Grid>
                    <Stack direction={"column"}>
                        {
                            places.map( value => {
                                return (
                                    <Grid sx={{
                                        // pt: 2
                                        p: 1
                                    }} onClick={() => {
                                        setCourtInfo({
                                            _id: value._id || "",
                                            name: value.name,
                                            ...value
                                        })
                                        window.location.reload()
                                    }}>
                                        <TCLabel>{value.name}</TCLabel>
                                    </Grid>
                                )
                            })
                        }
                    </Stack>
                </Grid>
            </TopCourtPopOver> */}
            <Grid container padding={0}>
                <Grid item xs={12}>
                    <Slide
                        indicators={true}
                        duration={3000}
                        autoplay={true}
                        transitionDuration={200}
                    >
                        {
                            place && place.photos.map( (value, index) => {
                                return (
                                    <MKBox
                                        minHeight={window.innerWidth >= 444 ? 444/1.78 : window.innerWidth / 1.78}
                                        // maxHeight={}
                                        onClick={() => {
                                            if (index == 0) {
                                                navigate('/class')
                                            }
                                            if (index == 1) {
                                                memorizeTabs('add-ons', 1)
                                                navigate('/add-ons')
                                            }
                                        }}
                                        width="100%"
                                        sx={{
                                            backgroundImage: `url(${value})`,
                                            backgroundSize: "contain",
                                            backgroundRepeat: 'no-repeat',
                                            backgroundPosition: "center",
                                            display: "grid",
                                            placeItems: "center",
                                        }}
                                    />
                                )
                            })
                        }
                    </Slide>
                    {/* <MKBox
                        minHeight={"28vh"}
                        width="100%"
                        sx={{
                            backgroundImage: `url(${(place && (place.photos && place.photos.length > 0)) ? place.photos[0] : bannerImages})`,
                            backgroundSize: "cover",
                            backgroundPosition: "top",
                            display: "grid",
                            placeItems: "center",
                        }}
                    /> */}
                </Grid>
                <Grid item xs={12}>
                    <MKBox sx={{
                        p: 1,
                        // backgroundColor: '#F8FAFC',
                        backgroundColor: '#FFFFFF',
                        // borderBottom: '0.01rem solid #34476733'
                    }}>
                        {/* <Tabs value={tab} onChange={(e, newValue) => {
                            setTab(newValue)
                        }}>
                            <Tab label={<TCLabel>Address</TCLabel>}/>
                            <Tab label={<TCLabel>Operational Hours</TCLabel>}/>
                        </Tabs>
                        {
                            tab == 0 &&
                            <TCLabel>{(place && place.address) ? place.address : '-'}</TCLabel>
                        }
                        {
                            tab == 1 &&
                            <OperationalHours data={place}/>
                        } */}
                        {/* operational hours component */}
                        <Grid container alignItems={"center"} style={{
                            cursor: 'pointer'
                        }} onClick={() => {
                            Confirmation("", "We will redirect you to Google Maps", () => {}, () => {
                                window.location.href = "https://www.google.com/maps/search/?api=1&query=Sempurna+Tennis+Center"
                            })
                        }}>
                            <Grid item>
                                <LocationOnTwoToneIcon sx={{
                                    color: (place && place.geoLocation) ? color.primary : color.grey
                                }}/>
                            </Grid>
                            <Grid item> 
                                <TCLabel style={{
                                    fontWeight: 'bold',
                                    marginBottom: 4
                                }}>{(place && place.address) ? place.address : '-'}</TCLabel>
                            </Grid>
                        </Grid>
                        <Grid>
                            <TCLabel>Opening Hours :</TCLabel>
                            <Grid container textAlign={"left"}>
                                <Grid item xs={12}>
                                    <TCLabel>Monday - Sunday 06.00 - 23.00 </TCLabel>
                                </Grid>
                                {/* <Grid item xs={6}>
                                    <TCLabel>Closed Tuesday</TCLabel>
                                </Grid> */}
                            </Grid>
                        </Grid>
                        {/* <OperationalHours data={place}/> */}
                    </MKBox>
                    <MainMenu setShowLogin={setShowLogin} />
                </Grid>
                <Grid xs={12} sx={{
                    position: 'fixed',
                    paddingTop: 1,
                    paddingBottom: 1,
                    bottom: 0,
                    right: 0,
                    left: 0,
                    backgroundColor: "#FFFFFF"
                }} textAlign={"center"}>
                    <TCLabel style={{fontSize: 10, color: color.grey}}>{"Copyright © 2024 Top-Court | All Rights Reserved."}</TCLabel>
                </Grid>
            </Grid>
            {
                // (loading || loadingTimer) &&
                loading &&
                <Loading/>
            }
            <UseWindowDimension forceCheckWidth={() => setReRender(Date.now)} />
        </>
    )
}

export default Main
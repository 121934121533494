import React from "react";
import * as ReactDOMClient from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import './scss/custom-style.css';

import App from "App";
import { Container } from "@mui/material";

window.Buffer = window.Buffer || require("buffer").Buffer;

const container = document.getElementById("root");

// Create a root.
const root = ReactDOMClient.createRoot(container);

root.render(
    <BrowserRouter>
      <Container sx={{ height: '100% !important', padding: '0px !important'}}>
        <App />
      </Container>
    </BrowserRouter>
);

import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';

import { Grid, Card, Avatar, Popover, Icon } from "@mui/material";

import { color } from "helper/constant";
import { TCLabel, TCTitleLabel } from "components/TopCourtComponents/Label/TopCourtLabel";

import Male from "@mui/icons-material/Male";
import Female from "@mui/icons-material/Female";
import moment from "moment";
import { getUserName } from "helper/user";
import MKButton from "components/MKButton";

import clubPlaceholder from "../../../assets/club-placeholder.png";
import { getUserAvatar } from "helper/user";
import { WhatsApp } from "@mui/icons-material";
import ImagesDetail from "../Images/ImagesDetail";
import { forceGMT7 } from "helper/time";

function UserCard ({
  value,
  detail = false,
  hideClub = false,
  clubCard = false,
  classCard = false,
  clubLeader, clubCoordinator, userAsLeader, userAsCoordinator,
  showContact = false,
  kickUser = () => {},
  promoteCoordinator = () => {},
  promoteLeader = () => {},
  demoteCoordinator = () => {},
  mb = 2,
  classInfo
}) {

    const navigate = useNavigate()

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    
    const open = anchorEl ? true : false;
    const id = open ? 'simple-popover' : undefined;

    const [openImageDetail, setOpenImageDetail] = useState({
        open: false,
        image: null
    })

    return (
        <Card sx={{padding: 2, mb: mb}} onClick={() => {
            // navigate(`/player/${value._id}`)
        }}>
            <Grid container alignItems={"center"} spacing={1}>
                <Grid item onClick={(e) => {
                    e.stopPropagation(true)
                    setOpenImageDetail({
                        open: true,
                        image: getUserAvatar(value)
                    })
                }}>
                    <Avatar src={getUserAvatar(value)}/>
                </Grid>    
                <Grid item xs={clubCard ? 6 : 7}>
                    <TCLabel bold>{getUserName(value)}{value.gender == "male" ? <Male style={{color: color.blue}}/> : <Female style={{color: 'pink'}}/>}</TCLabel>
                    {
                        clubCard ? 
                        <TCLabel subtitle>Joined: {forceGMT7(value.clubJoinDate, "DD/MM/YYYY")}</TCLabel>
                        :
                        classCard ?
                        <TCLabel subtitle>Joined: {forceGMT7(value.appliedDate, "DD/MM/YYYY")}</TCLabel>
                        :
                        <TCLabel subtitle>Joined: {forceGMT7(value.createdDate, "DD/MM/YYYY")}</TCLabel>
                    }                        
                    {
                        !hideClub &&
                        <Grid item container alignItems={"flex-end"} spacing={0.5}>
                            {/* <TCLabel>{value.status || "Regular"}</TCLabel> */}
                            {
                                value.club ?
                                <>
                                    <Grid item >
                                        <Avatar sx={{width: 20, height: 20}} src={value.club ? value.club.logo : clubPlaceholder} />
                                    </Grid>
                                    <Grid item >
                                        <TCLabel subtitle>{value.club ? value.club.name : "No Club"}</TCLabel>
                                    </Grid>
                                </>
                                :
                                <Grid item >
                                    <TCLabel subtitle>{"No Club"}</TCLabel>
                                </Grid>
                            }
                        </Grid>
                    }
                </Grid>
                {
                    clubCard &&
                    <Grid item xs container alignItems={"center"} textAlign={"right"}>
                        {
                            userAsLeader &&
                            <Grid item xs textAlign={"right"}>
                                <TCLabel bold>Leader</TCLabel>
                            </Grid>
                        }
                        {
                            userAsCoordinator &&
                            <Grid item xs={(clubCoordinator || (!clubLeader && !clubCoordinator)) ? 12 : 9} textAlign={"right"}>
                                <TCLabel bold>Coordinator</TCLabel>
                            </Grid>
                        }
                        {
                            (clubLeader && !userAsLeader) &&
                            <Grid item xs textAlign={"right"}>
                                {/* <TCLabel>{value.status || "Regular"}</TCLabel> */}
                                {/* <MKButton color="white" style={{
                                    border: `1px solid ${color.blueIndoor_1}`
                                }} onClick={(e) => {
                                    e.stopPropagation(true)
                                    // kickUser()
                                    handleClick(e)
                                }}>:</MKButton> */}
                                <Icon style={{
                                    color: color.primary, marginTop: 8
                                }}
                                    onClick={(e) => {
                                        e.stopPropagation(true)
                                        // kickUser()
                                        handleClick(e)
                                    }}
                                >more_vert</Icon>
                                <Popover
                                    id={id}
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                >
                                    <Grid p={2} container sx={{background: 'white'}}>
                                        <Grid pt={1} pb={1} item xs={12} sx={{borderBottom: `0.1px solid ${color.grey}`}}
                                            onClick={() => {
                                                promoteLeader()
                                            }}
                                        >
                                            <TCLabel>{"Promote Leader"}</TCLabel>
                                        </Grid>
                                        <Grid pt={1} pb={1} item xs={12} sx={!userAsCoordinator ? {
                                            borderBottom: `0.1px solid ${color.grey}`, color: color.primary
                                        } 
                                            : { color: color.pink }}
                                            onClick={() => {
                                                if (userAsCoordinator) { demoteCoordinator() }
                                                else { promoteCoordinator() }
                                            }}
                                        >
                                            <TCLabel inheritColor>{!userAsCoordinator ? "Promote Coordinator" : "Demote User"}</TCLabel>
                                        </Grid>
                                        {
                                            !userAsCoordinator &&
                                            <Grid pt={1} item xs={12} onClick={() => {
                                                kickUser()
                                            }}>
                                                <TCLabel style={{color: color.pink}}>{"Kick"}</TCLabel>
                                            </Grid>
                                        }
                                    </Grid>
                                </Popover>
                            </Grid>
                        }
                        {
                            (clubCoordinator && !userAsLeader && !userAsCoordinator) &&
                            <Grid item xs textAlign={"right"}>
                                {/* <TCLabel>{value.status || "Regular"}</TCLabel> */}
                                {/* <MKButton color="white" style={{
                                    border: `1px solid ${color.blueIndoor_1}`
                                }} onClick={(e) => {
                                    e.stopPropagation(true)
                                    // kickUser()
                                    handleClick(e)
                                }}>:</MKButton> */}
                                <Icon style={{
                                    color: color.primary, marginTop: 8
                                }}
                                    onClick={(e) => {
                                        e.stopPropagation(true)
                                        // kickUser()
                                        handleClick(e)
                                    }}
                                >more_vert</Icon>
                                <Popover
                                    id={id}
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                >
                                    <Grid p={2} container sx={{background: 'white'}}>
                                        {
                                            !userAsCoordinator &&
                                            <Grid pt={1} item xs={12} onClick={() => {
                                                kickUser()
                                            }}>
                                                <TCLabel style={{color: color.pink}}>{"Kick"}</TCLabel>
                                            </Grid>
                                        }
                                    </Grid>
                                </Popover>
                            </Grid>
                        }
                    </Grid>
                }
                {
                    showContact &&
                    <Grid item xs textAlign={"right"} justifyContent={"flex-end"} alignItems={"flex-end"}>
                        {/* <TCLabel>{value.status || "Regular"}</TCLabel> */}
                        <Grid item xs={12} pb={1}>
                            <TCLabel subtitle bold>{classInfo.pax} pax</TCLabel>
                        </Grid>
                        <Grid item container xs={12}>
                            <Grid item container xs={6} justifyContent={"center"} spacing={0.5} onClick={(e) => {
                                e.stopPropagation()
                                let replacePhoneNumber = value.phoneNumber ? value.phoneNumber.substring(1) : ""
                                console.log(`https://wa.me/${replacePhoneNumber}`)
                                window.open(`https://wa.me/${replacePhoneNumber}`, '_blank')
                            }}>
                                <Grid item>
                                    <WhatsApp style={{color: '#25D366', fontWeight: 'bold'}}/>
                                </Grid>
                            </Grid>
                            <Grid item container xs={6} justifyContent={"center"} spacing={0.5} onClick={(e) => {
                                e.stopPropagation()
                                console.log(value.phoneNumber)
                                window.location.href=`tel:${value.phoneNumber}`
                            }} >
                                <Grid item sx={{mt: 0.1, color: color.primary}}>
                                    <TCLabel style={{fontSize: 20}}>
                                        <Icon>phone</Icon>
                                    </TCLabel>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                }
                {
                    (!showContact && classInfo) &&
                    <Grid item xs textAlign={"right"} justifyContent={"flex-end"} alignItems={"flex-end"}>
                        {/* <TCLabel>{value.status || "Regular"}</TCLabel> */}
                        <Grid item xs={12} pb={1}>
                            <TCLabel subtitle bold>{classInfo.pax} pax</TCLabel>
                        </Grid>
                    </Grid>
                }
            </Grid>
            { detail && 
                <Grid container mt={1} style={{borderTop: '0.1px solid #0000001A'}}>
                <Grid item mt={1} xs={12} textAlign={"center"}>
                    <Grid container>
                        <Grid item xs={3} mr={2}>
                            <TCLabel subtitle>Tournament</TCLabel>
                            <TCLabel>15</TCLabel>
                            {/* <TCLabel>Grand Opening Cup</TCLabel>
                            <TCLabel subtitle>Round of 16</TCLabel> */}
                        </Grid>
                        <Grid style={{borderRight: '0.1px solid #0000001A'}} />
                        <Grid item xs={3} ml={2} mr={2}>
                            <TCLabel subtitle>Season Points</TCLabel>
                            <TCLabel>20,000 pts</TCLabel>
                        </Grid>
                        <Grid style={{borderRight: '0.1px solid #0000001A'}} />
                        <Grid item xs={3} ml={2}>
                            <TCLabel subtitle>Highest Points</TCLabel>
                            <TCLabel>20,000 pts</TCLabel>
                        </Grid>
                    </Grid>
                </Grid>   
            </Grid>
            }
            <ImagesDetail
                open={openImageDetail.open}
                setOpen={(e) => {
                    setOpenImageDetail({
                        open: e,
                        image: null
                    })
                }}
                src={openImageDetail.image}
            />
        </Card>
    )
}

export default UserCard
import React, { useState } from "react";
import { AppBar, Tab, Tabs, Box } from "@mui/material";
import { TCLabel } from "components/TopCourtComponents/Label/TopCourtLabel";
import MKTypography from "components/MKTypography";
import UseValuePack from "./UseValuePack";
import UseCoupon from "./UseCoupon";
import { color } from "helper/constant";


function CustomTabPanel(props) {
    const { children, value, index, boxPadding = 3, height = 100, mt = 0, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ mt, p: boxPadding, height }}>
            <MKTypography>{children}</MKTypography>
          </Box>
        )}
      </div>
    );
}

function BookingSummaryBottomSheet ({
    calculateSummary,
    // coupon
    setCoupon,
    coupon,
    // valuepack,
    user,
    booking,
    setOpenModal,
    packUsageInfo,
    setPackUsageInfo,
    setSelectValuePack,
    // active deactive
    couponUsed,
    valuePackUsed,
    loading,
    setLoading,
    // navigation
    navigate
}) {

    const [ activeTab, setActiveTab ] = useState( couponUsed ? 1 : 0)

    return (
        <>
            <Tabs value={activeTab} sx={{m: 2, mb: 0}} 
              TabIndicatorProps={{
                  style: {
                      backgroundColor: color.blueIndoor_2
                  }
              }}
              onChange={(event, newValue) => {
                  setActiveTab(newValue)
              }}>
                <Tab disabled={couponUsed} label={<TCLabel style={ couponUsed ? {
                  color: 'rgb(200, 200, 200)'
                } : activeTab == 0 ? {color: "white"} : {}}>Use Value Pack</TCLabel>} />
                <Tab disabled={valuePackUsed} label={<TCLabel style={ valuePackUsed ? {
                  color: 'rgb(200, 200, 200)'
                } : activeTab == 1 ? {color: "white"} : {}}>Apply Promo Code</TCLabel>} />
            </Tabs>
            <CustomTabPanel mt={1} boxPadding={0} value={activeTab} index={0}>
                <UseValuePack 
                  calculateSummary={calculateSummary}
                  user={user} booking={booking} coupon={coupon}
                  setOpenModal={setOpenModal} packUsageInfo={packUsageInfo}
                  setPackUsageInfo={setPackUsageInfo} setSelectValuePack={setSelectValuePack}
                  navigate={navigate}
                />
            </CustomTabPanel>
            <CustomTabPanel mt={1} boxPadding={0} value={activeTab} index={1}>
                <UseCoupon calculateSummary={calculateSummary} booking={booking} coupon={coupon} setCoupon={setCoupon}
                  loading={loading}
                  setLoading={setLoading}
                />
            </CustomTabPanel>   
        </>
    )
}

export default BookingSummaryBottomSheet
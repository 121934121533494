import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { AppBar, Avatar, Box, Card, CardContent, Grid, Icon } from "@mui/material";
import TCNavigationBar from "components/TopCourtComponents/NavigationBar/TopCourtNavigationBar";
import EmptyData from "components/TopCourtComponents/Placeholder/EmptyData";

import { getMe } from 'service/authService';

import Loading from "components/TopCourtComponents/Loading/TCLoadingComponent";
import { getClubs, applyClubMember } from "service/clubService";
import { TCTitleLabel, TCLabel } from "components/TopCourtComponents/Label/TopCourtLabel";
import MKButton from "components/MKButton";
import moment from "moment";
// import academy from "../../assets/logo/academy.png";
import clubPlaceholder from "../../assets/club-placeholder.png";
import { getUserInfo } from "helper/localStorage";
import { Confirm } from "helper/showAlert";
import { getUserName } from "helper/user";
import { color } from "helper/constant";
import { Confirmation } from "helper/showAlert";
import SlideAlert from "components/SlideInAlert/SlideAlert";
import Login from "pages/Auth/Login";
import TCTabs from "components/TopCourtComponents/Tabs/TopCourtTabs";
import MKTypography from "components/MKTypography";
import Club from "./Club";
import SlideDialog from "components/SlideDialog/SlideDialog";
import DialogHeader from "components/SlideDialog/DialogHeader";
import AddClub from "./AddClub";
import { memorizeTabs, getTabMemos } from "helper/tabsMemo";
import { loadImages } from "helper/loadImages";

// get current user

function CustomTabPanel(props) {
    const { children, value, index, boxPadding = 3, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: boxPadding }}>
            <MKTypography>{children}</MKTypography>
          </Box>
        )}
      </div>
    );
}

function Clubs ({
    isPopup = false,
    closeDialog
}) {

    const navigate = useNavigate()
    const isLogin = getUserInfo ? JSON.parse(getUserInfo) : null

    const [ clubs, setClubs ] = useState([])
    const [ userInfo, setUserInfo ] = useState(null)
    const [ showLoading, setShowLoading ] = useState(false)
    const [ showLogin, setShowLogin ] = useState(false)

    const [ addClub, setAddClub ] = useState({
        open: false
    })

    const [ clubDetail, setClubDetail ] = useState({
        open: false,
        data: null
    })

    let windowLocationHash = window.location.hash ? window.location.hash.substring(1) : null

    const [ activeTab, setActiveTab ] = useState(Number(getTabMemos('clubs')) || windowLocationHash ? 1 : (isLogin && isLogin.club) ? 0 : 1)
    const [ readRules, setReadRules ] = useState({
        open: false,
        data: null
    })

    const [ waitingApproval, setWaitingApproval ] = useState(false)

    
    useEffect(() => {
        setShowLoading(true)
        loadUserInfo()
        getClubList()
    }, [])


    const loadUserInfo = async () => {
        let { data, error } = await getMe()
        if (error && (getUserInfo != null)) { Confirm("" , error) }
        if (data) { setUserInfo(data) }
    }

    const getClubList = async () => {
        try {
            let { data, error } = await getClubs(isLogin ? isLogin._id : '')
            if (error) { console.log(error) }
            if (data) { console.log(data); 
                setClubs(data.clubs); 
                setWaitingApproval(data.waitingApproval) 
                for (let i = 0; i < data.clubs.length; i ++) {
                    if (windowLocationHash == data.clubs[i].name.toLowerCase().replace(/ /g, "-")) {
                        setClubDetail({
                            open: true,
                            data: data.clubs[i]
                        })
                    }
                }
                // order
            }
            setShowLoading(false)
        } catch (error) {
            console.log('here error getclubList', error)
        }
    }

    const applyMember = async (e) => {
        let { data, error } = await applyClubMember({
            club: e
        })
        if (data) { 
            if (data.join) Confirm("", "Joined the club")
            if (data.apply) Confirm("", "Please wait approval from club leader")
            loadUserInfo()
            getClubList()
        }
    }

    return (
        <>
            {
                !isPopup &&
                <>
                    <TCNavigationBar navigation={() => {
                        navigate("/")
                    }} transparent={false} title={"Clubs"}
                    showLogin={showLogin} setShowLogin={setShowLogin}
                    />
                    {/* <Grid sx={{pt: 7}} justifyContent={"center"} textAlign={"center"}/> */}
                </>
            }
            <AppBar position="static">
                <TCTabs
                    activeTab={activeTab}
                    tabs={[
                        'My Club',
                        'Club List'
                    ]}
                    onChange={(event, newValue) => {
                        memorizeTabs('clubs', newValue)
                        setActiveTab(newValue)
                    }}
                />
                {/* <Tabs value={activeTab} sx={{m: 2, mb: 1}} onChange={(event, newValue) => {
                    setActiveTab(newValue)
                }}>
                    <Tab label={<TCLabel>Classes</TCLabel>} />
                    <Tab label={<TCLabel>Coaches</TCLabel>} />
                </Tabs> */}
                <CustomTabPanel boxPadding={0} value={activeTab} index={0}>
                    <div style={{overflow: 'auto', height: '84vh', paddingTop: 8, paddingBottom: 16, paddingLeft: 0, paddingRight: 0}}>
                        {/* <Classes userDetail={userDetail} checkIsLogin={checkIsLogin}/> */}
                        <Grid sx={!isPopup ? {p: 2, pb: 0, pt: 0} : {}} direction={"column"}>
                            {
                                !isPopup &&
                                <Grid xs={12} textAlign={"right"} onClick={() => {
                                    if (!userInfo) { setShowLogin(true); return; }
                                    if (userInfo && userInfo.club) { return; }
                                    // navigate("/add-club")
                                    setAddClub({open: true})

                                }}>
                                    <div className={(userInfo && userInfo.club) ? 'mini-btn-200px-disabled' : 'mini-btn-200px'} style={{width: 100, maxWidth: 100}} >
                                        <TCLabel inheritColor>Create Club</TCLabel> 
                                    </div>
                                </Grid>
                            }
                        </Grid>
                        {
                            userInfo &&
                            <Club isDetail={false} club_id={userInfo.club} setShowLogin={setShowLogin}/>
                        }
                    </div>
                </CustomTabPanel>
                <CustomTabPanel boxPadding={1} value={activeTab} index={1}>
                    <div style={{overflow: 'auto', height: '84vh', marginLeft: 8, marginRight: 8}}>
                        {/* <Coaches userDetail={userDetail} checkIsLogin={checkIsLogin} reloadUserDetail={() => { loadMe() }}/> */}
                        <Grid>
                            {
                                clubs.length == 0 &&
                                <EmptyData/>
                            }
                            {
                                clubs.length > 0 &&
                                <div style={{marginTop: 5}}>
                                    {
                                        clubs.map( value => {
                                            console.log('here value', value)
                                            return (
                                                <Card key={value._id} sx={{padding: 2, mb: 2}} onClick={() => {
                                                    // navigate(`/club/${value._id}`)
                                                    setClubDetail({
                                                        open: true,
                                                        data: value
                                                    })
                                                    if (closeDialog) { closeDialog() }
                                                }}>
                                                    <Grid container columnSpacing={1}>
                                                        <Grid pt={0.8} item>
                                                            {/* <img src={value.logo ? value.logo : academy} width={"80%"} height={"auto"}/> */}
                                                            <Avatar src={value.logo ? loadImages(value.logo) : clubPlaceholder}/>
                                                        </Grid>
                                                        <Grid item xs={10}>
                                                            <Grid container alignItems={"center"}>
                                                                <Grid item xs={12}>
                                                                    <Grid item container alignItems={"flex-end"} spacing={0.5}>
                                                                        <Grid item >
                                                                            <TCLabel bold>{value.name}</TCLabel>
                                                                        </Grid>
                                                                        <Grid item pb={0.1}>
                                                                            <Grid item container spacing={0.5}>
                                                                                <Grid item><TCLabel subtitle>Members : </TCLabel></Grid>
                                                                                <Grid item><TCLabel subtitle bold>{value.members.length}</TCLabel></Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                        {
                                                                            value.private &&
                                                                            <Grid item mb={-0.1}>
                                                                                <div style={{
                                                                                    fontSize: 12,
                                                                                    color: color.primary
                                                                                }}>
                                                                                    <Icon>lock</Icon>
                                                                                </div>
                                                                            </Grid>
                                                                        }
                                                                    </Grid>
                                                                    <TCLabel subtitle>Since {moment(value.createdDate).format("DD/MM/YYYY")}</TCLabel>
                                                                    <Grid item container spacing={0.5}>
                                                                        <Grid item>
                                                                            <TCLabel subtitle bold>Leader : </TCLabel>
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <TCLabel subtitle>{` ${getUserName(value.leader)}`}</TCLabel>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <TCLabel subtitle>{value.highlightDescription}</TCLabel>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item pt={1} container xs={12} textAlign={"right"} spacing={1}>
                                                            <Grid item xs={6}>
                                                                {!value.private ?
                                                                    <MKButton fullWidth 
                                                                    disabled={userInfo ? (value.members.indexOf(userInfo._id) >= 0) ? true : userInfo.club ? true : false : false}
                                                                    color="primary" 
                                                                        onClick={(e) => {
                                                                            e.stopPropagation(true)
                                                                            if (!userInfo) { setShowLogin(true); return; }
                                                                            if (userInfo.club) { Confirm("", "Leave your current club before join new club"); return; }
                                                                            Confirmation("", "Join this club ?", () => {}, async () => { applyMember(value._id) })
                                                                        }}>
                                                                        <Icon>person_add_alt_1</Icon>
                                                                        <span style={{marginLeft: 2, paddingTop: 2}}>Join</span>
                                                                    </MKButton>
                                                                    :
                                                                    <MKButton fullWidth color="primary" 
                                                                    disabled={userInfo ? (value.queuingMembers.indexOf(userInfo._id) >= 0) ? true : userInfo.club ? true :
                                                                        (value.members.indexOf(userInfo._id) >= 0) ? true : false : false} 
                                                                        // waitingApproval ? true :
                                                                    onClick={(e) => {
                                                                        e.stopPropagation(true)
                                                                        if (!userInfo) { setShowLogin(true); return; }
                                                                        if (userInfo.club) { Confirm("", "Leave your current club before apply to new club"); return; }
                                                                        Confirmation("", "Join this club ?", () => {}, async () => { applyMember(value._id) })
                                                                    }}>
                                                                        <Icon>person_add_alt_1</Icon>
                                                                        <span style={{marginLeft: 2, paddingTop: 2}}>{userInfo ? (value.queuingMembers.indexOf(userInfo._id) >= 0) ? "Pending" : "Join" : "Join"}</span>
                                                                    </MKButton>
                                                                }
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <MKButton fullWidth color="white"
                                                                    sx={{
                                                                        p: 0,
                                                                        border: 1,
                                                                        pl: 1, pr: 1,
                                                                        color: color.primary
                                                                    }}
                                                                    onClick={(e) => {
                                                                        e.stopPropagation()
                                                                        setReadRules({
                                                                            open: e,
                                                                            data: value
                                                                        })
                                                                    }}
                                                                >
                                                                    <Icon>rules</Icon>
                                                                    <span style={{marginLeft: 2, paddingTop: 2}}>Read Rules</span>
                                                                </MKButton>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Card>
                                            )
                                        })
                                    }
                                </div>
                            }
                        </Grid>
                    </div>
                </CustomTabPanel>
            </AppBar>
            <SlideAlert
                // isOpen={showLogin || readRules.open} 
                isOpen={readRules.open} 
                setIsOpen={() => {
                    // if (showLogin) setShowLogin()
                    if (readRules.open) setReadRules({
                        open: false,
                        data: null
                    })
                }}
                maxWidth={"xs"}
            >
                {/* {showLogin && <Login isOpen={showLogin} dismissPopup={() => setShowLogin(false)}/> } */}
                {readRules.open && 
                    <Grid p={2}>
                        <DialogHeader title={readRules && readRules.data.name} closeDialog={() => { 
                            setReadRules(false)
                        }}/>
                        <Grid p={2} pt={0}>
                            {
                                !readRules.data &&
                                <span>Rules is empty</span>
                            }
                            {
                                readRules.data &&
                                <TCLabel><div dangerouslySetInnerHTML={{__html: readRules.data.description}}/></TCLabel>
                            }
                        </Grid>
                    </Grid>
                }
            </SlideAlert>
            <SlideDialog
                isOpen={clubDetail.open || addClub.open} 
                setIsOpen={() => {
                    if (clubDetail.open) setClubDetail({
                        open: false,
                        data: null
                    })
                    if (addClub.open) setAddClub({open: false})
                }}
                maxWidth={"xs"}
            >
                {
                    addClub.open &&
                    <AddNewClub closeDialog={(e) => {
                        setAddClub({open: e})
                    }} />
                }
                {
                    clubDetail.data &&
                    <>
                        <DialogHeader title={"Club Detail"} closeDialog={() => { setClubDetail({
                            open: false,
                            data: null
                        }) }}/>
                        <div style={{marginLeft: -16, marginRight: -16}}>
                            <Club isPopup={clubDetail} isDetail={false} club_id={clubDetail.data._id} setShowLogin={(e) => {
                                setClubDetail({
                                    open: false,
                                    data: null
                                })
                                setShowLogin(e)
                            }} />
                        </div>
                    </>
                }
            </SlideDialog>
            {
                showLoading &&
                <Loading />
            }
        </>
    )
}

function AddNewClub ({
    closeDialog,
}) {

    return (
        <Grid container >
            <DialogHeader title={<TCLabel bold style={{fontSize: 15}}>Create Club</TCLabel>} closeDialog={()=> { closeDialog() }}/>
            <Grid xs={12}>
                <AddClub isPopup={true} />
            </Grid>
        </Grid>
    )
}


export default Clubs
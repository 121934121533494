function sortInformation(array) {
    let newArrayList = []
    for (let i = 0; i < array.length; i ++) {
        console.log('here hours info', array[i].hours)
        if (array[i].hours.length == 0) { newArrayList.push(array[i]); continue; }
        if (newArrayList.length == 0) {
            newArrayList.push(array[i])
        } else {
            let unshift = false
            // Old Logic
            for (let j = 0; j < newArrayList.length; j ++) {
                let hours = newArrayList[j].hours
                let hour = hours[hours.length - 1]
                if (hour) {
                    let currHour = array[i].hours[array[i].hours.length - 1]
                    let hourNumber = Number(hour.split(':')[0])
                    let currHourNumber = Number(currHour.split(':')[0])
                    if (currHourNumber < hourNumber) {
                        unshift = true
                    }
                }
            }

            if (unshift) { 
                newArrayList.unshift(array[i])
            } else {
                newArrayList.push(array[i])
            }
        }
    }

    // here sort lvl2
    let lvl2ArrayList = []
    for (let i = 0; i < newArrayList.length; i ++) {
        // 
        if (newArrayList[i].hours.length == 0) { lvl2ArrayList.push(newArrayList[i]); continue; }
        if (lvl2ArrayList.length == 0) {
            lvl2ArrayList.push(newArrayList[i])
        } else {
            let unshift = null
            for (let j = 0; j < lvl2ArrayList.length; j ++) { 
                let hours = lvl2ArrayList[j].hours
                let hour = hours[hours.length - 1]
                if (hour) {
                    let currHour = newArrayList[i].hours[newArrayList[i].hours.length - 1]
                    let hourNumber = Number(hour.split(':')[0])
                    let currHourNumber = Number(currHour.split(':')[0])

                    if (hourNumber == currHourNumber) {
                        let startHour = hours[0]
                        let currStartHour = newArrayList[i].hours[0]
                        let startHourNumber = Number(startHour.split(':')[0])
                        let currStartHourNumber = Number(currStartHour.split(':')[0])
                        if (currStartHourNumber < startHourNumber) {
                            unshift = j
                        }
                    }
                }
            }

            if (unshift || unshift == 0) { 
                lvl2ArrayList.splice(unshift, 0, newArrayList[i])
            } else {
                lvl2ArrayList.push(newArrayList[i])
            }
        }
    }
    
    let lvl3ArrayList = []
    for (let i = 0; i < lvl2ArrayList.length; i ++) {
        // 
        if (lvl2ArrayList[i].hours.length == 0) { lvl3ArrayList.push(lvl2ArrayList[i]); continue; }
        if (lvl3ArrayList.length == 0) {
            lvl3ArrayList.push(lvl2ArrayList[i])
        } else {
            let unshift = null
            for (let j = 0; j < lvl3ArrayList.length; j ++) { 
                let hours = lvl3ArrayList[j].hours
                let hour = hours[hours.length - 1]
                if (hour) {
                    let currHour = lvl2ArrayList[i].hours[lvl2ArrayList[i].hours.length - 1]
                    let hourNumber = Number(hour.split(':')[0])
                    let currHourNumber = Number(currHour.split(':')[0])

                    let startHour = hours[0]
                    let currStartHour = lvl2ArrayList[i].hours[0]
                    let startHourNumber = Number(startHour.split(':')[0])
                    let currStartHourNumber = Number(currStartHour.split(':')[0])

                    if ((hourNumber == currHourNumber) && (currStartHourNumber == startHourNumber)) {
                        if (lvl3ArrayList[j].name > lvl2ArrayList[i].name) {
                            unshift = j
                        }
                    }
                }
            }

            if (unshift || unshift == 0) { 
                lvl3ArrayList.splice(unshift, 0, lvl2ArrayList[i])
            } else {
                lvl3ArrayList.push(lvl2ArrayList[i])
            }
        }
    }

    return lvl3ArrayList || []
}


// function sortCardInformation(array) {
//     let newArrayList = []
//     for (let i = 0; i < array.length; i ++) {
//         console.log('here hours info', array[i])
//         if (array[i].hours.length == 0) { newArrayList.push(array[i]); continue; }
//         if (newArrayList.length == 0) {
//             newArrayList.push(array[i])
//         } else {
//             let unshift = false
//             // Old Logic
//             for (let j = 0; j < newArrayList.length; j ++) {
//                 let hours = newArrayList[j].hours
//                 let hour = hours[hours.length - 1]
//                 let currHour = array[i].hours[array[i].hours.length - 1]
//                 let hourNumber = Number(hour.split(':')[0])
//                 let currHourNumber = Number(currHour.split(':')[0])

//                 if (currHourNumber < hourNumber) {
//                     unshift = true
//                 }
//             }

//             if (unshift) { 
//                 newArrayList.unshift(array[i])
//             } else {
//                 newArrayList.push(array[i])
//             }
//         }
//     }

//     // here sort lvl2
//     let lvl2ArrayList = []
//     for (let i = 0; i < newArrayList.length; i ++) {
//         // 
//         if (newArrayList[i].hours.length == 0) { lvl2ArrayList.push(newArrayList[i]); continue; }
//         if (lvl2ArrayList.length == 0) {
//             lvl2ArrayList.push(newArrayList[i])
//         } else {
//             let unshift = null
//             for (let j = 0; j < lvl2ArrayList.length; j ++) { 
//                 let hours = lvl2ArrayList[j].hours
//                 let hour = hours[hours.length - 1]
//                 let currHour = newArrayList[i].hours[newArrayList[i].hours.length - 1]
//                 let hourNumber = Number(hour.split(':')[0])
//                 let currHourNumber = Number(currHour.split(':')[0])

//                 if (hourNumber == currHourNumber) {
//                     let startHour = hours[0]
//                     let currStartHour = newArrayList[i].hours[0]
//                     let startHourNumber = Number(startHour.split(':')[0])
//                     let currStartHourNumber = Number(currStartHour.split(':')[0])
//                     if (currStartHourNumber < startHourNumber) {
//                         unshift = j
//                     }
//                 }
//             }

//             if (unshift || unshift == 0) { 
//                 lvl2ArrayList.splice(unshift, 0, newArrayList[i])
//             } else {
//                 lvl2ArrayList.push(newArrayList[i])
//             }
//         }
//     }
    
//     let lvl3ArrayList = []
//     for (let i = 0; i < lvl2ArrayList.length; i ++) {
//         // 
//         if (lvl2ArrayList[i].hours.length == 0) { lvl3ArrayList.push(lvl2ArrayList[i]); continue; }
//         if (lvl3ArrayList.length == 0) {
//             lvl3ArrayList.push(lvl2ArrayList[i])
//         } else {
//             let unshift = null
//             for (let j = 0; j < lvl3ArrayList.length; j ++) { 
//                 let hours = lvl3ArrayList[j].hours
//                 let hour = hours[hours.length - 1]
//                 let currHour = lvl2ArrayList[i].hours[lvl2ArrayList[i].hours.length - 1]
//                 let hourNumber = Number(hour.split(':')[0])
//                 let currHourNumber = Number(currHour.split(':')[0])

//                 let startHour = hours[0]
//                 let currStartHour = lvl2ArrayList[i].hours[0]
//                 let startHourNumber = Number(startHour.split(':')[0])
//                 let currStartHourNumber = Number(currStartHour.split(':')[0])

//                 if ((hourNumber == currHourNumber) && (currStartHourNumber == startHourNumber)) {
//                     if (lvl3ArrayList[j].name > lvl2ArrayList[i].name) {
//                         unshift = j
//                     }
//                 }
//             }

//             if (unshift || unshift == 0) { 
//                 lvl3ArrayList.splice(unshift, 0, lvl2ArrayList[i])
//             } else {
//                 lvl3ArrayList.push(lvl2ArrayList[i])
//             }
//         }
//     }

//     return lvl3ArrayList || []
// }


export { sortInformation }
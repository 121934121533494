import React, { useEffect, useState } from "react";
import { AvatarGroup, Card, Dialog, DialogActions, DialogContent, DialogContentText, Grid, Icon, Input, Popover } from "@mui/material";
import { TCLabel } from "components/TopCourtComponents/Label/TopCourtLabel";
import SlideDialog from "components/SlideDialog/SlideDialog";
import ClassForm from "./Components/ClassForm";
import EmptyData from "components/TopCourtComponents/Placeholder/EmptyData";
import moment from "moment";
import { currencyFormat } from "helper/numberFormat";
import MKAvatar from "components/MKAvatar";
import { color } from "helper/constant";
import MKButton from "components/MKButton";
import DialogHeader from "components/SlideDialog/DialogHeader";
import { getClasses, applyClassService, cancelApplyClassService, deleteClassService, submitOrderClass } from "service/classService";
import { getUserInfo } from "helper/localStorage";
import Loading from "components/TopCourtComponents/Loading/TCLoadingComponent";
import { getUserName } from "helper/user";
import UserCard from "components/TopCourtComponents/User/UserCard";
import { Confirm, Confirmation, ConfirmationYesNo } from "helper/showAlert";
import * as NumericInput from "react-numeric-input";

import equality from "../../assets/equality.svg";
import { submitItemOrder } from "pages/AddOns/Function";
import { useNavigate } from "react-router-dom";
import { TCInput } from "components/TopCourtComponents/Input/TopCourtInput";
import { courtTypeObj } from "helper/constant";
import { timeStringFormat } from "helper/constant";
import SlideAlert from "components/SlideInAlert/SlideAlert";
import QRPage from "pages/Orders/QRPage";
import { getUserAvatar } from "helper/user";
import TCNumberInput from "components/TopCourtComponents/Input/TopCourtCounterNumber";

import Slide from '@mui/material/Slide';
import { CopyShareLinkComponent } from "components/TopCourtComponents/Share/ShareComponent";
import ImagesDetail from "components/TopCourtComponents/Images/ImagesDetail";
import ClassDetailv2Component from "./Components/ClassDetailv2Component";
import { getClassDetail } from "service/classService";
import TCNavigationBar from "components/TopCourtComponents/NavigationBar/TopCourtNavigationBar";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const spacing = 0.5

function ClassDetail ({
    
}) {

    const navigate = useNavigate()

    const [ selectedUser, setSelectedUser ] = useState(null)
    const [isOpen, setIsOpen] = useState(false);
    const [openImageDetail, setOpenImageDetail] = useState({
        open: false,
        image: null
    })

    const [ loading, setLoading ] = useState(true);
    const [ selectionClass, setSelectionClass ] = useState([]);
    const [ disableView, setDisableView ] = useState(false);
    // const [ showMore, setShowMore ] = useState(isFromDetail);
    const [ showMore, setShowMore ] = useState(false);
    const [ fromJoin, setFromJoin ] = useState(false);
    const [ showAlert, setShowAlert ] = useState(false);

    const [ totalPax, setTotalPax ] = useState(0)
    const [ selectedPax, setSelectedPax ] = useState({
        qty: 0,
        price: 0
    })

    const [ data, setData ] = useState(null)

    useEffect(() => {
        loadClassDetail()
    }, [])

    const checkUserPending = (e) => {
        console.log('here check data', e.myWaitingPayment, e.totalPending, e.playersCapacity, 
        e.totalParticipant, e.disableJoin)
        if (e.disableJoin) {return {
            disable: true, 
            waitingPayment: false,
            full: (e.totalParticipant >= e.playersCapacity)
        } }
        if (e.isAdmin && (e.totalParticipant >= e.playersCapacity)) { return {
            disable: true, 
            waitingPayment: false,
            full: true
        } }
        if (e.myWaitingPayment) { return {
            disable: false,
            waitingPayment: true,
            full: false
        } }
        if ((e.totalPending + e.totalParticipant) >= e.playersCapacity) { 
            if (e.totalPending) {
                return {
                    disable: false, 
                    waitingPayment: true,
                    full: false
                } 
            } else {
                return {
                    disable: true, 
                    waitingPayment: false,
                    full: false
                } 
            }
        }
        return { disable: false, waitingPayment: false, full: false }
    }

    const maxValue = () => {
        return data.playersCapacity
    }

    const loadClassDetail = async (date) => {
        setLoading(true)
        try {
            const windowLocationHash = window.location.href.split('/d/')
            const windowLocationDetailHash = window.location.href.split('/detail/')
            let classRef = null

            console.log('here check windowlocationhash', windowLocationHash, windowLocationDetailHash)
            if (windowLocationHash.length > 1) {
                classRef = windowLocationHash[1].split('?')[0]
                setFromJoin(true)
            }
            if (windowLocationDetailHash.length > 1) {
                classRef = windowLocationDetailHash[1].split('?')[0]
                setShowMore(true)
            }
            const userInfo = getUserInfo ? JSON.parse(getUserInfo) : null
            let { data, error } = await getClassDetail(userInfo ? userInfo._id : null, classRef, date)
            setLoading(false)
            console.log('here load Data', data, error)
            if (error) { console.log(error) }
            if (data) {
                let endTime = data.hours[data.hours.length - 1]
                let endTimeNumber = Number(endTime.split(":")[0]) + 1
                let endTimeTime = `${endTimeNumber > 9 ? '' : '0'}${endTimeNumber}:00`
                let userPending = checkUserPending(data)

                // submitOrderClass
                const isApplied = data.appliedClass.indexOf(data._id.toString()) >= 0
                const isMyClass = data.myClass.indexOf(data._id.toString()) >= 0 
                
                setData({
                    ...data,
                    endTime,
                    endTimeNumber,
                    endTimeTime,
                    userPending,
                    isApplied,
                    isMyClass
                })
            }
        } catch (error) {
            console.log(error)
        }
    }

    const submitBundleOrder = async () => {
        setLoading(true)
        let submitOrderData = await submitOrderClass({
            classInfo: data._id,
            type: 'bundle',
            sessionBundle: data.classSchedule.bundle ? data.classSchedule.bundle.bundleSession : 0,
            qty: 1,
            price: data.classSchedule.bundle ? data.classSchedule.bundle.bundlePrice : 0,
            validity: data.classSchedule ? data.classSchedule.endClassDate : 0
        })
        if (submitOrderData.error) {
            setLoading(false)
            console.log(submitOrderData.error)
        }
        if (submitOrderData.data) {
            setLoading(false)
            navigate(`/payment/${submitOrderData.data._id}/add-ons?page=add-ons`)
        }
    }

    const checkIsLogin = () => {
        const currentUserString = getUserInfo;
        if (!currentUserString) {
            setShowAlert(true)
            return false
        }
        return true
    }

    const checkAgeandGender = () => {
        let { minAge, maxAge, gender } = data
        const userInfo = getUserInfo ? JSON.parse(getUserInfo) : null
        if (!userInfo) { return false }
        let myAge = moment().year() - userInfo.birthYear
        if ((gender == "male" || gender == "female") && (userInfo.gender != gender)) { return true }
        if (!minAge && !maxAge) { return false
        } else if (minAge && maxAge) {
            if (myAge > minAge && myAge < maxAge) { 
                return false
            }
        } else if (minAge) {
            if (myAge > minAge) { 
                return false
            }
        } else if (maxAge) {
            if (myAge < maxAge) { 
                return false
            }
        }
        return true
    }


    return (
        <>
            <TCNavigationBar transparent={false} title={<TCLabel style={{fontSize: 15}} bold>{data ? data.name : "Class"}</TCLabel>} showLogin={showAlert} setShowLogin={setShowAlert}/>
            {
                data ?
                    <Grid>
                        <Grid p={2}>
                            <Grid item container>
                                <Grid item xs={12}>
                                    <Grid item xs={12}>
                                        {/* <TCLabel bold>{data.name}</TCLabel> */}
                                        <Grid container spacing={0.5}>
                                            <Grid item>
                                                <TCLabel subtitle bold>Owner :</TCLabel>
                                            </Grid>
                                            <Grid item>
                                                <TCLabel subtitle>{
                                                    !data.isAdmin ? getUserName(data.createdBy) : 'STC'
                                                }</TCLabel>
                                            </Grid>
                                        </Grid>
                                        {/* <TCLabel subtitle>#Tennis</TCLabel> */}
                                    </Grid>
                                    <Grid item container xs={12} spacing={spacing}>
                                        <Grid item>
                                            <TCLabel style={{marginTop: 1.5}} subtitle bold>
                                                <Icon sx={{fontWeight: 'bold'}}>access_time</Icon>
                                            </TCLabel>
                                        </Grid>
                                        <Grid item>
                                            <TCLabel subtitle>
                                            {
                                                data.classSchedule ?
                                                `${moment(data.classSchedule.startClassDateString).format("DD MMM YYYY")} - ${moment(data.classSchedule.endClassDateString).format("DD MMM YYYY")} | ${data.hours[0]} - ${data.endTimeTime}`
                                                :
                                                `${moment(data.date).format("DD MMM YYYY (dddd)")} ${data.hours[0]} - ${data.endTimeTime}`
                                            }
                                            </TCLabel>
                                        </Grid>
                                    </Grid>
                                    <Grid item container xs={12} spacing={0.5}>
                                        {/* <Grid item>
                                            <TCLabel style={{marginTop: 1.5}} subtitle bold>
                                                <Icon sx={{fontWeight: 'bold'}}>people</Icon>
                                            </TCLabel>
                                        </Grid>
                                        <Grid item><TCLabel subtitle>{`${data.playersCapacity} • ${data.totalParticipant} going`}</TCLabel></Grid> */}
                                        <Grid item>
                                            <TCLabel style={{marginTop: 0.5}} subtitle bold>
                                                {/* <Icon sx={{fontWeight: 'bold'}}>people</Icon> */}
                                                <img src={equality} width={10} height={10}/>
                                            </TCLabel>
                                        </Grid>
                                        <Grid item>
                                            <TCLabel subtitle>
                                                {data.gender == "female" ? "Female Only" : data.gender == "male" ? "Male Only" : "All"}
                                            </TCLabel>
                                        </Grid>
                                        <Grid item ml={1}>
                                            <TCLabel subtitle>Age: </TCLabel>
                                        </Grid>
                                        <Grid item>
                                        {
                                            (data.minAge && data.maxAge) &&
                                            <TCLabel subtitle>{(data.minAge == data.maxAge) ? `${data.minAge}` : `${data.minAge} - ${data.maxAge}`}</TCLabel>
                                        }
                                        {
                                            (data.minAge && !data.maxAge) &&
                                            <TCLabel subtitle>{`> ${data.minAge}`}</TCLabel>
                                        }
                                        {
                                            (!data.minAge && data.maxAge) &&
                                            <TCLabel subtitle>{`< ${data.maxAge}`}</TCLabel>
                                        }
                                        {
                                            (!data.minAge && !data.maxAge) &&
                                            <TCLabel subtitle>{`All age`}</TCLabel>
                                        }
                                        </Grid>
                                    </Grid>
                                    <Grid item container xs={12} alignItems={"center"}>
                                        <Grid item container xs spacing={spacing}>
                                            <Grid item sx={{mt: 0.2}}>
                                                <TCLabel bold>
                                                    <Icon sx={{fontWeight: 'bold'}}>payments</Icon>
                                                </TCLabel>
                                            </Grid>
                                            <Grid item><TCLabel bold>{`${currencyFormat(data.fee)} / Session`}</TCLabel></Grid>
                                        </Grid>
                                    </Grid>
                                    {
                                        !data.isMyClass && (!data.disableJoin && (data.classSchedule && data.classSchedule.bundle && data.classSchedule.bundle.bundleSession) && !data.bundle && !checkAgeandGender()) &&
                                        <Grid container xs={12} mt={1} justifyContent={"center"}>
                                            <Grid container p={0.5} xs={12} sx={{
                                                border: data.disableBundle? `1px solid #eaeaea` : data.waitingPaymentBundle ? `1px solid #f79256` : `1px solid ${color.primary}`,
                                                color: data.disableBundle ? "#c0c0c0" : 'white',
                                                backgroundColor: data.disableBundle ? "#eaeaea" : data.waitingPaymentBundle ? '#f79256' : color.primary,
                                                borderRadius: 2
                                            }} justifyContent={"center"} onClick={() => {
                                                if (data.disableBundle) { Confirm("", "Sold Out"); return; }
                                                if (data.waitingPaymentBundle) {
                                                    Confirm("", "Awaiting payment")
                                                    return;
                                                }
                                                Confirmation("", "Proceed to payment ?", () => {}, () => {
                                                    // onClickBuy(value)
                                                    if (!checkIsLogin()) {
                                                        return;
                                                    }
                                                    submitBundleOrder()
                                                })
                                            }}>
                                                <Grid item sx={{pl: 0.5}}>
                                                    <TCLabel style={{ color: data.disableBundle ? 'rgba(16, 16, 16, 0.2)' : 'white' }} bold>BUY BUNDLE :</TCLabel>
                                                </Grid>
                                                <Grid item sx={{pl: 0.5}}><TCLabel style={{
                                                    color: data.disableBundle ? 'rgba(16, 16, 16, 0.2)' : 'white'
                                                }} bold>{`${currencyFormat(data.classSchedule.bundle.bundlePrice)} / ${data.classSchedule.bundle.bundleSession} Session`}</TCLabel></Grid>
                                            </Grid>
                                        </Grid>
                                    }
                                    {
                                        (!data.isMyClass && !data.disableJoin && data.bundle > 0) &&
                                        <Grid container xs={12} mt={1} justifyContent={"center"}>
                                            <Grid container p={0.5} xs={12} sx={{
                                                border: `1px solid ${color.primary}`,
                                                color: 'white',
                                                borderRadius: 2
                                            }} justifyContent={"center"}>
                                                <Grid item sx={{pl: 0.5}}>
                                                    <TCLabel style={{ color: color.primary }} bold>Remaining Balance : {data.bundle} Sessions</TCLabel>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                            {/* <div className="separation-line"/> */}
                            {
                                (data.description || data.classImages.length > 0) &&
                                <>
                                    <div className="separation-line"/>
                                    <Grid pt={1}>
                                        <TCLabel>
                                            {/* <div dangerouslySetInnerHTML={{__html: data.description}}/> */}
                                            <div>
                                                {(data.description.length > 200 && (!showMore && !data.disableJoin)) ?
                                                    <div dangerouslySetInnerHTML={{__html: data.description.slice(0, 200)}}/>
                                                    :
                                                    <div dangerouslySetInnerHTML={{__html: data.description}}/>
                                                }
                                                {
                                                    (showMore || data.disableJoin) &&
                                                    <Grid item container pt={1}>
                                                        {
                                                            data.classImages && data.classImages.map(value => {
                                                                return(<Grid item xs={6} style={{height: 'auto', width: '100%'}}
                                                                    onClick={() => {
                                                                        setOpenImageDetail({
                                                                            open: true,
                                                                            image: value
                                                                        })
                                                                    }}
                                                                >
                                                                    <img src={value} style={{
                                                                        objectFit: 'contain'
                                                                    }} width={"100%"} height={"100%"}/>
                                                                </Grid>)
                                                            })
                                                        }
                                                    </Grid>
                                                }
                                                {(data.description.length > 200 || data.classImages.length > 0) && 
                                                    <Grid textAlign={"right"} onClick={() => {
                                                        setShowMore(!showMore)
                                                    }}>
                                                        <TCLabel style={{fontSize: 10, fontWeight: 'bold'}}>{
                                                            !showMore ? '... Show more' : 'Show Less'
                                                        }</TCLabel>
                                                    </Grid>
                                                }
                                            </div>
                                        </TCLabel>
                                    </Grid>
                                </>
                            }
                        </Grid>
                        <ClassDetailv2Component disableView={disableView} item={data} maxValue={maxValue} 
                            setSelectionClass={(e) => { setSelectionClass(e) }}
                            onChangePax={(e) => { setTotalPax(e) }} 
                            setShowLogin={setShowAlert}
                            isMyClass={data.isMyClass}
                            checkAgeandGender={() => checkAgeandGender()}
                            disableJoinClass={data.disableJoin}
                            reloadData={() => { loadClassDetail() }}
                            fromJoin={fromJoin}
                            changeDate={(e) => { loadClassDetail(e) }}
                        />
                    </Grid>
                :
                    <>
                        {
                            !loading &&
                            <EmptyData/>
                        }
                    </>
            }
            {
                loading &&
                <Loading />
            }
        </>
    )
}

const createOrder = async ( classObj, qty = 1, navigate ) => {
    await submitItemOrder({
        type: "class",
        classObj,
        qty
    }, navigate)
}

export default ClassDetail
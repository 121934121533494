import React from "react"
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { TCLabel } from "../Label/TopCourtLabel";

function TCAccordion ({
    id,
    ariaControls,
    title,
    children,
    outsideFunction = null,
    bodyOnClick
}) {
    return (
        <Accordion disableGutters onClick={() => {
            if (bodyOnClick) { bodyOnClick() }
        }}>
            <AccordionSummary
                sx={{
                    margin: 0,
                    minHeight: 0
                }}
                expandIcon={<ArrowDropDownIcon onClick={() => {
                    if (outsideFunction) { outsideFunction() }
                }} />}
                aria-controls={ariaControls}
                id={id}
            >
                <TCLabel>{title}</TCLabel>
            </AccordionSummary>
            <AccordionDetails>
                {children && children}
            </AccordionDetails>
        </Accordion>
    )
}

export default TCAccordion
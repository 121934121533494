import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';

import { Grid, Icon, Container } from "@mui/material";
import MKBox from "components/MKBox";
import { TCLabel } from "../Label/TopCourtLabel";
import { color } from "helper/constant";

import SlideAlert from "components/SlideInAlert/SlideAlert";
import Login from "pages/Auth/Login";

export default function TCNavigationBar({
    transparent = true,
    title,
    fixed = true,
    light = false,
    action,
    sticky = true,
    navigation = null,
    showLogin = false,
    setShowLogin
}) {

    const navigate = useNavigate();
    const location = useLocation();

    const [ showAlert, setShowAlert ] = useState(false)

    useEffect(() => {
        setShowAlert(showLogin)
    }, [showLogin])

    return (
        <Container sx={sticky ? { position: "sticky", top: 0, zIndex: 3, maxWidth: 'none !important', padding: '0px !important', margin: '0px !important' } : null}>
            <MKBox
                display={{ xs: "inline-block"}}
                lineHeight={0}
                // position={fixed ? "fixed" : "absolute"}
                p={1.8}
                pt={2}
                pl={1}
                minHeight={58}
                maxHeight={58}
                // pl={1.5}
                sx={ transparent ? { zIndex: 20, width: '100%' } : {
                    zIndex: 20, width: '100%', backgroundColor: 'white !important', boxShadow: 'rgba(17, 17, 26, 0.1) 0px 1px 0px'
                }}>
                    <Grid container direction={"row"}>
                        <Grid item xs={1} md={1}>
                            <Icon fontSize="default" style={{color: light ? 'white' : color.blueIndoor_1}} onClick={() => {
                                if (navigation) {
                                    navigation()
                                } else {
                                    let path = window.location.href.split("/")
                                    if (path[path.length - 1] === "booking?page=cart") {
                                        window.location.replace('/bookings?page=cart')
                                    } else if (path[path.length - 1] === "bookings?page=cart") {
                                        window.location.replace('/')
                                    } else { 
                                        const anyHistoryEntryExist = location.key !== "default";
                                        if (!anyHistoryEntryExist) { window.location.href = "/"; return; }
                                        else { navigate(-1); return; }
                                    }
                                }
                            }}>arrow_back_ios</Icon>
                        </Grid>
                        <Grid item xs={10} md={10} justifyContent={"center"} textAlign={"center"}>
                            <TCLabel style={{fontSize: 18, padding: 0, fontWeight: 'bold', color: 'primary'}}>{title}</TCLabel>
                        </Grid>
                        {
                            action &&
                            <Grid item container xs={1} md={1} direction={"row"} alignItems={"center"} justifyContent={"flex-end"}  textAlign={"right"}>
                                {action}
                            </Grid>
                        }
                    </Grid>
            </MKBox>
            <SlideAlert
                isOpen={showAlert}
                setIsOpen={(e) => {
                    setShowAlert(e)
                    if (setShowLogin) { setShowLogin(e) }
                }}
                maxWidth={"xs"}
            >
                <Login isOpen={showAlert} reset={!showAlert} dismissPopup={() => {
                    setShowAlert(false)
                    if (setShowLogin) { setShowLogin(false) }
                }}/>
            </SlideAlert>
        </Container>
    )
}
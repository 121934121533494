import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';

import { AppBar, Card, Container, Grid, Icon, Tab, Tabs, Box, Button } from "@mui/material";
import MKTypography from "components/MKTypography";
import { getUserInfo } from "helper/localStorage";

import { getMe } from "service/authService";

import { TCLabel } from "components/TopCourtComponents/Label/TopCourtLabel";
import { setUserInfo, deleteUserInfo } from "helper/localStorage";
import TCNavigationBar from "components/TopCourtComponents/NavigationBar/TopCourtNavigationBar";
import Rent from "./Rent";
import Buy from "./Buy";
import TCTabs from "components/TopCourtComponents/Tabs/TopCourtTabs";
import TCTabPanel from "components/TopCourtComponents/Tabs/TopCourtTabPanel";
import ServiceAddOns from "./ServiceAddOns";

import { memorizeTabs, getTabMemos } from "helper/tabsMemo";


function AddOns ({
    
}) {

    const navigate = useNavigate();
    const [ showAlert, setShowAlert ] = useState(false)
    const [ activeTab, setActiveTab ] = useState(Number(getTabMemos('add-ons')) || 0)
    const userInfo = getUserInfo ? JSON.parse(getUserInfo) : null

    const [ userDetail, setUserDetail ] = useState(userInfo)

    useEffect(() => {
        loadMe()
        // if (!userInfo) {
        //     setShowAlert(true)
        // }
    }, [])

    const loadMe = async () => {
        let { data, error } = await getMe()
        if (error) { console.log(error) }
        if (data) {  setUserDetail(data); setUserInfo(data) }
    }

    return (
        <>
            <TCNavigationBar light={false} transparent={false} fixed title={"Add-On"}
                showLogin={showAlert} setShowLogin={setShowAlert}
            />
            <AppBar position="static">
                <TCTabs
                    activeTab={activeTab}
                    tabs={[
                        'Service',
                        'Rent',
                        'Buy'
                    ]}
                    onChange={(event, newValue) => {
                        memorizeTabs('add-ons', newValue)
                        setActiveTab(newValue)
                    }}
                />
                {/* <Tabs value={activeTab} 
                TabIndicatorProps={{
                    style: {
                      backgroundColor: color.blueIndoor_2
                    }
                }} sx={{m: 2, mb: 0}} 
                    onChange={(event, newValue) => {
                    setActiveTab(newValue)
                }}>
                    <Tab label={<TCLabel style={activeTab == 0 ? {color: "white"} : {}}>Service</TCLabel>} />
                    <Tab label={<TCLabel style={activeTab == 1 ? {color: "white"} : {}}>Rent</TCLabel>} />
                    <Tab label={<TCLabel style={activeTab == 2 ? {color: "white"} : {}}>Buy</TCLabel>} />
                </Tabs> */}
                <TCTabPanel boxPadding={1} value={activeTab} index={0}>
                    <div style={{overflow: 'auto', height: '82vh', marginLeft: 8, marginRight: 8}}>
                        <ServiceAddOns setShowAlert={setShowAlert}/>
                    </div>
                </TCTabPanel>
                <TCTabPanel boxPadding={1} value={activeTab} index={1}>
                    <div style={{overflow: 'auto', height: '82vh', marginLeft: 8, marginRight: 8}}>
                        <Rent setShowAlert={setShowAlert}/>
                    </div>
                </TCTabPanel>
                <TCTabPanel boxPadding={1} value={activeTab} index={2}>
                    <div style={{overflow: 'auto', height: '82vh', marginLeft: 8, marginRight: 8}}>
                        <Buy setShowAlert={setShowAlert}/>
                    </div>
                </TCTabPanel>
            </AppBar>
        </>
    )
}

export default AddOns;
import React, { useEffect, useState, useCallback } from "react"
import EmptyData from "components/TopCourtComponents/Placeholder/EmptyData"
import { getItems, getItemDetail } from "service/addOnService"
import Loading from "components/TopCourtComponents/Loading/TCLoadingComponent"
import { ProductComponent, ProductDetail } from "./ProductComponent"
import { Box, Checkbox, FormControlLabel, Grid, Icon, Modal } from "@mui/material"
import { centerBox } from "helper/constant"
import { getUserInfo } from "helper/localStorage"
import Login from "pages/Auth/Login"
import { Confirm } from "helper/showAlert"
import SlideDialog from "components/SlideDialog/SlideDialog"
import SlideAlert from "components/SlideInAlert/SlideAlert"
import { BottomSheet } from "react-spring-bottom-sheet"
import MKButton from "components/MKButton"
import { TCLabel } from "components/TopCourtComponents/Label/TopCourtLabel"

import { loadItemCart, submitItemOrderv2 } from "./Function"
import TCAddOnDrawer from "components/TopCourtComponents/Drawer/TCAddOnDrawer"
import DrawerTitle from "./AddOnComponent/DrawerTitle"
import { currencyFormat } from "helper/numberFormat"
import ItemList from "./AddOnComponent/ItemList"
import { useNavigate } from "react-router-dom"


function Rent ({
    setShowAlert
}) {

    const userInfo = getUserInfo ? JSON.parse(getUserInfo) : null
    const navigate = useNavigate()

    const [ showLogin, setShowLogin ] = useState(false)
    const [ products, setProducts ] = useState([])
    const [ showLoading, setShowLoading ] = useState(true)
    const [ showDetail, setShowDetail ] = useState({
        open: false,
        productId: null
    })
    const [ cart, setCart ] = useState([])
    const [ showBottomSheet, setShowBottomSheet ] = useState(false)
    const [ bottomSheetHeight, setBottomSheetHeight] = useState(null)
    const [ rerender, setRerender ] = useState(Date.now)
    const [ showDetailAddOn, setShowDetailAddOn] = useState(false)
    const [ filter, setFilter ] = useState({
        name: '',
        // status: serviceStatus[0],
        reset: false
    })
    const [ loading, setLoading ] = useState(false)

    useEffect(() => {
        loadItems()
    }, [filter.reset])

    useEffect(() => {
        loadItemCart(setCart)
    }, [])

    useEffect(() => {
        console.log('here check cart', cart)
    }, [cart])

    const loadItems = async () => {
        setShowLoading(true)
        let { data, error } = await getItems(filter, 'rent');
        if (error) { Confirm("", error) }
        if (data) { setProducts(data) }
        setShowLoading(false)
    }

    const bottomSheetRef = useCallback(n => {
        if (n !== null) {
            setBottomSheetHeight(n.getBoundingClientRect().height)
        }
    })

    const countSummary = () => {
        let totalSession = 0
        let totalPrice = 0
        if (cart.length >= 0) {
            for (let i = 0; i < cart.length; i ++) {
                let cartSession = cart[i].sessions
                for (let j = 0; j < cartSession.length; j ++) {
                    totalSession += cartSession[j].hours.length
                    totalPrice += (cart[i].productId.price * cartSession[j].hours.length)
                }
            }
        }
        return { totalSession, totalPrice }
    }

    let ratio = bottomSheetHeight / window.innerHeight
    let height = (bottomSheetHeight * ratio)
    let newHeight = (bottomSheetHeight - height)

    return (
        <>
        {
            showLoading &&
            <Loading/>
        }
        {
            !showLoading && products.length <= 0 && <EmptyData />
        }
        {/* {
            <Grid textAlign={"right"} onClick={() => {
                setShowBottomSheet(true)
            }}>
                <Icon fontSize="md">filter_alt</Icon>
            </Grid>
        } */}
        {
            products.length > 0 && 
            <div>
                <Grid container mt={1} pb={16} spacing={2} style={{
                    marginBottom: newHeight
                }}>
                    {
                        products.map( value => {
                            return (
                                <Grid item xs={6} lg={6} onClick={() => {
                                    if(!userInfo) {
                                        setShowAlert(true)
                                        return;
                                    }
                                    setShowDetail(prev => ({
                                        ...prev,
                                        open: true,
                                        productId: value._id
                                    }))
                                }}>
                                    <ProductComponent type={"rent"} data={value}/>
                                </Grid>
                            )
                        })
                    }
                    <TCAddOnDrawer
                        window={window}
                        isOpen={showDetailAddOn}
                        reRender={rerender}
                        titleText={"Add-On Summary"}
                        title={
                            <DrawerTitle setShowDetailAddOn={setShowDetailAddOn}
                                setRerender={setRerender} countSummary={countSummary} />
                        }
                        footer={
                            <div ref={bottomSheetRef}>
                                <Grid container mt={1} justifyContent={"flex-end"} 
                                    style={{paddingLeft: 16, paddingRight: 16}}>
                                    <Grid xs={6} md={6} lg={6} item style={{textAlign: 'left'}}>
                                        <TCLabel>Total {countSummary().totalSession} {countSummary().totalSession > 1 ? "Sessions" : "Session"}</TCLabel>
                                    </Grid>
                                    <Grid xs={6} md={6} lg={6} item style={{textAlign: 'right'}}>
                                        <TCLabel style={{fontWeight: 'bold'}}>Total <span style={{fontWeight: 'bold'}}>{currencyFormat(countSummary().totalPrice)}</span></TCLabel>
                                    </Grid>
                                </Grid>
                                <Grid container mt={1} mb={2} justifyContent={"flex-end"} 
                                    style={{paddingLeft: 16, paddingRight: 16}}>
                                    <Grid xs={12} md={12} lg={12} item style={{textAlign: 'center'}}>
                                        <MKButton color="primary" 
                                            disabled={loading || countSummary().totalSession == 0}
                                            fullWidth onClick={() => {
                                                setLoading(true)
                                                submitItemOrderv2({
                                                    type: "rent"
                                                }, () => { loadItemCart(setCart) }, setLoading, navigate)
                                            }}>
                                            Pay
                                        </MKButton>
                                    </Grid>
                                </Grid>
                            </div>
                        }
                    >
                        {/* Looping Cart */}
                        <ItemList items={cart} bottomSheetHeight={bottomSheetHeight} loadData={() => {
                            loadItemCart(setCart)
                        }}/>
                    </TCAddOnDrawer>
                </Grid>
            </div>
        }
            {/* <SlideAlert
                isOpen={showLogin} 
                setIsOpen={setShowLogin}
                maxWidth={"xs"}
            >
                <Login isOpen={showLogin} dismissPopup={() => setShowLogin(false)}/>
            </SlideAlert> */}
            <SlideDialog 
                isOpen={showDetail.open} 
                setIsOpen={(e) => {
                    if (showDetail) setShowDetail(prev => ({
                        ...prev,
                        open: e,
                        productId: null
                    }))
                }}
                maxWidth={"lg"}
            >
                {showDetail.open && 
                    <ProductDetail onClose={() => {
                        setShowDetail(prev => ({
                            ...prev,
                            open: false,
                            productId: null
                        }))
                    }} productId={showDetail.productId} loadData={loadItemDetail}
                        loadCart={() => {
                            loadItemCart(setCart)
                        }}
                    />
                }
            </SlideDialog>
            {/* <BottomSheet
                open={showBottomSheet}
                onDismiss={() => {setShowBottomSheet(false)}}
            >
                <Grid container p={2}>
                    <Grid container pt={2}>
                        <Grid item xs={6}>
                            <MKButton fullWidth
                                onClick={() => {
                                    setFilter({
                                        name: '',
                                        reset: !filter.reset
                                    })
                                    setShowBottomSheet(false)
                                }}
                                sx={{
                                    
                                }}
                            >Reset</MKButton>
                        </Grid>
                        <Grid item xs={6}>
                            <MKButton fullWidth color="primary"
                                onClick={() => {
                                    setShowBottomSheet(false);
                                    loadItems()
                                }}
                            >Filter</MKButton>
                        </Grid>
                    </Grid>
                </Grid>
            </BottomSheet> */}
        </>
    )
}

export default Rent

async function loadItemDetail (id) {
    let { data, error } = await getItemDetail(id)
    // check first if there is any sessions
    if (error) { return { error } }
    if (data) {
        return {
            data: data.item,
            userSessions: data.userSessions,
            itemsOrder: data.itemsOrder
        }
    }
}
import React, { useState, useEffect } from "react";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function SlideDialog ({
    title,
    isOpen,
    maxWidth,
    minHeight = '90vh',
    maxHeight,
    setIsOpen,
    children,
    action,
    disableBackdrop = false,
    useDialogContent = true
}) {

    const handleClose = (event, reason) => {
      if (disableBackdrop && (reason && reason == "backdropClick")) {
        return;
      }  
      setIsOpen(false)
    }

    const paperStyle = maxHeight ? {
      sx: {
        minHeight: minHeight,
        maxHeight: maxHeight
      }
    } : {
      sx: {
        minHeight: minHeight
      }
    }

    return (
        <Dialog
            PaperProps={{
              ...paperStyle
            }}
            fullWidth={true}
            maxWidth={window.innerWidth >= 444 ? 'xs' : maxWidth}
            open={isOpen}
            height={'100vh'}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
          >
            {
              title &&
              <DialogTitle>{title}</DialogTitle>
            }
            {
              !useDialogContent && children
            }
            {
              useDialogContent &&
              <DialogContent>
                {/* <DialogContentText id="alert-dialog-slide-description">
                  {children}
                </DialogContentText> */}
                {children}
              </DialogContent>
            }
            {
              action &&
              <DialogActions>
                {action}
              </DialogActions>
            }
        </Dialog>
    )
}

export default SlideDialog;
import { client, errorValidation } from "./service";

export const getCoachList = async function (place) {
  try {
      let response = await client.get(`/coaches`);
      if (response.data.statusCode !== 200) { throw response.data.data; }
      let data = response.data.data;
      return { data };
  } catch (e) {
      // return { error: e.response.data.errorCode }
      return errorValidation(e)
  }
}

export const applyCoach = async function (body) {
  try {
    let response = await client.post(`/coach/apply`, {
      ...body
    });
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data };
  } catch (error) {
    return errorValidation(error)
  }
}

export const editCoach = async function (body) {
  try {
    console.log('hello world')
    let response = await client.post(`/coach/update`, {
      ...body
    });
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data };
  } catch (error) {
    console.log(error)
    return errorValidation(error)
  }
}

export const getCoachDetail = async function (coachId) {
  try {
    let response = await client.get(`/coach/${coachId}`);
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data };
  } catch (e) {
    // return { error: e.response.data.errorCode };
    return errorValidation(e)
  }
}

export const getCoachRatingList = async function (coachId, userId) {
  try {
    let response = await client.get(`/coach/rating-list?coachId=${coachId}&userId=${userId || ""}`);
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data };
  } catch (e) {
    // return { error: e.response.data.errorCode };
    return errorValidation(e)
  }
}

export const giveCoachRatingService = async function (body) {
  try {
    let response = await client.post(`/coach/rating`, {
      ...body
    });
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data };
  } catch (error) {
    return errorValidation(error)
  }
}

export const updateCoachRatingService = async function (body) {
  try {
    let response = await client.post(`/coach/rating-update`, {
      ...body
    });
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data };
  } catch (error) {
    return errorValidation(error)
  }
}
import { getUserInfo, getCourtInfo } from 'helper/localStorage';
import { Confirm } from 'helper/showAlert';
import { getUserSellItemCart } from 'service/addOnService';
import { submitOrderItemv2 } from 'service/addOnService';
import { getUserCart, getUserItemCart, deleteUserCart, addItemtoCart, getProductSchedule, submitOrderItem } from "service/addOnService";

export const loadCart = async (setCart) => {
    try {
        let { data, error } = await getUserCart()
        if (error) { throw error }
        if (data) { setCart(data) }
    } catch (error) {
        // Confirm("", error)
        return { error }
    }
}

export const loadItemCart = async (setCart) => {
    try {
        let { data, error } = await getUserItemCart()
        if (error) { throw error }
        if (data) { setCart(data) }
    } catch (error) {
        console.log('here error', error)
        return { error }
    }
}

export const loadSellItemCart = async (setCart) => {
    try {
        let { data, error } = await getUserSellItemCart()
        if (error) { throw error }
        if (data) { setCart(data) }
    } catch (error) {
        console.log('here error', error)
        return { error }
    }
}

export const getServiceSchedule = async (body) => {
    try {
        let placeInfo = getCourtInfo ? JSON.parse(getCourtInfo) : null
        if (!placeInfo) { throw "Error" }
        console.log({
            ...body,
            placeId: placeInfo._id
        })
        let { data, error } = await getProductSchedule({
            ...body,
            placeId: placeInfo._id
        })
        if (error) { throw error }
        if (data) { return { data } }
    } catch (error) {
        Confirm("", error)
        return { error }
    }
}

export const addCart = async (body, loadData) => {
    try {
        let placeInfo = getCourtInfo ? JSON.parse(getCourtInfo) : null
        if (!placeInfo) { throw "Error" }
        console.log({
            ...body,
            placeId: placeInfo._id
        })
        let { data, error } = await addItemtoCart({
            ...body,
            placeId: placeInfo._id
        })
        console.log('error')
        if (error) { Confirm("", error ) }
        loadData()
    } catch (error) {
        Confirm("", error)
    }
}

export const deleteCart = async (body, loadData) => {
    try {
        let { data, error } = await deleteUserCart(body)
        if (error) { Confirm("", error ) }
        loadData()
    } catch (error) {
        Confirm("", error)
    }
}

export const submitItemOrder = async (body, navigate) => {
    try {
        let placeInfo = getCourtInfo ? JSON.parse(getCourtInfo) : null
        if (!placeInfo) { throw "Error" }
        console.log({
            ...body,
            placeId: placeInfo._id
        })
        let { data, error } = await submitOrderItem({
            ...body,
            placeId: placeInfo._id
        })
        if (error) { Confirm("", error ) }
        if (data) { navigate(`/payment/${data._id}/add-ons?page=add-ons`)  }
    } catch (error) {
        Confirm("", error )
    }
}

export const submitItemOrderv2 = async (body, loadCart, setLoading, navigate) => {
    try {
        let placeInfo = getCourtInfo ? JSON.parse(getCourtInfo) : null
        if (!placeInfo) { throw "Error" }
        console.log({
            ...body,
            placeId: placeInfo._id
        })
        let { data, error } = await submitOrderItemv2({
            ...body,
            placeId: placeInfo._id
        })
        console.log(data)
        if (error) { Confirm("", error ); loadCart(); setLoading(false); }
        if (data) { navigate(`/payment/${data._id}/add-ons?page=add-ons`)  }
    } catch (error) {
        loadCart();  Confirm("", error ); setLoading(false);
    }
}
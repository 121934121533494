import React, { useEffect, useState } from "react";

import moment from "moment";
import { Grid, Icon } from "@mui/material";

import { color, timeStringFormat, courtTypeObj } from "helper/constant";
import { Confirm } from "helper/showAlert";

import { TCLabel } from "components/TopCourtComponents/Label/TopCourtLabel";
import { orderStatusMap } from "helper/constant";

function ScheduleCard ({
    order,
    value,
    setShowQR,
    hideQR = false
}) {

    return (
        <Grid style={{marginTop: 5}} sx={{
            border: `0.5px solid ${color.primary}`,
            borderRadius: 2
        }}>
            <div className='custom-body' style={{padding: 10}}>
                <TCLabel style={{fontSize: 12, fontWeight: 'bold'}}>{moment(value.date).format("ddd DD/MM/YYYY")}</TCLabel>
                {
                    value.data.map( (v, index) => {
                        return(
                            <>
                                <div style={{display: 'flex', alignItems: "top", marginTop: 2}}>
                                    <div style={{width: '100%'}}>
                                        <TCLabel style={{fontSize: 12, fontWeight: 'bold' }}>{courtTypeObj[v.court.courtType]} {v.court.name}</TCLabel>
                                        <Grid container>
                                        {
                                            v.hours.map((h, index) => {
                                                return <Grid item>
                                                    <TCLabel style={{fontSize: 12}}>{`${h} - ${timeStringFormat(Number(h.split(':')[0]) + 1)}`}{index < (v.hours.length - 1) ? <span style={{padding: 3, fontFamily: 'roboto', fontWeight: 50}}>{"|"}</span> : ""}
                                                    {/* ${index < (v.hours.length - 1) ? " | " : ""} */}
                                                    </TCLabel>
                                                </Grid>
                                            })
                                        }
                                        </Grid>
                                        {/* <TCLabel>Total: <span style={{fontWeight: 'bold' }}>{currencyFormat(v.total)}</span></TCLabel> */}
                                    </div>
                                    <div>
                                    {
                                        (order.isPaid && orderStatusMap(order) != "Completed" && !hideQR) && 
                                        <Grid className="element element-1" xs={12}
                                            sx={{
                                                color: order.isPaid ? '#ffff' : color.grey,
                                                width: 40,
                                                height: 40,
                                                mt: 0.5
                                            }} 
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                if (order.isPaid) {
                                                    setShowQR({
                                                        open: true,
                                                        courtInfo: {
                                                            ...v.court,
                                                            hours: v.hours,
                                                            date: value.date
                                                        },
                                                        data: v,
                                                        uniqueCode: v.uniqueCode,
                                                        value: v.uniqueCode
                                                    })
                                                } else {
                                                    Confirm("", "Make payment to unlock the QR.")
                                                }
                                            }
                                        }>
                                            <Icon fontSize={'large'}>qr_code_2</Icon>
                                        </Grid>
                                    }
                                    </div>
                                </div>
                                { 
                                    (index < (value.data.length - 1)) && 
                                    <div className="separation-line"/>
                                }
                            </>
                        )
                    })
                }
            </div>
        </Grid>
    )
}

export default ScheduleCard
import React, { useState, useEffect } from "react";

import { Badge, Checkbox, FormControlLabel, Grid, Icon } from "@mui/material";
import DialogHeader from "components/SlideDialog/DialogHeader";
import { TCLabel } from "components/TopCourtComponents/Label/TopCourtLabel";
import { TCInput } from "components/TopCourtComponents/Input/TopCourtInput";
import { TCSelect } from "components/TopCourtComponents/Input/TopCourtInput";
import MKButton from "components/MKButton";
import { color } from "helper/constant";
import { updatePlayerInfo } from "service/playerService";
import { Confirm } from "helper/showAlert";
import { setUserInfo } from "helper/localStorage";
import { validation } from "helper/form";
import TCNavigationBar from "components/TopCourtComponents/NavigationBar/TopCourtNavigationBar";
import { getMe } from "service/authService";
import MKAvatar from "components/MKAvatar";
import { getUserAvatar } from "helper/user";
import tennis from "../../assets/logo/tennis.png";
import { handleUpload } from "helper/S3";
import Loading from "components/TopCourtComponents/Loading/TCLoadingComponent";

import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import ChangeNumber from "pages/Auth/ChangeNumber";
import SlideAlert from "components/SlideInAlert/SlideAlert";
import { useNavigate } from "react-router-dom";
import { getUserInfo } from "helper/localStorage";

function ProfileDetail ({
    data,
    closeDialog
}) {

    const userInfo = getUserInfo ? JSON.parse(getUserInfo) : null

    const [ form, setForm ] = useState( userInfo ? userInfo : {
        name: "",
        phoneNumber: "",
        alternativePhoneNumber: "",
        gender: "male",
        birthYear: null,
    })
    
    const [ loading, setLoading ] = useState(false)
    const [ bottomSheetOpen, setBottomSheetOpen ] = useState(false)

    const [ joi, setJOI ] = useState({
        name: "",
        gender: "",
        birthYear: "",
        confirmPassword: "",
    })

    const navigate = useNavigate()

    const [ showAlert, setShowAlert ] = useState(false)
    const [ updatePassword, setUpdatePassword ] = useState(false)

    useEffect(() => {
        setJOI({
            name: (!form.name || validation.isWhitespaceString(form.name))? "*" : null,
            gender: !form.gender ? "*" : null,
            // birthYear: !form.birthYear ? "*" : null,
            birthYear: (!form.birthYear || form.birthYear.length < 4) ? "*" : !checkYearInformation(form.birthYear) ? `Birth date must between ${yearMaxMinInformation().min} and ${yearMaxMinInformation().max}` : null,
            confirmPassword: (form.password && !validation.isSame(form.password, form.confirmPassword)) ? "password confirmation not match" : null
        })
    }, [form])

    useEffect(() => {
        loadMe()
    }, [])

    const loadMe = async () => {
        let { data, error } = await getMe()
        if (error) { console.log(error) }
        if (data) {  
            // let birthYears = getYearOptions()
            let formValue = {
                ...data
            }
            // for (let i = 0; i < birthYears.length; i ++) {
            //     if (data.birthYear == birthYears[i].value) {  
            //         formValue.birthYear = birthYears[i]
            //         break; 
            //     }
            // }
            if (formValue && !validation.userName(formValue.name)) { setJOI({ name: "Name maximum character is 20" });
            } else { if (joi.name) { setJOI({ name: "" }); } }
            setForm( prev => ({
                ...prev,
                ...formValue,
                password: ""
            }))
        }
    }

    const onChange = (e) => {
        // check name
        if (e.name && !validation.userName(e.name)) { 
            return;
        }
        if (e.alternativePhoneNumber) {
            if(!validation.phoneNumber(e.alternativePhoneNumber)) { return; }
        }
        setForm(prev => ({
            ...prev,
            ...e
        }))
    }

    const updateProfileImage = async (image) => {
        setLoading(true)
        let imageUrl = await handleUpload({
            selectedFile: image, dirName: `topcourt-staging/${form._id}/profile-pic`
        })
        let newData = {
            ...form
        }
        if (form.birthYear) {
            newData.birthYear = form.birthYear.value
        }
        let { data, error } = await updatePlayerInfo({
            ...newData,
            profilePic: imageUrl[0]
        })
        // setForm( prev => ({
        //     ...prev,
        //     profilePic: imageUrl[0]
        // }))
        if (data) { 
            setForm(prev => ({
                ...prev,
                profilePic: imageUrl[0]
            })) 
            setUserInfo(data); 
        }
        setLoading(false)
    }

    const updateUserDetail = async (e) => {
        if (joi.name) { Confirm("", joi.name); return; }
        if (!form.name) { Confirm("", "Name can't be empty"); return; }
        if (!form.birthYear) { Confirm("", "Birth year can't be empty"); return; }
        let newData = {
            ...form
        }
        // if (form.birthYear) {
        //     newData.birthYear = form.birthYear.value
        // }
        let { data, error } = await updatePlayerInfo({
            ...newData
        })
        if (data) { setUserInfo(data) }
        Confirm("", "Update profile success")
        setTimeout(() => navigate('/'), 1000);
    }

    return (
        <>
            <input id="photo-upload" type="file" accept="image/*" style={{display: 'none'}} onChange={(e) => {
                updateProfileImage(e.target.files[0])
            }}/> 
            <TCNavigationBar transparent={false} fixed title={"Update Profile"}/>
            <Grid container flexBasis={'100vh'} p={2} pt={0} paddingBottom={10} direction={"column"} spacing={1}>
                <Grid item xs={12} mt={2} textAlign={"center"}>
                    <Badge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        badgeContent={form ? 
                        <label htmlFor="photo-upload" className="fas" style={{backgroundColor: color.primary, color: 'white', borderRadius: 10, width: 20, height: 20, 
                            display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <Icon>create</Icon>
                        </label> : <></>}
                    >
                        <MKAvatar 
                            bgColor={"white"}
                            src={form ? getUserAvatar(form) : tennis} size="xxl"/>
                    </Badge>
                </Grid>
                <Grid item xs={12} >
                    <TCInput 
                        showAlert={joi.name}
                        fullWidth value={form.name} label={"Name"} onChange={(e) => {
                            let newValue = e.currentTarget.value.replace(/[\p{Emoji}\p{Emoji_Modifier}\p{Emoji_Component}\p{Emoji_Modifier_Base}\p{Emoji_Presentation}]/gu, '');
                            let regex = /^[a-zA-Z ]*$/
                            if (!regex.test(newValue)) { return ;}
                            onChange({name: newValue})
                    }} />
                </Grid>
                <Grid item container xs={12} spacing={1}>
                    <Grid item xs={9}>
                        <TCInput fullWidth label={"Reg Phone Number"} value={form.phoneNumber} disabled={true} />
                    </Grid>
                    <Grid item xs={3} mt={2}>
                        <MKButton color={"primary"}
                        fullWidth
                        onClick={() => {
                            setShowAlert(true)
                        }}
                        >Change</MKButton>
                    </Grid>
                </Grid>
                <Grid item xs={12} >
                    <TCInput fullWidth value={form.alternativePhoneNumber} label={"Alternative Phone Number"} onChange={(e) => {
                        onChange({alternativePhoneNumber: e.currentTarget.value})
                    }}/>
                </Grid>
                <Grid item xs={12} >
                    {/* <TCSelect label={"Birth Year"}
                        value={form.birthYear}
                        options={getYearOptions()}
                        onChange={(e) => {
                            onChange({birthYear: e})
                        }} 
                    /> */}
                    <TCInput label={"Birth Year"} showAlert={joi.birthYear} 
                        type={"number"}
                        placeHolder={"ex: 1988"}
                        value={form.birthYear}
                        onChange={(e) => {
                            if (e.currentTarget.value.length > 4) { return; }
                            onChange({birthYear: e.currentTarget.value})
                        }} 
                    />
                </Grid>
                <Grid item xs={12} >
                    <TCLabel>Gender</TCLabel>
                    <Grid item container>
                        <Grid item>
                            <FormControlLabel control={<Checkbox checked={form.gender == "male" ? true : false} onChange={() => {
                                onChange({gender: "male"})
                            }} />} label={<TCLabel>Male</TCLabel>} />
                        </Grid>
                        <Grid item>
                            <FormControlLabel control={<Checkbox checked={form.gender == "female" ? true : false} onChange={() => {
                                onChange({gender: "female"})
                            }} />} label={<TCLabel>Female</TCLabel>} /> 
                        </Grid>
                    </Grid>
                </Grid>
                {
                    !updatePassword &&
                    <Grid item container xs={12} spacing={1}>
                        <Grid item xs={9} >
                            <TCInput type={"password"} disabled={true} label={"Password (minimum 6 character)"} value={"******"}/>
                        </Grid>
                        <Grid item xs={3} mt={2}>
                            <MKButton color={"primary"}
                            fullWidth
                            onClick={() => {
                                setUpdatePassword(true)
                            }}
                            >Change</MKButton>
                        </Grid>
                    </Grid>
                }
                {
                    updatePassword &&
                    <>
                    <Grid item container xs={12} spacing={1}>
                        <Grid item xs={9}>
                            <TCInput type={"password"} label={"Password (minimum 6 character)"} value={form.password} onChange={(e) => {
                                onChange({password: e.currentTarget.value})
                            }}/>
                        </Grid>
                        <Grid item xs={3} mt={2}>
                            <MKButton fullWidth color="white" sx={{
                                border: 1,
                                color: color.primary
                            }} onClick={() => {
                                setUpdatePassword(false)
                            }}>
                                Cancel
                            </MKButton>
                        </Grid>
                    </Grid>
                    <Grid item xs={9} >
                        <TCInput type={"password"} label={"Confirm Password"} showAlert={joi.confirmPassword} value={form.confirmPassword} onChange={(e) => {
                            onChange({confirmPassword: e.currentTarget.value})
                        }}/>
                    </Grid>
                    </>
                }
                {/* {
                    updatePassword &&
                    <Grid item xs={12} >
                        <MKButton fullWidth color="white" sx={{
                            border: 1,
                            color: color.primary
                        }} onClick={() => {
                            setUpdatePassword(false)
                        }}>
                            Cancel
                        </MKButton>
                    </Grid>
                } */}
                <Grid item xs={12} flex={1} />
                {/* Request Cash Out */}
            </Grid>
            <div className="sticky-bottom">
                <Grid item p={2} mb={2} textAlign={"center"} xs={12} >
                    <MKButton disabled={(form.password && !validation.isSame(form.password, form.confirmPassword)) 
                    || (form.password && form.password.length < 6 )|| joi.name || joi.gender || joi.birthYear || joi.confirmPassword}
                    color="primary" fullWidth={true} onClick={() => { updateUserDetail() }}>Update</MKButton>
                </Grid>
            </div>
            <BottomSheet open={bottomSheetOpen} onDismiss={() => {setBottomSheetOpen(false)}}>
                <Grid p={2} sx={{minHeight: 200}} textAlign={"center"}>
                    <TCLabel>Are you sure want to change your number ?</TCLabel>
                    <MKButton color="primary">Change</MKButton>
                </Grid>
            </BottomSheet>
            <SlideAlert
                isOpen={showAlert} 
                setIsOpen={setShowAlert}
                maxWidth={"xs"}
            >
                <ChangeNumber isOpen={showAlert} dismissPopup={() => {
                    setShowAlert(false);
                    loadMe()
                }}/>
            </SlideAlert>
            { 
                loading && 
                <Loading/>
            }
        </>
    )
}

function getYearOptions () {
    let years = []
    let currentYear = new Date().getFullYear()
    let Ymin100 = currentYear - 100
    for (let i = currentYear; i > Ymin100; i --) {
        if (i < (new Date().getFullYear() - 5)) { 
            years.push({
                label: i,
                value: i
            })
        }
    }
    return years
}

function checkYearInformation (e) {
    if (e.length < 4) { return true }
    let currentYear = new Date().getFullYear()
    let Ymin100 = currentYear - 100
    if (Number(e) < Ymin100 || Number(e) >= currentYear) {
        return false
    }
    return true
}

function yearMaxMinInformation (e) {
    let currentYear = new Date().getFullYear()
    let Ymin100 = currentYear - 100
    return { min: Ymin100, max: currentYear}
}

export default ProfileDetail;
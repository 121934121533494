import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Grid, Icon, AppBar } from "@mui/material";

import { color } from "helper/constant";

import TCNavigationBar from "components/TopCourtComponents/NavigationBar/TopCourtNavigationBar";
import { TCLabel } from "components/TopCourtComponents/Label/TopCourtLabel";
import TCTabs from "components/TopCourtComponents/Tabs/TopCourtTabs";
import TCTabPanel from "components/TopCourtComponents/Tabs/TopCourtTabPanel";
import CompleteBooking from "./Bookings/Complete";
import OnProgressBooking from "./Bookings/OnProgress";
import { getTabMemos, memorizeTabs } from "helper/tabsMemo";

function Bookings ({

}) {
    
    const [ activeTab, setActiveTab ] = useState(Number(getTabMemos('bookings')) || 0)
    const navigate = useNavigate()

    return(
        <>
            <TCNavigationBar transparent={false} title={"My Booking"} action={
                <Grid justifyContent={"center"} textAlign={"center"} onClick={() => {
                    navigate('/court-booking')
                }}>
                    <Icon sx={{color: color.primary}}>eventicon</Icon>
                    <TCLabel subtitle>Schedule</TCLabel>
                </Grid>
            }/>
            <AppBar position="static">
                <TCTabs
                    activeTab={activeTab}
                    tabs={[
                        'On Going',
                        'Completed'
                    ]}
                    onChange={(event, newValue) => {
                        memorizeTabs('bookings', newValue)
                        setActiveTab(newValue)
                    }}
                />
                <TCTabPanel boxPadding={0} value={activeTab} index={0}>
                    <div style={{overflow: 'auto', height: '84vh', marginTop: 15, paddingRight: 16, paddingLeft: 16}}>
                        <OnProgressBooking/>
                    </div>
                </TCTabPanel>
                <TCTabPanel boxPadding={0} value={activeTab} index={1}>
                    <div style={{overflow: 'auto', height: '84vh', marginTop: 15, paddingRight: 16, paddingLeft: 16}}>
                        <CompleteBooking/>
                    </div>
                </TCTabPanel>
            </AppBar>
        </>
    )
}
export default Bookings
import React from "react"

import moment from "moment"
import { Grid, Icon } from "@mui/material"
import { TCLabel } from "components/TopCourtComponents/Label/TopCourtLabel"
import { color } from "helper/constant"
import { timeStringFormat } from "helper/constant"
import { orderStatusMap } from "helper/constant"
import { Confirm } from "helper/showAlert"
import { courtTypeObj } from "helper/constant"


function ClassScheduleCard ({ value, data, setQRCodePopup }) {
    return (
        <Grid style={{marginTop: 5}} sx={{
            border: `0.5px solid ${color.primary}`,
            borderRadius: 2
        }}>
            <div className='custom-body' style={{padding: 10, paddingBottom: 0}}>
                <TCLabel bold>{moment(value.date).format("ddd DD/MM/YYYY")}</TCLabel>
            </div>
            <div className='custom-body' style={{display: 'flex', alignItems: "top", padding: 10, paddingTop: 0}}>
                <div style={{width: '100%'}}>
                    {
                        <TCLabel style={{fontSize: 12, fontWeight: 'bold'}}>{value.courtInfo && `${courtTypeObj[value.courtInfo.courtType]} ${value.courtInfo.name}`} : {value.name}</TCLabel>
                    }
                    <Grid container direction={'row'}>
                    {
                        value.hours && value.hours.map((h, index) => {
                            return <TCLabel style={{marginRight: 3, fontSize: 12}}>{`${h} - ${timeStringFormat(Number(h.split(':')[0]) + 1)} 
                                ${index < (value.hours.length - 1) ? " | " : ""}`}</TCLabel>
                        })
                    }
                    </Grid>
                </div>
                {
                    ((data.isPaid && orderStatusMap(data) != "Completed")) &&
                    <div>
                        <Grid className="element element-1" xs={12}
                            sx={{
                                color: data.isPaid ? '#ffff' : color.grey,
                                width: 40,
                                height: 40,
                                mt: 0.5
                            }} 
                            onClick={(e) => {
                                e.stopPropagation()
                                if (data.isPaid) {
                                    setQRCodePopup({
                                        open: true,
                                        courtInfo: {
                                            ...data.court,
                                            ...value.courtInfo,
                                            hours: value.hours,
                                            date: value.date
                                        },
                                        data: {
                                            ...data,
                                            eventName: value.name,
                                            date: value.date,
                                            hours: value.hours,
                                        },
                                        uniqueCode: value.uniqueCode ? value.uniqueCode : data.uniqueCode,
                                        value: value.uniqueCode ? value.uniqueCode : data.uniqueCode
                                    })
                                } else {
                                    Confirm("", "Make payment to unlock the QR.")
                                }
                            }
                        }>
                            <Icon fontSize={'large'}>qr_code_2</Icon>
                        </Grid>
                    </div>
                }
            </div>
        </Grid>
    )
}

export default ClassScheduleCard
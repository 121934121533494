import { client, errorValidation } from "./service";

export const getCouponPopup = async function () {
  try {
      let response = await client.get(`/coupon/popup`);
      if (response.data.statusCode !== 200) { throw response.data.data; }
      let data = response.data.data;
      return { data };
  } catch (e) {
    //   return { error: e.response.data.message }
    return errorValidation(e)
  }
}

export const checkCoupon = async function (codePromo, totalSession, subTotal) {
    try {
        let response = await client.get(`/coupon/check?codePromo=${codePromo}&totalSessions=${totalSession || 0}&subTotal=${subTotal || 0}`);
        if (response.data.statusCode !== 200) { throw response.data.data; }
        let data = response.data.data;
        return { data };
    } catch (e) {
        // return { error: e.response.data.message }
        return errorValidation(e)
    }
}
import { Grid } from "@mui/material";
import MKButton from "components/MKButton";
import { TCLabel, TCTitleLabel } from "components/TopCourtComponents/Label/TopCourtLabel";
import { Confirm } from "helper/showAlert";
import React, { useEffect, useState } from "react";

import OtpInput from 'react-otp-input';
import { sendOTPService } from "service/authService";

function OTP({
    phoneNumber, otp, setOtp, submit
}) {

    const [ timer, setTimer ] = useState(60)
    const [ resendCount, setResendCount ] = useState(0)
    
    useEffect(() => {
        if (!timer || timer <= 0) { return; }

        const intervalId = setInterval(() => {
            setTimer(timer - 1);
          }, 1000);

        return () => clearInterval(intervalId);

    }, [timer])

    const resendOTP = async () => {
        let countResend = resendCount + 1
        let { data, error } = await sendOTPService({
            phoneNumber,
            resend: true,
            resendWithDevice: countResend >= 2 ? true : false
        })
        if (error) { console.log(error) }
        if (data) { 
            console.log(data)
            // Confirm("", "")
        }
        setResendCount(countResend)
        setTimer(180)
    }

    return(
        <>
            <Grid sx={{p: 1}} justifyContent={"center"} textAlign={"center"}>
                <TCTitleLabel style={{fontSize: 18}}>Verification Code</TCTitleLabel>
                <TCLabel style={{fontSize: 12}}>You will get a OTP via SMS</TCLabel>
            </Grid>
            <OtpInput
                containerStyle={{
                    justifyContent: 'center'
                }}
                inputType="tel"
                value={otp}
                onChange={setOtp}
                numInputs={6}
                renderSeparator={<div style={{padding: 8}}/>}
                renderInput={(props) => <input {...props} />}
                inputStyle={{
                    fontSize: 20,
                    width: 30,
                    height: 35,
                    borderColor: '#34476750',
                    borderRadius: 6
                }}
            />
            <Grid container sx={{p: 1, mb: 3}} spacing={0.5} justifyContent={"center"} textAlign={"center"}>
                <Grid item><TCLabel style={{fontSize: 12}}>Didn't receive OTP ?</TCLabel></Grid>
                <Grid item>
                    {
                        timer > 0 ?
                        <TCLabel style={{fontSize: 12}}>{`resend in ${timer} second`}</TCLabel>
                        :
                        <Grid onClick={() => {
                            resendOTP()
                        }}>
                            <TCLabel style={{fontSize: 12}}>{`resend OTP`}</TCLabel>
                        </Grid>
                    }
                </Grid>
                {/* <TCLabel style={{fontSize: 12}}>Send otp to my whatsapp</TCLabel> */}
            </Grid>
            {/* <MKButton style={{width: '100%'}} color="primary" onClick={submit}>Continue</MKButton> */}
        </>
    )
}

export default OTP
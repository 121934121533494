import React from "react"

import DeleteIcon from '@mui/icons-material/Delete';
import ChecklistIcon from "../../../assets/checklist.png";
import { color } from 'helper/constant';

import "./style.css";
import { TCLabel } from "../Label/TopCourtLabel";
import { currencyFormat } from "helper/numberFormat";
import { Box, Grid } from "@mui/material";

function Coupon({
    data,
    setCoupon,
    setCouponCode
}) {
    return(
        <div style={{display:'flex', alignItems: 'flex-end' }}>
            <div style={{width: '100%'}}>
                <div className="">
                    <div style={{borderStyle: 'solid', 
                        borderColor: color.green,
                        borderRadius: 10,
                        // borderTopLeftRadius: 10, borderBottomLeftRadius: 10,
                        // backgroundColor: 'rgb(125, 207, 182, 0.5)'
                    }}>
                        <Grid container alignItems={"center"} pl={1} pr={1} pt={0.2} pb={0.2}>
                            <Grid item xs={1} md={1} alignItems={"center"}>
                                <img src={ChecklistIcon} style={{width: 30, height: 30, marginTop: 10}}/>
                            </Grid>
                            <Grid item xs={9} md={9} pl={1}>
                                <div><TCLabel bold>{data.promoCode}</TCLabel></div>
                                <div><TCLabel subtitle style={{color: 'grey'}}>{data.promoCodeType == "nominal" ? "-" : "Disc"} {data.promoCodeType == "nominal" ? currencyFormat(data.amount) : `${data.discountInfo}%`}</TCLabel></div>
                            </Grid>
                            <Grid item xs={2} md={2}>
                                <Box textAlign={"right"}>
                                    <div onClick={(e) => {
                                        e.stopPropagation()
                                        setCouponCode("")
                                        setCoupon(null)
                                    }}>
                                        <DeleteIcon style={{width: 20, height: 20, color: color.pink}}/>
                                        {/* <TCLabel style={{fontSize: 12, color: color.pink, fontWeight: 'bold'}}></TCLabel> */}
                                    </div>
                                </Box>
                            </Grid>
                        </Grid>
                    </div>
                    {/* <div className="circle1-used"></div>
                    <div className="circle2-used"></div> */}
                </div>
            </div>
            {/* <div style={{width: '10%'}}>
                <div style={{width: '100%', textAlign: 'right'}} onClick={(e) => {
                    e.stopPropagation()
                    setCouponCode("")
                    setCoupon(null)
                }}>
                    <DeleteIcon style={{width: 20, height: 20, color: color.orange}}/>
                </div>
            </div> */}
        </div>
    )
}

export default Coupon
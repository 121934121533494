import React from "react"
const { Tabs, Tab } = require("@mui/material")
import { color } from "helper/constant"
import { TCLabel } from "../Label/TopCourtLabel"

function TCTabs ({
    tabs,
    activeTab,
    onChange
}) {
    return (
        <Tabs value={activeTab} 
            TabIndicatorProps={{
                style: {
                    backgroundColor: color.blueIndoor_2
                }
            }} sx={{m: 2, mb: 0}} 
                onChange={(event, newValue) => {
                    onChange(event, newValue)
            }}
        >
            {
                tabs.map((value, index) => {
                    return (<Tab label={<TCLabel style={activeTab == index ? {color: "white"} : {}}>{value}</TCLabel>} />)
                })
            }
        </Tabs>
    )
}

export default TCTabs
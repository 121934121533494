import React, { useEffect, useState } from "react"

import { Grid, Box, Icon, Card, Button, Avatar } from "@mui/material";

import TCNavigationBar from "components/TopCourtComponents/NavigationBar/TopCourtNavigationBar";
import { TCLabel, TCTitleLabel } from "components/TopCourtComponents/Label/TopCourtLabel";
import { getPlayerDetail } from "service/playerService";
import moment from "moment";
import { getUserName } from "helper/user";
import { getUserAvatar } from "helper/user";

function Player ({
    
}) {

    const url = window.location.href.split('/')
    const playerId = url[url.length - 1] 
    const [ loading, setLoading ] = useState(false)
    const [ playerInfo, setPlayerInfo ] = useState(null)


    useEffect(() => {
        loadPlayerDetail()
    }, [])

    const loadPlayerDetail = async () => {
        setLoading(true)
        let { data, error } = await getPlayerDetail(playerId);
        if (data) { setPlayerInfo(data) }
        console.log(data)
        setLoading(false)
    }

    return (
        <>
            <TCNavigationBar transparent={false} title={playerInfo ? playerInfo.name : "Player"}/>
            <Grid>
                <img 
                    style={{height: 150, width: '100%', objectFit: 'cover'}}
                    src={playerInfo && playerInfo.bannerPic ? playerInfo.bannerPic : "https://top-court.s3.ap-southeast-1.amazonaws.com/top-court/unsplash-tennis.jpg"}
                />
            </Grid>
            <Grid sx={{p: 2, mt: -15}} direction={"column"}>
                <Avatar sx={{width: 120, height: 120, border: '0.1px solid'}} src={getUserAvatar(playerInfo)}/>
                {/* Header ikutin kyk Linkedin */}
                <Grid>
                    <TCTitleLabel>{playerInfo && getUserName(playerInfo)}</TCTitleLabel>
                    <TCLabel>{playerInfo && playerInfo.status || "Regular"}</TCLabel>
                </Grid>
                <Grid sx={{pt: 2}}>
                    <TCTitleLabel>Recent Matches</TCTitleLabel>
                    <Card sx={{p: 2}}>
                        <TCLabel>{moment(Date.now()).format("DD/MM/YYYY")}</TCLabel>
                        <TCLabel bold={true}>Grand Slam</TCLabel>
                        <TCLabel>vs Ben Shelton</TCLabel>
                    </Card>
                </Grid>
                {/* <Grid>List Pertandingan terakhir ?</Grid> */}
            </Grid>
        </>
    )
}

export default Player
import React from "react";
import {
    InputAdornment,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@mui/material";
import {
    defaultCountries,
    FlagImage,
    parseCountry,
    usePhoneInput
} from "react-international-phone";
import { TCLabel } from "../Label/TopCourtLabel";

export default function MuiPhone ({
    value,
    onChange,
    enterAction,
    ...restProps
}) {
    
    const {
        phone,
        forceDialCode,
        handlePhoneValueChange,
        inputRef,
        country,
        setCountry
    } = usePhoneInput({
        forceDialCode: true,
        defaultCountry: "id",
        value,
        // countries: defaultCountries,
        countries: [["Indonesia", "id", "62"]],
        onChange: (data) => {
            // const re = /^[0-9\b]+$/;
            // // if value is not blank, then test the regex
            // if (data.phone === '' || re.test(data.phone)) {
            //     onChange(data.phone);
            // }
            let phoneValue = data.phone
            if (data.phone == "+0") {
                phoneValue = "+62"
            }
            if (data.phone == "+" || data.phone == ""
            ||data.phone == "+6" || data.phone == "+2") {
                phoneValue = "+62"
            }
            phoneValue = phoneValue.replace("+620", "+62")
            onChange(phoneValue);
        }
    });

    return (
        <TextField
            fullWidth
            variant="outlined"
            label=""
            color="primary"
            placeholder="Phone number"
            value={value}
            onChange={handlePhoneValueChange}
            type="tel"
            inputRef={inputRef}
            onKeyDown={(e) => {
                if(e.key == 'Enter') {
                    if (enterAction) {
                        enterAction()
                    }
                }
            }}
            sx={{
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "#344767"
                  },
                  "&.Mui-disabled input": {
                    backgroundColor: "#f5f5f5"
                  }
                },
                input: {
                    border: "none",
                    margin: 0,
                    padding: 1,
                    borderRadius: 2,
                    backgroundColor: "#f8fafc",
                    fontSize: '16px'
                },
                "& fieldset": { border: 'none' },
            }}
            InputProps={{
                startAdornment: (
                    <InputAdornment
                        position="start"
                        style={{ marginRight: "2px", marginLeft: "-8px" }}
                    >
                        <Select
                            disabled={true} // default Indo
                            MenuProps={{
                                style: {
                                    height: "300px",
                                    width: "360px",
                                    top: "10px",
                                    left: "-24px"
                                },
                                transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left"
                                }
                            }}
                            sx={{
                                width: "max-content",
                                // Remove default outline (display only on focus)
                                fieldset: {
                                    display: "none"
                                },
                                '&.Mui-focused:has(div[aria-expanded="false"])': {
                                    fieldset: {
                                        display: "block"
                                    }
                                },
                                // Update default spacing
                                ".MuiSelect-select": {
                                    padding: "8px",
                                    paddingRight: "24px !important"
                                },
                                svg: {
                                    right: 0
                                }
                            }}
                            value={country}
                            onChange={(e) => setCountry(e.target.value)}
                            renderValue={(value) => (
                                <FlagImage itemID={value} iso2={value.iso2} style={{ display: "flex" }} />
                            )}
                        >
                            {
                                defaultCountries.map((c) => {
                                    const country = parseCountry(c);
                                    return (
                                    <MenuItem key={country.iso2} value={country.iso2}>
                                        <FlagImage
                                            itemID={value}
                                            iso2={country.iso2}
                                            style={{ marginRight: "8px" }}
                                        />
                                        <TCLabel marginRight="8px">{country.name}</TCLabel>
                                        <TCLabel color="gray">+{country.dialCode}</TCLabel>
                                    </MenuItem>
                                )}
                            )}
                        </Select>
                    </InputAdornment>
                )
            }}
            {...restProps}
        />
    );
}
import React, { useState, useEffect } from "react";
// import Lottie from "react-lottie";
// import loadingTennis from "../../../assets/lottiejson/tennis_loading.json";
import loadingScreen from "../../../assets/loading-screen.gif";

export default function LoadingMini ({

}) {

    return (
        <div style={{textAlign: 'center'}}>
            <img src={loadingScreen} height={'30px'} width={'30px'}/>
        </div>
    )
}
import { client, errorValidation } from "./service";

export const getItems = async function (filter, type) {
  try {
    let response = await client.get(`/items/list?name=${filter.name || ""}&type=${type || ""}`);
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data };
  } catch (e) {
    // return { error: e.response.data.errorCode }
    return errorValidation(e)
  }
}

export const getServiceHours = async function (
  date
) {
  try {
    let response = await client.get(`/items/schedule-hours?date=${date}`);
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data };
  } catch (e) {
    return errorValidation(e);
  }
}

export const getServiceSummarySchedule = async function (
  date, hour, placeId
) {
  try {
    let response = await client.get(`/items/schedule-summary?date=${date}&hour=${hour}&placeId=${placeId}`);
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data };
  } catch (e) {
    return errorValidation(e);
  }
}

export const getUserAddOnService = async function (filter) {
  try {
    let response = await client.get(`/items/user-service?name=${filter.name || ""}&service=${filter.status || ""}`);
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data };
  } catch (e) {
    // return { error: e.response.data.errorCode }
    return errorValidation(e)
  }
}

export const getMyAddOn = async function (filter) {
  try {
    let response = await client.get(`/items/my-addon?date=${filter.date || ""}&hours=${filter.hours || ""}`);
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data };
  } catch (e) {
    // return { error: e.response.data.errorCode }
    return errorValidation(e)
  }
}

export const getUserAddOnServiceUser = async function (filter, userId) {
  try {
    let response = await client.get(`/items/user-service/id/${userId}?name=${filter.name}&service=${filter.status || ""}`);
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data };
  } catch (e) {
    // return { error: e.response.data.errorCode }
    return errorValidation(e)
  }
}

export const getItemDetail = async function (itemId) {
  try {
    let response = await client.get(`/item/${itemId}`);
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data }
  } catch (e) {
    // return { error: e.response.data.errorCode }
    return errorValidation(e)
  }
}

export const getItemSellDetail = async function (itemId, qty) {
  try {
    let response = await client.get(`/item-sell/${itemId}?qty=${qty||1}`);
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data }
  } catch (e) {
    // return { error: e.response.data.errorCode }
    return errorValidation(e)
  }
}

export const getUserServiceDetail = async function (itemId) {
  try {
    let response = await client.get(`/items/user-service/${itemId}`);
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data }
  } catch (e) {
    // return { error: e.response.data.errorCode }
    return errorValidation(e)
  }
}

export const getItemAvailbility = async function (body) {
  try {
    let response = await client.post(`/item-schedule-availbility`, {
      ...body
    });
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data }
  } catch (e) {
    // return { error: e.response.data.errorCode }
    return errorValidation(e)
  }
}

export const getProductSchedule = async function (body) {
  try {
    console.log(body)
    let response = await client.post(`/item/schedule-list`, {
      ...body
    });
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    console.log(data)
    return { data }
  } catch (error) {
    return errorValidation(error)
  }
}

export const addItemtoCart = async function (body) {
  try { 
    console.log(body)
    let response = await client.post(`/item/add-to-cart`, {
      ...body
    });
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data }
  } catch (error) {
    return errorValidation(error)
  }
}

export const getUserCart = async function () {
  try  {
    let response = await client.get(`/items/cart-service`);
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data }
  } catch (e) {
    return errorValidation(e)
  }
}

export const getUserItemCart = async function () {
  try  {
    let response = await client.get(`/items/cart-item?type=rent`);
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data }
  } catch (e) {
    return errorValidation(e)
  }
}

export const getUserSellItemCart = async function () {
  try  {
    let response = await client.get(`/items/cart-item?type=sell`);
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data }
  } catch (e) {
    return errorValidation(e)
  }
}

export const deleteUserCart = async function (body) {
  try  {
    let response = await client.post(`/item/delete-cart`, {
      ...body
    });
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data }
  } catch (e) {
    return errorValidation(e)
  }
}

export const submitOrderItemv2 = async function (body) {
  try {
    let response = await client.post(`item/submit-order-cart`, {
      ...body
    });
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data }
  } catch (e) {
    return errorValidation(e)
  }
}

export const submitOrderItem = async function (body) {
  try {
    let response = await client.post(`/item/submit-order`, {
      ...body
    });
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data }
  } catch (e) {
    // return { error: e.response.data.errorCode }
    return errorValidation(e)
  }
}
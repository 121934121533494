import React, { useState, useEffect } from "react";

// import styled from "styled-components";
import { Container, Grid, Checkbox, FormControlLabel, Input, TextField } from "@mui/material";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

import { setUserInfo } from "helper/localStorage";
import { registerUser } from "service/authService";
import { TCTitleLabel } from "components/TopCourtComponents/Label/TopCourtLabel";
import { TCLabel } from "components/TopCourtComponents/Label/TopCourtLabel";
import { TCInput } from "components/TopCourtComponents/Input/TopCourtInput";
import Loading from "components/TopCourtComponents/Loading/TCLoadingComponent";
import { TCSelect } from "components/TopCourtComponents/Input/TopCourtInput";
import { validation } from "helper/form";
import { Confirm } from "helper/showAlert";
import { setUserToken } from "helper/localStorage";
import { color } from "helper/constant";

function Register ({
    userInfo, dismissPopup
}) {

    const [ showLoading, setShowLoading ] = useState(false)
    const [ form, setForm ] = useState({
        _id: userInfo ? userInfo._id : "",
        name: userInfo ? userInfo.name : "",
        phoneNumber: userInfo ? userInfo.phoneNumber : "",
        alternativePhoneNumber: userInfo ? userInfo.alternativePhoneNumber : "",
        gender: userInfo ? userInfo.gender : "",
        birthYear: (userInfo && userInfo.birthYear) ? {value: userInfo.birthYear, label: userInfo.birthYear} : "",
        password: "",
        confirmPassword: ""
    })

    const [ joi, setJOI ] = useState({
        name: "",
        gender: "",
        birthYear: "",
        password: "",
        confirmPassword: "",
    })

    useEffect(() => {
        setJOI({
            name: (!form.name || validation.isWhitespaceString(form.name)) ? "*" : null,
            gender: !form.gender ? "*" : null,
            birthYear: (!form.birthYear || form.birthYear.length < 4) ? "*" : !checkYearInformation(form.birthYear) ? `Birth date must between ${yearMaxMinInformation().min} and ${yearMaxMinInformation().max}` : null,
            password: !form.password ? "*" : null,
            confirmPassword: (form.password && !validation.isSame(form.password, form.confirmPassword)) ? "password confirmation not match" : null
        })
    }, [form])

    useEffect(() => {setShowLoading(false)}, [])

    const submitRegisterForm = async() => {
        if (joi.name) { Confirm("", joi.name); return; }
        if (!form.name) { Confirm("", "Name can't be empty"); return; }
        if (!form.birthYear) { Confirm("", "Birth year can't be empty"); return; }
        setShowLoading(true)
        let { data, error } = await registerUser({
            ...form,
            // birthYear: form.birthYear ? form.birthYear.value : null
        })
        if (error) { console.log(error); setShowLoading(false); }
        if (data) { 
            setShowLoading(false)
            setUserInfo(data)
            dismissPopup()
            window.location.reload()
        }
    }

    const onChange = (e) => {
        if (e.name && !validation.userName(e.name)) { 
            setJOI({ name: "Name maximum character is 20" }); 
            return;
        } else if (joi.name) { setJOI({ name: "" }); }
        if (e.alternativePhoneNumber) {
            if(!validation.phoneNumber(e.alternativePhoneNumber)) { return; }
        }
        setForm(prev => ({
            ...prev,
            ...e
        }))
    }

    const checkValidButton = () => {
        return !form.password || !validation.isSame(form.password, form.confirmPassword) || form.password.length < 6
        || joi.name || joi.gender || joi.birthYear || joi.confirmPassword
    }

    return (
        <>
            <Grid container>
                <Grid item xs={12} md={12} lg={12}>
                    <TCTitleLabel>Register</TCTitleLabel>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <TCLabel>Fill up the information below</TCLabel>
                </Grid>
                <Grid item xs={12} md={12} lg={12} sx={{ mt: 2 }}>
                    <TCInput fullWidth showAlert={joi.name}
                    value={form.name} label={"Name"} onChange={(e) => {
                        let newValue = e.currentTarget.value.replace(/[\p{Emoji}\p{Emoji_Modifier}\p{Emoji_Component}\p{Emoji_Modifier_Base}\p{Emoji_Presentation}]/gu, '');
                        let regex = /^[a-zA-Z ]*$/
                        if (!regex.test(newValue)) { return ;}
                        onChange({name: newValue})
                    }} />
                </Grid>
                <Grid item xs={12} md={12} lg={12} sx={{ mt: 1 }}>
                    <TCInput fullWidth label={"Reg Phone Number"} value={form.phoneNumber} disabled={true} />
                </Grid>
                <Grid item xs={12} md={12} lg={12} sx={{ mt: 1 }}>
                    <TCInput fullWidth value={form.alternativePhoneNumber} label={"Alternative Phone Number"} onChange={(e) => {
                        onChange({alternativePhoneNumber: e.currentTarget.value})
                    }}/>
                </Grid>
                <Grid item xs={12} md={12} lg={12} sx={{mt: 1}}>
                    {/* <TCSelect label={"Birth Year"}
                        showAlert={joi.birthYear}
                        value={form.birthYear}
                        options={getYearOptions()}
                        onChange={(e) => {
                            onChange({birthYear: e})
                        }} 
                        inDialog={true}
                    /> */}
                    <TCInput label={"Birth Year"} showAlert={joi.birthYear} 
                        type={"number"}
                        placeHolder={"ex: 1988"}
                        value={form.birthYear}
                        onChange={(e) => {
                            if (e.currentTarget.value.length > 4) { return; }
                            onChange({birthYear: e.currentTarget.value})
                        }} 
                    />
                </Grid>
                <Grid item xs={12} md={12} lg={12} sx={{mt: 1}}>
                    {/* <TCLabel>Gender {joi.gender}</TCLabel> */}
                    <Grid container alignItems={'flex-end'} spacing={1}>
                        <Grid item>
                            <TCLabel style={{fontSize: 13}}>Gender</TCLabel>
                        </Grid>
                        {
                            joi.gender &&
                            <Grid item sx={{pt:'0px !important'}}>
                                <TCLabel style={{fontSize: 11, color: color.pink}}>{joi.gender}</TCLabel>
                            </Grid>
                        }
                    </Grid>
                    <FormControlLabel control={<Checkbox checked={form.gender == "male" ? true : false} onChange={() => {
                        onChange({gender: "male"})
                    }} />} label={<TCLabel>Male</TCLabel>} />
                    <FormControlLabel control={<Checkbox checked={form.gender == "female" ? true : false} onChange={() => {
                        onChange({gender: "female"})
                    }} />} label={<TCLabel>Female</TCLabel>} />
                </Grid>
                <Grid item xs={12} md={12} lg={12} sx={{mt: 1}}>
                    <Grid item xs={12} >
                        <TCInput type={"password"} showAlert={joi.password} label={"Password (minimum 6 character)"} value={form.password} onChange={(e) => {
                            onChange({password: e.currentTarget.value})
                        }}/>
                    </Grid>
                    <Grid item xs={12} >
                        <TCInput type={"password"} showAlert={joi.confirmPassword} label={"Confirm Password"} value={form.confirmPassword} onChange={(e) => {
                            onChange({confirmPassword: e.currentTarget.value})
                        }}/>
                    </Grid>
                </Grid>
                <Grid sx={{mt: 5}} item textAlign={"center"} xs={12} md={12} lg={12}>
                    <MKButton color="primary" disabled={checkValidButton()} onClick={() => {
                        submitRegisterForm()
                    }}>Register</MKButton>
                </Grid>
            </Grid>
            {
                showLoading &&
                <Loading/>
            }
        </>
    )
}

function getYearOptions () {
    let years = []
    let currentYear = new Date().getFullYear()
    let Ymin100 = currentYear - 100
    for (let i = currentYear; i > Ymin100; i --) {
        if (i < (new Date().getFullYear() - 5)) { 
            years.push({
                label: i,
                value: i
            })
        }
    }
    return years
}

function checkYearInformation (e) {
    if (e.length < 4) { return true }
    let currentYear = new Date().getFullYear()
    let Ymin100 = currentYear - 100
    if (Number(e) < Ymin100 || Number(e) >= currentYear) {
        return false
    }
    return true
}

function yearMaxMinInformation (e) {
    let currentYear = new Date().getFullYear()
    let Ymin100 = currentYear - 100
    return { min: Ymin100, max: currentYear}
}

export default Register;
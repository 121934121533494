import React, { useEffect, useState } from "react";
import { Card, Grid } from "@mui/material";
import { sessionBalance } from "service/packagesService";
import TCNavigationBar from "components/TopCourtComponents/NavigationBar/TopCourtNavigationBar";
import { TCLabel } from "components/TopCourtComponents/Label/TopCourtLabel";
import { TCTitleLabel } from "components/TopCourtComponents/Label/TopCourtLabel";
import { color } from "helper/constant";
import { courtTypeObj } from "helper/constant";
import moment from "moment";
import EmptyData from "components/TopCourtComponents/Placeholder/EmptyData";


function SessionHistory ({

}) {

    const [ loading, setLoading ] = useState(true)
    const [ sessionBalanceList, setSessionBalanceList ] = useState([])

    useEffect(() => {
        loadSessionHistory()
    }, [])

    const loadSessionHistory = async () => {
        setLoading(true)
        let { data, error } = await sessionBalance()
        if (error) { console.log(error) }
        if (data) { setSessionBalanceList(data) }
        setLoading(false)
    }

    return (
        <>
            {/* <TCNavigationBar transparent={false} title={"Session History"}/> */}
            {/* <Grid sx={{pt: 9}} justifyContent={"center"} textAlign={"center"}>
            </Grid> */}
            {(!loading && sessionBalanceList.length == 0) &&
                <EmptyData/>
            }
            <Grid container pl={2} pr={2} pt={0}>
            {
                sessionBalanceList.map( value => {
                    return (
                        <Grid item xs={12} mb={2}>
                            <SessionCard data={value}/>
                        </Grid>
                    )
                })
            }
            </Grid>
        </>
    )

}

function SessionCard ({
    data
}) {

    return (
        <Card>
            <Grid p={2}>
                <Grid container xs={12}>
                    <Grid item container xs={7} spacing={0.5}>
                        <Grid item>
                            <TCLabel bold>{data.title == "Value Pack" ? "Top Up via Value Pack" : data.title == "Voucher Redeem" ? "Top Up via Voucher" : data.title}</TCLabel>
                        </Grid>
                        <Grid item>
                            <TCLabel bold>{(data.title == "Booking Court" || data.title == "Refund") && data.bookingId}</TCLabel>
                        </Grid>
                    </Grid>
                    <Grid item xs={5} textAlign={"right"}>
                        <TCLabel bold>{moment(data.createdDate).format("DD/MM/YYYY HH:mm")}</TCLabel>
                    </Grid>
                </Grid>
                <Grid container xs={12}>
                    <Grid xs={6}>
                        {/* <TCLabel>{data.title == "Value Pack" ? "Buy Value Pack" : data.title}</TCLabel> */}
                        <TCLabel>{data.title == "Refund" || data.title == "Booking Court" || data.courtType || data.entry == "CR" ? courtTypeObj[data.courtType] : ""}</TCLabel>
                    </Grid>
                    <Grid xs={6} textAlign={"right"}><TCLabel bold style={{
                        color: data.title == "Refund" || data.title == "Value Pack" || data.entry == "CR" ? color.green : color.pink
                    }}>{data.title == "Refund" || data.title == "Value Pack" || data.entry == "CR" ? "+" : "-"}{data.value} {data.value > 1 ? "Sessions" : "Session"}</TCLabel></Grid>
                </Grid>
            </Grid>
        </Card>
    )
}

export default SessionHistory
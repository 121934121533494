// import { getUserInfo, getCourtInfo, setLocalBookingForm, getLocalBookingForm, deleteLocalBookingForm } from 'helper/localStorage';
// import { getCourtInformation, getCourtSchedule, getScheduleCart, 
//     addScheduleCart, deleteScheduleCart, submitOrder, setValuePack, getUserCartSummary } from 'service/bookingService';
// import moment from 'moment';
// import { getMe } from 'service/authService';
// import { getCouponPopup, checkCoupon } from 'service/couponService';
// import { Confirm } from 'helper/showAlert';

export const validation = {
    isEmailAddress:function(str) {
        if (!str) { return true }
        var pattern =/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        return pattern.test(str);  // returns a boolean
    },
    isWhitespaceString: function (str) {
        return !/\S/.test(str)
    },
    isNotEmpty:function (str) {
        var pattern =/\S+/;
        return pattern.test(str);  // returns a boolean
    },
    isNumber:function(str) {
        if (!str) { return true }
        var pattern = /^\d+\.?\d*$/;
        return pattern.test(str);  // returns a boolean
    },
    charaCount:function(str, count) {
        if (!str) { return true }
        return str.length <= count;  // returns a boolean
    },
    userName:function(str) {
        if (!str) { return true }
        return str.length <= 20;  // returns a boolean
    },
    clubName:function(str) {
        if (!str) { return true }
        return str.length <= 25;  // returns a boolean
    },
    className:function(str) {
        if (!str) { return true }
        return str.length <= 50;  // returns a boolean
    },
    phoneNumber:function(str) {
        if (!str) { return true }
        var pattern = /^\d+\.?\d*$/;
        return pattern.test(str) && str.length <= 13;  // returns a boolean
    },
    isSame:function(str1,str2){
        if (!str1 || !str2) { return false; }
        return str1 === str2;
    }
};   
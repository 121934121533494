import React from "react";
import EmptyData from "components/TopCourtComponents/Placeholder/EmptyData";
import { Grid } from "@mui/material";
import TCNavigationBar from "components/TopCourtComponents/NavigationBar/TopCourtNavigationBar";


function Notifications ({

}) {

    // Group the data from date
    
    return (
        <>
            <TCNavigationBar transparent={false} title={"Notifications"}/>
            <Grid>
                <EmptyData/>
            </Grid>
        </>
    )
}

export default Notifications
import React, { useCallback, useEffect, useState } from "react"

import { Checkbox, FormControlLabel, Grid, Icon, Input } from "@mui/material"
import { TCSelect } from "components/TopCourtComponents/Input/TopCourtInput"
import { TCLabel } from "components/TopCourtComponents/Label/TopCourtLabel"
import { serviceStatus } from "helper/constant"

import { ProductComponent, ProductDetail } from "./ProductComponent"
import { getUserInfo } from "helper/localStorage"
import Loading from "components/TopCourtComponents/Loading/TCLoadingComponent"
import SlideDialog from "components/SlideDialog/SlideDialog"
import EmptyData from "components/TopCourtComponents/Placeholder/EmptyData"
import { getUserAddOnService, getUserAddOnServiceUser } from "service/addOnService"
import { Confirm } from "helper/showAlert"
import { getUserServiceDetail } from "service/addOnService"
import SlideAlert from "components/SlideInAlert/SlideAlert"
import Login from "pages/Auth/Login"
import { BottomSheet } from "react-spring-bottom-sheet"
import { TCInput } from "components/TopCourtComponents/Input/TopCourtInput"
import MKButton from "components/MKButton"

import sliders from "../../assets/settings-sliders.svg";
import { getUserAvatar } from "helper/user"
import TCAddOnDrawer from "components/TopCourtComponents/Drawer/TCAddOnDrawer"
import { color } from "helper/constant"
import { currencyFormat } from "helper/numberFormat"
import { loadCart, submitItemOrderv2 } from "./Function"
import DrawerTitle from "./AddOnComponent/DrawerTitle"
import ItemList from "./AddOnComponent/ItemList"
import { useNavigate } from "react-router-dom"
import ServiceSummaryCalendar from "./AddOnComponent/ServiceSummaryCalendar"

function ServiceAddOns ({
    setShowAlert
}) {

    const userInfo = getUserInfo ? JSON.parse(getUserInfo) : null
    const navigate = useNavigate()

    const [ filter, setFilter ] = useState({
        name: '',
        status: serviceStatus[0],
        reset: false
    })

    const [ showLogin, setShowLogin ] = useState(false)
    const [ services, setServices ] = useState([])
    const [ showLoading, setShowLoading ] = useState(true)
    const [ showDetail, setShowDetail ] = useState({
        open: false,
        productId: null
    })
    const [ showBottomSheet, setShowBottomSheet ] = useState(false)
    const [ showCalendar, setShowCalendar ] = useState({
        open: false,
    })

    const [ cart, setCart ] = useState([])
    const [ bottomSheetHeight, setBottomSheetHeight] = useState(null)
    const [ rerender, setRerender ] = useState(Date.now)
    const [ showDetailAddOn, setShowDetailAddOn] = useState(false)
    const [ loading, setLoading ] = useState(false)

    const [ selectedCalendarDate, setSelectedCalendarDate ] = useState(null)

    useEffect(() => {
        loadServices()
    }, [filter.reset])

    useEffect(() => {
        loadCart(setCart)
    }, [])

    useEffect(() => {
        console.log('here check cart', cart)
    }, [cart])

    const loadServices = async () => {
        setShowLoading(true)
        if (userInfo) {
            let { data, error } = await getUserAddOnServiceUser({
                ...filter,
                status: filter.status ? filter.status.label : ''
            }, userInfo._id)
            if (error) { Confirm("", error) }
            if (data) { setServices(data) }
            setShowLoading(false)
        } else {
            let { data, error } = await getUserAddOnService({
                ...filter,
                status: filter.status ? filter.status.label : ''
            })
            if (error) { Confirm("", error) }
            if (data) { setServices(data) }
            setShowLoading(false)   
        }
    }

    const bottomSheetRef = useCallback(n => {
        if (n !== null) {
            setBottomSheetHeight(n.getBoundingClientRect().height)
        }
    })

    const countSummary = () => {
        let totalSession = 0
        let totalPrice = 0
        if (cart.length >= 0) {
            for (let i = 0; i < cart.length; i ++) {
                let cartSession = cart[i].sessions
                for (let j = 0; j < cartSession.length; j ++) {
                    totalSession += cartSession[j].hours.length
                    totalPrice += (cart[i].productId.price * cartSession[j].hours.length)
                }
            }
        }
        return { totalSession, totalPrice }
    }

    let ratio = bottomSheetHeight / window.innerHeight
    let height = (bottomSheetHeight * ratio)
    let newHeight = (bottomSheetHeight - height)

    return (
        <>
            <Grid container>
                {/* <TCSelect label={"Service Type"} options={serviceStatus} value={filter.status} onChange={(e) => {
                    setFilter(prev => ({
                        ...prev,
                        status: e
                    }))
                }} /> */}
                <Grid xs={6}>
                    <Icon onClick={() => { 
                            setSelectedCalendarDate(null)
                            setShowCalendar({ open: true }) 
                        }} 
                        fontSize="medium"
                        sx={{color: color.primary, cursor: 'pointer', height: 22}}>eventicon</Icon>
                </Grid>
                <Grid xs={6} textAlign={"right"}>
                    <img onClick={() => { setShowBottomSheet(true) }} style={{cursor: 'pointer'}} width={24} height={22} src={sliders}/>
                </Grid>
            </Grid>
            {
                showLoading &&
                <Loading/>
            }
            {
                !showLoading && services.length <= 0 && <EmptyData />
            }
            {
                services.length > 0 && 
                <div>
                    <Grid container mt={1} pb={16} spacing={2} style={{
                        marginBottom: newHeight
                    }}>
                        {
                            services.map( value => {
                                return (
                                    <Grid item xs={6} lg={6} 
                                    mb={2}
                                    sx={{
                                        paddingTop: '0px !important'
                                    }}
                                    onClick={() => {
                                        if(!userInfo) {
                                            setShowAlert(true)
                                            return;
                                        }
                                        setShowDetail(prev => ({
                                            ...prev,
                                            open: true,
                                            productId: value._id
                                        }))
                                    }}>
                                        <ProductComponent type={"service"} data={value}/>
                                    </Grid>
                                )
                            })
                        }
                        <TCAddOnDrawer
                            window={window}
                            isOpen={showDetailAddOn}
                            reRender={rerender}
                            titleText={"Add-On Summary"}
                            title={
                                <DrawerTitle setShowDetailAddOn={setShowDetailAddOn}
                                 setRerender={setRerender} countSummary={countSummary} />
                            }
                            footer={
                                <div ref={bottomSheetRef}>
                                    <Grid container mt={1} justifyContent={"flex-end"} 
                                        style={{paddingLeft: 16, paddingRight: 16}}>
                                        <Grid xs={6} md={6} lg={6} item style={{textAlign: 'left'}}>
                                            <TCLabel>Total {countSummary().totalSession} {countSummary().totalSession > 1 ? "Sessions" : "Session"}</TCLabel>
                                        </Grid>
                                        <Grid xs={6} md={6} lg={6} item style={{textAlign: 'right'}}>
                                            <TCLabel style={{fontWeight: 'bold'}}>Total <span style={{fontWeight: 'bold'}}>{currencyFormat(countSummary().totalPrice)}</span></TCLabel>
                                        </Grid>
                                    </Grid>
                                    <Grid container mt={1} mb={2} justifyContent={"flex-end"} 
                                        style={{paddingLeft: 16, paddingRight: 16}}>
                                        <Grid xs={12} md={12} lg={12} item style={{textAlign: 'center'}}>
                                            <MKButton color="primary" 
                                                disabled={loading || countSummary().totalSession == 0}
                                                fullWidth onClick={() => {
                                                    setLoading(true)
                                                    submitItemOrderv2({
                                                        type: "service"
                                                    }, () => { loadCart(setCart) }, setLoading, navigate)
                                                }}>
                                                Pay
                                            </MKButton>
                                        </Grid>
                                    </Grid>
                                </div>
                            }
                        >
                            {/* Looping Cart */}
                            <ItemList items={cart} bottomSheetHeight={bottomSheetHeight} loadData={() => {
                                loadCart(setCart)
                            }}/>
                        </TCAddOnDrawer>
                    </Grid>
                </div>
            }
            <SlideDialog 
                isOpen={showDetail.open || showCalendar.open} 
                setIsOpen={(e) => {
                    if (showDetail.open) {
                        setShowDetail(prev => ({
                            ...prev,
                            open: e,
                            productId: null
                        }))
                    }
                    if (showCalendar.open) {
                        setShowCalendar(prev => ({
                            ...prev,
                            open: false
                        }))
                    }
                }}
                maxHeight={"90vh"}
                maxWidth={"lg"}
            >
                {showDetail.open && 
                    <ProductDetail onClose={() => {
                        setShowDetail(prev => ({
                            ...prev,
                            open: false,
                            productId: null,
                            fromCalendar: false
                        }))
                        if (showDetail.fromCalendar) {
                            setShowCalendar({open: true})
                        }
                        // setShowDetail(prev => ({
                        //     ...prev,
                        //     open: false,
                        //     productId: null
                        // }))
                    }} productId={showDetail.productId} loadData={loadItemDetail}
                        loadCart={() => {
                            loadCart(setCart)
                        }}
                    />
                }
                {
                    showCalendar.open &&
                    <ServiceSummaryCalendar selectService={(e) => {
                        if(!userInfo) {
                            setShowAlert(true)
                            return;
                        }
                        setShowDetail(prev => ({
                            ...prev,
                            open: true,
                            productId: e,
                            fromCalendar: true
                        }))
                    }} 
                    selectedCalendarDate={selectedCalendarDate}
                    setSelectedCalendarDate={(e) => {
                        setSelectedCalendarDate(e)
                    }}
                    setOpen={(e) => {
                        setShowCalendar(prev => ({
                            ...prev,
                            open: e
                        }))
                    }} />
                }
            </SlideDialog>
            <BottomSheet 
                open={showBottomSheet}
                onDismiss={() => {setShowBottomSheet(false)}}
            >
                <Grid container p={2}>
                    {/* <TCInput label="Product Name" value={filter.name} onChange={(e) => {
                        if (e.currentTarget.value) { 
                            setFilter((prev) => ({
                                ...prev,
                                name: e.currentTarget.value
                            }))
                        }
                    }}/> */}
                    <Grid item container pt={1}>
                        <Grid xs={12} mb={0}>
                            <TCLabel>Type</TCLabel>
                        </Grid>
                        <Input sx={{display: 'none'}}/>
                        {
                            serviceStatus.map( value => {
                                return (
                                    <Grid item>
                                        <FormControlLabel control={<Checkbox checked={filter.status.value == value.value ? true : false} onChange={(e) => {
                                            console.log(value)
                                            setFilter(prev => ({
                                                ...prev,
                                                status: value
                                            }))
                                        }} />} label={<TCLabel>{value.label}</TCLabel>} />
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                    <Grid container pt={2}>
                        <Grid item xs={6}>
                            <MKButton fullWidth
                                onClick={() => {
                                    setFilter({
                                        name: '',
                                        status: serviceStatus[0],
                                        reset: !filter.reset
                                    })
                                    setShowBottomSheet(false)
                                }}
                            >Reset</MKButton>
                        </Grid>
                        <Grid item xs={6}>
                            <MKButton fullWidth color="primary"
                                onClick={() => {
                                    setShowBottomSheet(false);
                                    loadServices()
                                }}
                            >Filter</MKButton>
                        </Grid>
                    </Grid>
                </Grid>
            </BottomSheet>
        </>
    )
}

async function loadItemDetail (id) {
    let { data, error } = await getUserServiceDetail(id)
    // check first if there is any sessions
    if (error) { return { error } }
    if (data) {
        return {
            data: { 
                ...data.item,
                itemImages: data.item.itemImages.length > 0 ? data.item.itemImages : [getUserAvatar(data.item.user)],
            },
            userSessions: data.userSessions
        }
    }
}

export default ServiceAddOns
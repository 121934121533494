import { client, errorValidation } from "./service";

export const getTournament = async function () {
  try {
    let response = await client.get('/tournaments');
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data };
  } catch (e) {
    // return { error: e.response.data.errorCode };
    return errorValidation(e)
  }
}

export const createTournament = async function (body) {
  try {
    let response = await client.post('/tournament/create', {
      ...body
    });
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data };
  } catch (e) {
    // return { error: e.response.data.errorCode };
    return errorValidation(e)
  }
}
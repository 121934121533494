import { client, errorValidation } from "./service";

export const getClasses = async function (userId, filter30Days, classType) {
  try {
    let response = await client.get(`/class/list?userId=${userId || ""}&filter30Days=${filter30Days}&classType=${classType}`);
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data };
  } catch (e) {
    // return { error: e.response.data.errorCode }
    return errorValidation(e)
  }
}

export const getClassDetail = async function (userId, classRef, selectedDate = null) {
  try {
    let response = await client.get(`/class/detail?userId=${userId || ""}&classRef=${classRef || ""}&selectedDate=${selectedDate || ''}`);
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data };
  } catch (e) {
    // return { error: e.response.data.errorCode }
    return errorValidation(e)
  }
}

export const createClass = async function (body) {
  try {
    let response = await client.post(`/class/create`, {
      ...body
    });
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data };
  } catch (error) {
    return errorValidation(error)
  }
}

export const checkBooking = async function () {
  try {
      let response = await client.get(`class/check-booking`);
      if (response.data.statusCode !== 200) { throw response.data.data; }
      let data = response.data.data;
      return { data };
  } catch (e) {
      return errorValidation(e)
  }
}

export const applyClassService = async function (body) {
  try {
      let response = await client.post(`class/apply`, {
        ...body
      });
      if (response.data.statusCode !== 200) { throw response.data.data; }
      let data = response.data.data;
      return { data };
  } catch (e) {
      return errorValidation(e)
  }
}

export const cancelApplyClassService = async function (body) {
  try {
      let response = await client.post(`class/cancel`, {
        ...body
      });
      if (response.data.statusCode !== 200) { throw response.data.data; }
      let data = response.data.data;
      return { data };
  } catch (e) {
      return errorValidation(e)
  }
}

export const deleteClassService = async function (body) {
  try {
    let response = await client.post(`class/delete`, {
      ...body
    });
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data };
  } catch (e) {
      return errorValidation(e)
  }
}

export const getClassCart = async function (body) {
  try {
    let response = await client.get(`class/cart?classId=${body.classId}`);
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data };
  } catch (e) {
    return errorValidation(e)
  }
}

export const addClassToCart = async function (body) {
  try {
    let response = await client.post(`class/add-to-cart`, {
      ...body
    });
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data };
  } catch (e) {
    return errorValidation(e)
  }
}

export const deleteClassCart = async function (body) {
  try {
    let response = await client.post(`class/delete-cart`, {
      ...body
    });
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data };
  } catch (e) {
    return errorValidation(e)
  }
}

export const submitOrderClass = async function (body) {
  try {
    let response = await client.post(`class/submit-order`, {
      ...body
    });
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data };
  } catch (e) {
      return errorValidation(e)
  }
}
import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';

import { Grid, Icon, Button, Badge, Box } from "@mui/material";
import SlideAlert from "components/SlideInAlert/SlideAlert";
import { color } from "helper/constant";
import { getUserInfo } from "helper/localStorage";
import MKAvatar from "components/MKAvatar";
import tennis from "../../assets/logo/tennis.png";

import { getMe } from "service/authService";

import { TCLabel } from "components/TopCourtComponents/Label/TopCourtLabel";
import { setUserInfo, deleteUserInfo } from "helper/localStorage";
import Login from "pages/Auth/Login";
import TCNavigationBar from "components/TopCourtComponents/NavigationBar/TopCourtNavigationBar";
import { getUserAvatar } from "helper/user";
import Loading from "components/TopCourtComponents/Loading/TCLoadingComponent";
import MKButton from "components/MKButton";
import { Confirmation } from "helper/showAlert";

import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import { TCTitleLabel } from "components/TopCourtComponents/Label/TopCourtLabel";

import barcode1D from "../../assets/barcode1D.png";
import Barcode from 'react-barcode';
import Countdown from "react-countdown";
import { Confirm } from "helper/showAlert";
import { generateBarcode } from "service/playerService";

function Profile ({

}) {

    const timer = 180000

    const navigate = useNavigate();
    const [ showAlert, setShowAlert ] = useState(false)
    const [ activeTab, setActiveTab ] = useState(0)
    const [ loading, setLoading ] = useState(false)
    const [ showBarcode, setShowBarcode ] = useState(false)
    const [ barcode, setBarcode ] = useState(false)
    const userInfo = getUserInfo ? JSON.parse(getUserInfo) : null

    const [ userDetail, setUserDetail ] = useState(userInfo);
    const [ k, setKey ] = useState(false)

    useEffect(() => {
        loadMe()
        if (!userInfo) {
            window.location.replace("/")
        }
    }, [])

    useEffect(() => {
        setKey(!k)
    }, [barcode])

    useEffect(() => { }, [userDetail])

    const loadMe = async () => {
        let { data, error } = await getMe()
        if (error) { console.log(error) }
        if (data) {  setUserDetail(data); setUserInfo(data); }
    }

    const loadBarcode = async () => {
        setShowBarcode(true)
        let { data, error } = await generateBarcode()
        if (error) { Confirm("", error); setShowBarcode(false) }
        setBarcode(data)
    }

    return (
        <>
            <TCNavigationBar light={false} transparent={false} fixed title={"Profile"}/>
            <Grid p={2} xs={12} container flexBasis={'100vh'} direction={"column"}>
                <Grid item container xs={12} p={2} mb={2} sx={{
                    border: `0.5px solid ${color.blueIndoor_2}`,
                    borderRadius: 3
                }}>
                    <Grid xs={2} item>
                        <MKAvatar
                            src={userDetail ? getUserAvatar(userDetail) : tennis} size="md"/>
                    </Grid>
                    <Grid xs={8} item>
                        <TCLabel bold style={{fontSize: 14}}>
                            {userDetail ? userDetail.name : '-'}
                        </TCLabel>
                        <TCLabel style={{fontSize: 13}}>
                            {userDetail ? userDetail.phoneNumber : '-'}
                        </TCLabel>
                    </Grid>
                    <Grid xs={2} item pt={1.5} textAlign={"right"}
                        onClick={() => {
                            navigate('/profile-detail')
                        }}
                    >
                        <TCLabel style={{fontSize: 13}} bold>
                            Edit
                        </TCLabel>
                    </Grid>
                </Grid>
                <Grid item container xs={12} p={1} mb={2} sx={{
                    border: `0.5px solid ${color.blueIndoor_2}`,
                    borderRadius: 3
                }} justifyContent={"center"}
                    onClick={() => {
                        loadBarcode()
                    }}
                >
                    <Grid pb={0} item container justifyContent={"center"} textAlign={"center"} spacing={1}>
                        <Grid item>
                            <img src={barcode1D} width={30} height={20} style={{marginTop: 10}}/>
                        </Grid>
                        <Grid item>    
                            <TCLabel bold style={{paddingTop: 10}}>
                                User Code
                            </TCLabel>
                        </Grid>
                    </Grid>
                </Grid>
                {
                    (userInfo && (userInfo.ballBoyStatus === true || userInfo.rallyPartnerStatus === true
                        || userInfo.developmentCoachStatus === true || userInfo.leadCoachStatus === true ||
                        userInfo.seniorCoachStatus === true || userInfo.headCoachStatus === true
                    )) &&
                    <>
                        <Grid>
                            <TCTitleLabel>Schedule</TCTitleLabel>
                        </Grid>
                        <Grid item container xs={12} p={2} pt={1} pl={0} pb={2} mb={1} sx={{
                            // border: `0.5px solid ${color.blueIndoor_2}`,
                            borderBottom: `0.5px solid ${color.grey}`
                            // borderRadius: 3
                        }} alignItems={"center"}>
                            <Grid item container xs={6}>
                                {/* <WhatsAppIcon style={{color: color.blueIndoor_1, marginRight: 2}}/> */}
                                <Icon style={{color: color.blueIndoor_1, marginRight: 2}}>schedule</Icon>
                                <TCLabel>Set my schedule</TCLabel>
                            </Grid>
                            <Grid item container xs justifyContent={"flex-end"} textAlign={"center"}>
                                <Grid xs={6}></Grid>
                                <Grid xs={6}>
                                    <Box style={{
                                        marginLeft: 8, marginRight: 2,
                                        borderRadius: 6,
                                        padding: 6,
                                        backgroundColor: '#0198E1',
                                        width: 100
                                    }} onClick={() => {
                                        navigate('/my-schedule')
                                    }}>
                                        <TCLabel style={{color: 'white'}}>Set</TCLabel>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                }
                <Grid>
                    <TCTitleLabel>Contact Us</TCTitleLabel>
                </Grid>
                <Grid item container xs={12} p={2} pt={1} pl={0} pb={2} sx={{
                    // border: `0.5px solid ${color.blueIndoor_2}`,
                    borderBottom: `0.5px solid ${color.grey}`
                    // borderRadius: 3
                }} alignItems={"center"}>
                    <Grid item container xs={6}>
                        <WhatsAppIcon style={{color: color.blueIndoor_1, marginRight: 2}}/>
                        <TCLabel>WhatsApp</TCLabel>
                    </Grid>
                    <Grid item container xs justifyContent={"flex-end"} textAlign={"center"}>
                        <Grid xs={6}></Grid>
                        <Grid xs={6}>
                            <Box style={{
                                marginLeft: 8, marginRight: 2,
                                borderRadius: 6,
                                padding: 6,
                                backgroundColor: '#0198E1',
                                width: 100
                            }} onClick={() => {
                                window.open('https://wa.me/628116561133', '_blank')
                            }}>
                                <TCLabel style={{color: 'white'}}>Connect</TCLabel>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container xs={12} p={2} pl={0} mb={2} pb={2} sx={{
                    // border: `0.5px solid ${color.blueIndoor_2}`,
                    // borderRadius: 3
                    borderBottom: `0.5px solid ${color.grey}`
                }} alignItems={"center"}>
                    <Grid item container xs={6}>
                        <InstagramIcon style={{color: color.blueIndoor_1, marginRight: 2}}/>
                        <TCLabel>Instagram</TCLabel>
                    </Grid>
                    <Grid item container xs justifyContent={"flex-end"} textAlign={"center"}>
                        <Grid xs={6}></Grid>
                        <Grid xs={6}>
                            <Box style={{
                                marginLeft: 8, marginRight: 2,
                                borderRadius: 6,
                                padding: 6,
                                backgroundColor: '#0198E1',
                                width: 100
                            }} onClick={() => {
                                window.location.href = "https://www.instagram.com/sempurnatenniscenter"
                            }}>
                                <TCLabel style={{color: 'white'}}>Connect</TCLabel>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <div className="sticky-bottom">
                <Grid p={2} mb={2} item textAlign={"center"} xs={12}>
                    <MKButton fullWidth={true} color={"pink"}
                        onClick={() => {
                            Confirmation("", "Are you sure want to logout ?", () => {}, () => { 
                                deleteUserInfo();
                                window.location.href = "/";
                            })
                        }}>Logout</MKButton>
                </Grid>
            </div>
            <SlideAlert
                isOpen={showAlert || showBarcode} 
                setIsOpen={(e) => {
                    if (showAlert) setShowAlert(e)
                    if (showBarcode) setShowBarcode(e)
                }}
                maxWidth={"xs"}
                // disableBackdrop={true}
            >
                { showAlert && <Login isOpen={showAlert} dismissPopup={() => setShowAlert(false)}/> }
                { showBarcode && 
                    <Grid xs={12} textAlign={"center"}>
                        <TCLabel bold>Scan barcode at counter</TCLabel>
                        <Barcode 
                            value={barcode}
                            displayValue={false}
                        /> 
                        {/* <TCLabel>Barcode only valid for 3 minutes</TCLabel> */}
                        <TCLabel bold>
                            <Countdown
                                key={k}
                                daysInHours={true}
                                zeroPadDays={0}
                                zeroPadTime={2} 
                                date={Date.now() + timer}
                                renderer={({ formatted: { hours, minutes, seconds } }) => {
                                    return (
                                        <>
                                            {minutes}:{seconds}
                                        </>
                                    );
                                }}
                                onComplete={() => {
                                    loadBarcode()
                                }}
                            />
                        </TCLabel>
                    </Grid>
                }
            </SlideAlert>
            { 
                loading && 
                <Loading/>
            }
        </>
    )
}

export default Profile;
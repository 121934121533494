import React, { useEffect, useState, useCallback } from "react"
import EmptyData from "components/TopCourtComponents/Placeholder/EmptyData"
import { getItems } from "service/addOnService"
import { Confirm } from "helper/showAlert"
import Loading from "components/TopCourtComponents/Loading/TCLoadingComponent"
import { ProductComponent, ProductDetail } from "./ProductComponent"
import { Box, Grid } from "@mui/material"
import AlertInfo from "components/TopCourtComponents/AlertInfo/AlertInfo"
import SlideDialog from "components/SlideDialog/SlideDialog"
import MKButton from "components/MKButton"
import { getItemSellDetail } from "service/addOnService"
import { TCLabel } from "components/TopCourtComponents/Label/TopCourtLabel"

import TCAddOnDrawer from "components/TopCourtComponents/Drawer/TCAddOnDrawer"
import { loadSellItemCart, submitItemOrderv2 } from "./Function"
import { currencyFormat } from "helper/numberFormat"
import ItemList from "./AddOnComponent/ItemList"
import { useNavigate } from "react-router-dom"
import SellDrawerTitle from "./AddOnComponent/SellDrawerTitle"
import SellItemList from "./AddOnComponent/SellItemList"
import { getUserInfo } from "helper/localStorage"


function Buy ({
    setShowAlert
}) {

    const userInfo = getUserInfo ? JSON.parse(getUserInfo) : null

    const [ products, setProducts ] = useState([])
    const [ showDetail, setShowDetail ] = useState({
        open: false
    })
    const [ showLoading, setShowLoading ] = useState(true)

    const [ cart, setCart ] = useState([])
    const [ showBottomSheet, setShowBottomSheet ] = useState(false)
    const [ bottomSheetHeight, setBottomSheetHeight] = useState(null)
    const [ rerender, setRerender ] = useState(Date.now)
    const [ showDetailAddOn, setShowDetailAddOn] = useState(false)

    const [ loading, setLoading ] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        loadItems()
    }, [])

    useEffect(() => {
        loadSellItemCart(setCart)
    }, [])

    useEffect(() => {
        console.log(cart)
    }, [cart])

    const loadItems = async () => {
        setShowLoading(true)
        let { data, error } = await getItems({}, 'sell');
        if (error) { Confirm("", error) }
        if (data) { setProducts(data) }
        setShowLoading(false)
    }

    const bottomSheetRef = useCallback(n => {
        if (n !== null) {
            setBottomSheetHeight(n.getBoundingClientRect().height)
        }
    })

    let ratio = bottomSheetHeight / window.innerHeight
    let height = (bottomSheetHeight * ratio)
    let newHeight = (bottomSheetHeight - height)

    const countSummary = () => {
        let totalSession = 0
        let totalPrice = 0
        console.log('here check buy cart', cart)
        if (cart.length >= 0) {
            for (let i = 0; i < cart.length; i ++) {
                totalSession += cart[i].qty
                totalPrice += (cart[i].productId.price * cart[i].qty)
            }
        }
        return { totalSession, totalPrice }
    }

    return (
        <>
        {
            !showLoading && products.length <= 0 && <EmptyData />
        }
        {
            products.length > 0 && 
            <div>
                {/* <Grid item xs={12} pb={1}>
                    <AlertInfo label={"Place your order at counter"}/>
                </Grid> */}
                <Grid container mt={1} pb={16} spacing={2} style={{
                    marginBottom: newHeight
                }}>
                    {
                        products.map( value => {
                            return (
                                <Grid item xs={6} lg={6} onClick={() => {
                                    if(!userInfo) {
                                        setShowAlert(true)
                                        return;
                                    }
                                    setShowDetail(prev => ({
                                        ...prev,
                                        open: true,
                                        productId: value._id
                                    }))
                                }}>
                                    <ProductComponent type={"sell"} data={value} disabled={value.stock <= 0 ? true : false}/>
                                </Grid>
                            )
                        })
                    }
                    <TCAddOnDrawer
                        window={window}
                        isOpen={showDetailAddOn}
                        reRender={rerender}
                        titleText={"Add-On Summary"}
                        title={
                            <SellDrawerTitle setShowDetailAddOn={setShowDetailAddOn}
                                setRerender={setRerender} countSummary={countSummary} />
                        }
                        footer={
                            <div ref={bottomSheetRef}>
                                <Grid container mt={1} justifyContent={"flex-end"} 
                                    style={{paddingLeft: 16, paddingRight: 16}}>
                                    <Grid xs={6} md={6} lg={6} item style={{textAlign: 'left'}}>
                                        {/* <TCLabel>Total {countSummary().totalSession} {countSummary().totalSession > 1 ? "Sessions" : "Session"}</TCLabel> */}
                                    </Grid>
                                    <Grid xs={6} md={6} lg={6} item style={{textAlign: 'right'}}>
                                        <TCLabel style={{fontWeight: 'bold'}}>Total <span style={{fontWeight: 'bold'}}>{currencyFormat(countSummary().totalPrice)}</span></TCLabel>
                                    </Grid>
                                </Grid>
                                <Grid container mt={1} mb={2} justifyContent={"flex-end"} 
                                    style={{paddingLeft: 16, paddingRight: 16}}>
                                    <Grid xs={12} md={12} lg={12} item style={{textAlign: 'center'}}>
                                        <MKButton color="primary" 
                                            disabled={loading || countSummary().totalPrice == 0}
                                            fullWidth onClick={() => {
                                                setLoading(true)
                                                submitItemOrderv2({
                                                    type: "sell",
                                                }, () => { loadSellItemCart(setCart) }, setLoading, navigate)
                                            }}>
                                            Pay
                                        </MKButton>
                                    </Grid>
                                </Grid>
                            </div>
                        }
                    >
                        {/* Looping Cart */}
                        <SellItemList items={cart} bottomSheetHeight={bottomSheetHeight} loadData={() => {
                            loadSellItemCart(setCart)}}/>
                        {/* <ItemList items={cart} bottomSheetHeight={bottomSheetHeight} loadData={() => {
                            loadSellItemCart(setCart)
                        }}/> */}
                    </TCAddOnDrawer>
                </Grid>
            </div>
        }
        <SlideDialog 
            isOpen={showDetail.open} 
            setIsOpen={(e) => {
                if (showDetail) setShowDetail(prev => ({
                    ...prev,
                    open: e,
                    productId: null
                }))
            }}
            maxWidth={"lg"}
        >
            {showDetail.open && 
                <ProductDetail onClose={() => {
                    setShowDetail(prev => ({
                        ...prev,
                        open: false,
                        productId: null
                    }))
                }} productId={showDetail.productId} loadData={loadItemDetail}
                    loadCart={() => {
                        loadSellItemCart(setCart)
                    }}
                />
            }
        </SlideDialog>
        {
            showLoading &&
            <Loading/>
        }
        </>
    )
}

async function loadItemDetail (id, qty) {
    let { data, error } = await getItemSellDetail(id, qty)
    // check first if there is any sessions
    if (error) { return { error } }
    if (data) {
        console.log(data)
        return {
            data: data.item,
            userSessions: data.userSessions
        }
    }
}

export default Buy
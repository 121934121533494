import * as React from 'react';
import PropTypes from 'prop-types';
import { Global } from '@emotion/react';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { grey } from '@mui/material/colors';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { Grid } from '@mui/material';
import { TCLabel, TCTitleLabel } from '../Label/TopCourtLabel';
import { iOS } from 'helper/constant';

const drawerBleeding = 140;

const Root = styled('div')(({ theme }) => ({
  height: '100%',
  backgroundColor:
    theme.palette.mode === 'light' ? grey[100] : theme.palette.background.default,
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],
}));

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
  borderRadius: 3,
  position: 'absolute',
  top: 8,
  left: 'calc(50% - 15px)',
}));

function TCDrawer(props) {

  const { window, children, title, titleText, footer, isOpen = false, reRender = Date.now, height = '70%' } = props;
  const [ open, setOpen ] = React.useState(false);
  const [ changeOrientation, setChangeOrientation ] = React.useState(Date.now);

  React.useEffect(() => {
    setOpen(isOpen);
  }, [reRender])

  React.useEffect(() => {
    const debouncedResizeHandler = debounce(() => {
        console.log('***** debounced resize'); // See the cool difference in console
        // changeResolution() 
        setChangeOrientation(Date.now)
      }, 100); // 100ms
    window.addEventListener('resize', debouncedResizeHandler)
    return () =>  window.removeEventListener('resize', debouncedResizeHandler)
  }, [])

  const toggleDrawer = (newOpen) => {
    setOpen(newOpen);
  };

  // This is used only for the example
  // const container = window !== undefined ? () => window().document.body : undefined;
  const container = undefined;

  return (
    <Root>
      <CssBaseline />
      <Global
        styles={{
          '.MuiDrawer-root > .MuiPaper-root': {
            // height: `calc(90% - ${drawerBleeding}px)`,
            height: '97%',
            marginLeft: 'auto',
            marginRight: 'auto',
            maxWidth: '444px',
            overflow: 'visible',
          },
        }}
      />
      <SwipeableDrawer
        container={container}
        anchor="bottom"
        open={open}
        onClose={() => { toggleDrawer(false) } }
        onOpen={() => { toggleDrawer(true) } }
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        allowSwipeInChildren={true}
        ModalProps={{ keepMounted: true }}
        sx={{
          overflow:'auto'
        }}
      >
        <StyledBox
          sx={{
            position: 'relative',
            pb: 1,
            top: open ? -10 : -drawerBleeding,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            visibility: 'visible',
            right: 0,
            left: 0,
            zIndex: 9999
          }}
        >
          <Puller />
          {/* <Typography sx={{ p: 2, color: 'text.secondary' }}>51 results</Typography> */}
          {(!open) && <Grid container sx={{ mt: 2 }} justifyContent={"center"} 
              onClick={() => { toggleDrawer(true)}}
              style={{paddingLeft: 16, paddingRight: 16}}>
              <TCLabel>Show Booking Detail</TCLabel>
          </Grid>}
          {(title && !open) && React.cloneElement(title)}
          {
            open && 
              <Grid sx={{ p:2 }} textAlign={'center'} onClick={() => { toggleDrawer(false)}}>
                  <TCTitleLabel>{titleText}</TCTitleLabel>
              </Grid>
          }
        </StyledBox>
        <StyledBox
          sx={{
            px: 2,
            pb: 2,
            pt: 0,
            pl: 0, pr: 0,
            position: 'relative',
            top: 0,
            height: height,
            overflow: 'auto',
          }}
        >
          { children && React.cloneElement(children) }
          {/* <Skeleton variant="rectangular" height="100%" /> */}
        </StyledBox>
        <StyledBox
          sx={{
            position: iOS() ? '-webkit-sticky' : 'fixed',
            bottom: 0,
            right: 'auto',
            left: 'auto',
            minWidth: window.innerWidth >= 444 ? '444px' : '100%'
          }}
        >
          { footer && React.cloneElement(footer) }
          {/* <Skeleton variant="rectangular" height="100%" /> */}
        </StyledBox>
      </SwipeableDrawer>
    </Root>
  );
}

TCDrawer.propTypes = {
  window: PropTypes.func,
};

function debounce(fn, ms) {
  let timer;
  return _ => {
    clearTimeout(timer);
    timer = setTimeout(_ => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}

export default TCDrawer;